import React, { FC } from 'react';
import { TableBody } from '@mui/material';
// Store
import { IPlanSales } from 'store/dashboard/dashboardTypes';
// Components
import SalesTableRow from '../SalesTableRow';

interface SalesTableBodyProps {
  typeTable: string;
  planSales: IPlanSales[];
  pipeline_id: string;
}

const SalesTableBody: FC<SalesTableBodyProps> = ({ typeTable, planSales, pipeline_id }) => (
  <TableBody>
    {planSales?.map((plan) => (
      <SalesTableRow
        plan={plan}
        key={plan?.product_id}
        typeTable={typeTable}
        pipeline_id={pipeline_id}
      />
    ))}
  </TableBody>
);

export default SalesTableBody;
