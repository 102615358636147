import { IPipeline, IAdditionalLosseResponse } from 'store/dashboard/dashboardTypes';
import { calcTotalAdminEmployees } from "./share-calculation";

const calcTotalAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

export const calcSummary = (pipeline: IPipeline, tax: number, residence: string) => {
  // Доходы
  const caclProductMoney = +pipeline?.income?.toFixed(1)

  // Зарплата админа
  const salaryAdmin = calcTotalAdminEmployees(pipeline?.employees, residence) || 0;

  // Податкова ставка  =Доход*(Податкова ставка+0,015)
  const calcTaxes = +pipeline?.income * +((tax / 100))

  const sumTatalAdditianal = calcTotalAdditionalLosses(pipeline?.additional_losses?.expenses)

  // Постійні витрати
  const fixedCosts = +(+sumTatalAdditianal)?.toFixed(1) || 0;

  // Материалы упаковка
  const materials = pipeline?.expenseGroups?.Materials?.loss || 0;
  const packaging = pipeline?.expenseGroups?.Packaging?.loss || 0;
  const caclCosts = materials + packaging;
  const totalLabor = +pipeline?.expenseGroups?.labor?.loss || 0;

  // Зарплата команды
  const salary = +(+totalLabor + salaryAdmin)?.toFixed(0);

  // Податки
  const costs = +(caclProductMoney - fixedCosts - caclCosts - salary - calcTaxes)?.toFixed(1);

  return [
    {
      kye: 1,
      name: 'Дохід на місяць',
      money: Math.round(caclProductMoney),
      isRed: false,
    },
    {
      kye: 2,
      name: 'Накладні витрати на місяць',
      money: Math.round(fixedCosts),
      isRed: true,
    },
    {
      kye: 3,
      name: 'Матеріальні витрати на місяць',
      money: Math.round(caclCosts),
      isRed: true,
    },
    {
      kye: 4,
      name: 'Загальна заробітна плата, що виплачується на місяць',
      money: Math.round(salary),
      isRed: true,
    },
    {
      kye: 5,
      name: 'Податки і інші платежі в бюджет на бізнес',
      money: Math.round(calcTaxes),
      isRed: true,
    },
    {
      kye: 6,
      name: 'Чистий прибуток на місяць',
      money: Math.round(costs),
      isRed: false,
    },
  ]
}
