import React from 'react';
// Icons
import { Logo } from 'assets/logos';
// Styles
import {
  Container,
  Title,
  SubTitle,
  Email,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface SuccessfullySentProps {
  email: string;
}

const SuccessfullySent: React.FC<SuccessfullySentProps> = ({ email }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Logo />

      <Title variant="h1">
        {t('Реєстрація пройшла успішно')}!
      </Title>

      <SubTitle variant="h3">
        {t('Будь ласка, перейдіть на вашу пошту для активації користувача')}.
      </SubTitle>

      <Email variant="h3">
        {email}
      </Email>
    </Container>
  )
};

export default SuccessfullySent;
