import React, { useEffect }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useCreateLaborMutation } from 'store/tutors/tutorsServices';
import { IEmployeeProducts, IEmployeeLabor, ILabor } from 'store/tutors/tutorsTypes';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import EmployeeProductItem from './EmployeeProductItem';
// Styles
import { Container } from './styles';
import type  {SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';
import { useAuth } from '../../../hooks/useAuth';

const EmployeeProduct = () => {
  const { project_id, employeeProduct, materialProducts } = useSelector(selectTutor);
  const { step } = useParams();
  const { redirectToTutorStep } = useTutorRoutes();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [createLabor] = useCreateLaborMutation();

  const formState = useForm({
    defaultValues: { employeeProduct },
    mode: 'all',
  });

  const formStateArray = useFieldArray({
    control: formState.control,
    name: 'employeeProduct',
  });

  const updateLabors = (labors: IEmployeeLabor[]) => {
    const labor: ILabor[] = [];

    labors.forEach((item) => {
      item.employees.forEach((empl) => {
        labor.push({ labor_id: empl.labor_id, amount: +empl.amount, employee_id: empl.employee_id, extra: { name: empl.name, employee_id:  empl.employee_id, department: item.department } });
      });
    });

    const checkLaborId = labor.filter(item => !item.labor_id)

    if (checkLaborId?.length) {
      return checkLaborId;
    }

    return [];
  };

  const onNextStep: SubmitHandler<{ employeeProduct: IEmployeeProducts[] }> = async (values) => {
    try {
      const employees = values.employeeProduct.map((item: IEmployeeProducts) => ({
        product_id: item.product.id,
        labors: updateLabors(item.labors),
      }));

      const filteredEmployees = employees.filter((item) => item.labors?.length);

      if (filteredEmployees?.length) {
        await createLabor(filteredEmployees).unwrap();
        formState.reset();
      }

      formState.setValue('employeeProduct', employeeProduct);
      redirectToTutorStep(project_id, 7);
      const eventName = materialProducts?.length === 0
        ? SEGMENT_EVENT_NAME.PIPELINE_STEP_4
        : SEGMENT_EVENT_NAME.PIPELINE_STEP_6;
      const eventPayload: SegmentEventPayload = {
        eventName,
        payload: { email: user?.email },
      };
      dispatch(addEvent(eventPayload));
    } catch (err) {
      console.error(err);
    }
  };

  const onPrevStep = () => {
    if (materialProducts?.length === 0) {
      redirectToTutorStep(project_id, 3);
    } else {
      redirectToTutorStep(project_id, 5);
    }
  };

  useEffect(() => {
    formState.setValue('employeeProduct', employeeProduct);
  }, [employeeProduct]);

  return (
    <TabPanel index={6} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={5} />

        {formStateArray.fields.map((item, index) => (
          <EmployeeProductItem
            errors={formState.formState.errors}
            control={formState.control}
            key={item.id}
            indexProduct={index}
            itemProduct={item}
          />
        ))}
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={5}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default EmployeeProduct;
