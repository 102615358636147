import React, { FC } from 'react';
// Utils
import { ICashflowTable } from 'utils/calculates/summary-table';
import { currencyType } from 'utils/helpers';
import { isMinusValue } from 'utils/helpers';
// Styles
import { StyledTitle, StyledTableRow } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface RowTableProps {
  item: ICashflowTable;
  index: number;
  currency: string;
}

const RowTable: FC<RowTableProps> = ({ item, index, currency }) => {
  const isActive = index === 5;
  const { t } = useTranslation();
  return (
    <StyledTableRow
      isActive={isActive}
      sx={{ '& > *': { borderBottom: 'unset' } }}
    >
      <StyledTitle
        isActive={isActive}
        component="th"
        scope="row"
      >
        {item?.name}
      </StyledTitle>

      <StyledTitle isBold={item?.isBold} align="left" isActive={isActive}>
        {t(currencyType(currency))}
      </StyledTitle>

      {item?.month?.map((month, ind) => (
        <StyledTitle
          isBold={item?.isBold}
          isMinusValue={isMinusValue(month?.income?.toString())}
          key={month?.income + ind}
          color={item?.color}
        >
          {month?.income}
        </StyledTitle>
      ))}
    </StyledTableRow>
  );
};

export default RowTable;
