import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Button,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
// Store
import { useGetPipelineByIdQuery, useGetSalesPlanQuery, useGetSalesPlanProductsQuery } from 'store/dashboard/dashboardServices';
import {
  CalendarIcon, DownloadIcon, UploadIcon, PlusIcon, SettingsIcon,
} from 'assets/icons';
import Select from 'components/UI/Select';
import Footer from 'components/Footer';
import TeamTable from './TeamTable';
import AddEmployeeModal from './AddEmployeeModal';
import PagesSetting from './PagesSetting';
import TeamPayouts from './TeamPayouts';
import AverageTeamWorkload from './AverageTeamWorkload';
// Styles
import {
  H1,
  HeaderRow,
  HeaderRightBlock,
  TitleContainer,
  IconWrap,
  TableContainer,
  Container,
  SettingsLink,
} from './styles';
import {getUtcFormatDate} from 'utils/helpers';
import { DATE } from 'utils/constants/date';
import { useTranslation, } from 'react-i18next';

const data = [
  {
    value: 'Виробництво', label: 'Виробництво',
  },
  {
    value: 'Виробництво1', label: 'Виробництво1',
  },
  {
    value: 'Виробництво2', label: 'Виробництво2',
  },
];

const data1 = [
  {
    value: '10', label: '10',
  },
  {
    value: '50', label: '50',
  },
  {
    value: '100', label: '100',
  },
];

const RangeDatePicker: React.FC = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        // @ts-ignore
        setDateRange(update);
      }}
      isClearable
    />
  );
};

interface IDate {
  startDate: any;
  endDate: any;
}

const Teams: React.FC = () => {
  const { t } = useTranslation();
  const { pipeline_id, project_id } = useParams();

  const { isLoading, data: pipelineData, refetch } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string
  });

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data: dataPlan } = useGetSalesPlanProductsQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  return (
    <Container>
      <TitleContainer>
        <H1 variant="h1">
          {t('Команда')}
          {/* <SettingsLink onClick={() => { setOpenSettingsModal((prev) => !prev); }}>
            <SettingsIcon />
          </SettingsLink> */}
        </H1>
      </TitleContainer>
      {/* <TableContainer>
        <HeaderRow>
          <Typography variant="h2">Виплати команді</Typography>
        </HeaderRow>

        <TeamPayouts employees={pipelineData?.employees} products={pipelineData?.products} plan={dataPlan} />
      </TableContainer> */}
      {/* <TableContainer>
        <HeaderRow>
          <Typography variant="h2">Середнє навантаження команди</Typography>
        </HeaderRow>
        <AverageTeamWorkload />
      </TableContainer> */}
      <TableContainer>
        <HeaderRow>
          <Typography variant="h2">{t('Люди та гроші')}</Typography>
          <HeaderRightBlock>
            {/* <Select label="" className="toolbar-btns" data={data} />
            <Select label="" className="toolbar-btns" data={data1} /> */}
            <Button
              variant="textIcon"
              className="create-btn"
              startIcon={<PlusIcon />}
              onClick={() => { setOpenAddEmployeeModal((prev) => !prev); }}
            >
              {t('Співробітник')}
            </Button>
          </HeaderRightBlock>
        </HeaderRow>
        <TeamTable employees={pipelineData?.employees} refetch={refetch} />
        <AddEmployeeModal
          isOpen={openAddEmployeeModal}
          onClose={() => { setOpenAddEmployeeModal((prev) => !prev); refetch(); }}
        />
        <PagesSetting
          isOpen={openSettingsModal}
          onClose={() => { setOpenSettingsModal((prev) => !prev); }}
        />
      </TableContainer>
      <Footer isSpace isSticki />
    </Container>
  );
};

export default Teams;
