import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/auth/authSlice';
import {TRIAL_PERIOD} from "../utils/constants/user-profile";

export const useTrialUser = () => {
  const { user } = useSelector(selectCurrentUser);
  const date = dayjs(user?.created_at);
  const dateNow = new Date();
  const differenceDate = date.diff(dateNow, 'day');
  const diffDate = differenceDate?.toString()?.replace('-', '');
  const trailPeriod = TRIAL_PERIOD;
  const trialSubscriptionEnd = trailPeriod - +diffDate < 0;
  const userSubscriptionFree = user?.membership_status === 'free' || user?.membership_status === 'trial';

  return {
    trialSubscriptionEnd,
    userSubscriptionFree,
    lockNotFreeFunctionality: trialSubscriptionEnd && userSubscriptionFree
  };
}
