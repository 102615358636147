/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// Styles
import {
  Container,
  Title,
  Wrapper,
  SubTitle,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const BankCardFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Title variant="h6">
          {t("components.BankCard.BankCardFooter.Iм'я власника")}      </Title>

        <SubTitle variant="h6">
          Frank Sinatra
        </SubTitle>
      </Wrapper>

      <Wrapper>
        <Title variant="h6">
          {t("components.BankCard.BankCardFooter.Термін дії")}
        </Title>

        <SubTitle variant="h6">
          07/25
        </SubTitle>
      </Wrapper>
    </Container>
  )
};

export default BankCardFooter;
