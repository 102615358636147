import React from 'react';
// Components
import FooterLinks from './FooterLinks';
// Styles
import { Container, Label } from './styles';

interface FooterProps {
  isSticki?: boolean;
  isSpace?: boolean;
}

const Footer: React.FC<FooterProps> = ({ isSpace, isSticki }) => (
  <Container isSticki={isSticki}>
    <FooterLinks isSpace={isSpace} />

    <Label>
      © 2023 Pipeliner. All rights reserved
    </Label>
  </Container>
);

export default Footer;
