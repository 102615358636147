/* eslint-disable max-len */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// Store
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { IPipeline, IPlanSalesPipeline } from 'store/dashboard/dashboardTypes';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
// Utils
import { calcCashflowTable } from 'utils/calculates/summary-table';
// Components
import TableHeader from '../TableHeader';
import RowTable from './RowTable';
// Styles
import { StyledPaper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { NestedCall } from 'utils/helpers/nested';

interface CashflowTableProps {
  currency: string;
}

const CashflowTable: FC<CashflowTableProps> = ({ currency }) => {
  const { pipeline, salesPlanTable } = useSelector(selectDashboard);
  const { tax } = pipeline as IPipeline;
  const { residence } = useSelector(selectDashboard);
  const dataTable = calcCashflowTable(
    pipeline as IPipeline,
    salesPlanTable as IPlanSalesPipeline[],
    tax,
    residence
  )

  return (
    <StyledPaper>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHeader />

          <TableBody>
            {dataTable?.map((item, index) => (
              <RowTable key={item?.key}
                item={item}
                currency={currency}
                index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
};

export default CashflowTable;
