import { styled } from '@mui/material/styles';
import Input from 'components/UI/Input';
import { Box, Button } from '@mui/material';
import PencilFill from 'assets/icons/pencilFill.svg';
import Pencil from 'assets/icons/pencil.svg';

export const StyledInput = styled(Input)`
  .MuiFormHelperText-root {
    display: none;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const EditorWrap = styled('div')`
  display: flex;
  align-items: center;
  height: 40px;

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  .MuiOutlinedInput-input {
    height: 36px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding: 0 13px;
    z-index: 1;
  }

  fieldset {
    height: 36px;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.custom.grey.backgroundInput};
  }
`;
export const GreenLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.green.main};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.custom.green.main};
  }

  svg {
    margin-right: 5px;
  }
`;

export const ControlButtons = styled('div')`
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const PencilButton = styled('a')`
  background-image: url(${Pencil});
  transform: translateX(0);
  transition: all .3s ease-in-out;
  background-repeat: no-repeat center center fixed;
  background-size: cover;
  background-position: 100%;
  align-items: center;
  height: 11px;
  width: 11px;
  cursor: pointer;
  && {
    &:hover {
      background-image: url(${PencilFill});
    }
  }
`;

export const SubmitButton = styled(Button)`
  background: transparent;
  align-items: baseline;
  height: 18px;
  min-width: 18px;
  padding: 6px 5px;
  border-radius: 4px;
  margin-right: 12px;
  && {
    &:hover {
      background: ${({ theme }) => theme.palette.custom.green.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;

export const CancelButton = styled(SubmitButton)`
  color: ${({ theme }) => theme.palette.custom.red.dark};
  && {
    &:hover {
      background: ${({ theme }) => theme.palette.custom.red.dark};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;
