import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

interface FormHelperTextProps {
  isError: boolean | undefined;
}

export const Label = styled('span')`
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;

export const FormHelperText = styled('span')<FormHelperTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #D52727;
  margin-top: 8px;
  display: ${({ isError }) => (isError ? 'block' : 'none')};
`;

export const MaterialUISelect = styled(Select)`
  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }
`;
