import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

export const StyledPaper = styled(Paper)`
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
`;
