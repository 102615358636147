import React from 'react';
// Icons
import { ArrowGroupIcon, SettingsIcon, ArrowLeftIcon } from 'assets/icons';
// Styles
import {
  Container,
  Wrapper,
  Item,
  IconButton,
  WrapperItem,
  Label,
  ContainerSetting,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const TableFilter: React.FC = () => {
  const { t } = useTranslation();
  return(
  <Container>
    <Wrapper>
      <Item>
        <WrapperItem>
          <Label>
          { t('Дата') }
          </Label>

          <ArrowGroupIcon />
        </WrapperItem>
      </Item>

      <Item align="right">
        <WrapperItem>
          <Label>
             { t('Транзакція') } { t('No') }
          </Label>

          <ArrowGroupIcon />
        </WrapperItem>
      </Item>
      <Item align="right">
        <WrapperItem>
          <Label>
            { t('Послуга') }
          </Label>

          <ArrowGroupIcon />
        </WrapperItem>
      </Item>

      <Item align="right">
        <WrapperItem>
          <Label>
            { t('Інвойс') }
          </Label>

          <ArrowGroupIcon />
        </WrapperItem>
      </Item>

      <Item align="right">
        <WrapperItem>
          <Label>
            { t('Сума') }
          </Label>

          <ArrowGroupIcon />
        </WrapperItem>
      </Item>

      <Item align="right">
        <WrapperItem>
          <Label>
            { t('Статус') }
          </Label>

          <ArrowGroupIcon />

          <ContainerSetting>
            <ArrowLeftIcon />

            <ArrowLeftIcon />

            <SettingsIcon />
          </ContainerSetting>
        </WrapperItem>
      </Item>
    </Wrapper>
  </Container>
)};

export default TableFilter;
