import { css, styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('div')`
  display: flex;
  margin-bottom: 6px;
  flex-direction: column;

  .callapse {
    margin: 0 -40px;
    padding: 12px 20px;
    background: ${({ theme }) => theme.palette.custom.grey.darkLite};
  }
`;

export const CollapseHeader = styled('a')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  margin: 0 -40px;
`;

export const DepartmentNameCollapse = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.green.main};
  svg {
    margin-right: 17px;
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.green.main};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};

`;

export const FieldWrap = styled('div')`
  color: ${({ theme }) => theme.palette.custom.black.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const DeleteButton = styled(Button)`
  transform: translateX(0);
  transition: all .3s ease-in-out;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  && {
    text-decoration: none;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.custom.red.main};
    &:hover {
      color: ${({ theme }) => theme.palette.custom.red.main};
    }
    svg {
      margin-right: 10px;
    }
  }
`;
