import React from 'react';
import { Option as OptionType } from 'types/select';
import Option from './Option';
import {
  MenuList,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface OptionsProps {
  data: OptionType[],
  selected: OptionType | undefined,
  setSelected: (value: OptionType) => void,
  selectedForDelete: boolean,
}

const OptionsList: React.FC<OptionsProps> = ({
  selectedForDelete, data, selected, setSelected,
}) => {
  const { t } = useTranslation();
  return (
    <MenuList disablePadding>
      {data.map((item) => (
        <Option
          onSelect={() => setSelected(item)}
          data={item}
          selected={!!selected && selected.value === item.value}
          selectedForDelete={selectedForDelete && !!selected && selected.value === item.value}
        >
          {t(item.label)}
        </Option>
      ))}
    </MenuList>
  )
};

export default OptionsList;
