import { styled } from '@mui/material/styles';
import { Box, Typography, Button as StyledButton } from '@mui/material';

interface SubTitleProps {
  isDisabled: boolean;
}

interface ButtonProps {
  isUnsubscribe?: boolean;
}

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 30px;
  border-top: 1px solid ${({ theme }) => theme.palette?.primary?.main};
  position: relative;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  margin-bottom: 4px;
`;

export const SubTitle = styled(Typography)<SubTitleProps>`
  color: ${({ theme, isDisabled }) => isDisabled ? '#838383' : theme?.palette?.custom?.grey?.dark};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
`;

export const Wrapper = styled(Typography)`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(StyledButton)<ButtonProps>`
  background-color: ${({ isUnsubscribe }) => isUnsubscribe ? '#EBF7F2' : ''};
  color: ${({ isUnsubscribe }) => isUnsubscribe ? '#07B169' : '#FFFFFF'};
  height: 40px;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  max-width: 80px;

  &:hover {
    color: #FFFFFF;
  }
`;

export const Label = styled(Typography)`
  position: absolute;
  bottom: 0;
  right: 40px;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #07B169;
`;
