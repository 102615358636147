import React from 'react';
import {
  Button,
  InputAdornment
} from '@mui/material';
import { useTranslation } from "react-i18next";
import {Link, useParams} from 'react-router-dom';
import { userRoutesPath } from 'routes/routePath';
import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
// Icons
import { SearchIcon, PlusIcon, CloseFactIcon } from 'assets/icons';
// Styles
import {
  HeaderRow,
  StyledTabs,
  StyledTab,
  HeaderRightBlock,
  StyledInput
} from './styles';
import {useCreateProductMutation} from "store/tutors/tutorsServices";
import {useNavigate} from "react-router";

interface TableHeaderSectionProps {
  handleAddSourcesModal: () => void;
  handleChangeTabMode: (event: React.SyntheticEvent, newValue: number) => void;
  tabMode: number;
}

const TableHeaderSection: React.FC<TableHeaderSectionProps> = ({ handleChangeTabMode, handleAddSourcesModal, tabMode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { project_id, pipeline_id} = useParams<string>();
  const [create] = useCreateProductMutation();

  const createProduct = async (group: string) => {
    const newProduct = {
      name: t('Без назви'),
      group: group,
      amount: 0,
      price: 0,
    }

      const products = await create({
        project_id: project_id as string,
        body: [{ products: [newProduct], pipeline_id: pipeline_id as string }],
      }).unwrap();
    // @ts-ignore
    const {id} = products[0][0]
    navigate(`/dashboard/${project_id}/add-product/${pipeline_id}/${id}`)
  };

  return (
    <HeaderRow className="tabs-row">
      <StyledTabs
        value={tabMode}
        onChange={handleChangeTabMode}
      >
        <StyledTab label={t("Продукти")} />
        <Tooltip
          arrow
          placement="bottom"
          tooltipText=""
          tooltipContent={<LockTooltipText/>}
          PopperProps={{
            modifiers: [{
              name: "offset",
              options: { offset: [0, -20] }
            }],
          }}
        >
          <span style={{display: "flex"}}>
            <StyledTab icon={<CloseFactIcon />} disabled label={t("Джерела")} />
          </span>
        </Tooltip>
      </StyledTabs>

      <HeaderRightBlock className="right-block">
        {/* <StyledInput
          placeholder="Пошуковий запит..."
          className="toolbar-btns search-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}

        {tabMode === 0 ? (
          <>
            {/*<Link to={`/dashboard/${project_id}/add-product/${pipeline_id}`}>*/}
              <Button
                onClick={() => createProduct('product')}
                variant="textIcon"
                className="toolbar-btns create-btn"
                startIcon={<PlusIcon/>}
              >
                {t('Продукт')}
              </Button>
              <Button
                onClick={() => createProduct('service')}
                variant="textIcon"
                className="toolbar-btns create-btn"
                startIcon={<PlusIcon/>}
              >
                {t('Сервіс')}
              </Button>
            {/*</Link>*/}
            {/*<Link to={`/dashboard/${project_id}/add-product/${pipeline_id}`}>*/}
            {/*  <Button*/}
            {/*    variant="textIcon"*/}
            {/*    className="toolbar-btns create-btn"*/}
            {/*    startIcon={<PlusIcon/>}*/}
            {/*  >*/}
            {/*    {t('Сервіс')}*/}
            {/*  </Button>*/}
            {/*</Link>*/}
          </>
        ) : (
          <Button
            variant="textIcon"
            className="toolbar-btns create-btn"
            startIcon={<PlusIcon />}
            onClick={handleAddSourcesModal}
          >
            Джерело
          </Button>
        )}
      </HeaderRightBlock>
    </HeaderRow>
  )
}

export default TableHeaderSection;
