import { styled } from '@mui/material/styles';
import {Box, Table} from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
`;

export const TableStyled = styled(Table)`
  @media print {
    //width: initial;
  }
`;

export const Label = styled('span')`
  padding: 0 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme?.palette?.primary?.dark};
  color: #ffffff;
  height: 48px;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
`;
