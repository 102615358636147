import { useNavigate } from 'react-router';

interface HookReturnType {
  redirectToAddProject: () => void;
  redirectToProjects: () => void;
  redirectAddToPipeliner: (id: string) => void;
  redirectToEditProject: (id: string) => void;
  redirectToDashboard: (project_id: string, type: string, pipeline_id: string) => void;
}

export const useProjectRoutes = (): HookReturnType => {
  const navigate = useNavigate();

  const redirectToProjects = () => navigate('/');
  const redirectToAddProject = () => navigate('/projects/add');
  const redirectToEditProject = (id: string) => navigate(`/projects/edit/${id}`);
  const redirectAddToPipeliner = (id: string) => navigate(`/pipelines/${id}`);
  const redirectToDashboard = (project_id: string, type: string, pipeline_id: string) => navigate(`/dashboard/${project_id}/${type}/${pipeline_id}`);

  return {
    redirectToAddProject,
    redirectToProjects,
    redirectAddToPipeliner,
    redirectToEditProject,
    redirectToDashboard,
  };
};
