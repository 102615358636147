import React from 'react';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';
// Icons
import { GoogleIcon } from 'assets/icons';
// Interface
import { IRegistration } from 'interfaces/auth';
// Styles
import { Button, GoogleButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface RegistrationEmailFooterButtonsProps {
  formState: UseFormReturn<IRegistration>;
  onSubmit: SubmitHandler<IRegistration>;
  isValid: boolean;
}

const RegistrationEmailFooterButtons: React.FC<RegistrationEmailFooterButtonsProps> = ({
  onSubmit,
  formState,
  isValid,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={formState.handleSubmit(onSubmit)}
        disabled={!isValid}
        variant="contained"
        fullWidth
      >
        {t('Увійти')}
      </Button>

      {/* <GoogleButton
      startIcon={<GoogleIcon />}
      variant="textIcon"
      fullWidth
    >
      Увійти за домопогою Google
    </GoogleButton> */}
    </>
  )
};

export default RegistrationEmailFooterButtons;
