import React from 'react';
import { Outlet } from 'react-router-dom';
// Styles
import { Container } from './styles';

const TutorLayout: React.FC = () => (
  <Container>
    <Outlet />
  </Container>
);

export default TutorLayout;
