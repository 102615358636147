import { styled } from '@mui/material/styles';
import { CircularProgress, Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledLoading = styled(CircularProgress)`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
`;
