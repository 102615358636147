import { styled } from '@mui/material/styles';
import { Box, ToggleButtonGroup, Button } from '@mui/material';

interface WrapperDeleteBtnProps {
  isAdmin?: boolean;
}

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  max-height: 79px;

  .name-input {
    max-width: 299px;
  }
  .select-form-control {
    max-width: 267px;
    width: 100%;
  }
  .sum-input {
    max-width: 177px;
  }

  & > .MuiFormControl-root, .MuiToggleButtonGroup-root {
    margin-right: 20px;
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    height: 48px;
    align-self: end;
  }
`;

export const DeleteBtn = styled(Button)`
  background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  &:hover, &:focus {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  }
`;

export const WrapperDeleteBtn = styled('div')<WrapperDeleteBtnProps>`
  display: flex;
  justify-content: flex-end;
  // width: ${({ isAdmin }) => (isAdmin ? '239px' : 'fit-content')};
  //width: 239px;
  align-self: end;
  //margin-left: ${({ isAdmin }) => (isAdmin ? '20px' : 0)};
  margin-left: auto;
`;
