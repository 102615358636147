import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;

  svg {
    transform: rotate(90deg);
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette.custom.grey.dark};
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette.custom.grey.lightIco};
`;
