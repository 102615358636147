/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useFieldArray, FieldErrors, Control, Controller, useWatch,
} from 'react-hook-form';
// Store
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Interface
import { IEmployeeProducts, IEmployeeLabor, ICreateLaborEmployee } from 'store/tutors/tutorsTypes';
// Icons
import { CancelIcon } from 'assets/icons';
// Components
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import EmployeeProductTable from '../EmployeeProductTable';
import AddEmployeeModal from '../AddEmployeeModal';
// Styles
import { Form, DeleteBtn } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface IDataSelect {
  value: string;
  label: string;
}

interface EmployeeProductFormRowProps {
  errors: FieldErrors<{ employeeProduct: IEmployeeProducts[] }>
  control: Control<{ employeeProduct: IEmployeeProducts[] }>,
  deleteRow: (index: number) => void;
  indexProduct: number;
  indexRow: number;
  dataSelect: IDataSelect[];
  labor: IEmployeeLabor;
}

const EmployeeProductFormRow: FC<EmployeeProductFormRowProps> = ({
  control, indexProduct, indexRow, errors, dataSelect, deleteRow,
}) => {
  const { t } = useTranslation();
  const [openAddEmployeeModal, setOpneAddEmployeeModal] = useState(false);
  const { employees } = useSelector(selectTutor);
  const watch = useWatch({ control });
  const [selectEmpls, setSelectEmpls] = useState(dataSelect);

  const [deleteExpenses] = useDeleteExpensesMutation();

  const departmen = watch.employeeProduct?.[indexProduct]?.labors?.[indexRow]?.department;
  const countLabors = watch.employeeProduct?.[indexProduct]?.labors?.[indexRow]?.employees;

  const formStateArray = useFieldArray({
    control,
    name: `employeeProduct.${indexProduct}.labors.${indexRow}.employees`,
  });

  const caclTotalValue = () => {
    const productsTotal = watch.employeeProduct?.[indexProduct]?.labors?.[indexRow]?.employees as ICreateLaborEmployee[];
    const totalValue = productsTotal?.reduce((acc: number, item: ICreateLaborEmployee) => {
      const cost = item?.amount || '0';
      acc += +cost;

      return +acc;
    }, 0);

    return totalValue?.toFixed(2) || 0;
  };

  const filteredEmployees = () => {
    const empls = employees.filter((item) => item.department === departmen);
    const typeEmployee = empls.filter(item => item.type !== 'administration');

    return typeEmployee || [];
  };

  const deleteItem = async () => {
    if (formStateArray.fields.length) {
      formStateArray.fields.forEach(item => {
        if (item.labor_id) {
          deleteExpenses(item.labor_id)
        }
      })
    }

    deleteRow(indexRow);
  };

  const onCloseModal = () => setOpneAddEmployeeModal((prev) => !prev);

  const addedEmployee = (employee: ICreateLaborEmployee) => {
    formStateArray.append({
      employee_id: employee.employee_id,
      amount: employee.amount,
      name: employee.name,
    });

    setOpneAddEmployeeModal(false);
  };

  const deleteEmployee = async (index: number | number[], id?: string) => {
    formStateArray.remove(index);

    if (id) {
      await deleteExpenses(id).unwrap()
    }
  };

  const updateItem = (index: number, value: ICreateLaborEmployee) => {
    formStateArray.update(index, value)
  }

  useEffect(() => {
    if (departmen) {
      setSelectEmpls([{ value: departmen, label: departmen }])
    }
  }, [])

  return (
    <>
      <Form>
        <Controller
          render={({ field }) => (
            <Select
              disabled={!!departmen}
              label={t('Залучені підрозділ')}
              // data={selectEmpls.map((item) => ({
              //   value: item.value,
              //   label: t(item.label)
              // }))
              // }
              data={selectEmpls}
              error={!!errors?.employeeProduct?.[indexProduct]?.labors?.[indexRow]?.department?.message}
              errorMessage={errors?.employeeProduct?.[indexProduct]?.labors?.[indexRow]?.department?.message}
              className="subsection-input"
              defaultValue={departmen}
              {...field}
            />
          )}
          control={control}
          name={`employeeProduct.${indexProduct}.labors.${indexRow}.department`}
          rules={{ required: t('Це поле є обовязковим') }}
        />

        <Input
          label={t('Витрачений час')}
          value={caclTotalValue()}
          type="number"
          InputProps={{ readOnly: true }}
          className="amount-input"
        />

        <DeleteBtn
          onClick={deleteItem}
          variant="icon"
        >
          <CancelIcon />
        </DeleteBtn>

      </Form>

      <EmployeeProductTable
        updateItem={updateItem}
        employeesData={formStateArray.fields}
        deleteEmployee={deleteEmployee}
        isDepartmen={!!departmen}
        openAddModal={onCloseModal}
        departmen={departmen as string}
        countEmployees={filteredEmployees()?.length}
        countLabors={countLabors?.length || 0}
      />

      <AddEmployeeModal
        isOpen={openAddEmployeeModal}
        onClose={onCloseModal}
        departmen={departmen || ''}
        employees={filteredEmployees()}
        addedEmployee={addedEmployee}
        formStateArray={formStateArray}
      />
    </>
  );
};

export default EmployeeProductFormRow;
