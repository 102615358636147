import React, { FC } from 'react';
// Сomponents
import ToggleButtons from './ToggleButtons';
import PrintComponent from "./PrintComponent";
// Styles
import { Container } from './styles';

interface IDate {
  startDate: any;
  endDate: any;
}

interface SettingsSectionProps {
  dateValue: IDate;
  setDateValue: (value: IDate) => void;
}

const SettingsSection: FC<SettingsSectionProps> = () => {

  return (
    <Container>
      <ToggleButtons />
      <PrintComponent />
    </Container>
  );
}

export default SettingsSection;
