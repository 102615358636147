import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  margin: 0 -30px;
  flex-direction: column;
`;

export const Paper = styled(Box)`
  padding: 0 30px;
`;
