import React, { ReactElement } from 'react';
import {
  Tooltip as TooltipCustom,
  Typography,
  TooltipProps as TooltipPropsCustom,
} from '@mui/material';
// Styles
import { ContainerText } from './styles';

interface TooltipProps {
  tooltipText: string;
  children: JSX.Element;
  arrow?: boolean;
  placement?: TooltipPropsCustom['placement'];
  tooltipContent?: ReactElement;
  open?: boolean;
  PopperProps?: TooltipPropsCustom['PopperProps'];
}

const Tooltip: React.FC<TooltipProps> = ({
  tooltipText,
  tooltipContent,
  children,
  arrow = false,
  placement = 'top',
  open,
  PopperProps,
}) => (
  <TooltipCustom
    title={(
      <ContainerText>
        {
          tooltipContent || (
            <Typography variant="h3">{tooltipText}</Typography>
          )
        }
      </ContainerText>
    )}
    placement={placement}
    arrow={arrow}
    open={open}
    PopperProps={PopperProps}
  >
    {children}
  </TooltipCustom>
);

export default Tooltip;
