import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { calcProfitAndLoss } from 'utils/calculates/profit-and-loss-table';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import { IPipeline, IPlanSalesPipeline } from 'store/dashboard/dashboardTypes';
import { isMinusValue } from 'utils/helpers';
import { ChartWrapper, TooltipRow, ParamTitle, ParamValue, TooltipTitle, Wrapper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

export const data = [
  {
    name: '1 мiс.',
    x: 0,
    y: 0,
  },
  {
    name: '2 мiс.',
    x: 40,
    y: 24,
  },
  {
    name: '3 мiс.',
    x: 54,
    y: 23,
  },
  {
    name: '4 мiс.',
    x: 68,
    y: 46,
  },
  {
    name: '5 мiс.',
    x: 34,
    y: 57,
  },
  {
    name: '6 мiс.',
    x: 23,
    y: 69,
  },
  {
    name: '7 мiс.',
    x: 57,
    y: 35,
  },
  {
    name: '8 мiс.',
    x: 35,
    y: 68,
  },
  {
    name: '9 мiс.',
    x: 79,
    y: 45,
  },
  {
    name: '10 мiс.',
    x: 36,
    y: 42,
  },
  {
    name: '11 мiс.',
    x: 47,
    y: 48,
  },
  {
    name: '12 мiс.',
    x: 13,
    y: 35,
  },
];

interface CompareChartProps {
  planSalesOne: IPlanSalesPipeline[] | [];
  planSalesTwo: IPlanSalesPipeline[] | [];
  index: number;
  typeCompare: string;
  currency: string;
}

const CompareChart: React.FC<CompareChartProps> = ({ index, planSalesOne, planSalesTwo, typeCompare, currency }) => {
  // @ts-ignore
  const ChartTooltip = (props) => {
    return (
      <div className="custom-tooltip">
        <TooltipRow>
          <TooltipTitle>
            {typeCompare}
          </TooltipTitle>

          <Wrapper>
            <ParamTitle color="#07B169" />
            <ParamValue isMinusValue={isMinusValue(props?.payload?.[0]?.payload?.x?.toString())}>
              {currency} {props?.payload?.[0]?.payload?.x}
            </ParamValue>
          </Wrapper>

          <Wrapper>
            <ParamTitle color="#FE8C8C" />
            <ParamValue isMinusValue={isMinusValue(props?.payload?.[0]?.payload?.y?.toString())}>
              {currency} {props?.payload?.[0]?.payload?.y}
            </ParamValue>
          </Wrapper>
        </TooltipRow>
      </div>
    );

  }

  const { t } = useTranslation();
  const { comparePipelines, residence, pipeline } = useSelector(selectDashboard);
  const { tax } = pipeline as IPipeline;

  const profitDataOne = calcProfitAndLoss(
    comparePipelines?.[0] as IPipeline,
    planSalesOne as IPlanSalesPipeline[],
    tax,
    residence
  )?.[index]?.month;

  const profitDataTwo = calcProfitAndLoss(
    comparePipelines?.[1] as IPipeline,
    planSalesTwo as IPlanSalesPipeline[],
    tax,
    residence
  )?.[index]?.month;

  const dataDiagram = () => {
    const datafff = data?.map((item, ind) => ({
      ...{
        name: t(`${item.name}`),
        x: item.x,
        y: item.y,
      },
      x: profitDataOne?.[ind]?.income,
      y: profitDataTwo?.[ind]?.income,
    }))

    return datafff
  }

  return (
    <ChartWrapper>
      <ResponsiveContainer height={250} width={"100%"}>
        <LineChart
          data={dataDiagram()}
          margin={{
            top: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<ChartTooltip />} offset={-100} allowEscapeViewBox={{ x: true, y: true }} />
          <Line type="monotone" dataKey="x" stroke="#07B169" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="y" stroke="#FE8C8C" />
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}

export default CompareChart;
