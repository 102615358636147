import {
  Box,
  Typography,
  Button as StyledButton,
  ToggleButtonGroup,
  ListItem,
  List
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Input from 'components/UI/Input';

interface ButtonProps {
  component?: string | undefined;
}

interface StyledInputProps {
  isBackground?: boolean;
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    height: 48px;
    align-self: end;
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .MuiFormControl-root.select {
    margin-bottom: 20px;
  }
  
  .MuiFormControl-root.select-target {
    margin-top: 20px;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  max-width: 315px;
  margin-bottom: 10px;
`;


export const FormRowWrap = styled(Box)`
  width: 100%;
  max-width: 412px;
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const ContainerHeader = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerBody = styled(Box)`
  width: 100%;
  padding-top: 20px;
  .MuiSelect-select.Mui-disabled {
    background: #EBEBEB;
    color: #838383;
  }
`;

export const Button = styled(StyledButton)<ButtonProps>`
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  
  && {
    height: 60px;
  }
`;

export const WrapperButton = styled('label')`
  margin-bottom: 10px;
`;

export const LinkButton = styled(StyledButton)`
    text-decoration: none;
    align-items: flex-start;
    width: fit-content;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    svg {
      margin-right: 8px;
    }
    &:hover {
      text-decoration: none;
    }
`;

export const InlineFormWrap = styled('div')`
  width: 100%;
  display: flex;
  
  .inline-input {
    min-width: 198px;
  }
  
  .inline-select {
    margin-bottom: 20px;
  }
  
  .time-input {
    margin-right: 10px;
  }
  
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    &:read-only {
      background: #EBEBEB;
      color: #838383;
    }
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled(Input)<StyledInputProps>`
  .MuiOutlinedInput-root {
    background: ${({ isBackground }) => isBackground ? '#EBEBEB' : '#ffffff'} !important;
  }
`;