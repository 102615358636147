import React from 'react';
import {useReactToPrint} from "react-to-print";
import {useSelector} from "react-redux";
import { isSafari } from 'react-device-detect';

import { PrintIcon } from 'assets/icons';
import PrintDashboard from 'components/PrintDashboard';

import {IconButton, TooltipText, Wrapper} from "./styles";
import {selectDashboard} from "store/dashboard/dashboardSlice";
import {useTrialUser} from "hooks/useTrialUser";
import Tooltip from "components/UI/Tooltip";
import {useTranslation} from "react-i18next";

const PrintComponent: React.FC = () => {
  let componentRef = React.useRef<HTMLDivElement>(null);
  const { lockNotFreeFunctionality } = useTrialUser();
  const { pipeline } = useSelector(selectDashboard);
  const { t } = useTranslation();
  // @ts-ignore
  const fileName = `Dashboard ${pipeline?.pipeline_name} ${new Date().toJSON().slice(0, 10)}`

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: fileName,
  });

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        tooltipText=""
        tooltipContent={
          <TooltipText>
            {t('Ви можете підігнати результати для друку, відрегулювавши параметри. Наприклад альбомний вид та 70% маштабу.')}
          </TooltipText>
        }
      >
        {
          isSafari ? <IconButton
              disabled
              onClick={handlePrint}>
              <PrintIcon/>
            </IconButton> :
            <IconButton
              disabled={lockNotFreeFunctionality}
              onClick={handlePrint}>
              <PrintIcon/>
            </IconButton>
        }
      </Tooltip>

      <Wrapper>
        <PrintDashboard ref={componentRef} />
      </Wrapper>
    </>
  )
};

export default PrintComponent;
