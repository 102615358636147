import { type FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'components/SideBar/SideBarBody/styles';
import {LinkContainerStyled, NavContainerStyled} from './styles';
import { useTranslation, } from 'react-i18next';
import { getSideBarElements } from "utils/freelancer/helpers/getSideBarElements";

const SideBarBody: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const sideBarDashboardLinks = getSideBarElements(pathname, t);

  return (
    <Container>
      <NavContainerStyled>
        {sideBarDashboardLinks.map(({isActive, label, route, icon}) => (
          <LinkContainerStyled to={route} isActive={isActive} key={`${route}-${label}`}>
            {icon}
            {label}
          </LinkContainerStyled>
        ))}
      </NavContainerStyled>
    </Container>
  );
};

export default SideBarBody;
