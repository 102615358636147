/* eslint-disable no-nested-ternary */
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface RowTextProps {
  isBold?: boolean;
  isBackground: boolean;
  isMinusValue?: boolean;
  color?: string;
}

export const CardList = styled('ul')`
  cursor: pointer;
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};
`;

export const RowText = styled(Typography)<RowTextProps>`
  color: ${({ isMinusValue, color }) => (isMinusValue ? '#D52727' : color)};
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
  font-size: 16px;
  line-height: 24px;
`;

export const ListSubheader = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.custom.black.main};
`;

export const ListSubheaderGray = styled(Text)`
  font-weight: 500;
  color: #838383;
  //color: ${({ theme }) => theme.palette.custom.black.main};
`;

export const ListSubheaderBold = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.custom.black.main};
`;

export const BlockSubheader = styled(Text)`
  font-weight: bold;
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.custom.black.main};
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
`;

export const CardListItem = styled('li')<RowTextProps>`
  width: 100%;
  height: 44px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ isBackground }) => (isBackground ? '#EEEFF3' : 'transparent')};

  &&.no-border {
    border-bottom: none;
  }

  &&.accentuated {
    background: ${({ theme }) => theme.palette.primary.main};
    margin: 0 -33px;
    width: auto;
    padding: 0 33px;
  }
`;

export const CardListItemSummary = styled('li')`
  width: 100%;
  height: 44px;
  padding-left: 30px;
  padding-right: 30px;
  //max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #EEEFF3;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};

  &&.no-border {
    border-bottom: none;
  }

  &&.accentuated {
    background: ${({ theme }) => theme.palette.primary.main};
    margin: 0 -33px;
    width: auto;
    padding: 0 33px;
  }
`;

export const IncomeSumBlock = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TextGreen = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.green.main};
  margin-right: 37px;
  //padding-right: 5px;
`;
