import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  padding: 30px;
  max-width: 1122px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  cursor: pointer;
  height: fit-content;
`;

export const LinkButton = styled(Button)`
  text-decoration: none;
  padding: 0;
  margin-top: 30px;
  margin-right: 15px;
  &:hover {
    text-decoration: none;
  }
  width: fit-content;
`;
