import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonProps {
  component?: string | undefined;
  isDelete: boolean;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: fit-content;
  margin-bottom: 10px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  max-width: 315px;
  margin-bottom: 10px;
`;

export const IconContainer = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const Button = styled(StyledButton)<ButtonProps>`
  width: 100%;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: ${({ isDelete }) => isDelete ? '#D52727' : ''};
`;

export const WrapperButton = styled('label')`
  width: 100%;
  margin-bottom: 10px;
`;
