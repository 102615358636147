/* eslint-disable max-len */
import { BaseQueryParams } from 'store/baseQuery';
import {
  PipelineResponse, RequestMaterial, CreateTutorEmployee, CreateTutorProduct, IProduct, IAdditionalLosses,
} from './tutorTypes';

export const tutorServices = BaseQueryParams('tutor', ['TUTOR', 'UNITS']).injectEndpoints({
  endpoints: (builder) => ({
    getPipelineById: builder.query<PipelineResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/pipelines/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['TUTOR'],
    }),

    getUnits: builder.query<{ data: { name: string; project_id: string; unit_id: string; }[] }, { project_id: string }>({
      query: ({project_id}) => ({
        url: `/projects/${project_id}/units`,
        method: 'GET',
      }),
      providesTags: () => ['UNITS'],
    }),

    deletePipelines: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/pipelines/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: () => ['TUTOR'],
    }),

    createUnits: builder.mutation<{ name: string, unit_id: string, project_id: string }, { project_id: string, unit: { name: string } }>({
      query: ({ project_id, unit }) => ({
        url: `/projects/${project_id}/units`,
        method: 'POST',
        body: unit,
      }),
      invalidatesTags: () => ['UNITS'],
    }),

    createEmployee: builder.mutation<unknown, CreateTutorEmployee>({
      query: ({ employees, pipeline_id }) => ({
        url: '/pipelines/employees',
        method: 'POST',
        body: [{ pipeline_id, employees }],
      }),
      invalidatesTags: () => ['TUTOR'],
    }),

    createProduct: builder.mutation<unknown, CreateTutorProduct>({
      query: ({ products, pipeline_id }) => ({
        url: '/pipelines/products',
        method: 'POST',
        body: [{ pipeline_id, products }],
      }),
      invalidatesTags: () => ['TUTOR'],
    }),

    createMaterial: builder.mutation<unknown, RequestMaterial>({
      query: (body) => ({
        url: '/products/materials',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['TUTOR'],
    }),

    createEmployeeProducts: builder.mutation<unknown, unknown>({
      query: (body) => ({
        url: '/projects/employee-products',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['TUTOR'],
    }),

    createAdditionalLosses: builder.mutation<unknown, { data: any, project_id: string }>({
      query: ({ data, project_id }) => ({
        url: `/pipelines/${project_id}/additional-losses`,
        method: 'POST',
        body: data,
      }),
    }),

    createLossCategory: builder.mutation<{ name: string, loss_groups_id: string }, { name: string }>({
      query: (body) => ({
        url: '/loss-groups',
        method: 'POST',
        body,
      }),
    }),

    // Additional Losses
    getProjectAdditionalLosses: builder.query<IAdditionalLosses[], string>({
      query: (project_id) => ({
        url: `/pipelines/${project_id}/additional-losses`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateEmployeeMutation,
  useCreateProductMutation,
  useGetPipelineByIdQuery,
  useCreateMaterialMutation,
  useCreateEmployeeProductsMutation,
  useGetUnitsQuery,
  useCreateUnitsMutation,
  useCreateAdditionalLossesMutation,
  useCreateLossCategoryMutation,
  useDeletePipelinesMutation,
  useGetProjectAdditionalLossesQuery,
} = tutorServices;
