import React, { FC } from 'react';
import { isMinusValue } from 'utils/helpers';
// Styles
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { useProjectRoutes } from 'hooks/useProjectRoutes';
import {
  CardList,
  CardListItem,
  IncomeSumBlock,
  RowText,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface IDataRows {
  name: string;
  price: number | string;
  key: number;
  isBold: boolean;
  isBackground: boolean;
  color?: string;
}

interface PipelineListProp {
  pipeline: IPipeline;
  dataRows: IDataRows[];
  currency: string;
}

const PipelineList: React.FC<PipelineListProp> = ({ pipeline, currency, dataRows }) => {
  const { t } = useTranslation();
  const { redirectToDashboard } = useProjectRoutes();

  const goToDashboard = () => {
    redirectToDashboard(pipeline.project_id, 'indicators', pipeline.pipeline_id)
  }

  return (
    <CardList
      onClick={goToDashboard}
    >
      {dataRows?.map((item) => (
        <CardListItem isBackground={item?.isBackground} key={item?.key}>
          <RowText isBackground={item?.isBackground} isBold={item?.isBold} variant="h3">
            {t(`${item?.name}`)}
          </RowText>

          <IncomeSumBlock>
            <RowText
              isMinusValue={isMinusValue(item?.price?.toString())}
              isBackground={item?.isBackground}
              isBold={item?.isBold}
              color={item?.color}
              variant="h3"
            >
              {currency}  {item?.price}
            </RowText>
          </IncomeSumBlock>
        </CardListItem>
      ))}
    </CardList>
  );
};

export default PipelineList;
