import { styled } from '@mui/material/styles';

export const Container = styled('p')`
  width: 100%;
  max-width: 250px;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`;

export const Link = styled('span')`
  text-decoration: underline;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #07B169;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
