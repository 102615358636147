import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Store
import { useDeletePipelineMutation, useUpdatePipelineMutation } from 'store/pipeline/pipelineServices';
// Components
import DeletePipelineModal from 'components/Tutor/DeletePipelineModal';
// Icons
import { Logo, LogoText } from 'assets/logos';
import { SaveIcon, CancelIcon } from 'assets/icons';
// Styles
import {
  Header,
  Wrapper,
  Progress,
  LogoWrap,
  CurrentStepIndicator,
  ControlButtons,
  ControlButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface TutorHeaderProps {
  step: number;
}

const TutorHeader: FC<TutorHeaderProps> = ({ step }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { pipeline_id, project_id, step1 } = useSelector(selectTutor);
  const { redirectToProjects, redirectAddToPipeliner } = useProjectRoutes();
  const [deletePipeline] = useDeletePipelineMutation();
  const [updatePipeline] = useUpdatePipelineMutation();

  const onDelateHandler = () => setOpenDeleteModal((prev) => !prev);

  const chengeStatusPipeline = async () => {
    await updatePipeline({
      pipeline_id: pipeline_id as string,
      project_id: project_id as string,
      body: { status: 0, pipeline_name: step1.pipeline_name, tax: 0 }
    }).unwrap()

    redirectAddToPipeliner(project_id as string);
  };

  const deletePipelineHandler = async () => {
    await deletePipeline({
      pipeline_id: pipeline_id as string,
      project_id: project_id as string,
    }).unwrap();

    setOpenDeleteModal(false);
    redirectAddToPipeliner(project_id as string);
  };
  const { t } = useTranslation();
  return (
    <>
      <Header>
        <Wrapper>
          <LogoWrap onClick={redirectToProjects}>
            <Logo className="logo" />
            <LogoText />
          </LogoWrap>

          <CurrentStepIndicator>
            <Typography>
              {t('Крок')} {step} {t('з')} 6
            </Typography>
          </CurrentStepIndicator>

          <ControlButtons>
            <ControlButton
              onClick={chengeStatusPipeline}
              variant="icon"
            >
              <SaveIcon />
            </ControlButton>

            <ControlButton
              onClick={onDelateHandler}
              variant="textIcon"
            >
              <CancelIcon className="cancel" /> {t('Залишити тьютор')}
            </ControlButton>
          </ControlButtons>
        </Wrapper>

        <Progress variant="determinate" value={16.7 * step} />
      </Header>

      <DeletePipelineModal
        isOpen={openDeleteModal}
        onClose={onDelateHandler}
        deletePipeline={deletePipelineHandler}
      />
    </>
  );
};

export default TutorHeader;
