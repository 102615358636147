import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Form = styled('div')`
`;

export const LinkButton = styled(Button)`
  text-decoration: none;
  padding: 0;
  margin-top: 30px;
  margin-right: 15px;
  &:hover {
    text-decoration: none;
  }
`;

export const Container = styled('div')`
  display: flex;
  margin-bottom: 20px;
  max-height: 79px;

  .name-input {
    max-width: 337px;
  }
  .select-form-control {
    width: 100%;
  }
  .sum-input {
    max-width: 177px;
  }

  & > .MuiFormControl-root, .MuiToggleButtonGroup-root {
    margin-right: 20px;
  }
`;

export const FormFooter = styled('div')`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const TotalCost = styled('div')`
  display: flex;
  flex-direction: column;

  .total-cost-value {
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
`;
