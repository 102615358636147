import React, { FC, useState, useEffect } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { IProduct } from 'interfaces/product';
import { IPlanSales } from "store/dashboard/dashboardTypes";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ChartWrapper, ContainerHeader, Header, Prise, Circle, TooltipRow, ProductTitle } from './styles';

interface ProfitByMonthProps {
  products?: IProduct[],
  plan?: IPlanSales[]
}

const colors = [
  '#1DE490',
  '#1CC880',
  '#19AF70',
  '#148F5C',
];


const ProfitByMonth: FC<ProfitByMonthProps> = ({ products, plan }) => {
  const { t } = useTranslation();
  const [bars, setBars] = useState([]);
  const [productsMap, setProductsMap] = useState([]);
  useEffect(() => {
    // @ts-ignore
    const initialBars = plan && plan[0].sales.map(({ product_id, month }) => ({
      [product_id]: 0,
      name: `${month} ` + t('міс.'),
      month
    }))

    const initialProductsMap = plan && plan?.map(({ product_id, name }, index) => ({
      [product_id]: {
        label: name,
        color: colors[index]
      }
    }))
    if (!initialBars || !initialProductsMap) {
      return;
    }

    plan?.forEach(({ name, sales, product_id }) => {
      // @ts-ignore
      sales.forEach(({ amount, month }, index) => {
        // @ts-ignore
        const priceByProduct = getPriceByProduct(product_id)
        const profit = priceByProduct * amount;
        // @ts-ignore
        initialBars[index][product_id] = profit;
        initialBars[index].name = `${month} ` + t('міс.');
      });
    })

    // @ts-ignore
    setBars(initialBars.sort((a, b) => (a.month - b.month)));
    // @ts-ignore
    setProductsMap(initialProductsMap)
  }, [plan, products])

  const getPriceByProduct = (product_id: number) => {
    const foundProduct = products?.find(({ id }) => (
      // @ts-ignore
      id === product_id
    ))
    if (foundProduct) {
      return foundProduct.price;
    }
    return 0;
  }

  // @ts-ignore
  const ChartTooltip = (props) => {
    // @ts-ignore
    const renderProducts = productsMap && productsMap.length && props.payload && props.payload.length
      // @ts-ignore
      ? props.payload.filter(({ name }) => (name !== 'name' && name !== 'month')).map(({ name, value }, index) => {
        return (
          // @ts-ignore
          <TooltipRow><ProductTitle><Circle color={productsMap[index][name].color} />{productsMap[index][name].label}</ProductTitle>
            <Prise variant="regularText">{value}</Prise>
          </TooltipRow>
        );
      })
      : (<span />)
    return (
      <div className="custom-tooltip">
        <ContainerHeader>
          <Header variant="regularText">{t('Загальний місячний дохід')}</Header>
          <div>
            <Prise variant="boldRegularText"> {props?.payload?.[0]?.value} {t('гривень')}</Prise>
          </div>
        </ContainerHeader>
        {renderProducts}
      </div>
    );

  }
  return (
    <ChartWrapper>
      <ResponsiveContainer height={250} width={"100%"}>
        <BarChart
          data={bars}
          margin={{
            top: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<ChartTooltip />} offset={-100} allowEscapeViewBox={{ x: true, y: true }} />
          {
            // @ts-ignore
            bars && bars.length && Object.keys(bars[0]).filter((key) => (key !== 'name')).map((key, index) => (
              <Bar dataKey={key} stackId="a" fill={colors[index]} />
            ))
          }
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}

export default ProfitByMonth;