import { styled } from '@mui/material/styles';
import {Typography, Button as StyledButton, TableCell} from '@mui/material';

interface ButtonProps {
  isCompare?: boolean;
}

export const Container = styled('div')`
  position: relative;
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px 0 30px;
  overflow-y: auto;

  footer {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  }
`;

export const PipelinerCardContainer = styled('div')`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
`;

export const ContainerButtonCreate = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  max-width: 546px;
  width: 32%;
  height: 719px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const ButtonCreate = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background: rgba(7, 177, 105, 0.06);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.custom.green.main};

  svg {
    margin-bottom: 25px;
  }
`;


export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 18px;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
`;

export const UploadedFile = styled(StyledButton)`
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: inherit;
`;
export const UploadedFilesContainer = styled('div')`
  display: flex;
  align-items: center;
  & > button {
    margin-right: 15px;
  }
`;

export const LinkButton = styled(StyledButton)`
  margin-right: 30px;
  padding-left: 0;
  && {
    text-decoration: none;
  }
`;

export const TableContainer = styled('div')`
  border-radius: 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  margin: 30px;
`;

export const ItemRow = styled(TableCell)`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  &.first-td {
    max-width: 70px;
  }
`;

export const Button = styled(StyledButton)<ButtonProps>`
  display: flex;
  background: rgba(164, 164, 164, 0.1);
  color: ${({ theme }) => theme.palette.custom.primary.silver};
  line-height: 22px;
  align-items: center;
  && {
    &:hover {
      background: rgba(164, 164, 164, 0.5);
      color: ${({ theme }) => theme.palette.custom.primary.silver};
    }
  }
`;
