import { ComponentsOverrides, Theme } from '@mui/material';

interface muiRadioButtonProps {
  styleOverrides: ComponentsOverrides['MuiRadio'];
}

export const muiRadioButton = (theme: Theme): muiRadioButtonProps => ({
  styleOverrides: {
    root: {
      color: 'rgba(41, 41, 41, 0.07)',
      '&.Mui-checked': {
        color: theme.palette.custom.green.main,
      },
    },
  },
});
