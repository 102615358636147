import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';
import Input from 'components/UI/Input';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px 30px 0;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 18px;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    top: 0;
    background: ${({ theme }) => theme?.palette?.custom?.green.main};
  }

  .MuiTabs-flexContainer {
    height: 80px;
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme?.palette?.custom?.grey.lightIco};
  font-size: 16px;
  text-transform: inherit;

  svg {
    position: absolute;
    right: 0;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.custom?.grey.dark};
    font-weight: 400;
  }
`;

export const HeaderRightBlock = styled('div')`
  display: flex;
  align-items: center;

  .search-input {
    height: 48px;
  }

  .toolbar-btns {
    margin-right: 8px;
  }

  .filter-btn {
    min-width: 210px;
  }

  .create-btn {
    min-width: 117px;
    display: flex;
    align-items: center;
    .MuiButton-startIcon {
      height: 13px;
    }
  }

  & > * {
    margin-right: 30px;
  }
`;

export const StyledInput = styled(Input)`
  max-width: 218px;
  margin-left: 12px;

  .MuiFormHelperText-root {
    display: none;
  }
`;
