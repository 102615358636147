import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import Input from 'components/UI/Input';

interface ItemRowProps {
  color?: string;
}

export const ItemRow = styled(TableCell)<ItemRowProps>`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ color }) => (color || '#292929')};
`;

export const Container = styled('div')`
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px;
`;
