import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ContainerProps {
  isDisabled?: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  background: ${({ isDisabled }) => isDisabled ? '#F8F9FD' : '#EBF7F2'};
  border-radius: 8px;
  width: 248px;
  height: 270px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: ${({ isDisabled }) => isDisabled ? 1 : 0.5};
  }
`;

export const Title = styled(Typography)<ContainerProps>`
  color: ${({ theme, isDisabled }) => isDisabled ? '#A4A4A4' : theme?.palette?.custom?.green?.main};
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const SubTitle = styled(Typography)<ContainerProps>`
  color: ${({ theme, isDisabled }) => isDisabled ? '#A4A4A4' : theme?.palette?.custom?.green?.main};
  text-align: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const Price = styled(Typography)<ContainerProps>`
  color: ${({ theme, isDisabled }) => isDisabled ? '#A4A4A4' : theme?.palette?.custom?.green?.main};
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  max-width: 100px;
  height: 48px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  background: #07B169;
  border-radius: 8px;
  text-transform: inherit;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
