import React, {useEffect, useState} from 'react';
// Constants
import { languagesData } from 'utils/constants/user-profile';
// Components
import Select from 'components/UI/Select';
// Styles
import { Section } from '../styles';
import { Title, Container } from './styles';
// Translation
import { useTranslation } from 'react-i18next';
import { useAuth } from "hooks/useAuth";
import { useUpdateUserMutation } from "store/auth/authService";

// const lngs: any = {
//   en: { nativeName: 'English' },
//   ua: { nativeName: 'Ukrainian' }
// };

const UserProfileLanguage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [updateUser] = useUpdateUserMutation();
  const [lang, setLang] = useState("en");


  useEffect(() => {
    if (!user) { return }
    setLang(user.settings.language)
    i18n.changeLanguage(user.settings.language);
  }, [user])

  const handleLangChange = async (evt: any) => {
    const lang = evt.target.value;
    if (!user) { return; }
    try {
      await updateUser({
        body: {
          firstname: user?.firstname,
          lastname: user?.lastname,
          phone_number: user.phone_number,
          residence: user?.residence,
          settings: {
            language: lang
          }
        }
      })
      setLang(lang);
      i18n.changeLanguage(lang);
    } catch (e: any) {
      console.log(e);
    }

  };

  return (
    <Section isSpaceBottom={30} id="language">
      <Title variant="h2">
        {t('Мова')}
      </Title>
      <Container>
        <Select
          name="language"
          data={languagesData}
          value={lang}
          defaultValue={languagesData[1]?.value}
          onChange={handleLangChange}
          label={t('Оберіть мову, яку будете використовувати у нашому сервісі')}
        />
      </Container>
    </Section>
  )
};

export default UserProfileLanguage;
