import React from 'react';
// Icons
import { ArrowLeftIcon, LogoText } from 'assets/icons';
// Styles
import {
  IconButton,
  DrawerHeader,
  Link,
} from './styles';

interface SideBarHeaderProps {
  handleDrawer: () => void;
}
const SideBarHeader: React.FC<SideBarHeaderProps> = ({ handleDrawer }) => (
  <DrawerHeader>
    <Link to="/">
      <LogoText />
    </Link>

    <IconButton
      onClick={handleDrawer}
      variant="light"
    >
      <ArrowLeftIcon color="#ffffff" />
    </IconButton>
  </DrawerHeader>
);

export default SideBarHeader;
