import React, { FC, useState } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';

import { IAdditionalLosseResponse } from 'store/dashboard/dashboardTypes'
import { SettingsIcon, MoreSetingsIcon, ArrowRightIcon, ArrowLeftIcon } from 'assets/icons';
import Tooltip from 'components/UI/Tooltip';
import dayjs from 'dayjs';
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';

import SourceModal from './SourceModal';
import SettingsModal from './SettingsModal';
import AddExpenseModal from './AddExpenseModal';
import ConfirmationModal from "components/ConfirmationModal";
// Styles
import {
  CollapseTableRow,
  SettingsLink,
  StyledTableHeader,
  Arrow,
  StyledTableContainer,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
  PaginationWrap,
  PaginationRow
} from './styles';
import {AMOUNT_DATA_SELECT} from "utils/constants/tutor";

interface Data {
  name: string | number,
  cost: string,
  created_at: string,
  end: string,
  amount: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Витрати',
  },
  {
    id: 'cost',
    numeric: false,
    label: 'Бюджет',
  },
  {
    id: 'created_at',
    numeric: false,
    label: 'Початок',
  },
  {
    id: 'end',
    numeric: false,
    label: 'Кінець',
  },
  {
    id: 'amount',
    numeric: false,
    label: 'Періодичність',
  },
];

interface ExpensesTableHeaderProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSettingClick: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  collapse?: boolean;
}

function ExpensesTableHead(props: ExpensesTableHeaderProps) {
  const { t } = useTranslation();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, collapse, handleSettingClick } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <StyledTableHeader className={collapse ? 'collapse' : ''}>
      <TableRow>
        <TableCell padding="checkbox" className="empty-checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            width={400}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          {/* <SettingsLink onClick={handleSettingClick}>
            <SettingsIcon />
          </SettingsLink> */}
        </TableCell>
      </TableRow>
    </StyledTableHeader>
  );
}

interface ExpensesTableProps {
  additionalLosse?: IAdditionalLosseResponse[],
  refetch: () => void
}

const ExpensesTable: FC<ExpensesTableProps> = ({ additionalLosse, refetch }) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteExpensesItem, setDeleteExpensesItem] = useState('');
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState<boolean | number>(0);
  const [openSubheader, setOpenSubheader] = React.useState(false);
  const [editing, setEditing] = React.useState<IAdditionalLosseResponse | null>(null);

  const [deleteExpense] = useDeleteExpensesMutation();
  const toggleModal = () => setOpenDeleteModal((prev) => !prev);
  const categories = additionalLosse?.reduce((acc, { extras }) => {
    // @ts-ignore
    if (acc.includes(extras.category)) {
      return acc;
    }
    // @ts-ignore
    acc.push(extras.category);
    return acc;
  }, [])


  const tableData = categories?.map((category) => ({
    group: category,
    items: additionalLosse?.filter(({ extras }) => (extras.category === category))
  }));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.reduce((acc, { group, items }) => {
        // @ts-ignore
        acc = [...acc, ...items.map(n => n.name)];
        return acc;
      }, [])
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    // @ts-ignore
    const selectedIndex = selected?.indexOf(name);
    // @ts-ignore
    let newSelected = [];

    if (selectedIndex === -1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      // @ts-ignore
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    // @ts-ignore
    setSelected(newSelected || []);
  };
  // @ts-ignore
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const onCloseSettingsModal = () => setOpenSettingsModal((prev) => !prev);
  const getPereodicity = (amount: number) => {
    if (!amount) {
      return '';
    }

    const found = AMOUNT_DATA_SELECT.find(({ value }) => (amount === value));
    return found ? t(found.label) : '';
  }

  const updateDate = (data: string) => {
    const dataCreate = dayjs(data as any).format('DD.MM.YYYY');

    return dataCreate
  }

  const deleteAdditionalLosses = async () => {
    await deleteExpense(deleteExpensesItem);
    setOpenDeleteModal(false);
    refetch();
  }

  return (
    <StyledTableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
      >
        <ExpensesTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={6}
          handleSettingClick={() => { setOpenSettingsModal((prev) => !prev); }}
        />
        <TableBody>
          {
            tableData?.map(({ group, items }, index) => (
              <>
                <CollapseTableRow
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                  onClick={() => {
                    // @ts-ignore
                    if (open !== false && open === index) {
                      setOpen(false)
                    } else {
                      setOpen(index);
                    }
                  }
                  }
                >
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {t(group)}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Arrow
                      // @ts-ignore
                      $isOpen={open !== false && open === index}
                    />
                  </TableCell>
                </CollapseTableRow>
                {
                  // @ts-ignore
                  items?.length && items[0]?.rows
                    ? (
                      /* items?.map(({name, rows}) => (
                        <>
                          <TableRow sx={{ '& > *': { border: 'unset' } }}>
                            <TableCell style={{ padding: 0 }} colSpan={12}>
                              <Collapse in={open} timeout="auto" unmountOnExit>
                                <Table>
                                  <ExpensesTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={6}
                                    collapse
                                    handleSettingClick={() => {}}
                                  />
                                  <TableBody>
                                    <TableRow sx={{ '& > *': { border: 'unset' } }}>
                                      <TableCell></TableCell>
                                      <SubheaderTableCell>
                                        <Typography variant="tableSubheaderText">
                                          {name}
                                        </Typography>
                                        <Arrow
                                          $isOpen={openSubheader}
                                          onClick={() => setOpenSubheader(!openSubheader)}
                                        />
                                      </SubheaderTableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell style={{ padding: 0 }} colSpan={12}>
                              <Collapse in={openSubheader} timeout="auto" unmountOnExit>
                                <Table>
                                  <ExpensesTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={6}
                                    collapse
                                    handleSettingClick={() => {}}
                                  />
                                  <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                      .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                          <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.expenses)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                          >
                                            <TableCell padding="checkbox"  component="th" scope="row">
                                              <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                  'aria-labelledby': labelId,
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              component="th" scope="row"
                                              id={labelId}
                                            >
                                              {row.name}
                                            </TableCell>
                                            <TableCell>{row.cost}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                            <TableCell>{row.end}</TableCell>
                                            <TableCell>{row.amount}</TableCell>
                                            <TableCell>
                                              <Tooltip
                                                arrow
                                                placement="bottom"
                                                tooltipText=""
                                                tooltipContent={(
                                                  <MenuList disablePadding>
                                                    <MenuListItem disablePadding>
                                                      <MenuListItemButton
                                                        onClick={() => {setOpenAddExpenseModal((prev) => !prev);}}
                                                      >
                                                        <Text variant="regularText">Редагувати</Text>
                                                      </MenuListItemButton>
                                                    </MenuListItem>
                                                    <MenuListItem disablePadding>
                                                      <MenuListItemButton
                                                        onClick={() => {setOpenEditModal((prev) => !prev);}}
                                                      >
                                                        <Text variant="regularText">Джерела інвестицій</Text>
                                                      </MenuListItemButton>
                                                    </MenuListItem>
                                                    <MenuListItem disablePadding>
                                                      <MenuListItemButton>
                                                        <Text variant="regularText">Видалити</Text>
                                                      </MenuListItemButton>
                                                    </MenuListItem>
                                                  </MenuList>
                                                )}
                                              >
                                                <MenuButton variant="icon">
                                                  <MoreSetingsIcon />
                                                </MenuButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      )) */
                      ''
                    )
                    : (
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse in={
                            // @ts-ignore
                            open !== false && open === index
                          } timeout="auto" unmountOnExit>
                            <Table>
                              <ExpensesTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={6}
                                collapse
                                handleSettingClick={() => { }}
                              />
                              <TableBody>
                                {
                                  // @ts-ignore
                                  stableSort(items, getComparator(order, orderBy))
                                    .map((row, index) => {
                                      // @ts-ignore
                                      const isItemSelected = isSelected(row.name);
                                      const labelId = `enhanced-table-checkbox-${index}`;

                                      return (
                                        <TableRow
                                          hover
                                          onClick={
                                            // @ts-ignore
                                            (event) => handleClick(event, row.name)
                                          }
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={row.name}
                                          selected={isItemSelected}
                                        >
                                          <TableCell padding="checkbox" component="th" scope="row">
                                            <Checkbox
                                              color="primary"
                                              checked={isItemSelected}
                                              inputProps={{
                                                'aria-labelledby': labelId,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell
                                            component="th" scope="row"
                                            id={labelId}
                                          >
                                            {row.name}
                                          </TableCell>
                                          <TableCell>{row.value}</TableCell>
                                          <TableCell>
                                            {updateDate(row?.created_at as any)}
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell>{t(getPereodicity(row.amount))}</TableCell>
                                          <TableCell>
                                            <Tooltip
                                              arrow
                                              placement="bottom"
                                              tooltipText=""
                                              tooltipContent={(
                                                <MenuList disablePadding>
                                                  <MenuListItem disablePadding>
                                                    <MenuListItemButton
                                                      onClick={() => {
                                                        setEditing(row);
                                                        setOpenAddExpenseModal((prev) => !prev);
                                                      }}
                                                    >
                                                      <Text variant="regularText">{t('Редагувати')}</Text>
                                                    </MenuListItemButton>
                                                  </MenuListItem>
                                                  {/*<MenuListItem disablePadding>*/}
                                                  {/*  <MenuListItemButton*/}
                                                  {/*    onClick={() => { setOpenEditModal((prev) => !prev); }}*/}
                                                  {/*  >*/}
                                                  {/*    <Text variant="regularText">{t('Джерела інвестицій')}</Text>*/}
                                                  {/*  </MenuListItemButton>*/}
                                                  {/*</MenuListItem>*/}
                                                  <MenuListItem disablePadding>
                                                    <MenuListItemButton onClick={() => {
                                                      setDeleteExpensesItem(row?.id as string);
                                                      toggleModal();
                                                    }}>
                                                      <Text variant="regularText" color="red">{t('Видалити')}</Text>
                                                    </MenuListItemButton>
                                                  </MenuListItem>
                                                </MenuList>
                                              )}
                                            >
                                              <MenuButton>
                                                <MoreSetingsIcon />
                                              </MenuButton>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )
                }
              </>
            ))
          }
        </TableBody>
      </Table>
      {/*<PaginationRow>*/}
      {/*  <PaginationWrap>*/}
      {/*    <Text variant="h6">{t('Сторінка')}  1 - 10</Text>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowLeftIcon />*/}
      {/*    </Button>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowRightIcon />*/}
      {/*    </Button>*/}
      {/*  </PaginationWrap>*/}
      {/*</PaginationRow>*/}
      <SourceModal
        isOpen={openEditModal}
        id="1"
        onClose={() => { setOpenEditModal((prev) => !prev); }}
      />
      <AddExpenseModal
        isOpen={openAddExpenseModal}
        expense={editing}
        onClose={() => {
          setEditing(null);
          setOpenAddExpenseModal((prev) => !prev);
          refetch();
        }}
      />
      <ConfirmationModal
        deleteHandler={deleteAdditionalLosses}
        onClose={toggleModal}
        isOpen={openDeleteModal}
        title={t('Видалити')+'?'}
      />
      <SettingsModal isOpen={openSettingsModal} onClose={onCloseSettingsModal} />
    </StyledTableContainer>
  );
}

export default ExpensesTable;
