/* eslint-disable max-len */
import React, { FC } from 'react';
import {
  Control, Controller, FieldErrors,
} from 'react-hook-form';
import { CancelIcon } from 'assets/icons';
import Input from 'components/UI/Input';
// Interface
import { IProductCreate, IPackagingCreate } from 'interfaces/product';
// Styles
import { Container, DeleteBtn } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface PackagingFormRowProps {
  updateItem: (id?: string) => void;
  deleteItem: (id: number, material_id?: string) => void;
  errors: FieldErrors<{ materialProducts: IProductCreate[] }>
  control: Control<{ materialProducts: IProductCreate[] }>,
  indexProduct: number;
  indexMaterial: number;
  packaging: IPackagingCreate;
}

const PackagingFormRow: FC<PackagingFormRowProps> = ({
  deleteItem, errors, control, indexProduct, indexMaterial, packaging, updateItem,
}) => {
  const { t } = useTranslation();
  const updatePackaging = () => {
    updateItem(packaging?.packaging_id)
  }

  const deletePackaging = () => {
    deleteItem(indexMaterial, packaging?.packaging_id);
  };

  return (
    <Container onBlur={updatePackaging}>
      <Controller
        render={({ field }) => (
          <Input
            // label={`${indexMaterial + 1}. ` + t('Назва')}
            label={`${indexMaterial + 1}. ${field.value}`}
            {...field}
            className="name-input"
            error={!!errors.materialProducts?.[indexProduct]?.expenses?.Packaging?.[indexMaterial]?.name?.message}
            helperText={errors.materialProducts?.[indexProduct]?.expenses?.Packaging?.[indexMaterial]?.name?.message}
          />
        )}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Packaging.${indexMaterial}.name`}
        rules={{ required: t('Це поле є обовязковим') }}
      />

      <Controller
        render={({ field }) => (
          <Input
            label={t('Ціна')}
            {...field}
            type="number"
            placeholder="0"
            className="amount-input"
            error={!!errors.materialProducts?.[indexProduct]?.expenses?.Packaging?.[indexMaterial]?.value?.message}
            helperText={errors.materialProducts?.[indexProduct]?.expenses?.Packaging?.[indexMaterial]?.value?.message}
          />
        )}
        control={control}
        name={`materialProducts.${indexProduct}.expenses.Packaging.${indexMaterial}.value`}
        rules={{ required: t('Це поле є обовязковим') }}
      />

      <DeleteBtn variant="icon" onClick={deletePackaging}>
        <CancelIcon />
      </DeleteBtn>
    </Container>
  );
};

export default PackagingFormRow;
