import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
// Store
import { usePasswordForgotMutation } from 'store/auth/authService';
// Utils
import { defaultForgotPasswordValues } from 'utils/constants/auth';
import { errorByType } from 'utils/helpers/index';
// Global Components
import Loading from 'components/UI/Loading';
import AuthBackground from 'components/AuthBackgroud';
// Images
import ForgotPasswordImage from 'assets/images/forgot-password-image.svg';
// Components
import ForgotPasswordForm from './ForgotPasswordForm';
// Styles
import { Container } from './styles';
import {useTranslation} from "react-i18next";

const ForgotPassword: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const formState = useForm<{ email: string }>({
    defaultValues: defaultForgotPasswordValues,
    mode: 'all',
  });

  const [passwordForgot, { isLoading }] = usePasswordForgotMutation();

  const onSuccessfullyChange = () => {
    setIsSuccess((prev) => !prev);
  };

  const onSubmit: SubmitHandler<{ email: string }> = async (values) => {
    try {
      await passwordForgot(values).unwrap();

      onSuccessfullyChange();
    } catch (error: any) {
      formState.setError('email', {
        type: 'custom',
        message: t(errorByType(error?.data?.message)),
      });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <ForgotPasswordForm
        isSuccess={isSuccess}
        formState={formState}
        onSubmit={onSubmit}
      />

      <AuthBackground
        image={ForgotPasswordImage}
        maxHeight="670px"
        isImageBottom
      />
    </Container>
  );
};

export default ForgotPassword;
