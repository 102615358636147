import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IPlanSales } from 'store/dashboard/dashboardTypes';
import { useGetPipelineByIdQuery, useGetSalesPlanQuery, useGetSalesPlanProductsQuery } from 'store/dashboard/dashboardServices';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import {
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
} from '@mui/material';
import { getUtcFormatDate } from 'utils/helpers/index';
// Constants
import { month } from 'utils/constants/date';
// Icons
import { SearchIcon } from 'assets/icons';
// Components
import ToggleButtons from './ToggleButtons';
import SalesTableBody from './SalesTableBody';
// Styles
import {
  Container,
  Title,
  Wrapper,
  StyledInput,
  Label,
  WrapperInput,
  Paper,
  ItemRow,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface IDate {
  startDate: any;
  endDate: any;
}

interface SalesPlanSectionProps {
  salesPlanProductsData: IPlanSales[];
}

const SalesPlanSection: FC<SalesPlanSectionProps> = ({ salesPlanProductsData }) => {
  const { pipeline_id } = useParams();
  const [typeTable, setTypeTable] = useState('plan_sales');

  const { t } = useTranslation();
  return (
    <Container maxWidth={1122}>
      <Wrapper width="100%" maxWidth="100%">
        <Title variant="h1">
        { t('План продажів') }
        </Title>

        <WrapperInput>
          <ToggleButtons value={typeTable} setValue={setTypeTable} />

          {/* <StyledInput
            placeholder="Пошуковий запит..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Label variant="h6">
            Дивитись всі
          </Label> */}
        </WrapperInput>
      </Wrapper>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <ItemRow>
              { t('Назва') }
              </ItemRow>
              {month.map((item) => (
                <ItemRow key={item}>
                  { t(`${item}`)}
                </ItemRow>
              ))}
            </TableRow>
          </TableHead>

          <SalesTableBody
            typeTable={typeTable}
            planSales={salesPlanProductsData as IPlanSales[]}
            pipeline_id={pipeline_id as string}
          />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default SalesPlanSection;
