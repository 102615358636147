import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Store
import { useGetProjectByIdQuery } from 'store/dashboard/dashboardServices';
import { selectDashboard, setPipelineCompare, resetCompare } from 'store/dashboard/dashboardSlice'
import { useGetPipelineQuery } from 'store/pipeline/pipelineServices';
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { setDefaultValuePipeline } from 'store/tutors/tutorsSlice';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
import { useTrialUser } from 'hooks/useTrialUser';
// Components
import Loading from 'components/UI/Loading';
import Footer from 'components/Footer';
import ProjectCardBlocked from 'pages/Projects/ProjectsCard/ProjectCardBlocked';
import { PlusIcon } from 'assets/icons';
import { useDispatch } from 'react-redux';
import PipelinesTitleSection from './PipelinesTitleSection';
import PipelinesFilterSection from './PipelinesFilterSection';
import PipelineCard from './PipelineCard';
// Styles
import {
  PipelinerCardContainer,
  ContainerButtonCreate,
  ButtonCreate,
  Container,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import {useNavigate} from "react-router";
import {useHandleRequestError} from "hooks/useHandleRequestError";
const Pipelines: FC = () => {
  const [mode, setMode] = useState(1);
  const [isDraft, setIsDraft] = useState(false);
  const [ss, setComparePipelines] = useState<IPipeline[]>([]);
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const { redirectToTutor, redirectToComparePipeline } = useTutorRoutes();
  const { comparePipelines } = useSelector(selectDashboard);
  const { lockNotFreeFunctionality } = useTrialUser();
  const { isLoading, isFetching, data, refetch, isError } = useGetPipelineQuery({ id: id as string });
  const { t } = useTranslation();

  const { data: project, refetch: projectRefetch } = useGetProjectByIdQuery({
    project_id: id as string,
  });

  useHandleRequestError(isError);

  const handleChangeMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: number,
  ) => {
    if (newMode || newMode === 0) {
      setMode(newMode);
    }
  };

  const refetchGetPipeline = () => {
    refetch();
  }

  const redirectToCreateTutor = () => {
    redirectToTutor(id as string);
  };

  const filteredPipeline = () => {
    const updateList = (data?.data?.filter((item) => item?.status === mode) as IPipeline[]);

    return updateList;
  };

  const createTutorHandler = () => {
    if (lockNotFreeFunctionality && filteredPipeline().length >= 1) return false;
    dispatch(setDefaultValuePipeline(id as string));

    redirectToCreateTutor();
  };

  const checkDraftPipelines = () => {
    const checkDraft = data?.data?.filter((item) => item?.status === 0)

    setIsDraft(!!checkDraft?.length)
  }

  const handleCompare = (pipeline: IPipeline | string) => {
    dispatch(setPipelineCompare(pipeline));
  }

  const deleteCompare = () => {
    dispatch(resetCompare());
  }

  const redirectToComparePage = () => {
    redirectToComparePipeline(id as string);
  }

  useEffect(() => {
    projectRefetch()
    refetchGetPipeline()
  }, [])

  useEffect(() => {
    checkDraftPipelines()
  }, [data?.data])

  if (isLoading || isFetching) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <PipelinesTitleSection />

      <PipelinesFilterSection
        redirectToComparePipeline={redirectToComparePage}
        handleChangeMode={handleChangeMode}
        deleteCompare={deleteCompare}
        isCompare={!!(comparePipelines?.length === 2)}
        isDraft={isDraft}
        mode={mode}
      />

      <PipelinerCardContainer>
        <ContainerButtonCreate onClick={createTutorHandler}>
          <ButtonCreate role="button" block={lockNotFreeFunctionality && filteredPipeline().length >= 1}>
            <PlusIcon color={lockNotFreeFunctionality && filteredPipeline().length >= 1 ? "#A4A4A4" : "#07B169"} />
            {t('Створити новий пайплайн')}
          </ButtonCreate>
        </ContainerButtonCreate>

        {filteredPipeline()?.map((pipeline, index) => {
          if (lockNotFreeFunctionality && index >= 1) {
            return  <ProjectCardBlocked key={pipeline.pipeline_id} />
          } else {
            return (
              <PipelineCard
                handleCompare={handleCompare}
                redirectToComparePage={redirectToComparePage}
                comparePipelines={comparePipelines}
                currency={project?.currency as string}
                key={pipeline?.pipeline_id}
                pipeline={pipeline}
                isGeneral
              />
            )
          }
        })}
      </PipelinerCardContainer>

      <Footer isSpace  isSticki/>
    </Container>
  );
};

export default Pipelines;
