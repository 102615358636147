import React from 'react';
import {useTranslation} from "react-i18next";
// Icons
import { FooterLogo } from 'assets/icons';
// Styles
import {Container, StyledLink, StyledMuiLink} from './styles';

interface FooterLinksProps {
  isSpace?: boolean;
}

const FooterLinks: React.FC<FooterLinksProps> = ({ isSpace }) => {
  const { t } = useTranslation();
  return (
    <Container isSpace={isSpace}>
      <FooterLogo />

      <StyledLink to="/privacy-policy">
        Privacy Policy
      </StyledLink>

      <StyledLink to="/public-offer">
        Public Offer
      </StyledLink>

      <StyledMuiLink href="mailto:support@pipeliner.online?subject=Problem Report&body=Just let us know if you face any issues.">
        {t('Щось пішло не так?')}
      </StyledMuiLink>
    </Container>
  );
}

export default FooterLinks;
