import React from 'react';
// Styles
import { Typography } from '@mui/material';
import {
  Container,
  HeaderRow,
  Arrow,
} from './styles';

interface SelectHeaderProps {
  toggleDropdown: () => void,
  isOpen: boolean,
}

const SelectHeader: React.FC<SelectHeaderProps> = ({ children, toggleDropdown, isOpen }) => (
  <Container onClick={toggleDropdown}>
    <HeaderRow>
      <Typography variant="h3">
        {children}
      </Typography>
      <Arrow $isOpen={isOpen} />
    </HeaderRow>
  </Container>
);

export default SelectHeader;
