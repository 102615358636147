import React from 'react';
import { UseFormReturn, Controller, SubmitHandler } from 'react-hook-form';
import { authRoutePath } from 'routes/routePath';
import { useTranslation } from "react-i18next";
// Utils
import { InputsRules } from 'utils/constants/auth';
// Icons
import { Logo } from 'assets/logos';
import MasterCardIcon from 'assets/icons/visa-master-card.png';
// Global Components
import Input from 'components/UI/Input';
// Components
import SuccessfullySent from '../SuccessfullySent';
// Styles
import {
  Container,
  Form,
  Title,
  SubTitle,
  Button,
  Link,
  Image,
  Wrapper,
} from './styles';

interface ForgotPasswordFormProps {
  onSubmit: SubmitHandler<{ email: string }>;
  formState: UseFormReturn<{ email: string }>;
  isSuccess: boolean;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  isSuccess,
  formState,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { isValid } = formState.formState;
  const email = formState.watch('email');
  const error = formState.formState.errors.email?.message;

  if (isSuccess) {
    return (
      <Container>
        <SuccessfullySent email={email} />

        <Wrapper>
          <Link to={authRoutePath.LOGIN}>
            {t('Повернутись')}
          </Link>

          <Image src={MasterCardIcon} alt="cardIcon" />
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Form>
        <Logo />

        <Title variant="h1">
          {t('Забули пароль?')}
        </Title>

        <SubTitle variant="h3">
          {t('Будь ласка, введіть зареєстровану поштову адресу. На вашу скриньку буде надіслано подальші інструкції для допомоги скинути пароль.')}
        </SubTitle>

        <Controller
          render={({ field }) => (
            <Input
              error={!!error}
              helperText={error}
              label={t("Пошта")}
              {...field}
            />
          )}
          control={formState.control}
          rules={{
            required: {
              value: InputsRules.required.value,
              message: t(InputsRules.required.message)
            },
            pattern: {
              value: InputsRules.patternEmail.value,
              message: t(InputsRules.patternEmail.message),
            },
          }}
          name="email"
        />

        <Button
          // onClick={onSuccessfullyChange}
          onClick={formState.handleSubmit(onSubmit)}
          disabled={!isValid}
          variant="contained"
          fullWidth
        >
          {t('Надіслати інструкції щодо запиту')}
        </Button>
      </Form>

      <Wrapper>
        <Link to={authRoutePath.LOGIN}>
          {t('Повернутись')}
        </Link>

        <Image src={MasterCardIcon} alt="cardIcon" />
      </Wrapper>
    </Container>
  );
};

export default ForgotPasswordForm;
