import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 7px;
`;

export const Container = styled(Box)`
  max-width: 48%;
`;
