import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useUpdatePipelineMutation } from 'store/pipeline/pipelineServices';
import { useCreateAdditionalLossesMutation } from 'store/tutors/tutorsServices';
import { ICreateAdditionalCosts } from 'store/tutors/tutorsTypes';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import AdditionalCostItem from './AdditionalCostItem';
// Styles
import { Container, LinkButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { useAuth} from '../../../hooks/useAuth';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';

const AdditionalCosts = () => {
  const { t } = useTranslation();
  const { pipeline_id, project_id, additionalValues, step1 } = useSelector(selectTutor);
  const { step } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { redirectToTutorStep } = useTutorRoutes();
  const { redirectToDashboard } = useProjectRoutes();

  const [updatePipeline] = useUpdatePipelineMutation();
  const [createAdditionalLosses] = useCreateAdditionalLossesMutation();

  const formState = useForm<{ additionalCosts: ICreateAdditionalCosts[] }>({
    defaultValues: { additionalCosts: additionalValues },
    mode: 'all',
  });

  const formStateArray = useFieldArray({
    control: formState.control,
    name: 'additionalCosts',
  });

  const appendItem = () => {
    formStateArray.append({
      name: '',
      value: '',
      amount: 1,
      category: '',
      additional_id: '',
    });
  };

  const deleteItem = async (id: number) => {
    formStateArray.remove(id);
  };

  const updateValues = (values: ICreateAdditionalCosts[]) => {
    const filteredValue = values.filter(cost => !cost.additional_id);

    return filteredValue.map(item => ({
      name: item.name,
      value: item.value,
      amount: +item.amount === 0 ? 1 : +item.amount,
      extras: {
        category: item.category,
        isDuration: +item.amount === 0,
      },
      group: {
        parent_id: null,
        name: '',
      }
    }))
  }

  const onNextStep: SubmitHandler<{ additionalCosts: ICreateAdditionalCosts[] }> = async (values) => {
    try {
      if (updateValues(values.additionalCosts).length) {
        await createAdditionalLosses({
          pipeline_id: pipeline_id as string,
          project_id: project_id as string,
          body: updateValues(values.additionalCosts),
        }).unwrap();

        await updatePipeline({
          pipeline_id: pipeline_id as string,
          project_id: project_id as string,
          body: { status: 1, pipeline_name: step1.pipeline_name, tax: 0 }
        }).unwrap()
      }

      redirectToDashboard(project_id as string, 'indicators', pipeline_id as string);
      formState.reset();
      const eventPayload: SegmentEventPayload = {
        eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_FINAL,
        payload: { email: user?.email },
      };
      dispatch(addEvent(eventPayload));

    } catch (err) {
      console.error(err);
    }
  };

  const onPrevStep = () => {
    redirectToTutorStep(project_id, 6);
  };

  useEffect(() => {
    if (step === '7') {
      formState.trigger();
    }
  }, [step])

  useEffect(() => {
    formState.setValue('additionalCosts', additionalValues);
  }, [additionalValues]);

  return (
    <TabPanel index={7} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={6} />

        <Typography variant="h1">
          {t('Групи витрат')}
        </Typography>

        {formStateArray.fields.map((item, index) => (
          <AdditionalCostItem
            key={index}
            trigger={formState.trigger}
            update={formStateArray.update}
            errors={formState.formState.errors}
            control={formState.control}
            additionalCosts={item}
            deleteItem={deleteItem}
            index={index}
          />
        ))}

        <LinkButton
          onClick={appendItem}
          variant="link"
        >
          {t('Додати ще один')}
        </LinkButton>
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={6}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default AdditionalCosts;
