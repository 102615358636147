import React from 'react';
// Icons
import MasterCardIcon from 'assets/icons/visa-master-card.png';
import { authRoutePath } from 'routes/routePath';
// Styles
import {
  Container, Label, Link, Wrapper, Image,
} from './styles';
// Translation
import { useTranslation } from 'react-i18next';

const CreateAccountLink: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Label>
          {t('pages.Login.CreateAccountLink.Ще не зареєстровані?')}
        </Label>

        <Link to={authRoutePath.REGISTRATION}>
          {t('pages.Login.CreateAccountLink.Створити аккаунт')}
        </Link>
      </Wrapper>

      <Image src={MasterCardIcon} alt="cardIcon" />
    </Container>
  )
};

export default CreateAccountLink;
