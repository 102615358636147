import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Store
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import { selectTutor } from 'store/tutors/tutorsSlice';
import { IPipeline } from 'store/dashboard/dashboardTypes';
// Utils
import { incomData } from 'utils/calculates/general-income-diagram';
// Styles
import {
  Container, ContainerHeader, Header, Prise, TooltipRow, Wrapper, Circle, Title, Label,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface ChartTooltipProps {
  active: any;
  payload: any;
  label: any;
  offset: number;
  currency: string;
}

interface IRowItem {
  name: string;
  cost: number;
  percent: string | number;
  color: string;
  key: number;
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  active, payload, currency,
}) => {
  const { planSales } = useSelector(selectDashboard);
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Container>
       <ContainerHeader>
          <Header variant="regularText">
            { t('Загальний дохід') }
          </Header>

          <div>
            <Prise variant="boldRegularText">
              {currency} {incomData(planSales, payload)?.totalValue}
            </Prise>
          </div>
        </ContainerHeader>

        {incomData(planSales, payload)?.dataRow?.map((item: any) => (
          <TooltipRow key={item.key}>
            <Wrapper>
              <Circle color={item?.color as string} />

              <Title>
                {item?.product_name}
              </Title>
            </Wrapper>

            <Wrapper>
              <Label>
                {currency} {item?.income}
              </Label>

              <Label color="#838383" isMargin={5}>
                ({item?.percent} %)
              </Label>
            </Wrapper>
          </TooltipRow>
        ))}
      </Container>
    );
  }

  return null;
};

export default ChartTooltip;
