import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { type Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import type { SegmentEventPayload} from './analyticsTypes';

export interface AnalyticsState {
  segmentAnalytics: Analytics | null;
}

const initialState: AnalyticsState = {
  segmentAnalytics: null,
};

export const loadSegmentAnalytics = createAsyncThunk(
  'analytics/loadSegmentAnalytics',
  async (writeKey: string) => {
    const [response] = await AnalyticsBrowser.load({ writeKey });
    return response;
  },
);

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    identify: (state, action: PayloadAction<string>) => {
      if (!state.segmentAnalytics) return;
      state.segmentAnalytics.identify({ email: action.payload });
    },
    trackPage: (state) => {
      if (!state.segmentAnalytics) return;
      state.segmentAnalytics.page();
    },
    addEvent: (state, action: PayloadAction<SegmentEventPayload>) => {
      if (!state.segmentAnalytics) return;
      const { eventName, payload } = action.payload;
      state.segmentAnalytics.track(eventName, payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadSegmentAnalytics.fulfilled, (state, action: PayloadAction<Analytics>) => {
      if (!action.payload || state.segmentAnalytics) return;
      // @ts-ignore
      state.segmentAnalytics = action.payload;
    });
  }
});

export default analyticsSlice.reducer;

export const { identify, trackPage, addEvent } = analyticsSlice.actions;

export const segmentAnalytics = (state: RootState) => state.analytics.segmentAnalytics;
