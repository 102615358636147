import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme?.palette?.custom?.black?.light,
  padding: '15px 17px',
}));

export const IconButton = styled(Button)`
  min-width: 34px;
  height: 34px;
  border-radius: 8px;
  padding: 0;
  background: ${({ theme }) => theme?.palette?.custom?.dark?.main};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme?.palette?.custom?.dark?.main};
    opacity: 0.8;
  }

  &.transparent {
    background: transparent;

    &:hover {
      background-color: #F0F0F2;
    }
  }
`;

export const Link = styled(StyledLink)`
  display: flex;
  align-items: center;
  height: 100%;
  line-height: normal;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.7;
  }
`;
