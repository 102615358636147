import React from 'react';
// Icons
import { DeleteIcon } from 'assets/icons';
// Styles
import { Container, Title, IconButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface BankCardHeaderProps {
  onDeleteCard: () => void;
}

const BankCardHeader: React.FC<BankCardHeaderProps> = ({ onDeleteCard }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title variant="h4">
        {t("components.BankCard.BankCardHeader.Privat Bank")}    </Title>
      <IconButton onClick={onDeleteCard}>
        <DeleteIcon />
      </IconButton>
    </Container>
  )
};

export default BankCardHeader;
