import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
} from '@mui/material';

export const Container = styled(Box)`
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  margin-left:5px ;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
`;

export const WrapperText = styled(Box)`
  display: flex;
`;

export const TextButton = styled('span')`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
