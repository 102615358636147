import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar';
import { Container, Wrapper } from 'layouts/MainLayout/styles';
import { useScreenSize } from "hooks/useScreenSize";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import { useDrawer } from "hooks/useDrawer";

const FreelancerMainLayout: FC = () => {
  const { isMobile } = useScreenSize();
  const { isOpen, toggle } = useDrawer(true);
  const {
    isOpen: isMenuOpen,
    open: openMenu,
    toggle: toggleMenu,
  } = useDrawer();

  return (
    <Container>
      { isMobile ?
        <MobileMenu
          handleDrawer={toggleMenu}
          isOpen={isMenuOpen} /> :
        <SideBar
          handleDrawer={toggle}
          isOpen={isOpen}
        />
      }
      <Wrapper component="main">
        <Header handleDrawer={openMenu} />
        <Outlet />
      </Wrapper>
    </Container>
  );
};

export default FreelancerMainLayout;
