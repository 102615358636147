import React from 'react'
import { useTranslation } from "react-i18next";
import {
  Button,
  InputAdornment
} from '@mui/material';
// Icons
import {
  CalendarIcon,
  DownloadIcon,
  UploadIcon,
  SettingsIcon,
} from 'assets/icons';
// Components
import RangeDatePicker from '../RangeDatePicker';
// Styles
import { HeaderRow, H1, SettingsLink, HeaderRightBlock, DatePickerWrapper, IconWrap } from './styles';

interface TitleSectionProps {
  handleSettingModal: () => void;
}

const TitleSection: React.FC<TitleSectionProps> = ({ handleSettingModal }) => {
  const { t } = useTranslation();

  return (
    <HeaderRow>
      <H1 variant="h1">
        {t('Продукти та сервiси')}
        {/* <SettingsLink onClick={handleSettingModal}>
          <SettingsIcon />
        </SettingsLink> */}
      </H1>

      <HeaderRightBlock>
        {/* <Button variant="icon" className="toolbar-btns">
          <UploadIcon />
        </Button>

        <Button variant="icon" className="toolbar-btns">
          <DownloadIcon />
        </Button> */}

        {/* <DatePickerWrapper>
          <RangeDatePicker />

          <IconWrap>
            <CalendarIcon />
          </IconWrap>
        </DatePickerWrapper> */}
      </HeaderRightBlock>
    </HeaderRow>
  )
}

export default TitleSection;
