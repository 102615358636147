import { styled } from '@mui/material/styles';
import { Typography, CircularProgress } from '@mui/material';

export const Container = styled('div')`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  margin-bottom: 20px;
`;

export const StyledLoading = styled(CircularProgress)`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
`;
