import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  padding: 25px 30px;
  border-top: 1px solid ${({ theme }) => theme.palette?.primary?.main};
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-right: 10%;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;
