import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface TitleProps {
  isBold?: boolean;
}

export const Title = styled(TableCell)<TitleProps>`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: ${({ isBold }) => isBold ? 'bold' : 400};
  font-size: 14px;
  line-height: 22px;
  color: #292929;
  padding: 20px 10px;
  width: fit-content;

  max-width: fit-content;
  min-width: 90px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  & > .product-title {
    padding-left: 30px;
  }
  &:hover {
    opacity: 0.5;
  }
`;

export const TitleColapce = styled(TableCell)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  padding: 20px 10px;
  width: 210px;

  max-width: 210px;
  min-width: 90px;
  overflow-x: auto;
  white-space: nowrap;
  
  &.source-title {
    color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  }
`;
