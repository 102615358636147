import React from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Components
import Tooltip from 'components/UI/Tooltip';
// Icons
import { HorizontalMenuIcon } from 'assets/icons';
// Styles
import {
  Container, H1, ContainerIcon, ContainerMenu, Label, LabelEdit,
} from './styles';

interface ProjectsCardHeaderProps {
  handlerModal: (e: React.MouseEvent<HTMLElement>) => void;
  setProjectHandler: () => void;
  id: string;
  name: string;
}

const ProjectsCardHeader: React.FC<ProjectsCardHeaderProps> = ({
  handlerModal,
  setProjectHandler,
  id,
  name,
}) => {
  const { redirectToEditProject } = useProjectRoutes();
  const { t } = useTranslation();
  const editProjectHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setProjectHandler();
    redirectToEditProject(id);
  };

  return (
    <Container>
      <H1 variant="generalRegularText">
        {name.charAt(0)}
      </H1>

      <Tooltip
        tooltipContent={(
          <ContainerMenu>
            <Label onClick={editProjectHandler}>
              {t('Редагувати')}
            </Label>
            <Label onClick={handlerModal} isRed>
              {t('Видалити')}
            </Label>
          </ContainerMenu>
        )}
        tooltipText="Видалити Редагувати"
        placement="right"
      >
        <ContainerIcon>
          <HorizontalMenuIcon />
        </ContainerIcon>
      </Tooltip>
    </Container>
  );
};

export default ProjectsCardHeader;
