import { css, styled } from '@mui/material/styles';
import {
  Button, List, ListItem, ListItemButton, ListSubheader, Typography,
} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

export const Container = styled(ListItem)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  color: ${({ theme }) => theme.palette.custom.black.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;

  &:last-child {
    border-bottom: none;
  }
`;
