import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;
