/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch } from 'react-redux'
// Hooks
import { setDefaultValuePipeline } from 'store/tutors/tutorsSlice';
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Icons
import {
  ArrowUpIcon,
  DocumentIcon,
  FolderIcon,
  FolderOutlinedIcon,
  PlusIcon,
  StatOutlinedIcon,
  CloseFactIcon
} from 'assets/icons';
// Styles
import { IPipeline } from 'store/project/projectTypes';
import { useTutorRoutes } from 'hooks/useTutorRoutes';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Title,
  Button,
  TitleButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import {useParams} from "react-router-dom";

interface SideBarAccordionProps {
  lockNotFreeFunctionality: boolean;
  handleChangeAccordion: (panel: string) => (
    event: React.SyntheticEvent, newExpanded: boolean
  ) => void;
  isDisabledButton: boolean;
  expanded: string | false;
  panel: number;
  title: string;
  isOpen: boolean;
  projectId: string;
  pipelines: IPipeline[]
}

const SideBarAccordion: React.FC<SideBarAccordionProps> = ({
  lockNotFreeFunctionality = false,
  handleChangeAccordion,
  isDisabledButton,
  projectId,
  expanded,
  pipelines,
  isOpen,
  panel,
  title,
}) => {
  const { pipeline_id } = useParams<string>();
  const { redirectToTutor } = useTutorRoutes();
  const { redirectAddToPipeliner, redirectToDashboard } = useProjectRoutes();
  const isActive = expanded === `panel${panel}`;

  const dispatch = useDispatch();

  const createTutorHandler = () => {
    if (lockNotFreeFunctionality) { return false; }
    dispatch(setDefaultValuePipeline(projectId as string));
    redirectToTutor(projectId)
  };
  const { t } = useTranslation();

  return (
    <Accordion
      onChange={handleChangeAccordion(`panel${panel}`)}
      expanded={isActive}
      disableGutters
      elevation={0}
      square
    >
      <AccordionHeader
        aria-controls={`panel${panel}d-content`}
        expandIcon={<ArrowUpIcon />}
        id={`panel${panel}d-header`}
        active={isActive ? 1 : 0}
        onClick={() => redirectAddToPipeliner(projectId)}
      >
        {isActive ? <FolderIcon color="#ffffff" /> : <FolderOutlinedIcon />}

        <Title
          active={isOpen ? 1 : 0}
          variant="regularText"
        >
          {title}
        </Title>
      </AccordionHeader>

      <Button
        onClick={createTutorHandler}
        active={isOpen ? 1 : 0}
        variant="textIcon"
        startIcon={lockNotFreeFunctionality ? <CloseFactIcon /> : <PlusIcon />}
      >
        <TitleButton active={isOpen ? 1 : 0}>
        { t('components.SideBar.SideBarAccordion.Додати пайплайн') }
        </TitleButton>
      </Button>

      {/* <Button
        active={isOpen ? 1 : 0}
        variant="textIcon"
        startIcon={<StatOutlinedIcon />}
      >
        <TitleButton active={isOpen ? 1 : 0}>
          Порівняти сценарії
        </TitleButton>
      </Button> */}

      <AccordionBody>
        {pipelines.map((pipeline, index) => (
          lockNotFreeFunctionality ?
            <Button
              key={index}
              active={true}
              variant="textIcon"
              startIcon={<CloseFactIcon />}
            >
              <TitleButton active={true}>
                { pipeline.pipeline_name || 'undefined name' }
              </TitleButton>
            </Button>
            :
            <Button
              onClick={() => redirectToDashboard(pipeline.project_id, 'indicators', pipeline.pipeline_id)}
              key={index}
              active={isOpen ? 1 : 0}
              isOpen={pipeline.pipeline_id === pipeline_id}
              variant="textIcon"
              startIcon={<DocumentIcon />}
            >
              <TitleButton active={isOpen ? 1 : 0}>
                { pipeline.pipeline_name || 'undefined name' }
              </TitleButton>
            </Button>
        ))}

      </AccordionBody>
    </Accordion>
  );
};

export default SideBarAccordion;
