import { ISales, IPipelineEmployee, IPipeline, IPlanSalesPipeline, IExpenseLaborEmployee, IAdditionalLosseResponse } from 'store/dashboard/dashboardTypes';
import { IProduct, ILabor } from 'interfaces/product';
import { calcTotalAdminEmployees } from "./share-calculation";

const calcTotalAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

const calcEmployeesWorkHours = (employees: IExpenseLaborEmployee[]) => {
  if (employees?.length) {
    const totalValue = employees?.reduce((acc: number, item: IExpenseLaborEmployee) => {
      const hours = item?.amount ? +item?.amount : 0;

      acc += hours;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
}

// export const calcLossDiagram = (pipeline: IPipeline, taxRate: number) => {
//   const additional = calcTotalAdditionalLosses(pipeline?.additional_losses?.expenses);
//   const adminEmpls = +calcTotalAdminEmployees(pipeline?.employees)?.toFixed(1) || 0;
//   const sumTaxes = +pipeline?.income * ((taxRate / 100) + 0.015);
//
//   return {
//     additionalCost: Math.round(additional),
//     adminCosts: Math.round(adminEmpls),
//     sumTaxes: Math.round(sumTaxes)
//   }
// }

export const calculate = (plan: ISales, pipeline: IPipeline, residence: string) => {
  const costs = Math.round(plan?.result?.income) || 0

  const allCosts = Math.round(pipeline.income);

  const additional = calcTotalAdditionalLosses(pipeline?.additional_losses?.expenses);

  const adminEmpls = +calcTotalAdminEmployees(pipeline?.employees, residence)?.toFixed(1) || 0;

  const employeeCosts = +plan?.expenseGroups?.labor?.loss || 0;

  const add = additional - (plan?.result?.income - allCosts)

  const overheadStainedGlass = additional * plan?.result?.income / allCosts

  const indirectCosts = (+(Math.round(adminEmpls) * costs) / +allCosts) || 0;

  const materials = plan?.expenseGroups?.Materials?.loss || 0;
  const packaging = plan?.expenseGroups?.Packaging?.loss || 0;

  const sumExpenses = materials + packaging;

  const sumMonthlyCost = overheadStainedGlass + indirectCosts + employeeCosts + sumExpenses

  const calcWorkHours = calcEmployeesWorkHours(plan?.expenseGroups?.labor?.expenses) * plan?.amount;

  const sumProfit = costs - sumMonthlyCost

  const profitability = (sumProfit / costs) * 100

  return [
    {
      key: 1,
      money: Math.round(costs),
      isRed: false,
      isBackground: true,
    },

    {
      key: 2,
      money: Math.round(overheadStainedGlass),
      isRed: true,
      isBackground: false,
    },

    {
      key: 3,
      money: Math.round(indirectCosts),
      isRed: true,
      isBackground: false,
    },

    {
      key: 4,
      money: Math.round(employeeCosts),
      isRed: true,
      isBackground: false,
    },

    {
      key: 5,
      money: Math.round(sumExpenses),
      isRed: true,
      isBackground: true,
    },

    {
      key: 6,
      money: Math.round(sumMonthlyCost),
      isRed: true,
      isBackground: true,
    },
    {
      key: 6,
      money: Math.round(calcWorkHours),
      isRed: false,
      isBackground: false,
    },

    {
      key: 7,
      money: Math.round(sumProfit),
      isRed: false,
      isBackground: true,
    },

    {
      key: 8,
      money: `${Math.round(profitability || 0)} %`,
      isRed: false,
      isBackground: false,
    },

  ]
}

const calcTotalProductEmployees= (employees: IProduct[]) => {
  const totalValue = employees?.reduce((acc: number, item: IProduct) => {
    const wage = item?.expenseGroups?.labor?.loss ? +item?.expenseGroups?.labor?.loss : 0;

    acc += wage;

    return +acc;
  }, 0);

  return totalValue;
};

const calcTotalProductMaterials = (materials: IProduct[]) => {
  const totalValue = materials?.reduce((acc: number, item: IProduct) => {
    const wage = item?.expenseGroups?.Materials?.loss ? +item?.expenseGroups?.Materials?.loss : 0;

    acc += wage;

    return +acc;
  }, 0);

  return totalValue;
};

const calcTotalProductPackagind = (packaging: IProduct[]) => {
  const totalValue = packaging?.reduce((acc: number, item: IProduct) => {
    const wage = item?.expenseGroups?.Packaging?.loss ? +item?.expenseGroups?.Packaging?.loss : 0;

    acc += wage;

    return +acc;
  }, 0);

  return totalValue;
};

const calcTotalProductHours = (labor: ILabor[]) => {
  if (labor?.length) {
    const totalValue = labor?.reduce((acc: number, item: ILabor) => {
      const wage = item?.amount ? +item?.amount : 0;

      acc += wage;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

const total = (products: IProduct[]) => {
  const prod = products?.map(item => ({
    ...item,
    totalHors: calcTotalProductHours(item?.expenses?.labor as ILabor[])
  }))

  const totalValue = prod?.reduce((acc: number, item) => {
    const wage = item?.totalHors ? +item?.totalHors * item?.amount : 0;

    acc += wage;

    return +acc;
  }, 0);

  return totalValue || 0;

}

export const calcByPlanSalesPipeline = (sales: IPlanSalesPipeline, pipeline: IPipeline, tax: number, residence: string) => {
  const costs = Math.round(sales?.result?.income) || 0;
  const additional = calcTotalAdditionalLosses(pipeline?.additional_losses?.expenses);

  const overheadStainedGlass = additional;

  const adminEmpls = +calcTotalAdminEmployees(pipeline?.employees, residence) || 0;
  // const adminEmpls = +calcTotalAdminEmployees(sales?.result.employees) || 0;

  const indirectCosts = adminEmpls;

  const employeeCosts = pipeline?.expenseGroups?.labor?.loss || 0;

  // const materials = calcTotalProductMaterials(sales?.result.expenseGroups.Materials?.loss) || 0;
  const materials = sales?.result.expenseGroups.Materials?.loss || 0;
  // const packaging = calcTotalProductPackagind(sales?.result) || 0;
  const packaging = sales?.result.expenseGroups.Packaging?.loss || 0;

  const sumExpenses = materials + packaging;

  const sumMonthlyCost = overheadStainedGlass + indirectCosts + employeeCosts + sumExpenses;

  const calcWorkHours = total(pipeline?.products);

  const sumProfit = costs - sumMonthlyCost;

  const profitability = (sumProfit / costs) * 100;

  return [
    {
      key: 1,
      money: Math.round(costs),
      isRed: false,
      isBackground: true,
    },
    {
      key: 2,
      money: Math.round(overheadStainedGlass),
      isRed: true,
      isBackground: false,
    },
    {
      key: 3,
      money: Math.round(indirectCosts),
      isRed: true,
      isBackground: false,
    },
    {
      key: 4,
      money: Math.round(employeeCosts),
      isRed: true,
      isBackground: false,
    },
    {
      key: 5,
      money: Math.round(sumExpenses),
      isRed: true,
      isBackground: true,
    },
    {
      key: 6,
      money: Math.round(sumMonthlyCost),
      isRed: true,
      isBackground: true,
    },

    {
      key: 7,
      money: Math.round(calcWorkHours),
      isRed: false,
      isBackground: false,
    },

    {
      key: 8,
      money: Math.round(sumProfit),
      isRed: false,
      isBackground: true,
    },

    {
      key: 9,
      money: `${Math.round(profitability || 0)} %`,
      isRed: false,
      isBackground: false,
    },
  ]
}
