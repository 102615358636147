import React from 'react';
// Icons
import ForgotPasswordImage from 'assets/images/forgot-password-image.svg';
// Global Components
import AuthBackground from 'components/AuthBackgroud';
// Components
import SuccessfullySentDetail from './SuccessfullySentDetail';
// Styles
import { Container } from './styles';

const SuccessfullySent: React.FC = () => (
  <Container>
    <SuccessfullySentDetail />

    <AuthBackground
      image={ForgotPasswordImage}
      maxHeight="670px"
      isImageBottom
    />
  </Container>
);

export default SuccessfullySent;
