import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

export const Container = styled(Box)`
  display: flex;
`;

export const StyledInput = styled(Input)`
  max-width: 218px;
  height: 48px;

  && {
    .MuiFormHelperText-root {
      display: none;
    }
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 87px;
  height: 48px;
  margin-right: 10px;
`;
