import React, { FC, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useCreateEmployeeMutation, useDeleteEmployeeMutation, useUpdateEmployeeMutation } from 'store/tutors/tutorsServices';
import { ICreateEmployeeForm } from 'store/tutors/tutorsTypes';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Utils
import { addEmployee } from 'utils/helpers/tutor';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import EmployeeRowItem from './EmployeeRowItem';
import EmployeesAddButtons from './EmployeesAddButtons';
import EmployeeAdminRow from './EmployeeAdminRow';
// Styles
import { Container } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';
import { useAuth } from '../../../hooks/useAuth';

interface EmployeesProps {
  defaultValues: ICreateEmployeeForm[]
}

const Employees: FC<EmployeesProps> = () => {
  const { t } = useTranslation();
  const { pipeline_id, project_id, employees } = useSelector(selectTutor);
  const { step } = useParams();
  const { redirectToTutorStep } = useTutorRoutes();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const formState = useForm({
    defaultValues: { employees },
    mode: 'all',
  });

  const formStateArray = useFieldArray({
    control: formState.control,
    name: 'employees',
  });

  const filteredEmployees = (type: string) => formStateArray.fields.filter((item: ICreateEmployeeForm) => item.type === type);

  const [create] = useCreateEmployeeMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();

  const deleteItem = async (id: number, employee_id?: string) => {
    if (employee_id) {
      await deleteEmployee(employee_id).unwrap();
    }

    formStateArray.remove(id);
  };

  const [updateEmployee] = useUpdateEmployeeMutation();

  const appendEmployee = (type: string) => {
    formStateArray.append(addEmployee(type));
  };

  const updateItem = async (id?: string) => {
    if (!id) return;
    const empl = formState.watch('employees');
    const employee = empl.find((item) => item.employee_id === id);

    if (employee) {
      const updateDataEmployee = {
        department: employee.department,
        name: employee.name,
        wage: +employee.wage,
      };

      await updateEmployee({
        body: updateDataEmployee,
        project_id: project_id as string,
        pipeline_id: pipeline_id as string,
        employee_id: employee.employee_id,
      }).unwrap();
    }
  };

  const onNextStep: SubmitHandler<{ employees: ICreateEmployeeForm[] }> = async (values) => {
    try {
      const empls = values.employees.filter((item) => !item.employee_id);

      if (!empls?.length) {
        redirectToTutorStep(project_id, 3);

        return;
      }

      if (empls?.length) {
        const updateEmpls = empls.map((item) => ({
          amount_type: item.amount_type,
          type: item.type,
          department: item.department,
          name: item.name,
          wage: +item.wage,
        }));

        await create({
          project_id: project_id as string,
          body: [{ employees: updateEmpls, pipeline_id: pipeline_id as string }],
        }).unwrap();

        formState.reset();

        const eventPayload: SegmentEventPayload = {
          eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_2,
          payload: { email: user?.email },
        };
        dispatch(addEvent(eventPayload));

        redirectToTutorStep(project_id, 3);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onPrevStep = () => {
    redirectToTutorStep(project_id, 3);
  };

  useEffect(() => {
    formState.setValue('employees', employees);
  }, [employees]);

  return (
    <TabPanel index={2} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={1} />

        <Typography variant="h1">
          {t('Адміністрація')} ({filteredEmployees('administration').length})
        </Typography>

        {formStateArray.fields.map((employee, index) => (
          employee.type === 'administration'
          && (
            <EmployeeAdminRow
              updateItem={updateItem}
              errors={formState.formState.errors}
              control={formState.control}
              employee={employee}
              key={employee.id}
              trigger={formState.trigger}
              update={formStateArray.update}
              index={index}
              type="administration"
              label={t('Адміністрація')}
              deleteItem={deleteItem}
            />
          )
        ))}

        <Typography variant="h1">
          {t('Команда')} ({filteredEmployees('partner').length})
        </Typography>

        {formStateArray.fields.map((employee, index) => (
          employee.type === 'partner'
          && (
            <EmployeeRowItem
              updateItem={updateItem}
              errors={formState.formState.errors}
              control={formState.control}
              employee={employee}
              key={employee.id}
              trigger={formState.trigger}
              update={formStateArray.update}
              index={index}
              type="partner"
              label={t('Співробітник')}
              deleteItem={deleteItem}
            />
          )
        ))}

        <Typography variant="h1">
          {t('Контрагенти')} ({filteredEmployees('contractor').length})
        </Typography>

        {formStateArray.fields.map((employee, index) => (
          employee.type === 'contractor'
          && (
            <EmployeeRowItem
              updateItem={updateItem}
              errors={formState.formState.errors}
              control={formState.control}
              employee={employee}
              trigger={formState.trigger}
              update={formStateArray.update}
              key={employee.id}
              index={index}
              type="contractor"
              label={t('Контрагент')}
              deleteItem={deleteItem}
            />
          )
        ))}

        <EmployeesAddButtons appendEmployee={appendEmployee} />
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={2}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default Employees;
