import { styled } from '@mui/material/styles';
import { Box, Link as MuiLink } from '@mui/material';
import { Link } from "react-router-dom";

interface ContainerProps {
  isSpace?: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  display: flex;
  margin-left: ${({ isSpace }) => (isSpace ? 0 : '3%')};
`;

export const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #A4A4A4;
  margin-left: 40px;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const StyledMuiLink = styled(MuiLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #A4A4A4;
  margin-left: 40px;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;
