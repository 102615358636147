import { styled } from '@mui/material/styles';
import { Typography, TableCell } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: fit-content;
`;

export const ItemRow = styled(TableCell)`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  min-width: 80px;
`;
