import { styled, css } from '@mui/material/styles';
import { Button } from '@mui/material';

interface ButtonProps {
  disabled: boolean
}

export const Container = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.primary.main};
`;

export const AddButton = styled(Button)<ButtonProps>`
  transform: translateX(0);
  transition: all .3s ease-in-out;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  && {
    text-decoration: none;
    font-size: 14px;
    svg {
      margin-right: 10px;
    }
  }

  ${({ disabled, theme }) => (disabled ? css`
    && {
      color: ${theme.palette.custom.black.silver};
    }
  ` : css`
    && {
      color: ${theme.palette.custom.green.main};
    }
  `)};
`;

export const DeleteButton = styled(AddButton)<ButtonProps>`
  ${({ disabled, theme }) => (disabled ? css`
    && {
      color: ${theme.palette.custom.black.silver};
    }
  ` : css`
    && {
      color: ${theme.palette.custom.red.main};
    }
  `)};
`;

export const ConfirmDeleteButton = styled(Button)`
  && {
    background: ${({ theme }) => theme.palette.custom.red.main};
    width: 227px;
    font-size: 14px;
    max-height: 34px;
    margin-bottom: 20px;
    padding: 0 30px;
  }
`;
