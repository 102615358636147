/* eslint-disable no-unsafe-optional-chaining */
import React, { FC } from 'react';
// Store
import { useCreateSubscribeMutation } from 'store/payments/paymentsServicers';
// Interface
import { ITariff } from 'store/payments/paymentsTypes';
// Components
import Loading from 'components/UI/Loading';
// Styles
import {
  Container, Wrapper, Title, SubTitle, Price, Button,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';
import { useAuth } from '../../../hooks/useAuth';
import { useDispatch } from 'react-redux';

interface TarrifCardProps {
  typeSubscribe: string;
  tariff: ITariff;
}

const TarrifCard: FC<TarrifCardProps> = ({ typeSubscribe, tariff }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const isDisabled = t('components.PaywallModal.TarrifCard.Діскавері') !== tariff?.name;

  const [createSubscribe, { isLoading }] = useCreateSubscribeMutation();

  const onSubmit = async () => {
    const res = await createSubscribe({ price_id: tariff?.endpoint?.payload?.price_id }).unwrap();

    if (res?.url) {
      window.location.href = res?.url;
      const eventPayload: SegmentEventPayload = {
        eventName: SEGMENT_EVENT_NAME.SUBSCRIBE_BUTTON_CLICK,
        payload: { email: user?.email },
      };
      dispatch(addEvent(eventPayload));
    }
  };

  const transferСurrency = () => {
    const currency = tariff?.amountCents / 100;

    return currency || 0;
  };

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container isDisabled={isDisabled}>
      <Wrapper>
        <Title variant="h2" isDisabled={isDisabled}>
          {tariff?.name}
        </Title>

        <SubTitle variant="h3" isDisabled={isDisabled}>
          {tariff?.description}
        </SubTitle>
      </Wrapper>

      <Wrapper>
        <Price variant="h1" isDisabled={isDisabled}>
          ${transferСurrency()} / {typeSubscribe}
        </Price>

        <Button
          disabled={isDisabled}
          onClick={onSubmit}
          variant="contained"
        >
          {t('components.PaywallModal.TarrifCard.Оплатити')}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default TarrifCard;
