import React from 'react';
// Components
import SideBarHeader from './SideBarHeader';
import SideBarBody from './SideBarBody';
import SideBarFooter from './SideBarFooter';
// Styles
import { Drawer } from './styles';
import { useLogout } from '../../hooks/useLogout';

interface SideBarProps {
  handleDrawer: () => void;
  isOpen: boolean;
}

const SideBar: React.FC<SideBarProps> = ({ handleDrawer, isOpen }) => {
  const { logoutHandler } = useLogout();

  return (
    <Drawer variant="permanent" open={isOpen}>
      <SideBarHeader handleDrawer={handleDrawer} />
      <SideBarBody isOpen={isOpen} />
      <SideBarFooter logoutHandler={logoutHandler} isOpen={isOpen} />
    </Drawer>
  );
};

export default SideBar;
