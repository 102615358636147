import React, { forwardRef } from 'react';

import {TextFieldProps, InputAdornment, InputProps, IconButton, FormHelperText} from '@mui/material';
import { EyeIcon, EyeClosedIcon } from 'assets/icons';
import {Container, Label, StyledInput, StyledPasswordInput} from './styles';

export interface CustomInputProps {
  isSpaceBottom?: number;
  fullWidth?: boolean;
}

const Input = forwardRef(({
  label,
  isSpaceBottom,
  helperText,
  fullWidth,
  type,
  ...props
}: TextFieldProps & CustomInputProps & InputProps, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <Container
      ref={ref as React.RefObject<HTMLDivElement>}
      className={props.className || ''}
      isSpaceBottom={isSpaceBottom}
      fullWidth={fullWidth}
    >
      {label && <Label>{label}</Label>}

      {type === 'password' ?
        <>
          <StyledPasswordInput
            {...props}
            className={`${props.className} ${props.InputProps && props.InputProps.readOnly ? 'readonly' : ''}`}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <EyeClosedIcon/> : <EyeIcon/>}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{helperText ? helperText : ' '}</FormHelperText>
        </>
         :
        <StyledInput
          helperText={helperText || ' '}
          {...props}
          className={`${props.className} ${props.InputProps && props.InputProps.readOnly ? 'readonly' : ''}`}
        />
      }

    </Container>
  )
});

export default Input;
