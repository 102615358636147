import React, {useState} from 'react';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Icons
import {LockedIcon, PlusIcon, PresentSmallIcon} from 'assets/icons';
// Styles
import {Container, Box, Title, IconWrap} from './styles';
// Translation
import {Trans, useTranslation,} from 'react-i18next';
import {
  BottomContainer,
  IconWrapper,
  Link, PresentSmallIconWrap,
  SmallTooltipText,
  TextWrapper
} from "../ProjectsCard/ProjectCardBlocked/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import {HashLink} from "react-router-hash-link";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 320,
  },
});
const AddProjectCard: React.FC<{block: boolean}> = ({block = false}) => {
  const { redirectToAddProject } = useProjectRoutes();
  const { t } = useTranslation();

  const handleClick = () => {
    if(!block) { redirectToAddProject() }
  };

  if (block) {
    return (
        <Container>
          <Box>
            <CustomWidthTooltip
                title={
                  <TextWrapper>
                    {t('Нажаль період безкоштовного користування вичерпано. Для продовження необхідно обрати')}
                    <HashLink to='/profile#plans'>
                      <Link> {t('тарифний план')}</Link>
                    </HashLink>
                    <br/>
                    <br/>
                    <Trans i18nKey={'tooltipSubText'}>
                      Вам доступний промо-код зі знижкою 50%! Для його застосування, при офорленні підписки Вам потрібно ввести
                      <strong>PIPE50U</strong>.
                    </Trans>
                    <BottomContainer>
                      <SmallTooltipText>
                        {t('Ця знижка буде дійсна впродовж 6 місяців.')}
                      </SmallTooltipText>
                      <PresentSmallIconWrap>
                        <PresentSmallIcon/>
                      </PresentSmallIconWrap>
                    </BottomContainer>

                  </TextWrapper>
                }
                placement="bottom">
              <IconWrap>
                <PlusIcon color={block ? "#A4A4A4" : "#07B169"}/>

                <Title variant="h4" block={block}>
                  {t('pages.Projects.AddProjectCard.Створити новий проєкт')}
                </Title>
              </IconWrap>
            </CustomWidthTooltip>

          </Box>
        </Container>
    )
  }

  return (
    <Container onClick={handleClick}>
      <Box>
        <PlusIcon color={block ? "#A4A4A4" : "#07B169"} />

        <Title variant="h4" block={block}>
          {t('pages.Projects.AddProjectCard.Створити новий проєкт')}
        </Title>
      </Box>
    </Container>
  );
};

export default AddProjectCard;
