import React, { useState, useEffect } from 'react';
// Store
import { useGetProjectsQuery } from 'store/projects/projectsServices';
import { useTrialUser } from 'hooks/useTrialUser';
// Components
import Loading from 'components/UI/Loading';
import Footer from 'components/Footer';
import ProjectsTitle from './ProjectsTitle';
import ProjectsCard from './ProjectsCard';
import AddProjectCard from './AddProjectCard';
import ProjectCardBlocked from './ProjectsCard/ProjectCardBlocked';
// Styles
import { Container, ContainerCard, Wrapper } from './styles';
import {useNavigate} from "react-router";

const Projects: React.FC = () => {
  const { isLoading, isFetching, data, refetch, isError } = useGetProjectsQuery({});
  const navigate = useNavigate()
  const { lockNotFreeFunctionality } = useTrialUser();

  useEffect(() => {
    if (isError) {
      navigate('/error-page')
      return;
    }
  }, [isError])

  useEffect(() => {
    refetch()
  }, [])

  if (isLoading || isFetching) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <ProjectsTitle />

        <ContainerCard>
          <AddProjectCard block={lockNotFreeFunctionality}/>
          {(data?.data || []).map((project, index) => {
            if (lockNotFreeFunctionality) {
              return <ProjectCardBlocked key={project.project_id} />
            } else {
              return (
                <ProjectsCard
                  project={project}
                  key={project.project_id}
                />
              )
            }
          })}
        </ContainerCard>
      </Wrapper>

      <Footer isSpace />
    </Container>
  );
};

export default Projects;
