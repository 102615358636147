import React, { useState } from 'react';
// Components
import BankCard from 'components/BankCard';
import DeleteBankCardModal from '../DeleteBankCardModal';
// Styles
import { Container, Label } from './styles';

const UserBankCards: React.FC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleModal = () => setOpenDeleteModal((prev) => !prev);

  return (
    <Container>
      <Label>
        Спосіб оплати за умовчанням
      </Label>

      <BankCard onDeleteCard={handleModal} />

      <DeleteBankCardModal
        open={openDeleteModal}
        onClose={handleModal}
      />
    </Container>
  );
};

export default UserBankCards;
