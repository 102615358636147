import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { palette } from "styles/theme/palette";
import { Link } from "react-router-dom";

interface LinkContainerStyledProps {
  isActive: boolean;
}


export const NavContainerStyled = styled(Box)(() => ({
  marginTop: '87px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));


export const LinkContainerStyled = styled(Link)<LinkContainerStyledProps>(({ isActive }) => ({
  display: 'flex',
  padding: '11px 16px',
  margin: '0 40px',
  width: 'calc(100% - 80px)',
  background: isActive ? palette.custom.dark.main : 'transparent',
  color: `${isActive ? palette.common.white : palette.custom.grey.light}`,
  transition: '300ms ease all',
  borderRadius: '4px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: '700',
  overflow: 'hidden',
  'svg, path': {
    width: '22px',
    transition: '300ms ease color',
    fill: `${isActive ? palette.common.white : palette.custom.grey.light}`,
    marginRight: '25px',
  },
}));
