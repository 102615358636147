import { MATCH_PASSWORD_MESSAGE } from 'utils/constants/input-errors-message';

const PasswordRecoveryValidation = {
  isMatch: (
    password: string,
    confirmNewPassword: string,
  ): boolean | string => password === confirmNewPassword || MATCH_PASSWORD_MESSAGE,
};

export const { isMatch } = PasswordRecoveryValidation;
