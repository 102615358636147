import React from 'react';
// Styles
import { PlusIcon, TrashIcon } from 'assets/icons';
import {
  Container,
  AddButton,
  DeleteButton,
  ConfirmDeleteButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface SelectFooterProps {
  enableAdd: boolean,
  enableDelete: boolean,
  selectForDelete: () => void,
  removeElement: () => void,
  addElement: () => void,
  selectedForDelete: boolean,
}

const SelectFooter: React.FC<SelectFooterProps> = ({
  selectForDelete,
  enableAdd,
  enableDelete,
  removeElement,
  addElement,
  selectedForDelete,
}) => {
  const { t } = useTranslation();
  const handleConfirm = () => {
    removeElement();
    selectForDelete();
  };

  return (
    <Container>
      <AddButton
        variant="link"
        disabled={!enableAdd}
        onClick={addElement}
      ><PlusIcon />{t('Додати підрозділ')}
      </AddButton>
      {
        selectedForDelete
          ? (
            <ConfirmDeleteButton variant="contained" onClick={handleConfirm}>
              {t('Підтверджую видалення')}
            </ConfirmDeleteButton>
          )
          : (
            <DeleteButton
              variant="link"
              disabled={!enableDelete}
              onClick={selectForDelete}
            >
              <TrashIcon />{t('Видалити')}
            </DeleteButton>
          )
      }
    </Container>
  );
};
export default SelectFooter;
