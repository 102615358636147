import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  ToggleButton, FormControlLabel, Checkbox, FormGroup, InputAdornment,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
// Types
import { IPipelineEmployee } from 'store/dashboard/dashboardTypes';
import { useCreateEmployeeMutation, useUpdateEmployeeMutation } from 'store/tutors/tutorsServices';
// Global components
import Modal from 'components/UI/Modal';
import Select from 'components/UI/Select';
import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
import { Label, Container as InputContainer } from 'components/UI/Input/styles';
import { CloseFactIcon, DisabledInputIcon } from 'assets/icons';
// Translation
import { useTranslation, } from 'react-i18next';
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  Title,
  ContainerButton,
  ContainerForm,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  IconWrap,
  StyledToggleButtonGroup,
  ToggleFormWrap,
  StyledInput,
} from './styles';
import { departmentsData } from 'utils/helpers/employyers';

interface AddHoursModalProps {
  onClose: () => void;
  isOpen: boolean;
  departments?: Array<any> | null;
  employee?: IPipelineEmployee | null,
}

interface EmployeeForm {
  amount_type: string;
  department: string;
  id: string;
  employee_id: string;
  name: string;
  type: string;
  wage: number;
}

const amountTypeData = [
  {
    value: 'fixed',
    label: 'Фіксована',
  },
  {
    value: 'hourly',
    label: 'Погодинна',
  },
];
const AddEmployeeModal: React.FC<AddHoursModalProps> = ({
  onClose,
  isOpen,
  departments,
  employee
}) => {
  const { project_id, pipeline_id } = useParams();
  const { t } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<EmployeeForm>({
    mode: 'all',
    defaultValues: employee || {
      amount_type: 'fixed',
      department: '',
      employee_id: '',
      name: '',
      type: 'partner',
      wage: 0,
    },
  });

  const watched = useWatch({
    control,
  });

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    // @ts-ignore
    setValue('name', employee?.name);
    // @ts-ignore
    setValue('department', employee?.department);
    // @ts-ignore
    setValue('amount_type', employee?.amount_type);
    // @ts-ignore
    setValue('wage', employee?.wage);
  }, [employee]);

  const [updateEmployee] = useUpdateEmployeeMutation();
  const [create] = useCreateEmployeeMutation();

  const update = async (id?: string) => {
    const { amount_type, type, department, name, wage } = getValues();
    const wageFormatted = wage ? Number(wage) : 0
    if (!id) {
      const creatableData = [{
        amount_type,
        type,
        department,
        name,
        wage: wageFormatted,
      }];
      await create({
        project_id: project_id as string,
        body: [{ employees: creatableData, pipeline_id: pipeline_id as string }],
      }).unwrap();
      reset();
      onClose();
    };

    if (employee) {
      const updateDataEmployee = {
        department: department || employee.department,
        name: name || employee.name,
        wage: wageFormatted || employee.wage,
      };

      await updateEmployee({
        body: updateDataEmployee,
        project_id: project_id as string,
        pipeline_id: pipeline_id as string,
        employee_id: employee.id,
      }).unwrap();
      reset();
      onClose();
    }
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {employee ? t('Редагувати спiвробiтника') : t('Додати спiвробiтника')}
        </Title>
        <ContainerForm>
          {
            !employee
              ? (
                <ToggleFormWrap>
                  <Controller
                    render={({ field }) => (
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                      >
                        <ToggleButton value={'administration' as any}>{t('Адмін')}</ToggleButton>
                        <ToggleButton value={'partner' as any}>{t('Співробітник')}</ToggleButton>
                        <ToggleButton value={'contractor' as any}>{t('Контрагент')}</ToggleButton>
                      </StyledToggleButtonGroup>
                    )}
                    control={control}
                    name="type"
                  />
                </ToggleFormWrap>
              )
              : ''
          }

          <Controller
            render={({ field }) => (
              <Select
                label={t('Підрозділ')}
                data={departmentsData(departments as Array<string>)}
                {...field}
                error={!!errors?.department}
                errorMessage={errors?.department?.message}
                className="amount-input"
                fullWidth
              />
            )}
            control={control}
            name="department"
            rules={{ required: t('Це поле є обовязковим') }}
          />
          <Controller
            render={({ field }) => (
              <Select
                label={t('Умови співпраці')}
                data={amountTypeData}
                {...field}
                defaultValue="fixed"
                disabled={watched.type === 'administration' || Boolean(employee)}
                error={!!errors?.amount_type}
                errorMessage={errors?.amount_type?.message}
                className="amount-input"
                fullWidth
              />
            )}
            control={control}
            name="amount_type"
            rules={{ required: t('Це поле є обовязковим') }}
          />
          <Tooltip
            arrow
            placement="bottom"
            tooltipText=""
            tooltipContent={<LockTooltipText/>}
            PopperProps={{
              modifiers: [{
                name: "offset",
                options: { offset: [0, -30] }
              }],
            }}
          >
            <StyledInput
              disabled
              label={t('Посада')}
              isBackground
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseFactIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={watched.type === 'partner' ? t('Посада') : t('Умови співпраці')}
              className="name-input"
            />
          </Tooltip>

          <Controller
            render={({ field }) => (
              <StyledInput
                label={t('=Ім’я')}
                {...field}
                placeholder={t("Введіть ім'я")}
                className="name-input"
                error={!!errors?.name?.message}
                helperText={errors?.name?.message}
              />
            )}
            control={control}
            name="name"
            rules={{ required: { value: true, message: t('Це поле є обовязковим') } }}
          />
          <Tooltip
            arrow
            placement="bottom"
            tooltipText=""
            tooltipContent={<LockTooltipText/>}
            PopperProps={{
              modifiers: [{
                name: "offset",
                options: { offset: [0, -30] }
              }],
            }}
          >
            <StyledInput
              disabled
              isBackground
              label="Email"
              placeholder={t("Введіть email")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseFactIcon />
                  </InputAdornment>
                ),
              }}
              className="name-input"
            />
          </Tooltip>
        </ContainerForm>
        <ContainerForm className="halfWidth">
          <InlineFormWrap>
            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
            >
              <InputContainer className="inline-input">
                <Label>{t('Дата початку')}</Label>
                <DatePickerWrapper>
                  <DatePicker onChange={() => { }} readOnly={true} selected={date} />
                  <IconWrap>
                    {/*<CalendarIcon />*/}
                    <DisabledInputIcon />

                  </IconWrap>
                </DatePickerWrapper>
              </InputContainer>
            </Tooltip>
            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
            >
              <InputContainer className="inline-input">
                <Label>{t('Кінцева дата')}</Label>
                <DatePickerWrapper>
                  <DatePicker onChange={() => { }} readOnly={true} selected={date} />
                  <IconWrap>
                    {/*<CalendarIcon />*/}
                    <DisabledInputIcon />
                  </IconWrap>
                </DatePickerWrapper>
              </InputContainer>
            </Tooltip>
          </InlineFormWrap>
          <InlineFormWrap>
            <InputContainer className="inline-input">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox disabled={true} />
                  }
                  label={t('Не визначено')}
                />
              </FormGroup>
            </InputContainer>
            <InputContainer className="inline-input">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox disabled={true} />
                  }
                  label={t('Не визначено')}
                />
              </FormGroup>
            </InputContainer>
          </InlineFormWrap>
          <InlineFormWrap>
            <InputContainer className="inline-input">
              <Controller
                render={({ field }) => (
                  <StyledInput
                    label={t('Платня')}
                    {...field}
                    placeholder="Напр.: 28000,1"
                    className="inline-input time-input"
                  />
                )}
                control={control}
                name="wage"
                rules={{ required: { value: true, message: t('Це поле є обовязковим') } }}
              />
            </InputContainer>
            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
              PopperProps={{
                modifiers: [{
                  name: "offset",
                  options: { offset: [0, -30] }
                }],
              }}
            >
              <InputContainer className="inline-input">
                <StyledInput
                  label={t('Податок')}
                  disabled
                  isBackground
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseFactIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Напр., 5%')}
                  className="name-input"
                />
              </InputContainer>
            </Tooltip>
          </InlineFormWrap>
        </ContainerForm>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => update(employee?.id)}
          >
            {employee ? t('Зберегти') : t('Додати')}
          </Button>

          <Button
            variant="light"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            {t('Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddEmployeeModal;
