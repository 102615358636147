import React, { useState } from 'react';
import {
  Typography,
  TextField,
} from '@mui/material';
import { CheckIcon, CancelIcon } from 'assets/icons';
import {
  EditorWrap,
  ControlButtons,
  PencilButton,
  SubmitButton,
  CancelButton,
  NoEditingWrap,
  StyledInput,
  InputWrap,
} from './styles';

interface InlineEditorProp {
  title?: string,
  fullWidth?: boolean,
  // handleChange: (value: string) => {},
}
const InlineEditor: React.FC<InlineEditorProp> = ({ title, children, fullWidth }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const handleSubmit = () => {
    setIsEditing(false);
    // handleChange(newTitle);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewTitle(title);
  };

  return (
    <EditorWrap $fullWidth={fullWidth}>
      {
        !isEditing
          ? (
            <NoEditingWrap $fullWidth={fullWidth}>
              {children}
              <PencilButton
                onClick={() => setIsEditing(true)}
              />
            </NoEditingWrap>
          )
          : (
            <InputWrap>
              <StyledInput
                label=""
                size="small"
                defaultValue={newTitle}
                onChange={(evt) => setNewTitle(evt.target.value)}
              />
              <ControlButtons>
                <SubmitButton
                  variant="icon"
                  onClick={() => handleSubmit()}
                >
                  <CheckIcon />
                </SubmitButton>
                <CancelButton
                  variant="icon"
                  onClick={() => handleCancel()}
                >
                  <CancelIcon />
                </CancelButton>
              </ControlButtons>
            </InputWrap>
          )
      }
    </EditorWrap>
  );
};

export default InlineEditor;
