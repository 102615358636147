import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Interface
import { IProductCreate, IPackagingCreate } from 'interfaces/product';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useCreatePackagingMutation, useUpdatePackagingMutation } from 'store/tutors/tutorsServices';
import { IExpensesPackaging, ICreatePackagingRequest } from 'store/tutors/tutorsTypes';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import PackagingItem from './PackagingItem';
// Styles
import { Container } from './styles';
import { useAuth } from '../../../hooks/useAuth';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';

interface PackagingLogisticsCostsProps {
  currency: string;
}

const PackagingLogisticsCosts: React.FC<PackagingLogisticsCostsProps> = ({ currency }) => {
  const { project_id, materialProducts } = useSelector(selectTutor);
  const { step } = useParams();
  const { redirectToTutorStep } = useTutorRoutes();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [createPackaging] = useCreatePackagingMutation();
  const [updatePackaging] = useUpdatePackagingMutation();

  const formState = useForm({
    mode: 'all',
    defaultValues: { materialProducts },
  });

  const { fields } = useFieldArray({
    control: formState.control,
    name: 'materialProducts',
  });

  const onNextStep: SubmitHandler<{ materialProducts: IProductCreate[] }> = async (values) => {
    try {
      const filteredProducts = values.materialProducts.filter((item) => item?.pipeline_id);

      if (filteredProducts?.length) {
        const mats = filteredProducts.map((item) => {
          const packaging: IExpensesPackaging[] = [];

          item.expenses?.Packaging?.forEach((expens: IPackagingCreate) => {
            if (!expens.packaging_id) {
              packaging.push({
                extras: {},
                name: expens.name,
                value: expens.value,
                group: expens.group,
                amount: 1,
              });
            }
          });

          return { product_id: item.pipeline_id, expenses: packaging };
        });
        const filteredMaterials = mats.filter((item) => item.expenses?.length);
        if (filteredMaterials.length) {
          await createPackaging(filteredMaterials as ICreatePackagingRequest[]).unwrap();

          formState.reset();
        } else {
          redirectToTutorStep(project_id, 6);
        }

        formState.setValue('materialProducts', materialProducts);
        redirectToTutorStep(project_id, 6);

        const eventPayload: SegmentEventPayload = {
          eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_5,
          payload: { email: user?.email },
        };
        dispatch(addEvent(eventPayload));

        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateItem = async (productIndex: number, id?: string) => {
    if (!id) return;

    const productsArray = formState.watch('materialProducts');
    const packaging = productsArray?.[productIndex]?.expenses?.Packaging?.find((item) => item?.packaging_id === id);

    if (packaging?.packaging_id) {
      const updateDataMaterial = {
        amount: 1,
        name: packaging.name,
        value: packaging.value,
        extras: {},
      };

      await updatePackaging({
        body: updateDataMaterial,
        packaging_id: id,
      }).unwrap();
    }
  };

  const onPrevStep = () => {
    redirectToTutorStep(project_id, 4);
  };

  useEffect(() => {
    if (step === '5') {
      formState.trigger()
    }
  }, [step])

  useEffect(() => {
    formState.setValue('materialProducts', materialProducts);
  }, [materialProducts]);

  return (
    <TabPanel index={5} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={4} />

        {fields.map((product, index) => (
          <PackagingItem
            currency={currency}
            updateItem={updateItem}
            errors={formState.formState.errors}
            control={formState.control}
            key={product.id}
            product={product}
            indexProduct={index}
          />
        ))}
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={4}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default PackagingLogisticsCosts;
