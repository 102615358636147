import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  Button as StyledButton,
} from '@mui/material';
import Input from 'components/UI/Input';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DisabledInput = styled(Input)`
  .MuiOutlinedInput-root {
    background-color: ${({ theme }) => theme.palette.custom?.grey?.darkLite};
    border: none;
  }

  .Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.dark};
    -webkit-text-fill-color: unset;
  }

  fieldset {
    border: none;
  }
`;

export const Button = styled(StyledButton)`
  width: 100%;
  margin-top: 20px;
  height: 60px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme?.palette?.primary?.contrastText};
`;
