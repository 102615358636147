// Interface
import { IPipeline, IAdditionalLosseResponse, IPipelineEmployee } from 'store/dashboard/dashboardTypes';
import { IProduct, IMaterial, IEmployee } from 'store/pipeline/pipelineTypes';
import { IAdditionalLosses } from 'store/tutor/tutorTypes';

// Utils
import { repeatabilityFlow } from 'utils/helpers/index';
import {calcTotalAdminEmployees} from "./share-calculation";

// Расчёт дохода по всем продуктам за 1 месяц
export const calcInconProducts = (products: IProduct[]) => {
  const totalValue = products?.reduce((acc: number, item: IProduct) => {
    acc += (+item?.month_sales * +item?.price);

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт всех материалов по одному продукту
export const calcMaterials = (materials: IMaterial[]) => {
  const totalValue = materials?.reduce((acc: number, item: IMaterial) => {
    acc += (+item?.price * +item?.per_product);

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт всех материалов по всем продуктам
export const calcTotalMaterials = (products: IProduct[]) => {
  const totalValue = products?.reduce((acc: number, item: IProduct) => {
    acc += (item?.totalMaterials ? item.totalMaterials : 0);

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт Продажи и маркетинг
export const calcTotalAdditionalLosses = (additionalLosses: IAdditionalLosses[]) => {
  const totalValue = additionalLosses?.reduce((acc: number, item: IAdditionalLosses) => {
    acc += (item?.price * repeatabilityFlow(item?.amount as string));

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт зарплаты администраторов
export const caclAdminSalary = (employees: IEmployee[]) => {
  const totalValue = employees?.reduce((acc: number, item: IEmployee) => {
    acc += (item?.amount ? item?.amount : 0);

    return +acc;
  }, 0);

  return totalValue;
};

const calcTotalAditionalLoss = (additionalLosses: IAdditionalLosseResponse[]) => {
  if (additionalLosses?.length) {
    const totalValue = additionalLosses?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.value ? +item?.value : 0;
      const amount = item?.amount? +item?.amount : 0;
      acc += (amount * value);

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

const calcAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

export const caclPipelineCard = (pipeline: IPipeline, tax: number, residence: string) => {
  const sumIncome = +pipeline?.income;

  const materials = pipeline?.expenseGroups?.Materials?.loss || 0;
  const packaging = pipeline?.expenseGroups?.Packaging?.loss || 0;
  const salary = pipeline?.expenseGroups?.labor?.loss || 0;
  const caclCosts = materials + packaging + salary;


  const sumGrossSurplus = (sumIncome - caclCosts);

  const additionalLosses = calcAdditionalLosses(pipeline?.additional_losses?.expenses);
  const adminSalary = calcTotalAdminEmployees(pipeline?.employees, residence) || 0;

  const fixedCosts = additionalLosses + adminSalary;

  const sumTaxes = sumIncome * ((tax / 100));

  const earningsBeforeTaxation = sumGrossSurplus - fixedCosts;

  const sumNetProfit = sumIncome - caclCosts - additionalLosses - sumTaxes;

  return [
    {
      name: 'Дохід',
      price: Math.round(sumIncome),
      key: 1,
      isBold: true,
      isBackground: false,
      color: '#292929',
    },

    {
      name: 'Собівартість',
      price: Math.round(caclCosts),
      key: 2,
      isBold: false,
      isBackground: false,
      color: '#838383'
    },
    {
      name: 'Заробіток до оподаткування',
      price: Math.round(earningsBeforeTaxation),
      key: 3,
      isBold: true,
      isBackground: true,
      color: '#292929',
    },
    {
      name: 'Постійні витрати',
      price: Math.round(fixedCosts),
      key: 4,
      isBold: true,
      isBackground: false,
      color: '#D52727',
    },
    {
      name: 'Податки',
      price: Math.round(sumTaxes),
      key: 9,
      isBold: false,
      isBackground: false,
      color: '#D52727',
    },
    {
      name: 'Валовий прибуток',
      price: Math.round(sumGrossSurplus),
      key: 9,
      isBold: true,
      isBackground: false,
      color: '#292929',
    },
    {
      name: 'Чистий прибуток',
      price: Math.round(sumNetProfit),
      key: 10,
      isBold: true,
      color: '#292929',
      isBackground: true,
    },
  ]
}
