import React from 'react';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { IPipeline } from 'store/dashboard/dashboardTypes';
// Utils
import { calculate } from 'utils/calculates/sales-months';
import { isMinusValue } from 'utils/helpers';
import { StyledTitle, StyledTableRow } from './styles';

interface TableRowProps {
  activeIndex: number;
  product: any;
}

const TableRow: React.FC<TableRowProps> = ({ product, activeIndex }) => {
  const { pipeline, residence } = useSelector(selectDashboard);

  return (
    <StyledTableRow
      sx={{ '& > *': { borderBottom: 'unset' } }}
      isBold={product?.isBold}
    >
      <StyledTitle
        isBold={product?.isBold}
        component="th"
        scope="row"
      >
        {product?.name}
      </StyledTitle>

      {calculate(product?.sales?.[activeIndex], pipeline as IPipeline, residence)?.map(month => (
        <StyledTitle
          isMinus={isMinusValue(month?.money?.toString())}
          isBold={product?.isBold}
          key={month?.key}
          isRed={month?.isRed}
        >
          {month?.money}
        </StyledTitle>
      ))}
    </StyledTableRow>
  )
}

export default TableRow;
