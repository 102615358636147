import React from 'react';
// Global components
import Modal from 'components/UI/Modal';
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  Title,
  ContainerButton,
  Button,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface DeletePipelineModalProps {
  deletePipeline: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const DeletePipelineModal: React.FC<DeletePipelineModalProps> = ({
  deletePipeline,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {t('components.Tutor.DeletePipelineModal.Ви дійсно хочете залишити тьютор?')}
        </Title>

        <ContainerButton>
          <Button
            isDelete
            onClick={deletePipeline}
            variant="contained"
            component="span"
          >
            {t('components.Tutor.DeletePipelineModal.Видалити')}
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            {t('components.Tutor.DeletePipelineModal.Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  )
};

export default DeletePipelineModal;
