import React, { FC, useState } from 'react';
import { Typography, Collapse } from '@mui/material';
// Store
import { ICreateLaborEmployee } from 'store/tutors/tutorsTypes';
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';
// Components
import ControlPanel from './ControlPanel';
import EmployeeProductTableHeader from './EmployeeProductTableHeader';
import EmployeeProductTableBody from './EmployeeProductTableBody';
// Styles
import {
  Container, CollapseHeader, Arrow, ListStyled, LinkButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface EmployeeProductTableProps {
  updateItem: (index: number, value: ICreateLaborEmployee) => void;
  openAddModal: () => void;
  employeesData: ICreateLaborEmployee[] | []
  deleteEmployee: (index: number | number[], id?: string) => void;
  isDepartmen: boolean;
  departmen: string;
  countEmployees: number;
  countLabors: number;
}

const EmployeeProductTable: FC<EmployeeProductTableProps> = ({
  countEmployees,
  countLabors,
  employeesData,
  deleteEmployee,
  openAddModal,
  isDepartmen,
  departmen,
  updateItem,
}) => {
  const { t } = useTranslation();
  const isDisabled = countLabors === countEmployees || countEmployees === 0
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState<ICreateLaborEmployee[]>([]);

  const [deleteExpenses] = useDeleteExpensesMutation();

  const openTableHandler = () => setOpen((prev) => !prev);

  const handleCheck = (employee: ICreateLaborEmployee, evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setSelected([...selected, employee])
    } else {
      setSelected((prev) => prev.filter(({ employee_id }) => (employee_id !== employee.employee_id)))
    }
  }

  const handleCheckAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setSelected(employeesData)
    } else {
      setSelected([])
    }
  }

  const cancelСhanges = () => {
    setSelected([]);
  }

  const deleteAllEmployee = () => {
    if (selected.length) {
      let emplsIndex: number[] = [];

      selected.forEach((item, index) => {
        emplsIndex.push(index)

        if (item.labor_id) {
          deleteExpenses(item.labor_id).unwrap()
        }

      })

      setSelected([]);
      if (emplsIndex.length) {
        deleteEmployee(emplsIndex)
      }
    }
  }

  return (
    <Container>
      <CollapseHeader onClick={openTableHandler}>
        <Typography>
          {t('Співробітники та чоловіко-години')}
        </Typography>

        <Arrow $isOpen={open} />
      </CollapseHeader>

      <Collapse className="callapse" in={open} timeout="auto" unmountOnExit>
        {selected.length ?
          <ControlPanel count={selected.length} cancelСhanges={cancelСhanges} deleteAllEmployee={deleteAllEmployee}/> :
          null}

        <ListStyled disablePadding>
          <EmployeeProductTableHeader selected={!!selected?.length} handleCheckAll={handleCheckAll} />

          {employeesData.map((item, index) => (
            <EmployeeProductTableBody
              selected={selected}
              handleCheck={handleCheck}
              updateItem={updateItem}
              departmen={departmen}
              key={item.employee_id}
              employee={item}
              indexEmployee={index}
              deleteEmployee={deleteEmployee}
            />
          ))}
        </ListStyled>

        <LinkButton
          variant="link"
          onClick={openAddModal}
          disabled={!isDepartmen || isDisabled}
        >
          {t('Додати співробітника')}
        </LinkButton>
      </Collapse>
    </Container>
  );
};

export default EmployeeProductTable;
