import React from 'react';
// Icons
import { Logo } from 'assets/logos';
// Styles
import {
  Container,
  Title,
  SubTitle,
  Email,
} from './styles';
import {useTranslation} from "react-i18next";

interface SuccessfullySentProps {
  email: string;
}

const SuccessfullySent: React.FC<SuccessfullySentProps> = ({ email }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Logo />

      <Title variant="h1">
        {t('Деталі відправлені')}
      </Title>

      <SubTitle variant="h3">
        {t('Будь ласка, дотримуйтесь іструкцій, що були відправлені на вашу поштову скриньку:')}
      </SubTitle>

      <Email variant="h3">
        {email}
      </Email>
    </Container>
  );
}

export default SuccessfullySent;
