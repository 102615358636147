import React, { useState, useCallback} from 'react';
import { InputAdornment, Typography } from '@mui/material';
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import { Label, Container as InputContainer } from 'components/UI/Input/styles';
import {ClockIcon, CalendarIcon, CloseFactIcon} from 'assets/icons';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  IconWrap,
} from './styles';
;
interface EditEquipmentProps {
  onClose: () => void;
  isOpen: boolean;
}

const EditEquipment: React.FC<EditEquipmentProps> = ({
   onClose,
   isOpen,
 }) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Редагування обладнання
        </Title>
        <Input
          disabled
          label="Обладнання"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CloseFactIcon />
              </InputAdornment>
            ),
          }}
          value={''}
          className="amount-input"
        />
        <Select
          label="Співпробітник"
          fullWidth
          data={[]}
          value={''}
          className="amount-input"
        />
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => {}}
          >
            Зберегти
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default EditEquipment;
