import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Input from 'components/UI/Input';

export const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const DisabledInput = styled(Input)`
  margin-top: -4px;
  .MuiOutlinedInput-root {
    background-color: ${({ theme }) => theme.palette.custom?.grey?.darkLite};
    border: none;
  }

  .Mui-disabled {
    color: ${({ theme }) => theme.palette.primary.dark};
    -webkit-text-fill-color: unset;
  }

  fieldset {
    border: none;
  }
`;

export const Wrapper = styled('div')`
  width: 48%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: 20px;
`;
