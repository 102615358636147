import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
// Store
import { usePasswordResetMutation } from 'store/auth/authService';
// Hooks
import { useAuthRoutes } from 'hooks/userAuthRoutes';
// Utils
import { defaultPasswordRecoveryValues } from 'utils/constants/auth';
import { errorByType } from 'utils/helpers/index';
// Interfaces
import { IPasswordRecovery } from 'interfaces/auth';
// Global Components
import Loading from 'components/UI/Loading';
import AuthBackground from 'components/AuthBackgroud';
// Images
import ForgotPasswordImage from 'assets/images/forgot-password-image.svg';
// Components
import PasswordRecoveryForm from './PasswordRecoveryForm';
// Styles
import { Container } from './styles';
import {useTranslation} from "react-i18next";

const PasswordRecovery: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const formState = useForm<IPasswordRecovery>({
    defaultValues: defaultPasswordRecoveryValues,
    mode: 'all',
  });

  const { redirectToLogin } = useAuthRoutes();
  const [confirmPassword, { isLoading }] = usePasswordResetMutation();

  const onSubmit: SubmitHandler<IPasswordRecovery> = async (values) => {
    try {
      const recoveryToken = searchParams.get('token')!;

      await confirmPassword({
        token: recoveryToken,
        password: values.password,
      });

      redirectToLogin();
    } catch (error: any) {
      formState.setError('password', {
        type: 'custom',
        message: t(errorByType(error?.data?.message)),
      });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <PasswordRecoveryForm formState={formState} onSubmit={onSubmit} />

      <AuthBackground
        image={ForgotPasswordImage}
        maxHeight="670px"
        isImageBottom
      />
    </Container>
  );
};

export default PasswordRecovery;
