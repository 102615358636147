/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { Box } from '@mui/material';
import Footer from 'components/Footer';
// Styles
import {
  Container, Wrapper, H1, Content, P, H2, StyledUL, StyledLI, SubTitle,
} from './styles';

const PublicOffer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <H1>{t("Політика")}<Box>{t("Конфіденційності")}</Box></H1>

        <SubTitle>
          {t("редакція від 01.09.2022")}
        </SubTitle>

        <Content>
          <P variant="h5">
            {t("Обробка та захист персональних даних користувачів нашого веб-сайту має велике значення для нас, і ми докладаємо всіх зусиль для забезпечення захисту та обробки таких персональних даних у відповідності із законом. Ця Політика конфіденційності пояснює, як ми обробляємо ваші персональні дані, що ґрунтується на Законі України «Про захист персональних даних» від 01.06.2010 р. № 2297-VI  та Регламенті  Європейського парламенту і ради (ЄС) 2016/679 від 27 квітня 2016 року про захист фізичних осіб у зв'язку з опрацюванням персональних даних і про вільний рух таких даних, та про скасування Директиви 95/46/ЄС (Загальний регламент про захист даних).")}<br />
            {t("Погоджуючись із використанням файлів-cookie при першому відвіданні нашого веб-сайту, відповідно до положень цієї Політики, ви надаєте нам повний та безумовний дозвіл на використання файлів-cookie при кожному вашому наступному візиті, допоки ви не відкличете таку згоду.")}<br />
            {t("Шляхом проставлення відмітки про надання дозволу на обробку своїх персональних даних ви надаєте нам добровільну, повну та безумовну згоду на обробку своїх персональних даних, у порядку, строки та спосіб, що визначені цією Політикою Конфіденційності.")}<br />
            {t("Ви можете відкликати свою згоду на обробку персональних даних, шляхом надіслання про це повідомлення на нашу електронну адресу: hello@pipeliner.online")}<br />
          </P>
          <H2 variant="h2">
            {t("privacypolicy.1")}
          </H2>
          <P>
            {t("privacypolicy.1d1")}<br />
            {t("privacypolicy.1d1-1")}<br />
            {t("privacypolicy.1d1-2")}<br />
            {t("privacypolicy.1d1-3")}<br />
            {t("privacypolicy.1d1-4")}<br />
            {t("privacypolicy.1d1-5")}<br />
            {t("privacypolicy.1d1-6")}<br />
            {t("privacypolicy.1d1-7")}<br />
            {t("privacypolicy.1d1-8")}<br />
            {t("privacypolicy.1d1-9")}<br />
            {t("privacypolicy.1d2")}<br />
            {t("privacypolicy.1d3")}<br />
            {t("privacypolicy.1d3d1")}<br />
            {t("privacypolicy.1d3d1-1")}<br />
            {t("privacypolicy.1d4")}<br />
          </P>
          <H2 variant="h2">
            {t("privacypolicy.2")}<br />
          </H2>
          <P>
            {t("privacypolicy.2d1")}<br />
            {t("privacypolicy.2d1d1")}<br />
            {t("privacypolicy.2d1d2")}<br />
            {t("privacypolicy.2d1d3")}<br />
            {t("privacypolicy.2d1d4")}<br />
            {t("privacypolicy.2d1d5")}<br />
            {t("privacypolicy.2d2")}<br />
            {t("privacypolicy.2d2-1")}<br />
            {t("privacypolicy.2d2-2")}<br />
            {t("privacypolicy.2d2-3")}<br />
            {t("privacypolicy.2d2-4")}<br />
            {t("privacypolicy.2d2-5")}<br />
            {t("privacypolicy.2d2-6")}<br />
            {t("privacypolicy.2d2-7")}<br />
            {t("privacypolicy.2d2-8")}<br />
            {t("privacypolicy.2d2-9")}<br />
            {t("privacypolicy.2d2-10")}<br />
            {t("privacypolicy.2d2-11")}<br />
            {t("privacypolicy.2d2-12")}<br />
            {t("privacypolicy.2d2-13")}<br />
            {t("privacypolicy.2d2-14")}<br />
            {t("privacypolicy.2d2-15")}<br />
            {t("privacypolicy.2d2-16")}<br />
          </P>
          <H2 variant="h2">
            {t("privacypolicy.3")}

          </H2>
          <P>
            {t("privacypolicy.3d1")}<br />
            {t("privacypolicy.3d1-1")}<br />
            {t("privacypolicy.3d1-2")}<br />
            {t("privacypolicy.3d1-3")}<br />
            {t("privacypolicy.3d2")}<br />
            {t("privacypolicy.3d2-1")}<br />
            {t("privacypolicy.3d2-2")}<br />
            {t("privacypolicy.3d2-3")}<br />
            {t("privacypolicy.3d3")}<br />
            {t("privacypolicy.3d3-1")}<br />
            {t("privacypolicy.3d3-2")}<br />
            {t("privacypolicy.3d3-3")}<br />
            {t("privacypolicy.3d3-4")}<br />
            {t("privacypolicy.3d4")}<br />
          </P>
          <H2 variant="h2">{t("privacypolicy.4")}

          </H2>
          <P>
            {t("privacypolicy.4d1")}<br />
            {t("privacypolicy.4d2")}<br />
            {t("privacypolicy.4d3")}<br />
            {t("privacypolicy.4d4")}<br />
          </P>

          <H2 variant="h2">
            {t("privacypolicy.5")}

          </H2>
          <P>
            {t("privacypolicy.5d1")}<br />
            {t("privacypolicy.5d2")}<br />
          </P>
          <H2 variant="h2">
            {t("privacypolicy.6")}
          </H2>
          <P>
            {t("privacypolicy.6d1")}
          </P>

          <P>
            {t("privacypolicy.7")}
          </P>

          <H2 variant="h2">
            {t("Контролер персональних даних:")}
          </H2>

          <P>
            <StyledUL>
              <StyledLI>
                PipelinerOnline, Inc.
              </StyledLI>
              <StyledLI>
                EIN 61-2030800
              </StyledLI>
              <StyledLI>
                2093 PHILADELPHIA PIKE
              </StyledLI>
              <StyledLI>
                #9664
              </StyledLI>
              <StyledLI>
                CLAYMONT, DE 19703
              </StyledLI>

              <StyledLI>
                hello@pipeliner.online
              </StyledLI>

              <StyledLI>
                +13024809584
              </StyledLI>
            </StyledUL>
          </P>
        </Content>
      </Wrapper>
      <Footer isSpace />
    </Container>
  )
};

export default PublicOffer;
