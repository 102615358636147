import React from 'react';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';
import { authRoutePath } from 'routes/routePath';
// Global Components
import Loading from 'components/UI/Loading';
// Icons
import { Logo } from 'assets/logos';
import MasterCardIcon from 'assets/icons/visa-master-card.png';
// Interface
import { IRegistration } from 'interfaces/auth';
// Components
import SuccessfullySent from '../SuccessfullySent';
import RegistrationFormHeader from './RegistrationFormHeader';
import RegistrationFormFooter from './RegistrationFormFooter';
import ActionsRegistrationSteps from './ActionsRegistrationSteps';
// Styles
import {
  Container, Form, Title, Wrapper, Link, Image, ContainerSucces,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface RegistrationFormProps {
  formState: UseFormReturn<IRegistration>;
  onStepChange: (step: number) => void;
  onSubmit: SubmitHandler<IRegistration>;
  isLoading: boolean;
  step: number;
  isSuccess: boolean;
  email: string;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  onStepChange,
  onSubmit,
  formState,
  isLoading,
  step,
  isSuccess,
  email,
}) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (isSuccess) {
    return (
      <Container>
        <ContainerSucces>
          <SuccessfullySent email={email} />

          <Wrapper>
            <Link to={authRoutePath.LOGIN}>
              {t('Повернутись')}
            </Link>

            <Image src={MasterCardIcon} alt="cardIcon" />
          </Wrapper>
        </ContainerSucces>
      </Container>
    );
  }

  return (
    <Container>
      <RegistrationFormHeader />

      <Form>
        <Logo />

        <Title variant="h1">
          {t('Реєстрація')}
        </Title>

        <ActionsRegistrationSteps
          onStepChange={onStepChange}
          onSubmit={onSubmit}
          formState={formState}
          step={step}
        />
      </Form>

      <RegistrationFormFooter step={step} />
    </Container>
  );
};

export default RegistrationForm;
