import React from 'react';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';
// Interface
import { IUserProfile } from 'interfaces/user-profile';
// Components
import UserInfoInputs from '../UserInfoInputs';
import UserInfoAvatar from '../UserInfoAvatar';
// Styles
import { Section } from '../styles';
import { Title, Container } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface UserInfoProps {
  formState: UseFormReturn<IUserProfile>;
  onSubmit: SubmitHandler<IUserProfile>;
  isLoading: boolean;
}

const UserInfo: React.FC<UserInfoProps> = ({ formState, isLoading, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Section isSpaceBottom={30} id="info">
      <Title variant="h2">
        {t('pages.UserProfile.UserInfo.Персональна інформація')}
      </Title>

      <Container>
        <UserInfoInputs
          formState={formState}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />

        <UserInfoAvatar />
      </Container>
    </Section>
  )
};

export default UserInfo;
