import { styled } from '@mui/material/styles';
import { FormControl, TextField, Input } from '@mui/material';

interface ContainerProps {
  isSpaceBottom?: number;
}

export const Container = styled(FormControl)<ContainerProps>`
  margin-bottom: ${({ isSpaceBottom }) => (isSpaceBottom ? `${isSpaceBottom}px` : 0)};
  width: 100%;
`;

export const Label = styled('span')`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;

export const StyledInput = styled(TextField)`
  &&.readonly {
    .MuiInputBase-root {
      background: ${({ theme }) => theme?.palette?.primary?.main};
    }
    
    .MuiInputBase-input {
      color: ${({ theme }) => theme?.palette.custom?.primary?.silver};;
    }
  }

  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }

  .MuiFormHelperText-root {
    min-height: 20px;
    height: fit-content;
    white-space: nowrap;
  }
`;

export const StyledPasswordInput = styled(Input)`
  &.MuiInputBase-root {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    &:after {display: none;}
    &:before {display: none;}
  }
  input {
    color: ${({theme}) => theme.palette.primary.dark};
    padding: 13px 20px;
    font-size: 1rem;
    &::placeholder {
      color: ${({theme}) => theme.palette.custom?.primary?.silver}
    }
  }
  
  &&.readonly {
    .MuiInputBase-root {
      background: ${({ theme }) => theme?.palette?.primary?.main};
    }
    
    .MuiInputBase-input {
      color: ${({ theme }) => theme?.palette.custom?.primary?.silver};
    }
  }

  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }

  .MuiFormHelperText-root {
    min-height: 20px;
    height: fit-content;
    white-space: nowrap;
  }
  
  +.MuiFormHelperText-root {
    color: #d32f2f;
    margin-left: 0;
  }
`;
