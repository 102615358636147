import React from 'react';
import NavBarProject from '../NavBarProject';
import NavBarDashboard from '../NavBarDashboard';

interface NavBarModeProps {
  type: 'propject' | 'profile' | 'dashboard'
}

type navBar = {
  [key in NavBarModeProps['type']]: () => JSX.Element;
};

const NavBarMode = ({ type }: NavBarModeProps): JSX.Element => {
  const navBar: navBar = {
    propject: () => <NavBarProject />,

    dashboard: () => <NavBarDashboard />,

    profile: () => (
      <div>
        PROFILE
      </div>
    ),
  };

  return navBar[type]();
};

export default NavBarMode;
