import React from 'react';
import { useTranslation } from "react-i18next";

import {Typography} from "@mui/material";

import {Image, ImgWrap, RegularText, Wrapper} from './styles'
import ThinkingCat from 'assets/images/thinking-cat.svg';

const MobileView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Typography variant="h1"> {t('Мобільна версія наразі не підтримується')} 👀</Typography>
      <RegularText>{t('Будь ласка перейдіть до настільної версії веб-сайту')}</RegularText>
      <ImgWrap>
        <Image src={ThinkingCat} />
      </ImgWrap>
    </Wrapper>
  )
};

export default MobileView;
