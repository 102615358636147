import React from 'react';
import { useLocation } from 'react-router-dom';
// Icons
import { BlockIcon } from 'assets/icons';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Components
import HeaderUserMenu from 'components/HeaderUserMenu';
import ProjectBredCrumbs from '../ProjectBredCrumbs';
// Styles
import { Container, IconButton, Wrapper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';


const NavBarProject: React.FC = () => {
  const { redirectToProjects } = useProjectRoutes();
  const { pathname } = useLocation();
  const isPepilines = pathname?.split('/')?.[1] === 'pipelines';
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <IconButton
          onClick={redirectToProjects}
          startIcon={<BlockIcon />}
          variant="textIcon"
        >
          { t('components.NavBars.NavBarProject.Перейти до проектiв') }
        </IconButton>

        <ProjectBredCrumbs isPepilines={isPepilines} />
      </Wrapper>

      <HeaderUserMenu />
    </Container>
  );
};

export default NavBarProject;
