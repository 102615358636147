import React from 'react';
// Components
import BankCardHeader from './BankCardHeader';
import BankCardBody from './BankCardBody';
import BankCardFooter from './BankCardFooter';
import BankCardActionButtons from './BankCardActionButtons';
// Styles
import { Container } from './styles';

interface BankCardProps {
  onDeleteCard: () => void;
}

const BankCard: React.FC<BankCardProps> = ({ onDeleteCard }) => (
  <>
    <Container>
      <BankCardHeader onDeleteCard={onDeleteCard} />

      <BankCardBody />

      <BankCardFooter />
    </Container>

    <BankCardActionButtons />
  </>
);

export default BankCard;
