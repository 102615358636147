export const SEGMENT_EVENT_NAME = {
  LOG_OUT: 'Log Out',
  LOG_IN: 'Log In',
  PROJECT_CREATED: 'Project Created',
  PIPELINE_UPDATED: 'Pipeline Updated',
  PIPELINE_STEP_1: 'Pipeline - Step 1',
  PIPELINE_STEP_2: 'Pipeline - Step 2',
  PIPELINE_STEP_3: 'Pipeline - Step 3',
  PIPELINE_STEP_4: 'Pipeline - Step 4',
  PIPELINE_STEP_5: 'Pipeline - Step 5',
  PIPELINE_STEP_6: 'Pipeline - Step 6',
  PIPELINE_STEP_FINAL: 'Pipeline - Step Final',
  GO_TO_CHECKOUT: 'Go to checkout',
  SUBSCRIBE_BUTTON_CLICK: 'Subscribe button clicked',
};
