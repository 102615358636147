import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

interface TitleProps {
  isTitle: boolean;
}

interface ContainerProps {
  isSpaceBottom?: number;
  isDisabled?: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  margin-bottom: ${({ isSpaceBottom }) => (isSpaceBottom ? `${isSpaceBottom}px` : 0)};
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled(Typography)<TitleProps>`
  display: ${({ isTitle }) => (isTitle ? 'blcok' : 'none')};
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: fit-content;
  margin-bottom: 10px;
`;

export const ContainerGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;

  label:nth-of-type(1) {
    margin-right: 40px;
  }

  label:nth-of-type(2) {
    margin-right: 40px;
  }
`;

export const ContainerItem = styled(FormControlLabel)`
  display: flex;
  width: fit-content;

  .MuiFormControlLabel-label {
    color: ${({ theme }) => theme?.palette?.primary?.dark};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;
