import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  width: 100%;
  padding: 13px 20px 4px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const Link = styled(Typography)`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;
