import { type FC } from 'react';
// Components
import SideBarHeader from 'components/SideBar/SideBarHeader';
import SideBarFooter from 'components/SideBar/SideBarFooter';
import SideBarBody from './SideBarBody';
// Styles
import { Drawer } from 'components/SideBar/styles';
import { useLogout } from 'hooks/useLogout';

interface SideBarProps {
  handleDrawer: () => void;
  isOpen: boolean;
}

const SideBar: FC<SideBarProps> = ({ handleDrawer, isOpen }) => {
  const { logoutHandler } = useLogout();

  return (
    <Drawer variant="permanent" open={isOpen}>
      <SideBarHeader handleDrawer={handleDrawer} />
      <SideBarBody />
      <SideBarFooter logoutHandler={logoutHandler} isOpen={isOpen} />
    </Drawer>
  );
};

export default SideBar;
