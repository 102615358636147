import React, {FC, useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import { selectPipeline, setWorkingHours } from 'store/pipeline/pipelineSlice';
import InputAdornment from '@mui/material/InputAdornment';
import {
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useUpdatePipelineMutation } from "store/pipeline/pipelineServices";
import { IPipeline } from "store/dashboard/dashboardTypes";
// Icon
import { CloseFactIcon } from 'assets/icons';
// Components
import TitleTable from './TitleTable';
import TableBody from './TableBody';
import SummaryList from './SummaryList';
import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
// Styles
import {
  Container,
  Wrapper,
  ContainerInput,
  StyledInput,
  Paper,
  ItemRow,
  StyledTable,
} from './styles';
import {useParams} from "react-router-dom";
import {useGetPipelineByIdQuery} from "../../../store/dashboard/dashboardServices";

const columnsTitle = ['Назва', 'Ціна в продажу', 'Продажі/міс', 'Пряма собівартість', 'Маржа'];

interface SummarySectionProps {
  pipeline: IPipeline;
  refetchPipeline: () => void;
  refetchSalesPlanProducts: () => void;
  refetchSalesPlan: () => void;
}

const SummarySection: FC<SummarySectionProps> = ({ pipeline, refetchPipeline, refetchSalesPlanProducts, refetchSalesPlan }) => {
  const { workingHours } = useSelector(selectPipeline);
  const [tax, setTax] = useState(0);
  const [timer, setTimer] = useState<any>(null)
  const { pipeline_id, project_id } = useParams();

  // const { data, refetch } = useGetPipelineByIdQuery({
  //   pipeline_id: pipeline_id as string,
  //   project_id: project_id as string,
  // })
  const { t } = useTranslation();
  const [updatePipelineTax] = useUpdatePipelineMutation();

  const onChangeTax = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setTax(+event.target.value)
    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await updatePipelineTax({
        project_id: pipeline.project_id,
        pipeline_id: pipeline.pipeline_id,
        body: {
          pipeline_name: pipeline.pipeline_name,
          status: pipeline.status,
          tax: +event.target.value
        }
      })
      refetchPipeline();
      refetchSalesPlanProducts();
      refetchSalesPlan()
    }, 500)

    setTimer(newTimer)
  };

  useEffect(() => {
    setTax(pipeline.tax)
  }, [pipeline])

  return (
    <Container>
      <Wrapper
        width={60}
        maxWidth={738}
        marginRight={30}
      >
        <ContainerInput>
          <StyledInput
            onChange={onChangeTax}
            label={t('Податковка ставка %')}
            value={tax}
          />

          <Tooltip
            arrow
            placement="bottom"
            tooltipText=""
            tooltipContent={<LockTooltipText />}
            PopperProps={{
              modifiers: [{
                name: "offset",
                options: { offset: [0, -30] }
              }],
            }}
          >
            <StyledInput
              disabled
              isBackground
              label={t('Річне зростання')}
              value={t('Повільне')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseFactIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>

          <Tooltip
            arrow
            placement="bottom"
            tooltipText=""
            tooltipContent={<LockTooltipText />}
            PopperProps={{
              modifiers: [{
                name: "offset",
                options: { offset: [0, -30] }
              }],
            }}
          >
            <StyledInput
              disabled
              isBackground
              // onChange={({ target }) => dispatch(setWorkingHours(target.value))}
              label={t('Робочих годин на мiсяць')}
              value={workingHours}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CloseFactIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </ContainerInput>

        <TitleTable />

        <TableContainer component={Paper}>
          <StyledTable aria-label="simple table">
            <TableHead>
              <TableRow>
                {columnsTitle.map((title) => (
                  <ItemRow key={title} align="left">
                    { t(`${title}`)}
                  </ItemRow>
                ))}
              </TableRow>
            </TableHead>

            <TableBody refetchPipelinePlanSale={() => {}} />
          </StyledTable>
        </TableContainer>
      </Wrapper>

      <Wrapper width={35} maxWidth={354}>
        <SummaryList />
      </Wrapper>
    </Container>
  );
};

export default SummarySection;
