import React from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { Radio } from '@mui/material';
// Utils
import { InputsRules } from 'utils/constants/auth';
// Interfaces
import { ICreateProject } from 'store/projects/projectsTypes';
// Styles
import {
  ContainerGroup,
  Container, Title,
  ContainerItem,
} from './styles';

interface IRadioGroupInputs {
  value: string;
  label: string;
}

interface RadioGroupProps {
  formState: UseFormReturn<ICreateProject>;
  title?: string;
  inputs: IRadioGroupInputs[];
  isSpaceBottom?: number;
  name: string;
  isDisabled?: boolean;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  isSpaceBottom,
  isDisabled,
  formState,
  title,
  inputs,
  name,
}) => (
  <Container isSpaceBottom={isSpaceBottom} isDisabled={isDisabled}>
    <Title
      isTitle={!!title}
      variant="h4"
    >
      {title}
    </Title>

    <Controller
      name={name as keyof ICreateProject}
      control={formState.control}
      // rules={{ required: InputsRules.required }}
      render={({ field }) => (
        <ContainerGroup
          {...field}
        >
          {inputs.map(({ value, label }) => (
            <ContainerItem
              key={label}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </ContainerGroup>
      )}
    />
  </Container>
);

export default RadioGroup;
