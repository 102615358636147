import React from 'react';
import { Typography } from '@mui/material';
import InlineEditor from 'components/InlineEditor';
import { Option as OptionType } from 'types/select';
// Styles
import {
  MenuListItem,
  MenuListItemButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface OptionProps {
  data: OptionType,
  onSelect: () => void,
  selectedForDelete: boolean,
  selected: boolean,
}

const Option: React.FC<OptionProps> = ({
  children, selected, selectedForDelete, onSelect, data,
}) => {
  const { t } = useTranslation();
  return (
    <MenuListItem disablePadding>
      <MenuListItemButton
        onClick={onSelect}
        className={selected ? 'selected' : ''}
        $forDelete={selectedForDelete}
      >
        <InlineEditor fullWidth title={data.label}>
          <Typography className="read-only" variant="regularText">
            {children}
          </Typography>
        </InlineEditor>
      </MenuListItemButton>
    </MenuListItem>
  )
};

export default Option;
