import React, {useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';

import {
  SettingsIcon,
  MoreSetingsIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  EditOutlineIcon,
  TrashIcon,
  CancelIcon
} from 'assets/icons';
import SettingsModal from './SettingsModal';
import EditSourceModal from './EditSourceModal';
import Tooltip from 'components/UI/Tooltip';
// Styles
import {
  CollapseTableRow,
  SettingsLink,
  StyledTableHeader,
  Arrow,
  StyledTableContainer,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
  PaginationWrap,
  PaginationRow,
  ControlButton,
  ControlButtons,
  ControlPanel,
  SourcesWrap,
  SourcesContent,
  TooltipLink
} from './styles';

interface Data {
  sources: string,
  startDate: string,
  endDate: string,
}

function createData(
  sources: string,
  startDate: string,
  endDate: string
): Data {
  return {
    sources,
    startDate,
    endDate,
  };
}

const sources = [
  {
    name: 'E-commerce',
    rows: [
      createData('Website', '11.01.2022', '22.01.2022'),
      createData('Prom.ua', '11.01.2022', '22.01.2022'),
      createData('Rozetka', '11.01.2022', '22.01.2022'),
    ]
  },
  {
    name: 'E-commerce',
    rows: [
      createData('Sub Page 1', '11.01.2022', '22.01.2022'),
      createData('Sub Page 2', '11.01.2022', '22.01.2022'),
      createData('Sub Page 3', '11.01.2022', '22.01.2022'),
    ]
  }
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'sources',
    numeric: false,
    label: 'Джерела',
  },
  {
    id: 'startDate',
    numeric: false,
    label: 'Дата старту',
  },
  {
    id: 'endDate',
    numeric: false,
    label: 'Кінцева дата',
  },
];

interface SourcesTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSettingClick: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  collapse?: boolean;
}

function SourcesTableHead(props: SourcesTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, collapse, handleSettingClick } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <StyledTableHeader className={collapse ? 'collapse' : ''}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            width={400}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <SettingsLink onClick={handleSettingClick}>
            <SettingsIcon />
          </SettingsLink>
        </TableCell>
      </TableRow>
    </StyledTableHeader>
  );
}

const SourcesTable = () => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('sources');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = sources.reduce((acc, {name, rows}) => {
        // @ts-ignore
        acc = [...acc, ...rows.map(n => n.name)];
        return acc;
      }, [])
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllDepartmentClick = (event: React.ChangeEvent<HTMLInputElement>, source: string) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelecteds = sources.find((d) => (d.sources === source))
        .rows.map((n) => (n.sources));

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, product: string) => {
    const selectedIndex = selected.indexOf(product);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const onCloseSettingsModal = () => setOpenSettingsModal((prev) => !prev);
  const onCloseEditModal = () => setOpenEditModal((prev) => !prev);


  return (
    <StyledTableContainer>
      <ControlPanel>
        <ControlButtons>
          Вибрано {selected.length} елементи
        </ControlButtons>
        <ControlButtons>
          <ControlButton disabled={!selected.length} variant="link">
            <EditOutlineIcon />Редагувати
          </ControlButton>
          <ControlButton disabled={!selected.length} onClick={() => {}} variant="link">
            <TrashIcon />Видалити
          </ControlButton>
          <ControlButton variant="link">
            <CancelIcon />Скасувати
          </ControlButton>
        </ControlButtons>
      </ControlPanel>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
      >
        <SourcesTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={6}
          handleSettingClick={() => {setOpenSettingsModal((prev) => !prev);}}
        />
        <TableBody>
          {
            sources.map(({name, rows}) => (
              <>
                <CollapseTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell padding="checkbox">
                    <Checkbox onChange={(event) => handleSelectAllDepartmentClick(event, name)} />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {name}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Arrow
                      $isOpen={open}
                      onClick={() => setOpen(!open)}
                    />
                  </TableCell>
                </CollapseTableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                      rows.slice().sort(getComparator(order, orderBy)) */}
                      <Table>
                        <SourcesTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={6}
                          collapse
                          handleSettingClick={() => {}}
                        />
                        <TableBody>
                          {stableSort(rows, getComparator(order, orderBy))
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.sources);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  hover
                                  onClick={(event) => handleClick(event, row.sources)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.sources}
                                  selected={isItemSelected}
                                >
                                  <TableCell padding="checkbox"  component="th" scope="row">
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    component="th" scope="row"
                                    id={labelId}
                                  >
                                    {row.sources}
                                  </TableCell>
                                  <TableCell>
                                    <SourcesWrap>
                                      <SourcesContent>
                                        {row.startDate}
                                      </SourcesContent>
                                      {
                                        row.sources.length > 25
                                          ? (
                                            <Tooltip
                                              arrow
                                              placement="bottom"
                                              tooltipText=""
                                              tooltipContent={(
                                                <div>{row.startDate}</div>
                                              )}
                                            >
                                              <TooltipLink>Усі</TooltipLink>
                                            </Tooltip>
                                          )
                                          : ''
                                      }

                                    </SourcesWrap>
                                  </TableCell>
                                  <TableCell>{row.endDate}</TableCell>
                                  <TableCell>
                                    <Tooltip
                                      arrow
                                      placement="bottom"
                                      tooltipText=""
                                      tooltipContent={(
                                        <MenuList disablePadding>
                                          <MenuListItem disablePadding>
                                            <MenuListItemButton
                                              onClick={() => {setOpenEditModal((prev) => !prev);}}
                                            >
                                              <Text variant="regularText">Редагувати джерело</Text>
                                            </MenuListItemButton>
                                          </MenuListItem>
                                          <MenuListItem disablePadding>
                                            <MenuListItemButton>
                                              <Text variant="regularText" className="red">Видалити</Text>
                                            </MenuListItemButton>
                                          </MenuListItem>
                                        </MenuList>
                                      )}
                                    >
                                      <MenuButton variant="icon">
                                        <MoreSetingsIcon />
                                      </MenuButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          }
        </TableBody>
      </Table>
      <PaginationRow>
        <PaginationWrap>
          <Text variant="h6">Сторінка  1 - 10</Text>
          <Button variant="icon">
            <ArrowLeftIcon />
          </Button>
          <Button variant="icon">
            <ArrowRightIcon />
          </Button>
        </PaginationWrap>
      </PaginationRow>
      <SettingsModal isOpen={openSettingsModal} onClose={onCloseSettingsModal} />
      <EditSourceModal isOpen={openEditModal} onClose={onCloseEditModal} id="1"/>

    </StyledTableContainer>
  );
}

export default SourcesTable;
