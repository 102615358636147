import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Container = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
`;

export const Wrapper = styled('div')`
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
`;

export const H1 = styled(Typography)`
  && {
    font-size: 34px;
    line-height: 50px;
    font-weight: 700;
    display: flex;
    div {
      color: ${({ theme }) => theme?.palette?.custom?.green.main};
      margin-left: 5px;
    }
  }
`;

export const Content = styled('div')`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 30px;
  height: 100%;
  width: 100%;
`;

export const P = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black.silver};
`;

export const H2 = styled(Typography)`
   color: ${({ theme }) => theme?.palette?.custom?.black.main};
   margin: 20px auto;
  &.MuiTypography-h3 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const StyledUL = styled('ul')`
   margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const StyledLI = styled('li')`
  padding-left: 0;
  color: ${({ theme }) => theme?.palette?.custom?.black.silver};
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black.silver};
  padding-bottom: 15px;
`;

export const Img = styled('img')`
  margin-bottom: 30px;
  margin-top: 15px;
  max-width: 800px;
`
