import React, { useState } from 'react';
import {Box} from "@mui/material";
// Global components
import Modal from 'components/UI/Modal';
import CheckboxButton from 'components/UI/CheckboxButton';
import { Label, Container as InputContainer } from 'components/UI/Input/styles';

// Styles
import {
  Container,
  Title,
  ContainerButton,
  ContainerForm,
  Button,
} from './styles';

interface AddFilterModalProps {
  onClose: () => void;
  isOpen: boolean;
  id?: string;
}

const AddFilterModal: React.FC<AddFilterModalProps> = ({
  onClose,
  isOpen,
}) => {

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Додати фільтр
        </Title>
        <ContainerForm>
          <Label>Фільтри</Label>
          <Box className={"checkboxes-container"}>
            <CheckboxButton title="cashflow"/>
            <CheckboxButton title="Призначення"/>
            <CheckboxButton title="p&l"/>
            <CheckboxButton title="product_amount"/>
            <CheckboxButton title="workers"/>
            <CheckboxButton title="revenue"/>
          </Box>
        </ContainerForm>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
          >
            Додати фільтр
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddFilterModal;
