import React, { useState } from 'react';
import DatePicker from "react-datepicker";
// Translation
import { useTranslation, } from 'react-i18next';
import {
  Button, Checkbox, FormControlLabel, FormGroup, ToggleButton, ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Footer from 'components/Footer';
// Store
import {
  useGetPipelineByIdQuery,
  useGetSalesPlanQuery,
  useGetSalesPlanProductsQuery,
  useGetProjectByIdQuery
} from 'store/dashboard/dashboardServices';
import {CalendarIcon, DownloadIcon, UploadIcon, PlusIcon, SettingsIcon, CloseFactIcon} from 'assets/icons';
import Select from 'components/UI/Select';
import GeneralProfit from './GeneralProfit';
import ProfitByMonth from './ProfitByMonth';
import ProfitTable from './ProfitTable';
import { DATE } from "utils/constants/date";
import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
// Styles
import {
  H1,
  HeaderRow,
  HeaderRightBlock,
  DatePickerWrapper,
  IconWrap,
  TableContainer,
  Container,
  SettingsLink,
  Wrapper,
  WrapperButton,
  Text
} from './styles';
import {getUtcFormatDate} from "utils/helpers";

const RangeDatePicker: React.FC = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        // @ts-ignore
        setDateRange(update);
      }}
      isClearable={true}
    />
  );
};


interface IDate {
  startDate: any;
  endDate: any;
}

const Profit: React.FC = () => {
  const { t } = useTranslation();
  const { pipeline_id, project_id } = useParams();

  const { isLoading, data: pipelineData } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string
  });

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data: dataPlan } = useGetSalesPlanProductsQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const {data: dataPlanMonth} = useGetSalesPlanQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  })

  const { data: project, refetch } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  const [viewToggle, setViewToggle] = useState('product');

  const handleChangeToggle = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;

    setViewToggle(nextView);
  };

  const [viewToggleCurrency, setViewToggleCurrency] = useState('currency');

  const handleChangeToggleCurrency = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;

    setViewToggleCurrency(nextView);
  };

  return (
    <Container>
      <HeaderRow>
        <H1 variant="h1">
        { t('Дохід') }
          {/*<SettingsLink onClick={() => {setOpenSettingsModal((prev) => !prev);}}>*/}
          {/*  <SettingsIcon />*/}
          {/*</SettingsLink>*/}
        </H1>
      </HeaderRow>
      <HeaderRow className="no-top-space">
        <Wrapper>
          <ToggleButtonGroup
            onChange={handleChangeToggle}
            orientation="horizontal"
            value={viewToggle}
            exclusive
          >
            <ToggleButton value="product">
              {t('За продуктом')}
            </ToggleButton>
            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
            >
              <span style={{display: 'flex'}}>
                <ToggleButton disabled value="source">
                  <WrapperButton>
                    <CloseFactIcon />
                    <Text>{t('За джерелом')}</Text>
                  </WrapperButton>
                </ToggleButton>
              </span>
            </Tooltip>

          </ToggleButtonGroup>
          <ToggleButtonGroup
            onChange={handleChangeToggleCurrency}
            orientation="horizontal"
            value={viewToggleCurrency}
            exclusive
          >
            <ToggleButton  value="currency">
            {t('Гроші')}
            </ToggleButton>

            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
            >
              <span style={{display: 'flex'}}>
                <ToggleButton disabled value="amount">
                  <WrapperButton>
                    <CloseFactIcon />
                    <Text>{t('Кількість')}</Text>
                  </WrapperButton>
                </ToggleButton>
              </span>
            </Tooltip>
          </ToggleButtonGroup>
        </Wrapper>
      </HeaderRow>
      <TableContainer>
        <HeaderRow>
          <Typography variant="h2">{t('Місячний дохід')}</Typography>
        </HeaderRow>
        <ProfitByMonth products={pipelineData?.products} plan={dataPlan}/>
      </TableContainer>
      <ProfitTable currency={project?.currency as string} products={pipelineData?.products} plan={dataPlan} planByMonthes={dataPlanMonth} />
      <Footer isSpace isSticki/>
    </Container>
  );
};

export default Profit;
