import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface WrapperProps {
  maxWidth?: string | number;
  width?: string | number;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 48%;
  max-width: 551px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 8px;
  padding: 30px;
  height: 400px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: fit-content;
  margin-bottom: 30px;
`;

export const Wrapper = styled(Box)<WrapperProps>`
  width: 100%;
  max-width: 1122px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .recharts-legend-wrapper {
    display: none;
  }
`;
