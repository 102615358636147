import { styled } from '@mui/material/styles';
import {Button} from "@mui/material";

export const Checked = styled(Button)`
  height: 48px;
  font-size: 16px;
  display: flex;
  align-items: inherit;
`;

export const Unchecked = styled(Checked)`
  background: ${({ theme }) => theme.palette.custom.green.checkboxButton};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;