import { styled, css } from '@mui/material/styles';
import {
  Typography,
  Button as StyledButton,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

interface SideBarAccordionProps {
  active: number | boolean;
}

interface SideBarButtonProps {
  active: number | boolean;
  isOpen?: boolean;
}

export const Accordion = styled(MuiAccordion)`
  padding: 0;
  margin: 0;
  width: 100%;
  background: ${({ theme }) => theme?.palette?.custom?.black?.light};
`;

export const AccordionBody = styled(MuiAccordionDetails)(() => ({
  padding: '0 17px 28px 17px',
  borderBottom: '1px solid #333333',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const AccordionHeader = styled(MuiAccordionSummary)<SideBarAccordionProps>`
  padding: 0 20px 0 20px;
  margin: 0;
  transition: opacity 0.3s;

  svg {
    color: ${({ active }) => (active ? 'rgba(255, 255, 255, 1)' : 'rgba(131, 131, 131, 1)')};
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }

  ${({ active }) => (active && css`
    &::before {
      position: absolute;
      width: 4px;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0px 2px 2px 0px;
    }
  `)};

  &:hover {
    opacity: 0.5;
  }
`;

export const Title = styled(Typography)<SideBarAccordionProps>`
  margin-left: 12px;
  color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  transition: all 0.5s ease;
  display: ${({ active }) => (active ? 'block' : 'none')};
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export const Button = styled(StyledButton)<SideBarButtonProps>`
  transition: all 0.2s;
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  display: flex;
  padding: ${({ active }) => (active ? '15px 20px' : '0')};
  justify-content: ${({ active }) => (active ? 'flex-start' : 'center')};
  background-color: ${({ isOpen }) => (isOpen ? '#2a2a2a' : 'transparent')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  overflow: hidden;
  min-width: 50px;
  align-items: center;
  width: ${({ active }) => (active ? '100%' : '50px')};

  &:hover {
    background-color: #333333;
    color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  }

  .MuiButton-startIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ active }) => (active ? 'fit-content' : '100%')};
    margin: ${({ active }) => (active ? '13px' : '0 0 0 0')};
  }
`;

export const TitleButton = styled('span')<SideBarAccordionProps>`
  transition: all 0.5s ease;
  display: ${({ active }) => (active ? 'block' : 'none')};
  /* opacity: ${({ active }) => (active ? 1 : 0)}; */

  color: #ffffff;
`;
