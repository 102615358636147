import { IPlanSalesPipeline, IPlanSales, IPipeline, IAdditionalLosseResponse } from 'store/dashboard/dashboardTypes';
import { calcTotalAdminEmployees } from "./share-calculation";

const calcTotalAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

export const updateDataDiagram = (salasPlan: IPlanSalesPipeline[], residence: string) => {
  const additionalLoss = calcTotalAdditionalLosses(salasPlan?.[0]?.result?.additional_losses?.expenses || []);
  const calcFixedCostMonths = Math.round(calcTotalAdminEmployees(salasPlan?.[0]?.result?.employees, residence) + additionalLoss);

  const data = salasPlan?.map((item, index) => {
    const sumMaterials = item?.result?.expenseGroups?.Materials?.loss || 0;
    const sumPackaging = item?.result?.expenseGroups?.Packaging?.loss || 0;
    const sumTaxes = Math.round(+item?.result?.income * ((item.result.tax / 100)));
    const labor = item?.result?.expenseGroups?.labor?.loss || 0;
    const sumCosts = Math.round((sumMaterials + sumPackaging + labor));
    const total = sumCosts + calcFixedCostMonths + sumTaxes;

    return {
      name: index + 1,
      pv: +item.result.income.toFixed(1),
      uv: +total.toFixed(1),
    }
  })

  return data;
}

export const updateDataDiagramProduct = (salasPlanProduct: IPlanSales[]) => {
  const array: any = []

  for(let i = 0; i < 12; i++) {
    salasPlanProduct.forEach((element, index) => {
      array[i] = { ...array[i], [index]: element.sales[i].result.income, name: (i + 1).toString(), product_name: element.name, income: element.sales[i]?.result?.income, data: element.sales[i] }
    });
  }

  return array;
};

export const totalMonthExpence = (plan: IPlanSalesPipeline, index: number, sumSalaryAdmin: number) => {
  const additionalLoss = calcTotalAdditionalLosses(plan?.result?.additional_losses?.expenses || []);
  const sumMaterials = plan?.result?.expenseGroups?.Materials?.loss || 0;
  const sumPackaging = plan?.result?.expenseGroups?.Packaging?.loss || 0;
  const labor = plan?.result?.expenseGroups?.labor?.loss || 0;
  const sumCosts = Math.round((sumMaterials + sumPackaging + labor));
  const fixedCost = sumSalaryAdmin + additionalLoss;
  const sumTaxes = Math.round(+plan?.result?.income * ((plan.result.tax / 100)));

  return {
    name: index + 1,
    loss: sumCosts,
    fixedCost: fixedCost,
    taxes: sumTaxes || 0,
    tatal: Math.round(sumCosts + fixedCost + sumTaxes),
    key: index + 1,
  }
}

export const updateExpensesDiagram = (plans: IPlanSalesPipeline[] | [], pipeline: IPipeline, residence: string) => {
  const sumSalaryAdmin = calcTotalAdminEmployees(pipeline?.employees, residence) || 0;

  return plans?.map((item, index) => totalMonthExpence(item, index, sumSalaryAdmin));
}
