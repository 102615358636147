import { styled } from '@mui/material/styles';
import {
  TableCell, TableBody, TableRow, Link,
} from '@mui/material';

interface ItemProps {
  isUnderline?: boolean;
}

export const Container = styled(TableBody)`
`;

export const Row = styled(TableRow)`

`;

export const StyledLink = styled(Link)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  color: #07B169;

  &:hover {
    opacity: 0.5;
  }
`;

export const Item = styled(TableCell)<ItemProps>`
  padding: 14px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ color }) => color || '#292929'};
  text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : 'inherit')};
`;
