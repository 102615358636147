/* eslint-disable max-len */
import React, { FC } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { TableRow } from '@mui/material';
// Store
import { IPlanSales } from 'store/dashboard/dashboardTypes';
import { useUpdateSalesMonthMutation } from 'store/dashboard/dashboardServices';
// Components
import TransitionInput from 'components/UI/TransitionInput';
import { ItemRow } from './styles';

interface SalesTableRowProps {
  plan: IPlanSales;
  typeTable: string;
  pipeline_id: string;
}

const SalesTableRow: FC<SalesTableRowProps> = ({ pipeline_id, plan, typeTable }) => {
  const [update] = useUpdateSalesMonthMutation();
  const updateMonthHandler = async (sales_plan_id: string, completion_percent: number) => {
    try {
      await update({
        pipeline_id,
        sales_plan_id,
        completion_percent,
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  // const editMonthValue = (month_id: string, price: number) => {
  //   const updateMonthlySalesPlan = product?.monthlySalesPlan?.map(month => {
  //     if (month_id === month?.id) {
  //       return { ...month, plan_sales: price, sum_month_sales: calcPercent(product?.month_sales, price) }
  //     } else {
  //       return month
  //     }
  //   })

  //   // onCreatePlan(product?.product_id as string)

  //   dispatch(setMonthlySalesPlan({
  //     product_id: product?.product_id,
  //     monthlySalesPlan: updateMonthlySalesPlan,
  //   }))
  // }

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      key={plan?.product_id}
    >
      <ItemRow
        component="th"
        scope="row"
      >
        {plan?.name}
      </ItemRow>

      {plan?.sales?.map((month) => (
        <ItemRow key={month?.sales_plan_id}>
          <TransitionInput
            defaultValue={month?.completion_percent}
            id={month?.sales_plan_id as string}
            productId={month.product_id}
            editPrice={updateMonthHandler}
            isPercentIcon={typeTable}
          />
        </ItemRow>
      ))}
    </TableRow>
  );
};

export default SalesTableRow;
