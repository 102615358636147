import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

export const Title = styled(TableCell)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  padding: 10px 10px;
  /* border: none; */
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
