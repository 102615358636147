/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useForm, useWatch, Controller, useFieldArray,
} from 'react-hook-form';
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import {
  UpdateIcon,
} from 'assets/icons';
// Store
import {useGetUnitsQuery} from 'store/tutor/tutorServices';
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'components/UI/Select';
import FormRow from './FormRow';
import {
  Container,
  Title,
  ContainerButton,
  Button,
  LinkButton,
  ContainerHeader,
} from './styles';

interface Department {
  name: string;
  project_id: string;
  unit_id: string;
}
interface SettingForm {
  departments: Department[]
}
interface PagesSettingProps {
  onClose: () => void;
  isOpen: boolean;
}

const PagesSetting: React.FC<PagesSettingProps> = ({
  onClose,
  isOpen,
}) => {
  const { project_id } = useParams();

  const {data: unitsData} = useGetUnitsQuery({
    project_id: project_id as string,
  });
  const {
    control,
    formState: { errors },
  } = useForm<SettingForm>({
    mode: 'all',
    defaultValues: {
      departments: unitsData?.data,
    },
  });

  const watched = useWatch({
    control,
  });

  const {
    fields, append, remove, update,
  } = useFieldArray({
    control,
    name: 'departments',
  });

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Налаштування сторінки
        </Title>
        <ContainerHeader>
          <LinkButton variant="link">
            <UpdateIcon />Оновити сторінку згідно Факту
          </LinkButton>
        </ContainerHeader>
        <Controller
          render={({ field }) => (
            <Input
              label="Назва департаменту"
              {...field}
              className="amount-input"
              fullWidth
            />
          )}
          control={control}
          name={`departments.${fields.length - 1}.name`}
          rules={{ required: 'Це поле є обовязковим' }}
        />
        <LinkButton
          variant="link"
          onClick={() => append({
            name: '',
            project_id: project_id as string,
            unit_id: ''
          })}
        >
          Додати новий департамент
        </LinkButton>
        {
          (
            // @ts-ignore
            watched?.departments && watched?.departments.length > 1
              // @ts-ignore
              ? watched?.departments.filter((item, index, arr) => index !== arr.length - 1).map((item, index) => (
                <FormRow
                  errors={errors}
                  control={control}
                  index={index}
                  update={update}
                  remove={remove}
                  watched={watched}
                />
              ))
              : ''
          )
        }
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => {}}
          >
            Зберегти зміни
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default PagesSetting;
