export const authRoutePath = {
  REGISTRATION: '/registration',
  LOGIN: '/',
  LOGIN_VERIFY: '/auth/verify',
  RECOVERY: '/recovery',
  SET_NEW_PASSWORD: '/auth/reset',
  COUNTRY_SENT: '/country-sent',
};

export const tutorRoutesPath = {
  TUTOR: '/tutor',
  TUTOR_STEP: '/tutor/:id/:step',
}

export const userRoutesPath = {
  ERROR_PAGE: '/error-page',
  VIDEO_LESSONS: '/video/lesson',
  PROJECTS_ADD: '/projects/add',
  PROJECTS_EDIT: '/projects/edit/:id',
  PROJECTS_ADD_COPY: '/projects/edit/:id/copy',
  PIPELINES: '/pipelines/:id',
  PRODUCT_ADD: 'dashboard/:project_id/add-product/:pipeline_id/:product_id',
  PRODUCT_EDIT: 'dashboard/:project_id/edit-product/:pipeline_id/:product_id',
  SERVICE_ADD: 'dashboard/add-service',
  SERVICE_EDIT: 'dashboard/edit-service',
  PROFILE: '/profile',
  TUTOR_ID: '/tutor/:id',
  DASHBOARD: '/dashboard/:project_id/indicators/:pipeline_id',
  PRODUCTS_AND_SERVICES: '/dashboard/:project_id/products/:pipeline_id',
  TEAMS: '/dashboard/:project_id/team/:pipeline_id',
  EXPENSES: '/dashboard/:project_id/costs/:pipeline_id',
  PROFIT: '/dashboard/:project_id/income/:pipeline_id',
  PUBLIC_OFFER: '/public-offer',
  PRIVACY_POLICY: '/privacy-policy',
  HELP: '/help',
  COMPARE_PIPELINES: '/compare-pipelines/:project_id',
}

export const freelanceUserRoutesPath = {
  DASHBOARD: '/',
  PROJECTS: '/projects',
  INVOICES: '/invoices',
  SERVICES: '/services',
  PROFIT: '/profit',
  EXPENSES: '/expenses',
  MEMBERS: '/members',
  SETTINGS: '/settings',
}
