import React from 'react';
import { UseFormReturn, Controller, SubmitHandler } from 'react-hook-form';
// Hooks
import { useAuthRoutes } from 'hooks/userAuthRoutes';
// Global Components
import SelectCountries from 'components/UI/SelectCountries';
import GooglePlacesAutocomplete from 'components/UI/GooglePlacesAutocomplete';
import Input from 'components/UI/Input';
// Interface
import { IRegistration } from 'interfaces/auth';
// Utils
import { InputsRules } from 'utils/constants/auth';
// Components
import CountryActionsButtons from '../CountryActionsButtons';
// Styles
import { SubTitle, Container } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface RegistrationCountryStepProps {
  formState: UseFormReturn<IRegistration>;
  onStepChange: (step: number) => void;
  onSubmit: SubmitHandler<IRegistration>;
}

const RegistrationCountryStep: React.FC<RegistrationCountryStepProps> = ({
  onStepChange,
  formState,
}) => {
  const residence = formState.watch('residence');
  const isCountry = residence === 'noСountry';
  const isValid = !!residence;
  const isNotCountry = !!formState.watch('newCountry');
  const { redirectToCountrySent } = useAuthRoutes();

  const changeStep = () => onStepChange(2);
  const { t } = useTranslation();
  return (
    <>
      <SubTitle variant="h3">
        {t('Оберіть країну реєстрації вашого бізнесу')}
      </SubTitle>

      <Controller
        rules={{ required: InputsRules.required }}
        control={formState.control}
        name="residence"
        render={({ field }) => (
          <SelectCountries
            errorMessage={formState.formState.errors?.residence?.message}
            error={!!formState.formState.errors?.residence?.message}
            label={t('Країна вашого резидентства')}
            isSpaceBottom={10}
            country={residence}
            // isCheckbox
            {...field}
          />
        )}
      />

      <Container isCountry={isCountry}>
        <Controller
          control={formState.control}
          name="email"
          rules={{
            required: InputsRules.required,
            pattern: InputsRules.patternEmail,
          }}
          render={({ field }) => (
            <Input
              label={t("Залиште вашу пошту і ми обов'язково з вами зв'яжемось")}
              error={!!formState.formState.errors?.email?.message}
              helperText={formState.formState.errors?.email?.message}
              placeholder={t('Пошта')}
              {...field}
            />
          )}
        />

        {/*<Controller*/}
        {/*  control={formState.control}*/}
        {/*  name="newCountry"*/}
        {/*  rules={{ required: InputsRules.required }}*/}
        {/*  render={({ field }) => (*/}
        {/*    <GooglePlacesAutocomplete*/}
        {/*      label={t('Оберіть країну де б ви хотіли мати доступ до нашого сервісу')}*/}
        {/*      {...field}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
      </Container>

      <CountryActionsButtons
        redirectToCountrySent={redirectToCountrySent}
        isNotCountry={isNotCountry}
        changeStep={changeStep}
        isCountry={isCountry}
        isValid={isValid}
      />
    </>
  );
};

export default RegistrationCountryStep;
