import React from 'react';
// Images
import { ErrorIcon } from 'assets/icons';
import MockBankCard from 'assets/images/MockBankCard.png';
// Global components
import Modal from 'components/UI/Modal';
// Styles
import {
  Container,
  Title,
  Image,
  SubTitle,
  Label,
  ContainerInfo,
  Text,
  Button,
} from './styles';

interface DeleteBankCardModalProps {
  onClose: () => void;
  open: boolean;
}

const DeleteBankCardModal: React.FC<DeleteBankCardModalProps> = ({ open, onClose }) => (
  <Modal
    isOpen={open}
    onClose={onClose}
    radius={8}
  >
    <Container>
      <Title variant="h1">
        Видалити картку
      </Title>

      <Image src={MockBankCard} alt="card" />

      <SubTitle variant="h4">
        Ви впевнені?
      </SubTitle>

      <Label>
        Mastercard **0758
      </Label>

      <ContainerInfo>
        <ErrorIcon />

        <Text>
          Це ваш улюблений спосіб оплати. Ви можете встановити інший бажаний, коли закінчите.
          Будь-які незавершені транзакції з цією карткою все одно оброблятимуться
          навіть після того, як ви її видалите.
        </Text>
      </ContainerInfo>

      <Button
        onClick={onClose}
        variant="contained"
      >
        Видалити цю картку
      </Button>
    </Container>
  </Modal>
);

export default DeleteBankCardModal;
