import { styled, css } from '@mui/material/styles';

interface ListWrapProps {
  $isOpen: boolean,
  $hasError?: boolean
}

export const Container = styled('div')<ListWrapProps>`
  border-radius: 8px;
  padding: 13px 20px;
  background-color: rgba(255, 255, 255, 1);
  min-width: 192px;
  max-width: 267px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  ${({ $isOpen }) => ($isOpen ? css`
    height: auto;
    z-index: 1;
  ` : css`
    height: 48px;
  `)};

  ${({ $isOpen, $hasError, theme }) => (!$isOpen && $hasError ? css`
    border: 1px solid ${theme.palette.custom.red.main};
  ` : '')};

  .hidden-input {
    display: none;
  }
`;

export const OptionsListWrap = styled('div')<ListWrapProps>`
  padding-top: 13px;
  transition: all .3s ease-in-out;
  ${({ $isOpen, theme }) => ($isOpen ? css`
    border-top: 1px solid ${theme.palette.primary.main};
    height: 100%;
  ` : css`
    height: 0;
    padding-top: 0;
    & > * {
      display: none !important;
    }
  `)};
`;

export const FormHelperText = styled('span')<ListWrapProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #D52727;
  margin-top: 24px;
  margin-left: -20px;
  display: ${({ $hasError, $isOpen }) => (!$isOpen && $hasError ? 'block' : 'none')};
`;
