import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
// Global components
import DatePicker from 'components/UI/DatePicker';
import Input from 'components/UI/Input';
import Modal from 'components/UI/Modal';
// Images
import BankImage from 'assets/images/BankImage.png';
// Styles
import {
  Container,
  Title,
  Image,
  Wrapper,
  Button,
} from './styles';

interface AddBankCardModalProps {
  open: boolean;
  onClose: () => void;
}

const AddBankCardModal: React.FC<AddBankCardModalProps> = ({ onClose, open }) => (
  <Modal
    isOpen={open}
    onClose={onClose}
    radius={8}
  >
    <Container>
      <Title variant="h1">
        Додати нову картку
      </Title>

      <Input
        name="numberCard"
        label="Номер карти"
        type="number"
        placeholder="0000 0000 0000 0000"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Image src={BankImage} />
            </InputAdornment>
          ),
        }}
      />

      <Wrapper>
        <DatePicker />

        <Input
          name="ccv"
          label="CCV"
          type="number"
          placeholder="xxx"
        />
      </Wrapper>

      <Input
        name="adress"
        label="Платіжна адреса"
        placeholder="Адсера"
      />

      <Button
        onClick={onClose}
        variant="contained"
      >
        Додати картку
      </Button>
    </Container>
  </Modal>
);

export default AddBankCardModal;
