import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface ContainerProps {
  isCountry: boolean;
}

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const Container = styled(Box)<ContainerProps>`
  display: ${({ isCountry }) => (isCountry ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
`;
