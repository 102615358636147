import React from 'react';
// Styles
import { Container, Image } from './styles';

interface AuthBackgroundProps {
  image: string;
  maxHeight?: string;
  isImageBottom?: boolean;
}

const AuthBackground: React.FC<AuthBackgroundProps> = ({
  isImageBottom,
  maxHeight,
  image,
}) => (
  <Container isImageBottom={isImageBottom}>
    <Image
      maxHeight={maxHeight}
      src={image}
    />
  </Container>
);

export default AuthBackground;
