import React, { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';
import { IconBredCrumbs } from 'assets/icons';
// Styles
import { Link, Container } from './style';
// Translation
import { useTranslation, } from 'react-i18next';

interface ProjectBredCrumbsProps {
  isPepilines: boolean;
}

const ProjectBredCrumbs: FC<ProjectBredCrumbsProps> = ({ isPepilines }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    <Link to="/" key="1">
      { t("components.NavBars.ProjectBredCrumbs.Mій проект") }
    </Link>,

    <Typography variant="smallRegularText" key="2">
      { t("components.NavBars.ProjectBredCrumbs.Ваші пайплайни") }
    </Typography>,
  ];
  
  return (
    <Container isPepilines={isPepilines}>
      <Breadcrumbs
        separator={<IconBredCrumbs />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Container>
  );
};

export default ProjectBredCrumbs;
