import React, { FC, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// Icon
import { CloseFactIcon } from 'assets/icons';
// Utils
import { ICashflowTable } from 'utils/calculates/summary-table';
// Styles
import { Title, StyledTableRow, TitleColapce } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface RowTableProps {
  index: number;
  item: ICashflowTable;
  currency: string;
}

const RowTable: FC<RowTableProps> = ({ item, currency, index }) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <StyledTableRow
        onClick={() => setOpen(!open)}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <Title component="th" scope="row" isBold={item?.isBold}>
          {t(item?.name)} {!!(index === 2) && (<CloseFactIcon />)}
        </Title>

        <Title align="left">
          {t('грн')} ({currency})
        </Title>

        {item?.month?.map((month, index) => (
          <Title key={month?.income + index} align="left" isBold={item?.isBold}>
            {month?.income}
          </Title>
        ))}
      </StyledTableRow>

      {item?.rows?.map((row, index) => (
        <TableRow key={row?.name + index} sx={{ border: 'none', background: '#FAFAFA' }}>
          <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TitleColapce component="th" scope="row">
                {t(row?.name)}
              </TitleColapce>
              <Title align="left">
                {t('грн')} ({currency})
              </Title>

              {row?.month?.map((rowMonth, index) => (
                <Title key={rowMonth?.income + index} align="left">
                  {rowMonth?.income}
                </Title>
              ))}
            </Collapse>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default RowTable;
