import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: fit-content;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin: 10px 0;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

export const Email = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  text-align: center;
  width: 100%;
`;
