import React from 'react';
// Styles
import { Button } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface CountryActionsButtonsProps {
  redirectToCountrySent: () => void;
  isCountry: boolean;
  isValid: boolean;
  isNotCountry: boolean;
  changeStep: () => void;
}

const CountryActionsButtons: React.FC<CountryActionsButtonsProps> = ({
  redirectToCountrySent,
  isCountry,
  isValid,
  isNotCountry,
  changeStep,
}) => {
  const { t } = useTranslation();
  if (isCountry) {
    return (
      <Button
        disabled={!isNotCountry}
        onClick={redirectToCountrySent}
        variant="contained"
        fullWidth
      >
        {t('Надіслати запит')}
      </Button>
    );
  }

  return (
    <Button
      disabled={!isValid}
      onClick={changeStep}
      variant="contained"
      fullWidth
    >
      {t('Йти далі')}
    </Button>
  );
};

export default CountryActionsButtons;
