import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
