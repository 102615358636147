import React, { useState, useMemo, useEffect } from 'react';
import {
  Typography,
  Collapse,
  FormControlLabel,
  Checkbox,
  FormGroup,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import {
  IncreaseIcon,
  DecreaseIcon,
  HorizontalMenuIcon,
  EditOutlineIcon,
  TrashIcon,
  CancelIcon
} from 'assets/icons';
// Components
import Tooltip from 'components/UI/Tooltip';
import AddHoursModal from './AddHoursModal';
import AddEmployeeModal from './AddEmployeeModal';
import EditEquipment from './EditEquipment';
// Styles
import {
  Container,
  CollapseHeader,
  Arrow,
  Head,
  TitleTable,
  SortBtns,
  SortBtn,
  TableRow,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  ControlPanel,
  ControlButtons,
  ControlButton,
  ListStyled,
  LinkButton,
  ListHeaderWrap
} from './styles';
const mockData = [
  {
    employee_id: 1,
    name: 'Іванов Олександр Павлович',
    position: 'Тімлід',
    hours: '6 годин',
    date: '20.03.2022'
  },
  {
    employee_id: 2,
    name: 'Іванов Олександр Павлович',
    position: 'Тімлід',
    hours: '6 годин',
    date: '20.03.2022'
  },
  {
    employee_id: 3,
    name: 'Іванов Олександр Павлович',
    position: 'Тімлід',
    hours: '6 годин',
    date: '20.03.2022'
  },
  {
    name: 'Іванов Олександр Павлович',
    position: 'Тімлід',
    hours: '6 годин',
    date: '20.03.2022'
  }
]

const headersEmployee= [
  'Ім’я та прізвище',
  'Посада',
  'Витрачено годин',
  'Дата'
]

const headersEquipment = [
  'Назва обладнання',
  'Свпіробітник',
  'Витрачено годин',
  'Дата'
]

interface EditableListProps {
  equipment?: boolean
}


const EmployeesEditableList: React.FC<EditableListProps> = ({equipment}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [openEditEquipmentModal, setOpenEditEquipmentModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [removedEmployeesList, setRemovedEmployeesList] = useState([]);
  const [headers, setHeaders] = useState(headersEmployee);

  const removedOptionsList = useMemo(() => {
    return []
  }, [removedEmployeesList])

  const tooltipContent = useMemo(() => {
    if(equipment) {
      return (
        <MenuList disablePadding>
          <MenuListItem disablePadding>
            <MenuListItemButton onClick={() => setOpenEditEquipmentModal(true)}>
              <Text variant="regularText">Редагування обладнання</Text>
            </MenuListItemButton>
          </MenuListItem>
          <MenuListItem disablePadding>
            <MenuListItemButton onClick={() => setOpenHoursModal(true)}>
              <Text variant="regularText">Додати години обладнанню</Text>
            </MenuListItemButton>
          </MenuListItem>
          <MenuListItem disablePadding>
            <MenuListItemButton onClick={() => (removeFromList())}>
              <Text variant="regularText">Видалити</Text>
            </MenuListItemButton>
          </MenuListItem>
        </MenuList>
      );
    }
    return (
      <MenuList disablePadding>
        <MenuListItem disablePadding>
          <MenuListItemButton onClick={() => setOpenHoursModal(true)}>
            <Text variant="regularText">Редагувати</Text>
          </MenuListItemButton>
        </MenuListItem>
        <MenuListItem disablePadding>
          <MenuListItemButton onClick={() => (removeFromList())}>
            <Text variant="regularText">Видалити</Text>
          </MenuListItemButton>
        </MenuListItem>
      </MenuList>
    );
  }, [equipment])

  const onCloseHoursModal = () => setOpenHoursModal((prev) => !prev);
  const onCloseEmployeeModal = () => setOpenEmployeeModal((prev) => !prev);
  const onCloseEditEquipmentModal = () => setOpenEditEquipmentModal((prev) => !prev);
  const handleCheck = () => {
  }
  const handleCheckAll = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setSelected([])
    } else {
      setSelected([])
    }
  }
  const removeFromList = () => {
  }

  const addEmployee = (newItemData: {employee_id: string, date: Date, hours: number}) => {
  }

  const removeAll = () => {
  }

  const editHumanHours = async (data: {date: Date, hours: number}, index: number) => {
  }

  useEffect(() => {
    if(equipment) {
      setHeaders(headersEquipment);
    }
  }, [equipment]);

  return (
    <Container>
      <CollapseHeader
        className={isOpen ? 'open' : ''}
        onClick={() => (setIsOpen((prev) => (!prev)))}
      >
        <Typography>
          { equipment ? 'Oбладнання' : 'Співробітники та чоловіко-години' }
        </Typography>
        <Arrow $isOpen={isOpen} />
      </CollapseHeader>
      <Collapse className="callapse" in={isOpen} timeout="auto" unmountOnExit>
        <ControlPanel>
          <ControlButtons>
            Вибрано {selected.length} елементи
          </ControlButtons>
          <ControlButtons>
            <ControlButton disabled={!selected.length} variant="link">
              <EditOutlineIcon />Редагувати
            </ControlButton>
            <ControlButton disabled={!selected.length} onClick={() => {removeAll()}} variant="link">
              <TrashIcon />Видалити
            </ControlButton>
            <ControlButton variant="link">
              <CancelIcon />Скасувати
            </ControlButton>
          </ControlButtons>
        </ControlPanel>
        <ListStyled disablePadding>
          <ListHeaderWrap>
            <Head>
              {headers.map((title, index) => (
                <TitleTable key={title}>
                  {
                    index ===  0
                      ? (
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={!!selected.length} onChange={ handleCheckAll } />} label={title || ''} />
                          </FormGroup>
                        )
                      : title
                  }
                  <SortBtns>
                    <SortBtn><IncreaseIcon /></SortBtn>
                    <SortBtn><DecreaseIcon /></SortBtn>
                  </SortBtns>
                </TitleTable>
              ))}
              <TitleTable></TitleTable>
            </Head>
          </ListHeaderWrap>
          {
            mockData.map((employee, employeeIndex: number) => (
              <TableRow>
                <ListItemIcon>
                  <FormGroup>
                    <FormControlLabel
                        label={employee.name || '' }
                        control={
                        <Checkbox
                          checked={!!selected.find(({employee_id}) => (employee_id === employee.employee_id))}
                          onChange={(evt) => handleCheck()}
                        />
                      }
                    />
                  </FormGroup>
                </ListItemIcon>
                <ListItemText>{employee?.position}</ListItemText>
                <ListItemText>{employee?.hours ? `${employee?.hours} годин` : ''}</ListItemText>
                <ListItemText>{employee?.date}</ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip
                    arrow
                    placement="bottom"
                    tooltipText=""
                    tooltipContent={tooltipContent}
                  >
                    <HorizontalMenuIcon />
                  </Tooltip>
                </ListItemSecondaryAction>
                <AddHoursModal
                  onClose={onCloseHoursModal}
                  isOpen={openHoursModal}
                  equipment={equipment}
                />
              </TableRow>
            ))
          }
        </ListStyled>
        <LinkButton
          variant="link"
          disabled={!removedOptionsList.length}
          onClick={() => setOpenEmployeeModal(true)}
        >
          Додати співробітника
        </LinkButton>
      </Collapse>
      <AddEmployeeModal
        onClose={onCloseEmployeeModal}
        isOpen={openEmployeeModal}
      />
      <EditEquipment
        onClose={onCloseEditEquipmentModal}
        isOpen={openEditEquipmentModal}
      />
    </Container>
  );
};

export default EmployeesEditableList;
