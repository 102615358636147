import React, {useEffect} from 'react';
import { Button } from '@mui/material';
import {useNavigate} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import { useTranslation, } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";

import {setPipelineId} from "store/tutors/tutorsSlice";
import {useCreateProductMutation, useGetPipelineByIdQuery} from "store/tutors/tutorsServices";

import ProductName from "./ProductName";
import AddMaterialSection from "./AddMaterialSection";
import PackagingSection from "./PackagingSection";
import EmployeeProduct from "./EmployeeProduct";
import Footer from "components/Footer";

// Styles
import {
  H1,
  HeaderRow,
  Container,
  ContainerButton,
  LinkWrapper, ContainerSection
} from './styles';

const AddProductPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { project_id, pipeline_id } = useParams<string>();
  const {pathname} = useLocation();

  const { isLoading, data, refetch } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string
  });
  const dispatch = useDispatch();
  const isAddProductPage = pathname?.split('/')?.[3] === 'add-product';

  useEffect(() => {
      dispatch(setPipelineId({
        project_id: project_id as string,
        pipeline_id: pipeline_id as string,
      }));
  }, [pipeline_id])

  return (
    <Container>
      <HeaderRow>
        <H1 variant="h1">
          {isAddProductPage ? t('Додати продукт або сервiс') : t('Редагувати продукт')}
        </H1>
      </HeaderRow>

      <ContainerSection>
        <ProductName  />
      </ContainerSection>

      <ContainerSection>
        <EmployeeProduct  />
      </ContainerSection>

      <ContainerSection>
        <AddMaterialSection  />
      </ContainerSection>

      <ContainerSection>
        <PackagingSection />
      </ContainerSection>

      <ContainerButton>
        <Button
          variant="contained"
          onClick={() => navigate(`/dashboard/${project_id}/products/${pipeline_id}`)}
        >
          {/*{pathname === '/add-product' ? t('pages.AddProduct.Створити продукт') : t('pages.AddProduct.Редагувати продукт')}*/}
          {t('Повернутись')}
        </Button>

        {/*<Button*/}
        {/*  variant="light"*/}
        {/*>*/}
        {/*  <Link to={`/dashboard/${project_id}/products/${pipeline_id}`}>*/}
        {/*    {t('pages.AddProduct.Скасувати')}*/}
        {/*  </Link>*/}
        {/*</Button>*/}
      </ContainerButton>
      <Footer isSpace />
    </Container>
  );
};

export default AddProductPage;
