import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  padding: 30px;
  max-width: 1122px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  cursor: pointer;
  height: fit-content;
`;
