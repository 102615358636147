import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
import { LinkContainerStyled } from "../SideBar/SideBarBody/styles";
import { palette } from "styles/theme/palette";
import { ExitIcon } from "assets/icons";

interface LinkStyledProps {
  isActive: boolean;
}

export const MobileDrawerStyled = styled(Drawer)(() => ({
  '.MuiPaper-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}));

export const HeaderStyled = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 25',
  height: '100px',
}));

export const ContentContainerStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FooterStyled = styled(HeaderStyled)(() => ({
  backgroundColor: palette.custom.grey.backgroundInput,
  height: '80px',
}));

export const ExitContainerStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '193px',
  padding: '10px 18px'
}));

export const ExitIconStyled = styled(ExitIcon)(() => ({
  marginRight: '25px',
  width: '24px',
  height: '22px'
}));

export const LinkStyled = styled(LinkContainerStyled)<LinkStyledProps>(({ isActive }) => ({
  width: '193px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 18px',
  background: isActive ? palette.custom.grey.backgroundInput : 'transparent',
  color: `${isActive ? palette.custom.black.silver : palette.common.black}`,
  fontSize: '20px',
  'svg, path': {
    fill: `${isActive ? palette.custom.black.silver : palette.common.black}`,
    marginRight: '25px',
    width: '24px',
    height: '22px'
  },
}));
