import { Theme, ComponentsOverrides } from '@mui/material';

interface muiInputProps {
  styleOverrides: ComponentsOverrides['MuiInput'];
}

export const muiInput = (theme: Theme): muiInputProps => ({
  styleOverrides: {
    root: {
      color: theme.palette.primary.dark,
      '&.MuiSelect-root': {
        borderRadius: '8px',
        '&:before': {
          borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        '& fieldset': {
          borderWidth: '1px',
        },
        '&:hover fieldset': {
          borderWidth: '1px',
          borderColor: '#DDDDDD',
        },
        '&.Mui-focused fieldset': {
          borderWidth: '1px',
        },
      },
      input: {
        color: theme.palette.primary.dark,
        padding: '0.625rem 0',
        fontWeight: 500,
        fontSize: '0.813rem',
        lineHeight: theme.spacing(1),
        '&::placeholder': {
          color: theme.palette.custom?.grey?.main,
        },
      },
    },
  },
});
