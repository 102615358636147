import React, { useState, useCallback} from 'react';
import {useForm, useWatch, Controller, useFieldArray} from "react-hook-form";
import {Typography} from '@mui/material';
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import FormRow from './FormRow';
import {
  Container,
  Title,
  ContainerButton,
  Button,
  LinkButton,
  ContainerHeader,
  FormContainerFooter
} from './styles';

interface Product {
  name: string;
  startDate: Date;
  endDate: Date;
  shareOfsales: string;
  unit: string;
}
interface ProductsForm {
  name: string;
  link: string;
  groupOfChannels: string;
  products: Product[]
}
interface AddSourcesProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddSources: React.FC<AddSourcesProps> = ({
 onClose,
 isOpen,
}) => {
  const {
    control,
    formState: { errors }
  } = useForm<ProductsForm>({
    mode: "all",
    defaultValues: {
      name: 'Official web site',
      link: '',
      groupOfChannels: '',
      products: [
        {
          name: 'Продукт 1',
          startDate: new Date(),
          endDate: new Date(),
          shareOfsales: '',
          unit: '%'
        },
        {
          name: 'Продукт 2',
          startDate: new Date(),
          endDate: new Date(),
          shareOfsales: '',
          unit: '%'
        }
      ]
    }
  });

  const watched = useWatch({
    control,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "products"
  });

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Додати джерело продажу
        </Title>
        <Input
          label="Назва"
          className="amount-input"
          fullWidth
        />
        <Input
          label="Лінк"
          className="amount-input"
          fullWidth
        />
        <Select
          label="Група каналів"
          data={[]}
          className="amount-input"
          fullWidth
        />
        <Title variant="h1" className="second-head">
          Продукти в цьому каналі
        </Title>
        {
          // @ts-ignore
          fields.filter((item, index, arr) => index !== arr.length - 1).map((item, index) => (
            <FormRow
              errors={errors}
              control={control}
              index={index}
              update={update}
              remove={remove}
              watched={watched}
            />
          ))
        }
        <LinkButton variant="link" onClick={() => append({
          name: '',
          startDate: new Date(),
          endDate: new Date(),
          shareOfsales: '',
          unit: '%'
        })}>
          Додати продукт
        </LinkButton>
        <FormContainerFooter>
          Нерозподілений залишок: <Typography variant="h1">90%</Typography>
        </FormContainerFooter>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => {}}
          >
            Зберегти зміни
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddSources;
