import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface StyledTitleProps {
  color?: string;
  isRed?: boolean;
  isBold?: boolean;
  isMinus?: boolean;
}

interface TableRowProps {
  isActive?: boolean;
  isBold?: boolean;
}

export const Title = styled(TableCell)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #292929;
  padding: 20px 10px;
  width: fit-content;

  max-width: fit-content;
  min-width: 90px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const StyledTitle = styled(TableCell)<StyledTitleProps>`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: ${({ isBold }) => isBold ? 'bold' : 400};
  font-size: 14px;
  line-height: 22px;
  color: ${({ isRed, isMinus }) => isMinus ? '#D52727' : isRed ? '#D52727' : '#07B169'};
  padding: 20px 10px;
  width: fit-content;

  max-width: fit-content;
  min-width: 90px;
  overflow-x: auto;
  white-space: nowrap;

  @media print {
    padding: 10px;
  }
`;

export const TitleColapce = styled(TableCell)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  padding: 20px 10px;
  padding-left: 20px;
  width: 200px;

  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  border-left: 1px solid #07B169;
`;

export const StyledTableRow = styled(TableRow)<TableRowProps>`
  background: ${({ isBold }) => (isBold ? '#F8F9FD' : '#ffffff')};
`;
