import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonProps {
  component?: string | undefined;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin-bottom: 7px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const Button = styled(StyledButton)<ButtonProps>`

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  && {
    height: 60px;
  }
`;

export const WrapperButton = styled(Box)`
  margin-bottom: 10px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

export const Text = styled(Typography)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #838383;
  margin-left: 5px;
`;
