import React, { useState, useEffect } from 'react';
// Hooks
import useDebounce from 'hooks/useDebounce';
// Styles
import { StyledInput } from './styles';

interface EditInputProps {
  defaultValue: number;
  productId: string;
  onChangeValue: (product_id: string, price: number) => void;
}

const EditInput: React.FC<EditInputProps> = ({ onChangeValue, defaultValue, productId }) => {
  const [value, setValue] = useState(defaultValue?.toString())
  const debouncedPrice = useDebounce<string>(value, 1000)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  useEffect(() => {
    onChangeValue(productId, +value)
  }, [debouncedPrice])

  return (
    <StyledInput
      onChange={handleChange}
      defaultValue={defaultValue}
      value={value}
      type="number"
    />
  )
}

export default EditInput;
