import React from 'react';
import { Checkbox } from '@mui/material';
import { CheckLightIcon } from 'assets/icons';
import { Unchecked } from './styles';

const CheckboxLight: React.FC = ({ ...props }) => (
  <Checkbox
    {...props}
    icon={<Unchecked />}
    checkedIcon={<Unchecked><CheckLightIcon /></Unchecked>}
  />
);

export default CheckboxLight;
