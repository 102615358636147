import React, { FC } from 'react';
// Icons
import { SettingsIcon } from 'assets/icons';
// Styles
import { Container, Label } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { re } from 'mathjs';

const Title: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Label variant="h1">
        {t('Огляд показників')}
      </Label>

      {/* <SettingsIcon /> */}
    </Container>
  )
};

export default Title;
