import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 1122px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
