import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  align-items: center;

  @media (max-width: 640px) {
    position: static;
    margin-top: 20px;
    bottom: 0;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
`;

export const Image = styled('img')`
  width: 80px;
  height: 20px;
  margin-top: 5px;
`;

export const Label = styled('span')`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const Link = styled(StyledLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  text-decoration: underline;
  margin-left: 5px;
`;
