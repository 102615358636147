import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
// Icons
import { SearchIcon } from 'assets/icons';
// Constants
import { itemsFilteredData } from 'utils/constants/user-profile';
// Styles
import { Container, StyledInput, StyledSelect } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
const FilterInputs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledSelect
        defaultValue={itemsFilteredData[1].value}
        data={itemsFilteredData}
        name="items"
      />

      <StyledInput
        placeholder={t('Пошуковий запит...')}
        name="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
};

export default FilterInputs;
