import React, {ReactNode, useState} from 'react';
import {Collapse, Typography} from "@mui/material";

import {Arrow} from "pages/AddProduct/EmployeesEditableList/styles";
import {AccordionContainer, AccordionHeader} from './styles';


interface AccordionProps {
  title: string | ReactNode;
  children: ReactNode
  open?: boolean;
  handleClick: (isOpen: any) => any;
}

const Accordion: React.FC<AccordionProps> = ({handleClick, title, children, open = false}) => {
  // const [isOpen, setIsOpen] = useState<boolean>(open);
  return (
    <AccordionContainer>
      <AccordionHeader
        className={open ? 'open' : ''}
        onClick={handleClick}>
          {title}
        <Arrow $isOpen={open} />
      </AccordionHeader>
      <Collapse className="callapse" in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </AccordionContainer>
  )
};

export default Accordion;
