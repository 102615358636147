import React from 'react';
// Styles
import { StyledIconBtn } from './styles';

interface IconButtonProp {
  icon: React.ReactElement;
  transparent?: boolean;
  onClick?: () => void;
}
const ButtonsPage: React.FC<IconButtonProp> = ({ transparent, icon, onClick }) => (
  <StyledIconBtn
    className={transparent ? 'transparent' : ''}
    onClick={onClick}
    variant="light"
  >
    {icon}
  </StyledIconBtn>
);

export default ButtonsPage;
