export const currency = [
  {
    label: 'Гривня (₴)',
    value: '₴',
  },
  {
    label: 'Євро (€)',
    value: '€',
  },
  {
    label: 'Долар ($)',
    value: '$',
  },
];
