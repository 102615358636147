import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  gap: 0 20px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.lightIco};
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const Image = styled('img')`
  width: 100%;
  margin-bottom: 20px;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  margin-top: 30px;
`;

export const ContainerInfo = styled(Box)`
  display: flex;
  border: 1px solid #07B169;
  border-radius: 8px;
  background: ${({ theme }) => theme?.palette?.custom?.green?.light};
  padding: 20px;
  width: 100%;
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: fit-content;
  margin-left: 10px;
`;
