import { styled, css } from '@mui/material/styles';
import {Typography, Button, TableHead, TableContainer, TableRow, List, ListItem, ListItemButton} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  margin-right: 33px;
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  margin-right: 10px;
  margin-bottom: 12px;
  & > * {
    margin-left: 10px;
  }
`;

export const PipelinerCardContainer = styled('div')`
  display: grid;
  column-count: 2;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  grid-auto-rows: 750px;
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  margin-right: 33px;
`;

export const HeaderRightBlock = styled('div')`
  display: flex;
  align-items: center;
  
  & > * {
    margin-right: 30px;
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const StyledTableContainer = styled(TableContainer)`
`;

export const CollapseTableRow = styled(TableRow)`
  background: ${({ theme }) => theme.palette.custom.green.light};
  .MuiTableCell-root {
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};
`;

export const StyledTableHeader = styled(TableHead)`
  &.collapse {
    visibility: collapse;
  }
`;

export const SettingsLink = styled('a')`
`;

export const MenuButton = styled(Button)`
  && {
    height: 20px;
    min-width: 20px;
    padding: 0;
    width: 20px;
    background: transparent;
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
    
    &:hover {
      border-radius: 4px;
      color: ${({ theme }) => theme.palette.custom.green.main};
      background: ${({ theme }) => theme.palette.custom.green.light};
    }
  }
`

export const MenuList = styled(List)`
  margin: -8px -17px;
  padding: 0;
  width: 217px;
`;

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  height: 38px;
`;

export const MenuListItemButton = styled(ListItemButton)`
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};

  &.status {
    margin-right: 5px;
  }

  &.red {
    color: ${({ theme }) => theme.palette.custom.red.main};
  }
`;

export const PaginationRow = styled('div')`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const PaginationWrap = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 30px;
  h6 {
    margin-right: 20px;
  }
  button:first-of-type {
    margin-right: 10px;
  }
`;

export const ControlPanel = styled('div')`
  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
`;

export const ControlButtons = styled('div')`

`;

export const ControlButton = styled(Button)`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  && {
    text-decoration: none;
    svg {
      margin-right: 12px;
    }
  }
`;
export const SourcesWrap = styled('div')`
  display: flex;
`;

export const SourcesContent = styled('div')`
  max-width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TooltipLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  &:hover {
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
`;