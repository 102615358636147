import React, { FC, useState, SyntheticEvent } from 'react';
import { useTranslation, } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Icon
import { CloseFactIcon } from 'assets/icons';
// Tables
import CashflowTable from './CashflowTable';
import ProfitAndLossTable from './ProfitAndLossTable';
import SalesMonths from './SalesMonths';
import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
// Styles
import { Container, TitleTab, Wrapper } from './styles';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isDisabled?: boolean;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

interface DashboardTablesProps {
  currency: string;
}

const DashboardTables: FC<DashboardTablesProps> = ({ currency }) => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 4 || newValue === 5 || newValue === 3) return

    setValue(newValue);
  };

  const tabName = [
    'Профіт&Лосс', 'Кешфлоу', 'Продажі за місяцями', 'Баланс', 'Оборотні активи', 'Позаоборотні активи',
  ];

  const isCloseTab = (index: number) => {
    if (index === 4 || index === 5 || index === 3) {
      return <CloseFactIcon />;
    }

    return ''
  }

  return (
    <Container>
      <Wrapper sx={{ width: '100%' }}>
        <Box sx={{
          borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px',
        }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
          >
            {tabName.map((tab, index) => (
              isCloseTab(index) ? (
                  <Tooltip
                    arrow
                    placement="bottom"
                    tooltipText=""
                    tooltipContent={<LockTooltipText/>}
                    PopperProps={{
                      modifiers: [{
                        name: "offset",
                        options: { offset: [0, -20] }
                      }],
                    }}
                  >
                    <TitleTab icon={<CloseFactIcon />} key={tab} label={t(`${tab}`)} {...a11yProps(index)} />
                  </Tooltip>
                ) :
                <TitleTab key={tab} label={t(`${tab}`)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <ProfitAndLossTable currency={currency} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CashflowTable currency={currency} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <SalesMonths />
        </TabPanel>

        <TabPanel value={value} index={3}>
          Item Four
        </TabPanel>

        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>

        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
      </Wrapper>
    </Container>
  );
};

export default DashboardTables;
