import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface ContainerProps {
  isSubscribe?: boolean;
  isDisabled?: boolean;
}

interface ImageProps {
  isActive: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ isSubscribe, isDisabled }) => (isDisabled ? '#F8F9FD' : !isSubscribe ? '#EBF7F2' : '#F8F9FD')};
  border-radius: 8px;
  width: 32%;
  max-width: 500px;
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};

  &:hover {
    background: #F8F9FD;
    transition: all 0.5s ease;
  }
`;

export const Image = styled('img')<ImageProps>`
  display: ${({ isActive }) => isActive ? 'block' : 'none'};
  width: 19px;
  height: 43px;
  position: absolute;
  top: 0;
  right: 16px;
`;
