import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useGoogleLogin } from '@react-oauth/google';
import { useLoginGoogleMutation } from 'store/auth/authService';
// Global Components
import LoadingButton from 'components/UI/Buttons/LoadingButton';
// Interfaces
import { ILogin } from 'interfaces/auth';
// Icons
import { GoogleIcon } from 'assets/icons';
// Styles
import { GoogleButton } from './styles';
// Translation
import { useTranslation } from 'react-i18next';

interface LoginButtonsProps {
  onSubmit: SubmitHandler<ILogin>;
  formState: UseFormReturn<ILogin>;
  isLoading: boolean;
}

const LoginButtons: React.FC<LoginButtonsProps> = ({
  onSubmit,
  formState,
  isLoading,
}) => {
  const [createLogin] = useLoginGoogleMutation();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      createLogin(codeResponse?.access_token as string);
    },
    flow: 'implicit',
  });
  const { t } = useTranslation();
  return (
    <>
      <LoadingButton
        onClick={formState.handleSubmit(onSubmit)}
        isDisabled={!formState.formState.isValid}
        title={t('pages.Login.LoginButtons.Увійти')}
        loading={isLoading}
      />

      {/* <GoogleButton
        onClick={() => login()}
        startIcon={<GoogleIcon />}
        variant="textIcon"
        fullWidth
      >
        Увійти за домопогою Google
      </GoogleButton> */}
    </>
  );
};

export default LoginButtons;
