import React, { useState, useCallback } from 'react';
import { InputAdornment, Typography } from '@mui/material';
import DatePicker from "react-datepicker";
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import { Label, Container as InputContainer } from 'components/UI/Input/styles';
import { ClockIcon, CalendarIcon } from 'assets/icons';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  IconWrap,
  ReadOnlyWrap,
  ReadOnlyRow
} from './styles';
;
// Translation
import { useTranslation, } from 'react-i18next';
interface AddHoursModalProps {
  onClose: () => void;
  isOpen: boolean;
  equipment?: boolean;
}

const AddHoursModal: React.FC<AddHoursModalProps> = ({
  onClose,
  isOpen,
  equipment
}) => {
  const [hours, setHours] = useState('');
  const [date, setDate] = useState(new Date());

  const changeHoursHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(event.target.value)
  };

  const addHours = useCallback(() => {
    onClose();
  }, [hours, date, onClose])
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Додати години {equipment ? 'обладнанню' : 'співробітнику'}
        </Title>
        <ReadOnlyWrap>
          <ReadOnlyRow className="bordered">
            <Label>Імʼя та прізвище</Label>
            <Typography>Іванов Олександр Павлович</Typography>
          </ReadOnlyRow>
          <ReadOnlyRow>
            <Label>{equipment ? 'Обладнання' : t('Підрозділ')}</Label>
            <Typography>Майстерня</Typography>
          </ReadOnlyRow>
        </ReadOnlyWrap>

        <InlineFormWrap>
          <Input
            label="Витрачено годин"
            type="number"
            placeholder="0"
            className="inline-input time-input"
            defaultValue={hours}
            onChange={changeHoursHandler}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <ClockIcon />
              </InputAdornment>)
            }}
          />
          <InputContainer className="inline-input">
            <Label>Дата</Label>
            <DatePickerWrapper>
              <DatePicker selected={date} onChange={(date: Date) => setDate(date)}></DatePicker>
              <IconWrap>
                <CalendarIcon />
              </IconWrap>
            </DatePickerWrapper>
          </InputContainer>
        </InlineFormWrap>


        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={addHours}
          >
            Додати витрату
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddHoursModal;
