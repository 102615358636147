import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';
// Store
import { IProduct } from 'interfaces/product';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
// Icons
import {
  MoreSetingsIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from 'assets/icons';
// Components
import Tooltip from 'components/UI/Tooltip';
import CollapseTableRow from './CollapseTableRow';
import ProductsTableBody from './ProductsTableBody';
import TeamsTableHead from './TeamsTableHead';
import SettingsModal from './SettingsModal';
import ControlPanelTable from './ControlPanelTable';
// Styles
import {
  // CollapseTableRow,
  SettingsLink,
  StyledTableHeader,
  Arrow,
  StyledTableContainer,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
  PaginationWrap,
  PaginationRow,
  ControlButton,
  ControlButtons,
  ControlPanel,
  SourcesWrap,
  SourcesContent,
  TooltipLink
} from './styles';
import {useTranslation} from "react-i18next";

interface Data {
  product: string,
  sources: string,
  price: number,
}

function createData(
  product: string,
  sources: string,
  price: number
): Data {
  return {
    product,
    sources,
    price,
  };
}

const departments = [
  {
    name: 'Назва групи 1',
    rows: [
      createData('Продукт 1', 'Prom.ua, Rozetka, Вебсайт...', 22),
      createData('Продукт 2', 'Prom.ua, Rozetka', 10),
      createData('Продукт 3',
        'Prom.ua, Rozetka, Вебсайт, Amazon, Prom.ua, Rozetka, Вебсайт, Amazon, Prom.ua, Rozetka, Вебсайт, Amazon.', 23),
    ]
  },
  {
    name: 'Назва групи 2',
    rows: [
      createData('Продукт 1', 'Prom.ua, Rozetka, Вебсайт...', 22),
      createData('Продукт 2', 'Prom.ua, Rozetka', 10),
      createData('Продукт 3', 'Prom.ua, Rozetka, Вебсайт...', 23),
    ]
  }
]

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface ProductsTableProps {
  products: IProduct[] | [];
}

const ProductsTable: React.FC<ProductsTableProps> = ({ products }) => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IProduct>("group");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openProductTable, setOpenProductTable] = useState(false);
  const { t } = useTranslation();
  const isProducts = !!products?.filter(item => item?.group === 'product')?.length;
  const isServices = !!products?.filter(item => item?.group === 'service')?.length;

  const openHandleTableProduct = () => {
    setOpenProductTable(prev => !prev);
  }

  // const { products, planSales } = useSelector(selectDashboard);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IProduct,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = departments.reduce((acc, {name, rows}) => {
        // @ts-ignore
        acc = [...acc, ...rows.map(n => n.name)];
        return acc;
      }, [])
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllDepartmentClick = (event: React.ChangeEvent<HTMLInputElement>, product: string) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelecteds = departments.find((d) => (d.product === product))
        .rows.map((n) => (n.product));

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, product: string) => {
    const selectedIndex = selected.indexOf(product);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const onCloseSettingsModal = () => setOpenSettingsModal((prev) => !prev);

  return (
    <StyledTableContainer>
      {/*<ControlPanelTable count={selected.length} />*/}

      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
      >
        <TeamsTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={6}
          handleSettingClick={() => {setOpenSettingsModal((prev) => !prev);}}
        />

        <TableBody>
          {isProducts && (
            <ProductsTableBody
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              isSelected={isSelected}
              groupName={t("Продукти")}
              products={products}
              order={order}
              orderBy={orderBy}
              group="product"
            />
          )}

          {isServices && (
            <ProductsTableBody
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              isSelected={isSelected}
              groupName={t("Сервіс")}
              products={products}
              order={order}
              orderBy={orderBy}
              group="service"
            />
          )}
        </TableBody>
      </Table>
      {/*<PaginationRow>*/}
      {/*  <PaginationWrap>*/}
      {/*    <Text variant="h6">Сторінка  1 - 10</Text>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowLeftIcon />*/}
      {/*    </Button>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowRightIcon />*/}
      {/*    </Button>*/}
      {/*  </PaginationWrap>*/}
      {/*</PaginationRow>*/}
      <SettingsModal isOpen={openSettingsModal} onClose={onCloseSettingsModal} />
    </StyledTableContainer>
  );
}

export default ProductsTable;
