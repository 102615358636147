import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, css } from '@mui/material/styles';
import { Toolbar, Button } from '@mui/material';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface IconButtonProps {
  $noSideBar?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 252,
    width: `calc(100% - ${252}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Container = styled(Toolbar)`
  && {
    padding: 0;
    background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  }
`;

export const IconButton = styled(Button)<IconButtonProps>`
  width: 80px;
  height: 64px;
  border-radius: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: transparent;
  }

  &.transparent {
    background: transparent;
  }
  
  ${({ $noSideBar, theme }) => ($noSideBar ? css`
    width: 180px;
    color: black;
    display: flex;
    align-items: center;
    svg {
      margin-right: 15px;
    }
  ` : css`
    width: 80px;
  `)};
`;
