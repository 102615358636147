import React from 'react';
// Styles
import { Button } from './styles';

interface LoadingButtonProps {
  loading: boolean;
  title: string;
  onClick: () => void;
  isDisabled: boolean;
}
const LoadingButton: React.FC<LoadingButtonProps> = ({
  onClick,
  isDisabled,
  loading,
  title,
}) => (
  <Button
    size="small"
    onClick={onClick}
    loading={loading}
    variant="outlined"
    disabled={isDisabled}
  >
    {!loading && title}
  </Button>
);

export default LoadingButton;
