import React, {useState} from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';

import {LockedIcon, PresentSmallIcon} from 'assets/icons';
import {
  Container,
  IconWrapper,
  TextWrapper,
  Link,
  SmallTooltipText,
  PresentSmallIconWrap,
  BottomContainer
} from './styles';
import {Trans, useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 320,
    },
});
const ProjectCardBlocked: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <CustomWidthTooltip
        title={
          <TextWrapper>
              {t('Нажаль період безкоштовного користування вичерпано. Для продовження необхідно обрати')}
              <HashLink to='/profile#plans'>
                <Link> {t('тарифний план')}</Link>
              </HashLink>
              <br/>
              <br/>
              <Trans i18nKey={'tooltipSubText'}>
                  Вам доступний промо-код зі знижкою 50%! Для його застосування, при офорленні підписки Вам потрібно ввести
                  <strong>PIPE50U</strong>.
              </Trans>
            <BottomContainer>
              <SmallTooltipText>
                {t('Ця знижка буде дійсна впродовж 6 місяців.')}
              </SmallTooltipText>
              <PresentSmallIconWrap>
                <PresentSmallIcon/>
              </PresentSmallIconWrap>
            </BottomContainer>
          </TextWrapper>
        }
        placement="bottom">
        <IconWrapper>
          <LockedIcon/>
        </IconWrapper>
      </CustomWidthTooltip>
    </Container>
  )
}

export default ProjectCardBlocked;
