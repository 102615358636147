import { styled, css } from '@mui/material/styles';
import {Typography, Button, TableHead, TableContainer, TableRow, List, ListItem, ListItemButton} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled(TableRow)`
  background: ${({ theme }) => theme.palette.custom.green.light};

  .MuiTableCell-root {
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};

  cursor: pointer;
`;
