import React from 'react';
import dayjs from 'dayjs';
// Store
import { useGetInvoicesQuery } from 'store/auth/authService';
// Styles
import { Container, Row, Item, StyledLink } from './styles';
import {useTranslation} from "react-i18next";

const TableBody: React.FC = () => {
  const { data } = useGetInvoicesQuery({});
  const { t } = useTranslation();
  const formatData = (i: number) => dayjs.unix(data?.[i]?.description?.[0]?.period?.start as number).format('DD.MM.YYYY')

  return (
    <Container>
      {data?.map((item, index) => (
        <Row
          key={item?.description?.[0]?.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <Item align="left" component="th" scope="row">
            {formatData(index)}
          </Item>

          <Item align="left" color="#07B169">
            {item?.description?.[0]?.id}
          </Item>

          <Item align="left" color="#07B169">
            {t('Підписка')} “{t(item?.description?.[0]?.name)}“
          </Item>

          <Item align="left" color="#07B169" isUnderline>
            <StyledLink href={item?.invoice_pdf} target="_blank">
              Invoice
            </StyledLink>
          </Item>

          <Item align="left">
            $ {item?.description?.[0]?.amount / 100}
          </Item>

          <Item align="left" color="#838383">
            {t('Зараховано')}
          </Item>
        </Row>
      ))}
    </Container>
  )
};

export default TableBody;
