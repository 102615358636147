import React, { FC } from 'react';
// Styles
import { Header, Title, Subtext } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const AddTutorHeader: FC = () => {
  const { t } = useTranslation();
  return (
    <Header>
      <Title variant="h4">
        {t('Пайплайнер – це інструмент для швидкої перевірки продуктових гіпотез')}
      </Title>

      <Subtext variant="h3">
        {t('Якщо простіше, то це калькулятор, що дозволить вам порахувати, чи окупається проект, коли і яким чином він вийде на беззбитковий рівень та поверне інвестиції та кредити')}.
      </Subtext>
    </Header>
  )
};

export default AddTutorHeader;
