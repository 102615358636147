import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';
import {Link} from "react-router-dom";
export const Container = styled(Box)`
  display: flex;
`;

export const IconButton = styled(Button)`
  background: ${({ theme }) => theme?.palette?.primary.contrastText};
  border-radius: 6px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  width: 100%;
  max-width: 191px;
  padding: 0 8px;

  .MuiButton-startIcon {
    margin-right: 8px;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme?.palette?.primary.contrastText};
    }
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
