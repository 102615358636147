import React from 'react';
// Styles
import { Container, Title, SubTitle } from './styles';

interface TariffPlanCardHeaderProps {
  title: string;
  subTitle: string;
}

const TariffPlanCardHeader: React.FC<TariffPlanCardHeaderProps> = ({ title, subTitle }) => (
  <Container>
    <Title>
      {title}
    </Title>

    {/* <SubTitle variant="h3">
      {subTitle}
    </SubTitle> */}
  </Container>
);

export default TariffPlanCardHeader;
