import { styled } from '@mui/material/styles';
import { ListSubheader } from '@mui/material';

export const Container = styled(ListSubheader)`
  padding: 0;
`;

export const Head = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  color: ${({ theme }) => theme.palette.custom.black.silver};
  padding: 0;
`;

export const TitleTable = styled('div')`
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};;
  display: flex;
  align-items: center;
`;

export const SortBtns = styled('div')`
  margin-left: 5px;
`;

export const SortBtn = styled('a')`
  display: flex;
  margin: 3px 0;
`;
