import { BaseQueryParams } from 'store/baseQuery';
import {
  CreateProjectRequest,
  CreateProjectResponse,
  ProjectsResponse,
  IProject,
  UpdateProjectRequest,
} from './projectTypes';

export const projectServices = BaseQueryParams('project', ['PROJECT']).injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation<CreateProjectResponse, CreateProjectRequest>({
      query: (data) => ({
        url: '/projects',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => ['PROJECT'],
    }),

    getProjects: builder.query<ProjectsResponse, unknown>({
      query: () => ({
        url: '/projects',
        method: 'GET',
      }),
      providesTags: () => ['PROJECT'],
    }),

    getOneProject: builder.query<IProject, { id: string }>({
      query: ({ id }) => ({
        url: `/projects/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['PROJECT'],
    }),

    updateProject: builder.mutation<IProject, { body: UpdateProjectRequest, id: string }>({
      query: ({ body, id }) => ({
        url: `/projects/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['PROJECT'],
    }),

    deleteProject: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['PROJECT'],
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetProjectsQuery,
  useGetOneProjectQuery,
  useDeleteProjectMutation,
} = projectServices;
