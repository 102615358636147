import { type FC } from "react";
import { useScreenSize } from "hooks/useScreenSize";
import { Link } from "react-router-dom";
import { freelanceUserRoutesPath } from "routes/routePath";
import { Logo } from "assets/logos";
import { HeaderContainerStyled } from "./styles";
import { HamburgerIcon } from "assets/icons";
import { IconContainerStyled } from "../styles";

interface HeaderProps {
  handleDrawer: () => void;
}

const Header: FC<HeaderProps> = ({ handleDrawer }) => {
  const { isMobile } = useScreenSize();

  if (!isMobile) return null;
  return (
    <HeaderContainerStyled>
      <Link to={freelanceUserRoutesPath.DASHBOARD}>
        <Logo />
      </Link>
      <IconContainerStyled onClick={handleDrawer}>
        <HamburgerIcon />
      </IconContainerStyled>
    </HeaderContainerStyled>
  )
};

export default Header;
