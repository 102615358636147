import React from 'react';
// Hooks
import { useAuthRoutes } from 'hooks/userAuthRoutes';
// Icons
import { ArrowLeftGreenIcon } from 'assets/icons';
// Styles
import {
  Container,
  Wrapper,
  Button,
  Label,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const RegistrationFormHeader: React.FC = () => {
  const { redirectToLogin } = useAuthRoutes();
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Button
          onClick={redirectToLogin}
          icon={<ArrowLeftGreenIcon />}
        />

        <Label variant="regularText">
          {t('Назад')}
        </Label>
      </Wrapper>
    </Container>
  );
};

export default RegistrationFormHeader;
