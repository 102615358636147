import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserLogout } from 'store/auth/authSlice';
import { useAuth } from './useAuth';
import { SEGMENT_EVENT_NAME } from '../utils/constants/analytics';
import { addEvent } from '../store/analytics/analyticsService';
import type { SegmentEventPayload } from '../store/analytics/analyticsTypes';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const logoutHandler = useCallback(() => {
    const eventPayload: SegmentEventPayload = {
      eventName: SEGMENT_EVENT_NAME.LOG_OUT,
      payload: { email: user?.email },
    };
    dispatch(addEvent(eventPayload));
    dispatch(setUserLogout());
  }, [dispatch, user]);

  return { logoutHandler };
};
