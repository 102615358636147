import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DATE } from "utils/constants/date";
// Store
import {
  useGetPipelineByIdQuery,
  useGetProjectByIdQuery,
  useGetSalesPlanProductsQuery, useGetSalesPlanQuery
} from 'store/dashboard/dashboardServices';
import {useHandleRequestError} from "hooks/useHandleRequestError";
import {IPipeline, IPlanSales} from "store/dashboard/dashboardTypes";
import {getUtcFormatDate} from "utils/helpers";
// Components
import Footer from 'components/Footer';
import Loading from 'components/UI/Loading';
import Title from './Title';
import SettingsSection from './SettingsSection';
import SummarySection from './SummarySection';
import SalesPlanSection from './SalesPlanSection';
import AnalysisIncomeSection from './AnalysisIncomeSection';
import StructureAnalysisSections from './StructureAnalysisSections';
import DashboardTables from './DashboardTables';
// Styles
import { Container } from './styles';

interface IDate {
  startDate: any;
  endDate: any;
}

const PlanDashboard: FC = () => {
  const { pipeline_id, project_id } = useParams();

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data: pipeline, isLoading, isError, refetch: refetchPipeline } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string
  });

  const { data, refetch: refetchSalesPlan } = useGetSalesPlanQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const { data: salesPlanProductsData, refetch: refetchSalesPlanProducts } = useGetSalesPlanProductsQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  useHandleRequestError(isError);

  const { data: project, refetch } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  useEffect(() => {
    refetch()
  }, [])

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Title />

      <SettingsSection dateValue={dateValue} setDateValue={setDateValue} />

      <SummarySection pipeline={pipeline as IPipeline}
                      refetchSalesPlanProducts={refetchSalesPlanProducts}
                      refetchPipeline={refetchPipeline}
                      refetchSalesPlan={refetchSalesPlan}
      />

      <SalesPlanSection salesPlanProductsData={salesPlanProductsData as IPlanSales[]} />

      <AnalysisIncomeSection currency={project?.currency as string} />

      <StructureAnalysisSections currency={project?.currency as string} />

      <DashboardTables currency={project?.currency as string} />

      <Footer isSpace />
    </Container>
  );
};

export default PlanDashboard;
