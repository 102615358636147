import { styled } from '@mui/material/styles';
import { Link as StyledLink } from 'react-router-dom';
import { Box } from '@mui/material';

interface ContainerProps {
  isPepilines: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  display: ${({ isPepilines }) => (isPepilines ? 'flex' : 'none')};
  align-items: center;
  width: 380px;
`;

export const Link = styled(StyledLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #292929;
`;
