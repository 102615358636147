import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPipelineID, setPipelineCount } from 'store/projects/projectSlice';
import { Typography, Button, Box } from '@mui/material';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useCopyPipelineMutation, useDeletePipelineMutation } from 'store/pipeline/pipelineServices';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import { useUpdateProjectMutation } from 'store/project/projectServices';
import { selectProject } from 'store/projects/projectSlice';
import { setPipelineId } from 'store/tutors/tutorsSlice';
// Utils
import { caclPipelineCard } from 'utils/calculates/pipeline-card';
// Interface
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { CompareIcon } from 'assets/icons';
// Components
import Tooltip from 'components/UI/Tooltip';
import PipelineList from './PipelineList';
import PipelineHeader from './PipelineHeader';
// Styles
import {
  Container,
  CompareButton,
  CreateButton,
  ButtonsRowStyled,
  Wrapper,
  WrapperButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import {selectDashboard} from "../../../store/dashboard/dashboardSlice";
interface PipelinerCardProp {
  handleCompare: (ipeline: IPipeline | string) => void;
  redirectToComparePage: () => void;
  comparePipelines: IPipeline[] | [];
  isGeneral: boolean,
  pipeline: IPipeline,
  currency: string;
}

const PipelineCard: React.FC<PipelinerCardProp> = ({ pipeline, currency, handleCompare, comparePipelines, redirectToComparePage }) => {
  const { t } = useTranslation();
  const [isCompareSelected, setIsCompareSelected] = useState(false);
  const { residence } = useSelector(selectDashboard);
  const { main_pipeline_id } = useSelector(selectProject);
  const dispatch = useDispatch();
  const { redirectToTutorStep } = useTutorRoutes();
  const isSelected = !!comparePipelines?.find(item => item?.pipeline_id === pipeline?.pipeline_id);
  const isDisabled = comparePipelines?.length === 2 && !isSelected;

  const compareHandler = () => {
    if (isDisabled) return;

    if (isSelected) {
      handleCompare(pipeline?.pipeline_id as string)

      return;
    }

    handleCompare(pipeline)
  }

  const isMainPipeline = main_pipeline_id === pipeline?.pipeline_id;

  const [copy] = useCopyPipelineMutation();
  const [deletePipeline] = useDeletePipelineMutation();

  const [updateGeneralPipelineId] = useUpdateProjectMutation();

  const updateProjectHandler = async () => {
    dispatch(setPipelineID(pipeline?.pipeline_id as string))

    try {
      await updateGeneralPipelineId({
        id: pipeline?.project_id,
        body: {
          main_pipeline_id: pipeline?.pipeline_id as string,
        },
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitCopy = async () => {
    try {
      await copy({
        pipeline_id: pipeline.pipeline_id as string,
        project_id: pipeline.project_id as string,
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHandler = async () => {
    await deletePipeline({
      pipeline_id: pipeline?.pipeline_id as string,
      project_id: pipeline.project_id as string,
    }).unwrap();
  };

  const redirectToEditPipeline = () => {
    dispatch(setPipelineId({
      project_id: pipeline.project_id,
      pipeline_id: pipeline.pipeline_id,
    }));

    redirectToTutorStep(pipeline.project_id, 2);
  };

  const dataRows = caclPipelineCard(pipeline, pipeline.tax, residence);

  return (
    <Container>
      <PipelineHeader
        title={pipeline.pipeline_name}
        pipeline_id={pipeline?.pipeline_id}
        project_id={pipeline?.project_id}
        deleteHandler={deleteHandler}
        status={pipeline?.status}
        updateProjectHandler={updateProjectHandler}
        isMainPipeline={isMainPipeline}
        isEmptyProductValue
        redirectToEditPipeline={redirectToEditPipeline}
      />

      <Wrapper>
        <PipelineList dataRows={dataRows} pipeline={pipeline} currency={currency} />

         <WrapperButton>
          <ButtonsRowStyled>
            <CreateButton
              onClick={onSubmitCopy}
              variant="light"
            >
              {t('Створити копiю')}
            </CreateButton>
          </ButtonsRowStyled>

          <Tooltip
            arrow
            tooltipText={t('Перейти до порiвняння')}
            tooltipContent={(
              <Button variant="link" onClick={redirectToComparePage}>
                {isDisabled ? t('Порівняти можна лише 2 Пайплайна') : t('Перейти до порiвняння')}
              </Button>
            )}
          >
            <CompareButton
              variant="light"
              onClick={compareHandler}
              isSelected={isSelected}
            >
              {/* {isSelected ? t('Відмінити порівняння') : t('Додати до порiвняння')} */}
              <CompareIcon />
            </CompareButton>
          </Tooltip>
         </WrapperButton>
      </Wrapper>
    </Container>
  );
};

export default PipelineCard;
