import { styled } from '@mui/material/styles';
import {
  TableCell, TableHead, TableRow, Box,
} from '@mui/material';

export const Container = styled(TableHead)`
`;

export const Wrapper = styled(TableRow)`

`;

export const Item = styled(TableCell)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  padding: 20px 0 14px 0;
  border-color: #EBEBEB;
`;

export const IconButton = styled(Box)`
  margin: 0;
  padding: 0;
  width: fit-content;
  height: fit-content;
`;

export const WrapperItem = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Label = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-right: 5px;
`;

export const ContainerSetting = styled(Box)`
  display: flex;
  margin-left: 10px;
  align-items: center;
  border-left: 1px solid #EBEBEB;
  padding-left: 10px;
  width: 100%;

  svg:nth-of-type(2) {
    transform: rotate(180deg);
    margin: 0 10px;
  }
`;
