import { styled } from '@mui/material/styles';
import { Box, Typography, TableCell } from '@mui/material';
import Input from 'components/UI/Input';

interface WrapperProps {
  maxWidth?: string | number;
  width?: string | number;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  border-radius: 8px;
  padding: 30px;
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 50px;
  
  @media print {
    padding: 15px;
    margin-bottom: 30px;
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: fit-content;
`;

export const ItemRow = styled(TableCell)`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  margin-left: 15px;
  width: fit-content;
`;

export const Wrapper = styled(Box)<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  margin-bottom: 10px;
`;

export const WrapperInput = styled(Box)`
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: flex-end;
`;

export const StyledInput = styled(Input)`
  max-width: 290px;
  height: 50px;
  margin-left: 12px;

  .MuiFormHelperText-root {
    display: none;
  }
`;

export const Paper = styled(Box)`
  width: 100%;
  max-width: 1122px;
`;
