import { styled } from '@mui/material/styles';
import { Box, Button as StyledButton } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  margin-top: 20px;
`;

export const Button = styled(StyledButton)`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  width: fit-content;
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 3%;
  text-transform: inherit;
  text-decoration: underline;
`;
