import { type Breakpoint, useMediaQuery, useTheme} from "@mui/material";

interface ScreenSizeReturnProps {
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean
}

export const useScreenSize = (): ScreenSizeReturnProps => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('md' as Breakpoint));
  const isTablet = useMediaQuery(breakpoints.down('lg' as Breakpoint));
  const isDesktop = useMediaQuery(breakpoints.up('lg' as Breakpoint));

  return { isMobile, isTablet, isDesktop };
}
