import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  svg {
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  margin-right: 10px;
`;
