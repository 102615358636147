import { useState } from "react";

export const useDrawer = (initialState = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const toggle = () => setIsOpen((prev) => !prev);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { isOpen, open, close, toggle };
}
