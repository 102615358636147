import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CardMedia } from '@mui/material';
import {useTranslation} from "react-i18next";
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { useCreateTutorMutation } from 'store/tutors/tutorsServices';
import { IStepOne } from 'store/tutors/tutorsTypes';
import { setPipelineId } from 'store/tutors/tutorsSlice';
// Components
import TabPanel from '../TabPanel';
import AddTutorHeader from './AddTutorHeader';
import AddTutorForm from './AddTutorForm';
// Styles
import { TutorContainer, Container, AccordionContainer, ContentContainer, ImgContainer } from './styles';
import Accordion from "components/UI/Accordion";
import HelpPageEn from "pages/HelpPage/HelpPage.en";

import CreatePipelineImg from 'assets/images/create-pipeline-pic.png'
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { useAuth } from '../../../hooks/useAuth';
import { addEvent } from '../../../store/analytics/analyticsService';

interface AddTutorProps {
  defaultValues: IStepOne;
}

const AddTutor: FC<AddTutorProps> = ({ defaultValues }) => {
  const { i18n, t } = useTranslation();
  const [openAccordion, setOpenAccordion] = useState(false);
  const [checked, setChecked] = useState(true);
  const { id, step } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const formState = useForm<{ pipeline_name: string }>({ defaultValues, mode: 'all' });
  const [createTutor] = useCreateTutorMutation();

  const { redirectAddToPipeliner } = useProjectRoutes();
  const { redirectToTutorStep } = useTutorRoutes();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onSubmit: SubmitHandler<{ pipeline_name: string }> = async ({ pipeline_name }) => {
    try {
      const response = await createTutor({
        pipeline_name,
        project_id: id as string,
      }).unwrap();

      if (response?.pipeline_id && !checked) {
        redirectAddToPipeliner(id as string);
      }

      if (response?.pipeline_id && checked) {
        dispatch(setPipelineId({
          project_id: response.project_id,
          pipeline_id: response.pipeline_id,
        }));

        redirectToTutorStep(response.project_id, 2);
      }
      if (response?.pipeline_id) {
        const eventPayload: SegmentEventPayload = {
          eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_1,
          payload: { email: user?.email },
        };
        dispatch(addEvent(eventPayload));
      }
    } catch (error) {
      formState.setError('pipeline_name', {
        type: 'custom',
        message: 'Помилка сервера',
      });
    }
  };

  return (
    <TabPanel index={1} value={!step ? 1 : 0}>
      <TutorContainer>
        {i18n.language === 'uk' ?
          <CardMedia
            component="iframe"
            style={{height: '500px'}}
            image={"https://www.youtube.com/embed/lvUeTcfnO0g"}
          /> :
          <ImgContainer src={CreatePipelineImg}/>
        }

        <Container>
          <AddTutorHeader />

          <AddTutorForm
            checked={checked}
            formState={formState}
            handleChange={handleChange}
            onSubmit={onSubmit}
          />
        </Container>
        {i18n.language === 'en' ?
          <AccordionContainer>
            <Accordion title={t('Ознайомитись з тим як це працює')}
                       handleClick={() => setOpenAccordion((prevState) => !prevState)}
                       open={openAccordion}
            >
              <ContentContainer>
                <HelpPageEn/>
              </ContentContainer>
            </Accordion>
          </AccordionContainer> : ''
        }
      </TutorContainer>
    </TabPanel>
  );
};

export default AddTutor;
