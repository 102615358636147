import React from 'react'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Styles
import { Title } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const TableHeader = () => {
  const { t } = useTranslation();
  const titleTableColums = ['Назва', 'Дохід', 'Накладні витрати', 'Непрямі витрати на оплату праці', 'Виробнича вартість праці', 'Вартість матеріалу', 'Загальна вартість виробництва', 'Кількість годин', 'Загальний прибуток', 'Рента- бельність'];
  return (
    <TableHead>
      <TableRow>
        {titleTableColums.map((title) => (
          <Title key={title} align="left">
            {t(title)}
          </Title>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader;
