/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// Icons
import { Logo } from 'assets/logos';
// Styles
import {
  Container,
  Title,
  SubTitle,
  Wrapper,
  Link,
} from './styles';

const SuccessfullySentDetail: React.FC = () => (
  <Container>
    <Wrapper>
      <Logo />

      <Title variant="h1">
        Дякуємо за ваш запит!
      </Title>

      <SubTitle variant="h3">
        Кожна нова локалізація нашого сервісу потребує пильної уваги,
        тож для нас дійсно важливо розуміти та бути на зв'язку з тими,
        хто по-справжньому зацікавлений у розвитку свого бізнесу та своєї країни.
      </SubTitle>
    </Wrapper>

    <Link href="http://pipeliner.online/">
      Повернутись
    </Link>
  </Container>
);

export default SuccessfullySentDetail;
