import { styled, css } from '@mui/material/styles';
import { Typography, Button, TextField } from '@mui/material';
import Input from 'components/UI/Input';
import PencilFill from 'assets/icons/pencilFill.svg';
import Pencil from 'assets/icons/pencil.svg';

interface IWrap {
  $fullWidth?: boolean;
}

export const NoEditingWrap = styled('div')<IWrap>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ $fullWidth }) => ($fullWidth ? css`
  ` : css`
    a {
      margin-left: 15px;
    }
  `)};
`;

export const EditorWrap = styled('div')<IWrap>`
  display: flex;
  align-items: center;
  position: relative;
  ${({ $fullWidth }) => ($fullWidth ? css`
    width: 100%;
  ` : css`
    width: auto;
  `)};
  
  .MuiOutlinedInput-root {
    background: none;
  }
`;

export const StyledInput = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      line-height: 22px;
    }

    .MuiOutlinedInput-input {
      z-index: 1;
    }

    input {
      padding: 8px 14px 6px;
      line-height: 22px;
      height: auto;
    }
    
    fieldset {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.custom.grey.backgroundInput};
    }
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const GreenLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.green.main};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
  
  svg {
    margin-right: 5px;
  }
`;
export const InputWrap = styled('div')`
  position: relative;
`;

export const ControlButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 10px;
  top: 9px;
  
  svg {
    cursor: pointer;
  }
`;

export const PencilButton = styled('a')`
  background-image: url(${Pencil});
  transform: translateX(0);
  transition: background-image .3s ease-in-out;
  background-repeat: no-repeat center center fixed;
  background-size: cover;
  background-position: 100%;
  align-items: center;
  height: 11px;
  width: 11px;
  cursor: pointer;
  && {
    &:hover {
      background-image: url(${PencilFill});
    }
  }
`;

export const SubmitButton = styled(Button)`
  background: transparent;
  align-items: baseline;
  height: 18px;
  min-width: 18px;
  padding: 6px 5px;
  border-radius: 4px;
  margin-right: 12px;
  z-index: 10;
  && {
    &:hover {
      background: ${({ theme }) => theme.palette.custom.green.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;

export const CancelButton = styled(SubmitButton)`
  color: ${({ theme }) => theme.palette.custom.red.dark};
  z-index: 10;
  margin-right: 0;
  && {
    &:hover {
      background: ${({ theme }) => theme.palette.custom.red.dark};
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`;
