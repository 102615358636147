import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Control, FieldErrors, SubmitHandler, useFieldArray, useForm} from "react-hook-form";

import {selectTutor} from "store/tutors/tutorsSlice";
import {IMaterialCreate, IProductCreate} from "interfaces/product";
import MaterialItem from "./MaterialItem";
import {
  useCreateMaterialMutation,
  useDeleteExpensesMutation,
  useUpdateMaterialMutation
} from "store/tutors/tutorsServices";
import {ICreateMaterialRequest, IExpensesMaterial} from "store/tutors/tutorsTypes";
import {useSelector} from "react-redux";
import {FormContainer, FormContainerFooter, FormRow, FormWrap, LinkButton, LinkWrapper, HeaderSection} from "../styles";

interface MaterialsProps {
}

const AddMaterialSection: React.FC<MaterialsProps> = ({}) => {
  const [hideContent, setHideContent] = useState(false);
  const { t } = useTranslation();
  const { project_id, materialProducts, products } = useSelector(selectTutor);
  const [create] = useCreateMaterialMutation();
  const [updateMaterial] = useUpdateMaterialMutation();
  const {product_id} = useParams<string>();
  const formState = useForm({
    mode: 'all',
    defaultValues: { materialProducts },
  });

  const { fields } = useFieldArray({
    control: formState.control,
    name: 'materialProducts',
  });

  const submitNewItem = async ( materialProducts: IProductCreate[]) => {
    try {
      const filteredProducts = materialProducts.filter((item) => item?.pipeline_id);

      if (filteredProducts?.length) {
        const mats = filteredProducts.map((item) => {
          const materials: IExpensesMaterial[] = [];

          item.expenses?.Materials?.forEach((expens: IMaterialCreate) => {
            if (!expens.material_id) {
              materials.push({
                extras: { dimension: expens.extras.dimension },
                name: expens.name,
                cost: expens.cost,
                value: expens.value,
                group: expens.group,
                amount: expens.amount,
              });
            }
          });

          return { product_id: item.pipeline_id, expenses: materials };
        });
        const filteredMaterials = mats.filter((item) => item.expenses?.length);
        if (filteredMaterials.length) {
          await create(filteredMaterials as ICreateMaterialRequest[]).unwrap();
          formState.reset();
        } else {
          // redirectToTutorStep(project_id, 5);
        }
        formState.setValue('materialProducts', materialProducts);
        // redirectToTutorStep(project_id, 5);

        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const triggerValidate = () => {
    formState.trigger();
  }

  const updateItem = async (productIndex: number, id?: string) => {
    const { materialProducts } = formState.getValues();
    if (formState.formState.isValid) {
      submitNewItem(materialProducts)
    }

    if (!id) return;

    const material = materialProducts?.[productIndex]?.expenses?.Materials?.find((item) => item?.material_id === id);

    if (material?.material_id) {
      const updateDataMaterial = {
        amount: +material.amount,
        cost: +material.cost,
        name: material.name,
        value: material.value,
        extras: {
          dimension: material.extras.dimension,
        },
      };

      await updateMaterial({
        body: updateDataMaterial,
        material_id: id,
      }).unwrap();
    }
  };

  useEffect(() => {
    // @ts-ignore
    const findService: IProductCreate = products.find(product => product.id === product_id)
    if (findService?.group === 'service') {
      setHideContent(true)
      return;
    }
    // @ts-ignore
    const findMaterialProducts: IProductCreate = materialProducts.find(product => product.pipeline_id === product_id)
    formState.setValue('materialProducts', [findMaterialProducts]);
  }, [materialProducts]);

  if (hideContent) {
    return null;
  }

  return (
    <FormWrap id={"materialForm"} >
      <HeaderSection>
        <Typography variant="h2">{t('Матеріали')}</Typography>
      </HeaderSection>
      <FormContainer>
        {fields.map((product, index) => (
          <FormRow key={index}>
            <MaterialItem
              triggerValidate={triggerValidate}
              updateItem={updateItem}
              errors={formState.formState.errors}
              control={formState.control}
              key={product.id}
              product={product}
              indexProduct={index}
              currency={''}
            />
          </FormRow>
        ))}
        {/*<LinkWrapper>*/}
        {/*  <LinkButton*/}
        {/*    variant="link"*/}
        {/*    // onClick={addedMaterial}*/}
        {/*  >*/}
        {/*    {t('pages.AddProduct.Додати ще матеріал')}*/}
        {/*  </LinkButton>*/}
        {/*</LinkWrapper>*/}
      </FormContainer>
    </FormWrap>
  )
};

export default AddMaterialSection;
