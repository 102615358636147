// Interface
import {
  IProductCreate, IProduct, IMaterial, IMaterialCreate, IPackaging, IPackagingCreate, ILabor
} from 'interfaces/product';
import {
  ICreateEmployeeForm, IEmployeeProducts, IPipelineEmployee, IEmployeeLabor, IAdditionalLosseResponse,
} from 'store/tutors/tutorsTypes';
import { SELECT_UNITS } from "utils/constants/tutor";

interface CustomDepartmentsData {
  value: string,
  label: string,
}

export const departmentsData = (customDepartments: Array<string>): CustomDepartmentsData[] | [] => {
  if (!customDepartments) { return SELECT_UNITS; }

  const arr = customDepartments.map(item => ({
    value: item,
    label: item
  }))

  return [...arr, ...SELECT_UNITS].filter(
    (v,i,a) => a.findIndex(v2 =>( v2.value === v.value)) === i
  )
}

export const updateEmployees = (employees: IPipelineEmployee[]) => {
  const empls: ICreateEmployeeForm[] = employees?.map((item: IPipelineEmployee) => ({
    amount_type: item.amount_type,
    type: item.type,
    department: item.department,
    name: item.name,
    wage: +item.wage,
    id: item.id,
    employee_id: item.id,
  }));

  return empls;
};

const uniqDepartment = (labors: ILabor[]) => {
  let tmpArray: string[] = [];

  const itemCheck = (item: ILabor) => {
    if (tmpArray.indexOf(item.department as any) === -1) {

      tmpArray.push(item.department);

      return true
    }

    return false;
  }
  const departmens = labors.filter((item) => itemCheck(item))

  return departmens
}

const filteredLabors = (labors: ILabor[], departmen: string) => {
  const empls = labors.filter(item => item.department === departmen);

  if (empls.length) {
    const updateEmpls = empls.map(item => ({
      labor_id: item.id,
      employee_id: item.employee_id,
      amount: item.amount,
      name: item.name,
    }))

    return updateEmpls;
  }

  return []
}

export const updateLabors = (labors: ILabor[]) => {
  const newLabors = uniqDepartment(labors).map(item => ({
    department: item.department,
    labor_id: item.id,
    employees: filteredLabors(labors, item.department)
  }))

  return newLabors as IEmployeeLabor[];
}

export const updateProducts = (products: IProduct[]) => {
  const prod: IEmployeeProducts[] = products?.map((item: IProduct) => {

    if (item.expenses?.labor?.length) {
      return {
        product: item,
        labors: updateLabors(item.expenses?.labor as ILabor[])
      }
    } else {
      return {
        product: item,
        labors: [
          {
            department: '',
            employees: [],
          },
        ],
      }
    }
  });

  return prod;
};

export const updateAdditionalLosses = (losses: IAdditionalLosseResponse[]) => {
  const items = losses?.map((item: IAdditionalLosseResponse) => ({
    name: item.name,
    value: item.value ? item.value?.toString() : '',
    amount: item.amount,
    category: item.extras.category,
    additional_id: item.id,
  }));

  return items;
};

export const employeesSelect = (employees: IPipelineEmployee[]) => {
  const filteredEmployee = employees.filter((item) => item.type !== 'administration');

  const empls = filteredEmployee.map((employee) => ({
    value: employee.department,
    label: employee.department,
  }));

  const uniqCategory = empls.filter((v, i, a) => a.findIndex((t) => (t?.label === v?.label)) === i);

  return uniqCategory;
};

export const updateMaterials = (expenses: IMaterial[], prevState?: any) => {
  if (expenses?.length) {
    const materials = expenses?.map((item) => ({
      amount: item.amount,
      cost: item.cost,
      name: item.name,
      value: item.value,
      product_id: item.product_id,
      material_id: item.id,
      price: 0,
      group: {
        parent_id: '',
        name: 'Materials',
      },
      extras: {
        price: item.extras?.price as string,
        dimension: item.extras?.dimension as string,
        per_product: item?.extras?.per_product as string,
      },
    }));

    return materials;
  }
  return [
    {
      amount: 0,
      cost: '',
      name: '',
      value: '',
      product_id: '',
      material_id: '',
      group: {
        parent_id: '',
        name: 'Materials',
      },
      extras: {
        dimension: '',
        per_product: 0,
        price: 0,
      },
    },
  ];
};

export const updatePackaging = (packagings: IPackaging[]) => {
  if (packagings?.length) {
    const packaging = packagings?.map((item) => ({
      name: item.name,
      value: item.value,
      amount: item.amount,
      packaging_id: item.id,
      group: {
        parent_id: null,
        name: 'Packaging',
      },
    }));

    return packaging;
  }

  return [
    {
      name: '',
      value: '0',
      amount: '',
      packaging_id: '',
      group: {
        parent_id: null,
        name: 'Packaging',
      },
    },
  ];
};

export const updateCreateProducts = (products: IProduct[]) => {
  const prod: IProductCreate[] = products?.map((item: IProduct) => ({
    ...item,
    pipeline_id: item.id,
    expenses: {
      Materials: updateMaterials(item?.expenses?.Materials as IMaterial[]) as IMaterialCreate[],
      Packaging: updatePackaging(item?.expenses?.Packaging as IPackaging[]) as IPackagingCreate[],
    },
  }));

  return prod;
};

export const getProductsWithSortedMaterials = (products: IProduct[]): IProduct[] =>
  products
    .filter(item => item.group === 'product').map((product) => ({
      ...product,
      expenses: {
        ...product.expenses,
        Materials: product?.expenses?.Materials?.sort((a, b) => {
          const aDate = a.created_at ? new Date(a.created_at).getMilliseconds() : 0;
          const bDate = b.created_at ? new Date(b.created_at).getMilliseconds() : 0;
          return aDate - bDate;
        }),
      },
  }));

export const updateCreateMaterialProducts = (products: IProduct[], prevState?: any) => {
  const filteredProducts = getProductsWithSortedMaterials(products);

  const prod: IProductCreate[] = filteredProducts?.map((item: IProduct) => ({
    ...item,
    pipeline_id: item.id,
    expenses: {
      Materials: updateMaterials(item?.expenses?.Materials as IMaterial[], prevState) as IMaterialCreate[],
      Packaging: updatePackaging(item?.expenses?.Packaging as IPackaging[]) as IPackagingCreate[],
    },
  }));

  return prod;
};
