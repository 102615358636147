import React, { FC } from 'react';
import Tooltip from 'components/UI/Tooltip';
import { ListItemSecondaryAction } from '@mui/material';
// Icons
import { HorizontalMenuIcon } from 'assets/icons';
// Styles
import {
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  Container,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface EmployeeEditTooltipProps {
  onEdit: () => void;
  removeEmployee: () => void;
}

const EmployeeEditTooltip: FC<EmployeeEditTooltipProps> = ({ removeEmployee, onEdit }) => {
  const { t } = useTranslation();
  return (
    <ListItemSecondaryAction>
      <Tooltip
        arrow
        placement="bottom"
        tooltipText=""
        tooltipContent={(
          <MenuList disablePadding>
            <MenuListItem disablePadding>
              <MenuListItemButton onClick={onEdit}>
                <Text variant="regularText">{t('Редагувати')}</Text>
              </MenuListItemButton>
            </MenuListItem>

            <MenuListItem disablePadding>
              <MenuListItemButton onClick={removeEmployee}>
                <Text isRed variant="regularText">{t('Видалити')}</Text>
              </MenuListItemButton>
            </MenuListItem>
          </MenuList>
        )}
      >
        <Container>
          <HorizontalMenuIcon />
        </Container>
      </Tooltip>
    </ListItemSecondaryAction>
  )
};
export default EmployeeEditTooltip;
