import React from 'react';
import Tooltip from 'components/UI/Tooltip';
// Styles
import {
  Container, Title, SubTitle, Wrapper, Button, Label,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface TariffPlanCardFooterProps {
  onSubmit: () => void;
  typeSubscribe: string;
  price: number;
  isActive: boolean;
  isDisabled: boolean;
  onClose: () => void;
  isUnSubscribe: boolean;
  dateString: string;
  name: string;
}

const TariffPlanCardFooter: React.FC<TariffPlanCardFooterProps> = ({
  onSubmit,
  typeSubscribe,
  isActive,
  isDisabled,
  price,
  onClose,
  dateString,
  isUnSubscribe,
  name
}) => {
  const { t } = useTranslation();
  if (!isActive) {
    return (
      <Container>
        {name === 'Ентерпрайз' || name === 'Початківець' ? "" :
          <>
            <Wrapper>
              <Title variant="h6">
                {t('components.TariffPlanCard.TariffPlanCardFooter.Вартiсть тарифу')}</Title>

              <SubTitle isDisabled={isDisabled}>
                {price}$ / {typeSubscribe}.
              </SubTitle>
            </Wrapper>
              <Button
                disabled={isDisabled}
                onClick={onSubmit}
                variant="contained"
              >
                {t('components.TariffPlanCard.TariffPlanCardFooter.Оплатити')}
              </Button>

          </>
        }
      </Container>
    );
  }
  return (
    <Container>
      {(isActive && isUnSubscribe) && (
        <Label>
          {t('components.TariffPlanCard.TariffPlanCardFooter.Дійсна до')} {dateString}
        </Label>
      )}

      <Wrapper>
        <Title variant="h6">
          {t('components.TariffPlanCard.TariffPlanCardFooter.Вартiсть тарифу')}
        </Title>

        <SubTitle isDisabled={isDisabled}>
          {price}$ / {t(typeSubscribe)}.
        </SubTitle>
      </Wrapper>

      <Button
        disabled={isActive && isUnSubscribe}
        onClick={onClose}
        isUnsubscribe
        variant="contained"
      >
        {t('components.TariffPlanCard.TariffPlanCardFooter.Відписатися')}
      </Button>
    </Container>
  );
};

export default TariffPlanCardFooter;
