import React from 'react';
import { Outlet } from 'react-router-dom';
// Hooks
import { useActivate } from 'hooks/useActivate';
// Styles
import { Container } from './styles';

const AuthLayout: React.FC = () => {
  useActivate();

  return (
    <Container>
      <Outlet />
    </Container>
  )
};

export default AuthLayout;
