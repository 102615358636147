import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  background: ${({ theme }) => theme.palette.custom.grey.blueGrey};
  height: 100%;
  display: grid;
  grid-template-areas: 
    "header"
    "step"
  ;
  grid-template-rows: 84px auto;
`;
