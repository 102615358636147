import React from 'react';
import {Content, H2, Img, P, StyledLI, StyledUL} from "./styles";

import {
  Help1,
  Help2,
  Help3,
  Help4,
  Help5,
  Help6,
  Help7,
  Help8,
  Help9,
  Help10,
  Help11,
  Help12,
  Help13,
  Help14,
  Help15,
  Help16,
  Help17,
  Help18,
  Help19,
  Help20,
  Help21,
} from 'assets/images/help/en'

const HelpPageEn = () => {
  return (
      <Content>
        <P>Thank you for being with us! The Pipeliner project is currently at the stage of testing the basic functionality.
          So you have the opportunity to be the first to join this process.</P>
        <P>We appreciate your feedback and ideas on how to optimize future releases. Our mailbox is always open for your
          ideas hello@pipeliner.online</P>
        <P>You also have the opportunity to join our <a href="https://www.facebook.com/pipeliner.online">Facebook</a> or
          <a href="https://www.linkedin.com/company/pipeliner-online"> LinkedIn</a> community to be the first to learn
          about service updates or participate in discussions. It is very important for us!</P>
        <H2 variant="h2">
          Using the service, you will understand:
        </H2>
        <P>- how much money is needed to start your business,</P>
        <P>- how long it will take to return the initial investments,</P>
        <P>- what prices for your goods or services will cover production costs and much more.</P>

        <H2 variant="h2">
          How to use the service.
        </H2>

        <H2 variant="h3">
          Create your first Pipeline
        </H2>
        <P>After logging in, you get to your Profile page, where you need to fill in the basic information.</P>
        <Img src={Help1} />
        <P><strong>The service has a free period of 14 days.</strong> Next, the system will offer you to switch to a
          paid plan. Currently, only the basic plan is available at $7 per month. You also can search for special
          offers on our social network.</P>
        <P>Today, each signed subscription is a great support that allows us to move forward!</P>

        <Img src={Help2} />

        <P>Projects are actually your businesses. For example, you have a bakery. This is your Project. To create it, you need to click &quot;Create a new project&quot;.</P>
        <Img src={Help3} />
        <P>Here you can enter the title of the project, indicate the country of origin, currency and tax information (if
          applicable), which will further help us in calculations. If you have not yet registered a business, it is sufficient
          to select &quot;Not yet registered&quot; and click &quot;Create project&quot;.</P>
        <P><strong>For some countries we already made localization with taxes. If you don&#39;t see a module with taxes for your
          country it means that it is still under development.</strong></P>
        <P>In order to create a Pipeline, you need to click on &quot;Create a new pipeline&quot; on the Project page.</P>
        <Img src={Help4} />
        <P><strong>A pipeline in our context is one of the possible scenarios for the development of your business.</strong>
          Next, you need to enter the title of your Pipeline.</P>
        <Img src={Help5} />
        <P>In the following steps, our tutor will help you describe your business. So leave a checkbox enabled, please.</P>
        <H2 variant={"h3"} id="team">Team</H2>
        <P>On the first screen, the tutor needs to describe your team.</P>
        <Img src={Help6} />
        <P>All employees have several common parameters:</P>
        <StyledUL>
          <StyledLI>
            Name,
          </StyledLI>
          <StyledLI>
            Department to which he belongs in the company,
          </StyledLI>
          <StyledLI>
            Amount of wages (either monthly or hourly).
          </StyledLI>
        </StyledUL>
        <P><strong>Administration</strong> is the type of your employees to whom you pay a fixed salary per month and who are
          not directly involved in the production of your products (for example, an accountant, a supply manager, etc.)</P>
        <P><strong>Team</strong> is the type of employees who are directly involved in the production of your products or
          services. They can have either hourly or fixed wages.</P>
        <P><strong>Counterparties</strong> are external partners who help create or sell products or services. They may also
          have hourly or fixed wages.</P>
        <P>It is important that the hourly rate is calculated as the amount multiplied by the hours worked. And the fixed one
          also takes into account taxes depending on your country.</P>
        <P><strong>For some countries we already made localization with taxes. If you don&#39;t see a module with taxes for your
          country it means that it is still under development.</strong></P>
        <P>To add an employee, you need to select &quot;Add..&quot;, enter a name, choose from templates or create your own
          department, enter the amount and choose the type of salary. To proceed further, you need to click &quot;Next step&quot;.</P>
        <P><strong>If the button is inactive, it means that there are incomplete data. You can delete redundant types of
          employees.</strong></P>
        <P><strong>Remember!</strong> You can save and leave the tutor at any time (icon with a diskette), in this case your
          pipeline will go to Drafts. To continue filling it you will need to hover over the three dots in the upper right
          corner of the pipeline card and click &quot;Edit&quot;.</P>
        <P>Or you can choose &quot;Leave Tutor&quot;, then the <strong>pipeline will be deleted.</strong></P>
        <H2 variant="h2" id="product-service">Product or service</H2>
        <P>Here you need to enter the names of your products or services.</P>
        <Img src={Help7} />
        <P>It is important that here you can choose two types - Product or Service. In the first case, we mean a physical
          product that consists of some components, materials, etc. In the second case, the Service provides only the
          calculation of man-hours.</P>
        <H2 variant="h2" id="production-costs">Production costs</H2>
        <P>To calculate the cost price, you need to go through two steps. The first step is to describe the costs of materials
          that you need to create <strong>one</strong> product.</P>
        <Img src={Help8} />
        <P>This unit consists of:</P>
        <StyledUL>
          <StyledLI>
            material name,
          </StyledLI>
          <StyledLI>
            unit of measure,
          </StyledLI>
          <StyledLI>
            how much of this material goes into 1 product,
          </StyledLI>
          <StyledLI>
            how much a unit of this material costs.
          </StyledLI>
        </StyledUL>
        <H2 variant="h3" id="packaging-logistics-costs">Packaging and logistics costs</H2>
        <P>In this step, you need to enter additional costs for logistics or packaging of your product. If there are none,
          these fields can be removed by clicking on the cross.</P>
        <Img src={Help9} />
        <H2 variant="h3" id="man-hours">Man-hours</H2>
        <P>To fully calculate the cost of production, you need to indicate how much and who in your company spends time on the
          production of each unit of production.</P>
        <Img src={Help10} />
        <P>For example, to make one chair, a craftsman needs to spend 3 hours in the workshop. To add hours, you need to select
          a department, then expand the tab and click &quot;Add employee&quot;.</P>
        <Img src={Help11} />
        <P>Choose an employee in the menu and specify the amount of working time he spends on the production of one product
          unit.</P>
        <H2 variant="h3" id="fixed-expenses">Fixed expenses</H2>
        <P>In addition to production costs, you also have indirect costs, so this section should describe them - what your team
          spends time on in order to support the business of the company.</P>
        <P><strong>Do not take into account the time of direct production of products or services! It can be workshop rental,
          internet, subscriptions, etc.</strong></P>
        <Img src={Help12} />
        <P>To finish and go to the Dashboard, you need to click &quot;Well, let&#39;s see.&quot;</P>
        <H2 variant="h2">Dashboard</H2>
        <H2 variant="h3">Summary</H2>
        <P>An overview of indicators allows you to analyze each product in the context of the overall operational activity. In
          order to see the calculations and start working with the data entered in the tutor, you need to specify:</P>
        <StyledUL>
          <StyledLI>
            Selling price per unit,
          </StyledLI>
          <StyledLI>
            Amount of sales per month.
          </StyledLI>
        </StyledUL>
        <Img src={Help13} />
        <P>Components of the Overview of indicators:</P>
        <StyledUL>
          <StyledLI>
            Product or service name,
          </StyledLI>
          <StyledLI>
            Selling price,
          </StyledLI>
          <StyledLI>
            Sales volume per month.
          </StyledLI>
          <StyledLI>
            COSG (cost of goods sold)
          </StyledLI>
          <StyledLI>
            Direct margin.
          </StyledLI>
        </StyledUL>
        <P>The <strong>Summary</strong> module is automatically calculated and consists of:</P>
        <StyledUL>
          <StyledLI>
            <strong>Revenue earned per month</strong> is the total amount of revenue you are estimated to earn per month
            based on the <strong>selling price</strong> and <strong>amount of sales</strong> you entered.
          </StyledLI>
          <StyledLI>
            <strong>Fixed cost per month</strong> are costs that do not change depending on the amount of sales you produce
            each month.
          </StyledLI>
          <StyledLI>
            <strong>Material cost per month</strong> is the amount of material cost you can pay based on the production
            costs and expected sales you entered for each product.
          </StyledLI>
          <StyledLI>
            <strong>Total wages paid per month</strong> is the total amount of money that will be paid in salary.
          </StyledLI>
          <StyledLI>
            <strong>Taxes and fees</strong> – calculated depending on the amount entered in &quot;Tax&quot; cell.
          </StyledLI>
          <StyledLI>
            <strong>Net profit per month</strong> is the amount of money you earn after paying all your monthly expenses,
            material costs and wages.
          </StyledLI>
        </StyledUL>

        <H2 variant="h2">Sales plan</H2>
        <P>Allows you to forecast deviations from the plan that you specified in the Overview as the planned number of
          sales per month.</P>
        <Img src={Help14} />
        <H2 variant="h2">Analytic</H2>
        <H2 variant="h3">Graph Profit and Loss</H2>
        <P>Shows the ratio of the cost structure to the income from each product. Allows you to optimize expenses.</P>
        <Img src={Help15} />
        <H2 variant="h3">Analysis of the income structure</H2>
        <P>Shows how much each product takes in the total profit.</P>
        <Img src={Help16} />
        <H2 variant="h3">Analysis of the cost structure</H2>
        <P>Shows the structure of costs by types.</P>
        <H2 variant="h3">Profit and Loss table</H2>
        <P>This table shows how much money the company plans to make each year. It assumes you paid yourself for the hours
          worked, so the &quot;Net Income&quot; at the bottom is the rest of the profit your company made. </P>
        <P><strong>This table is also useful to show to your bank or include in grant applications.</strong></P>
        <P><strong>Calculated automatically.</strong></P>
        <Img src={Help17} />
        <H2 variant="h3">Cashflow table</H2>
        <P>A cash flow analysis shows whether you have enough money in the first year to buy materials, pay your employees or
          make an investment.</P>
        <P><strong>Calculated automatically.</strong></P>
        <Img src={Help18} />
        <H2 variant="h3">Sales per month table</H2>
        <P>The structure of costs and profits in the context of sales. Gives an opportunity to see what exactly allows you to
          earn or lose in the context of sales of all your products or services.</P>
        <Img src={Help19} />
        <H2 variant="h3">Compare pipelines</H2>
        <P>To compare Pipelines, you need to click the Compare button, which is located in the lower right corner of each
          Pipeline card.</P>
        <P><strong>Importantly! Only two pipelines can be compared.</strong></P>
        <Img src={Help20} />
        <P>To cancel the comparison, click on the &quot;Stop comparison&quot; button. To go to the comparison, you need to click
          on &quot;Compare scenarios&quot;.</P>
        <Img src={Help21} />
        <P>Three metrics are currently available for comparison: profit, gross revenue, and revenue.</P>
        <P>To return to the Pipelines screen, click Stop Comparison.</P>
      </Content>
  )
}

export default HelpPageEn;
