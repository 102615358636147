import { IProduct } from 'interfaces/product';
import { IPlanSales } from 'store/dashboard/dashboardTypes';

export const caclcProduct = (product: IProduct, planSales: IPlanSales[]) => {
  const productPlan = planSales?.find(item => item?.product_id === product?.id)
  const amount = product?.amount || 1;

  // Cумма материалов
  const sumMaterials = (product.expenseGroups?.Materials?.loss || 0) / amount;
  // Cумма упаковки
  const sumPackaging = (product.expenseGroups?.Packaging?.loss || 0) / amount;

  // Средние число зарплаты
  const sumSalary = product.expenseGroups?.labor?.loss && product?.amount ? product.expenseGroups?.labor?.loss : 0;

  // Средние количество продаж
  const sumProductSales = productPlan?.amount ? productPlan?.amount : 1;

  const sum = (+sumMaterials + +sumPackaging + sumSalary / +sumProductSales) || 0

  return sum ? sum?.toFixed(2) : 0
}

export const calcMargProduct = (product: IProduct, cost: number | string) => {
  const price = product?.price || 1;
  const costProduct = +cost || 0;

  const sum = ((price - costProduct) / price) * 100;

  return sum ?  sum?.toFixed(1) : '0';
}
