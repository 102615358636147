import { BaseQueryParams } from 'store/baseQuery';
import {
  LoginRequest,
  LoginResponse,
  RegisterResponse,
  RegisterRequest,
  IUser,
  IUserUpdate,
  LoginGoogleResponse,
  IInvoices,
} from './authTypes';

export const authService = BaseQueryParams('login', ['USER']).injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => ['USER'],
    }),

    loginGoogle: builder.mutation<LoginGoogleResponse, string>({
      query: (token) => ({
        url: '/auth/login/google',
        method: 'POST',
        body: { token },
      }),

      invalidatesTags: () => ['USER'],
    }),

    verifyUser: builder.query<LoginResponse, string>({
      query: (token) => ({
        url: `/auth/verify?token=${token}`,
        method: 'GET',
      }),
    }),

    registration: builder.mutation<RegisterResponse, RegisterRequest>({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        body: data,
      }),
    }),

    passwordForgot: builder.mutation<unknown, { email: string }>({
      query: (data) => ({
        url: '/auth/password/forgot',
        method: 'POST',
        body: data,
      }),
    }),

    passwordReset: builder.mutation<unknown, { password: string, token: string }>({
      query: (data) => ({
        url: '/auth/password/reset',
        method: 'POST',
        body: data,
      }),
    }),

    getUser: builder.query<IUser, unknown>({
      query: () => ({
        url: '/me',
        method: 'GET',
      }),
      providesTags: () => ['USER'],
    }),

    getInvoices: builder.query<IInvoices[], unknown>({
      query: () => ({
        url: '/payments/stripe/invoices',
        method: 'GET',
      }),
    }),

    updateUser: builder.mutation<IUserUpdate, { body: IUserUpdate }>({
      query: ({ body }) => ({
        url: `/me`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['USER'],
    }),

    cancelSubscribe: builder.mutation<unknown, { subscription_id: string }>({
      query: ({ subscription_id }) => ({
        url: '/payments/stripe/cancel',
        method: 'POST',
        body: { subscription_id },
      }),

      invalidatesTags: () => ['USER'],
    }),
  }),
});

export const {
  useCancelSubscribeMutation,
  useGetInvoicesQuery,
  useVerifyUserQuery,
  useLoginMutation,
  useGetUserQuery,
  useRegistrationMutation,
  useUpdateUserMutation,
  usePasswordForgotMutation,
  usePasswordResetMutation,
  useLoginGoogleMutation,
} = authService;
