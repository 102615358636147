import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

export const Container = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  .description {
    color: ${({ theme }) => theme.palette.custom.primary.silver};
  }
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperTitle = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Image = styled('img')`
  width: 40px;
  margin-left: 10px;
`;

export const Link = styled(HashLink)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
