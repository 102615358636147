import { css, styled } from '@mui/material/styles';
import {
  Button as StyledButton, List, ListItem, ListItemButton, ListSubheader, Typography, Box,
} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
`;

export const ContainerButtons = styled('div')`
`;

export const Button = styled(StyledButton)`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  && {
    text-decoration: none;
    svg {
      margin-right: 12px;
    }
  }
`;
