/* eslint-disable max-len */
import { BaseQueryParams } from 'store/baseQuery';
import { IEmployee } from 'interfaces/employee';
import { IProductCreateRequest } from 'interfaces/product';
import {
  ICreateTutor, ICreateEmployeeRequest, IUpdateEmployeeRequest, ICreateLaborRequest,
  IPipeline, IUpdateProduct, ICreateMaterialRequest, ICreatePackagingRequest, IUpdateMaterialRequest,
  IUpdatPackagingRequest, ICreateAdditionalLossesRequest, IUpdateAdditionalLossesRequest
} from './tutorsTypes';

export const tutorsServices = BaseQueryParams('tutor/create', ['TUTOR_BY_ID']).injectEndpoints({
  endpoints: (builder) => ({
    // GET
    getPipelineById: builder.query<IPipeline, { project_id: string, pipeline_id: string }>({
      query: ({ project_id, pipeline_id }) => ({
        url: `projects/${project_id}/pipelines/${pipeline_id}`,
        method: 'GET',
      }),

      providesTags: () => ['TUTOR_BY_ID'],
    }),

    // CREATE
    createTutor: builder.mutation<IPipeline, ICreateTutor>({
      query: ({ pipeline_name, project_id }) => ({
        url: `/projects/${project_id}/pipelines`,
        method: 'POST',
        body: { pipeline_name },
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    updateNameTutor: builder.mutation<IPipeline, ICreateTutor>({
      query: (body) => ({
        url: '/pipelines',
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createEmployee: builder.mutation<[IEmployee[]], { project_id: string, body: ICreateEmployeeRequest[] }>({
      query: ({ body, project_id }) => ({
        url: `/projects/${project_id}/pipelines/employees`,
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createProduct: builder.mutation<unknown, IProductCreateRequest>({
      query: ({ body, project_id }) => ({
        url: `/projects/${project_id}/pipelines/products`,
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createMaterial: builder.mutation<unknown, ICreateMaterialRequest[]>({
      query: (body) => ({
        url: '/products/materials',
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createPackaging: builder.mutation<unknown, ICreatePackagingRequest[]>({
      query: (body) => ({
        url: 'products/expenses',
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createLabor: builder.mutation<unknown, ICreateLaborRequest[]>({
      query: (body) => ({
        url: '/products/labor',
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    createAdditionalLosses: builder.mutation<unknown, ICreateAdditionalLossesRequest>({
      query: ({ project_id, pipeline_id, body }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/additional-losses`,
        method: 'POST',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),
    updateAdditionalLosses: builder.mutation<unknown, IUpdateAdditionalLossesRequest>({
      query: ({
        body, additional_loss_id,
      }) => ({
        url: `expenses/${additional_loss_id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),


    updateEmployee: builder.mutation<unknown, IUpdateEmployeeRequest>({
      query: ({
        body, pipeline_id, employee_id, project_id,
      }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/employees/${employee_id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    updateProduct: builder.mutation<unknown, IUpdateProduct>({
      query: ({
        body, product_id,
      }) => ({
        url: `products/${product_id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    updateMaterial: builder.mutation<unknown, IUpdateMaterialRequest>({
      query: ({
        body, material_id,
      }) => ({
        url: `expenses/${material_id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    updatePackaging: builder.mutation<unknown, IUpdatPackagingRequest>({
      query: ({
        body, packaging_id,
      }) => ({
        url: `expenses/${packaging_id}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    updateLabor: builder.mutation<unknown, { id: string, amount: string | number }>({
      query: ({
        amount, id,
      }) => ({
        url: `expenses/labor/${id}`,
        method: 'PATCH',
        body: { amount },
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    // DELETE

    deleteEmployee: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    deleteEmployeeProduct: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),

    deleteExpenses: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/expenses/${id}`,
        method: 'DELETE',
      }),

      invalidatesTags: () => ['TUTOR_BY_ID'],
    }),
  }),
});

export const {
  useCreateTutorMutation,
  useGetPipelineByIdQuery,
  useCreateEmployeeMutation,
  useCreateProductMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateProductMutation,
  useCreateMaterialMutation,
  useDeleteExpensesMutation,
  useCreatePackagingMutation,
  useCreateLaborMutation,
  useUpdateMaterialMutation,
  useUpdatePackagingMutation,
  useDeleteEmployeeProductMutation,
  useCreateAdditionalLossesMutation,
  useUpdateLaborMutation,
  useUpdateAdditionalLossesMutation
} = tutorsServices;
