import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Store
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
// Utils
import { expensesData } from 'utils/calculates/general-expenses-diagram';
// Styles
import {
  Container, ContainerHeader, Header, Prise, TooltipRow, Wrapper, Circle, Title, Label,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface ChartTooltipProps {
  active: any;
  payload: any;
  label: any;
  offset: number;
  currency: string;
}

interface IRowItem {
  name: string;
  cost: number;
  percent: string | number;
  color: string;
  key: number;
}

const ChartTooltipIncome: FC<ChartTooltipProps> = ({
  active, payload, currency
}) => {
  if (active && payload && payload.length) {
    const { t } = useTranslation();
    return (
      <Container>
        <ContainerHeader>
          <Header variant="regularText">
            {t('Собівартість реалізованої продукції')}
          </Header>

          <div>
            <Prise variant="boldRegularText">
              {currency} {payload?.[0]?.payload?.tatal || 0}
            </Prise>
          </div>
        </ContainerHeader>

        {expensesData(payload)?.dataRow?.map((item: IRowItem) => (
          <TooltipRow key={item.key}>
            <Wrapper>
              <Circle color={item?.color as string} />

              <Title>
              {t(item?.name)}
              </Title>
            </Wrapper>

            <Wrapper>
              <Label>
                {currency} {item?.cost}
              </Label>

              <Label color="#838383" isMargin={5}>
                ({item?.percent} %)
              </Label>
            </Wrapper>
          </TooltipRow>
        ))}
      </Container>
    );
  }

  return null;
};

export default ChartTooltipIncome;
