import { styled } from '@mui/material/styles';
import { Box, FormControlLabel } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const Label = styled(FormControlLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
`;

export const Link = styled(StyledLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
`;
