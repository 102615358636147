import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme?.palette?.primary?.dark};
  color: #ffffff;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  div:nth-of-type(1) {
    span {
      margin-left: 10px;
    }
  }

  div:nth-of-type(2) {
    span {
      margin-left: 10px;
    }
  }

  div:nth-of-type(3) {
    span {
      margin-right: 10px;
    }

    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Label = styled('span')`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;
