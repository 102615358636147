import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const IconButton = styled(Button)`
  background: ${({ theme }) => theme?.palette?.primary.contrastText};
  border-radius: 6px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  width: 191px;
  padding: 0 8px;
  margin-right: 30px;

  .MuiButton-startIcon {
    margin-right: 8px;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme?.palette?.primary.contrastText};
    }
  }
`;
