import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'store/auth/authSlice';
import { useAuthRoutes } from 'hooks/userAuthRoutes';
import { authRoutePath } from "../routes/routePath";

export const useActivate = () => {
  const { token, user } = useSelector(selectCurrentUser);

  const { redirectToLogin } = useAuthRoutes();
  const [searchParams] = useSearchParams();
  const recoveryToken = searchParams.get('token')!;
  const location = useLocation();

  useEffect(() => {
    if (recoveryToken && location.pathname === authRoutePath.SET_NEW_PASSWORD) {
      return;
    }

    if (!token && !user) {
      redirectToLogin();
    }
  }, [user, token]);
};
