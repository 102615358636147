import React, { useState } from 'react';
import { useTranslation, } from 'react-i18next';
// Icon
import { CloseFactIcon } from 'assets/icons';

import { LockTooltipText } from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";
// Styles
import {
  Container,
  Button,
  WrapperButton,
  Text,
} from './styles';

const ToggleButtons: React.FC = () => {
  const { t } = useTranslation();
  const [view, setView] = useState('plan');

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;

    setView(nextView);
  };

  return (
    <Container
      onChange={handleChange}
      orientation="horizontal"
      value={view}
      exclusive
    >
      <Button
        value="plan"
        aria-label="list"
      >
        { t('План') }
      </Button>
      <Tooltip
        arrow
        placement="bottom"
        tooltipText=""
        tooltipContent={<LockTooltipText />}
      >
        <span>
          <Button disabled value="fact" aria-label="module">
            <WrapperButton>
              <CloseFactIcon />
              <Text>
                { t('Факт') }
              </Text>
            </WrapperButton>
          </Button>
        </span>
      </Tooltip>
    </Container>
  );
};

export default ToggleButtons;
