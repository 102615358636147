import { styled } from '@mui/material/styles';

export const ContainerText = styled('div')`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
`;

export const ContainerTextSmall = styled('p')`
  width: 100%;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`
