import { BaseQueryParams } from 'store/baseQuery';
// Interface
import { IProject } from 'interfaces/project';
import { IGetProject, ICreateProject } from './projectsTypes';

export const projectsServices = BaseQueryParams('projects', ['PROJECTS']).injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<IGetProject, unknown>({
      query: () => ({
        url: '/projects',
        method: 'GET',
      }),
      providesTags: () => ['PROJECTS'],
    }),

    createProject: builder.mutation<IProject, ICreateProject>({
      query: (data) => ({
        url: '/projects',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => ['PROJECTS'],
    }),

    copyProject: builder.mutation<IProject, { project_id: string }>({
      query: ({ project_id }) => ({
        url: `projects/${project_id}/copy`,
        method: 'POST',
      }),
      invalidatesTags: () => ['PROJECTS'],
    }),

    updateProject: builder.mutation<IProject, { body: ICreateProject, id: string }>({
      query: ({ body, id }) => ({
        url: `/projects/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['PROJECTS'],
    }),

    deleteProject: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['PROJECTS'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useCopyProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} = projectsServices;
