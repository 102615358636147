import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// Images
import AuthBackground from 'assets/images/auth-background.png';

interface ImageProps {
  maxHeight?: string;
}

interface ContainerProps {
  isImageBottom?: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  display: flex;
  align-items: ${({ isImageBottom }) => (isImageBottom ? 'flex-end' : 'center')};
  justify-content: center;
  padding: ${({ isImageBottom }) => (isImageBottom ? '40px 40px 0 40px' : '40px')};
  width: 50%;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  background-image: url(${AuthBackground});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const Image = styled('img')<ImageProps>`
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  max-height: ${({ maxHeight }) => maxHeight || '586px'};
  object-fit: contain;
`;
