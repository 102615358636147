import {IPipelineEmployee} from "../../store/dashboard/dashboardTypes";

export const calcTotalAdminEmployees= (employees: IPipelineEmployee[], residence: string) => {
  const empls = employees?.filter(item => item?.type === 'administration')

  if (empls?.length) {
    const totalValue = empls?.reduce((acc: number, item: IPipelineEmployee) => {
      const wage = item?.wage ? +item?.wage : 0;
      const ppfo = wage / (1 - 0.18 - 0.015) * 0.18;
      const military = (wage + ppfo) * 0.015;
      const sso = (wage + ppfo + military) * 0.22;
      const sum = wage + ppfo + military + sso;

      if (residence === 'UA') {
        acc += sum;
      } else {
        acc += wage;
      }

      return +acc;
    }, 0);

    return totalValue || 0;
  }

  return 0;
};
