import React from 'react';
import {
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import {useTranslation} from "react-i18next";
// Interface
import { IProduct } from 'interfaces/product';
// Icons
import { SettingsIcon } from 'assets/icons';
// Styles
import { StyledTableHeader, SettingsLink } from './styles';

type Order = 'asc' | 'desc';

interface Data {
  product: string,
  sources: string,
  price: number,
}

interface TeamTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IProduct) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSettingClick: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  collapse?: boolean;
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'product',
    numeric: false,
    label: 'Продукт',
  },
  {
    id: 'sources',
    numeric: false,
    label: '',
  },
  {
    id: 'price',
    numeric: true,
    label: 'Ціна',
  },
];

const TeamsTableHead: React.FC<TeamTableProps> = ({
  numSelected,
  onRequestSort,
  onSelectAllClick,
  handleSettingClick,
  order,
  orderBy,
  rowCount,
  collapse,
}) => {
  const { t } = useTranslation();
  const createSortHandler =  (property: keyof IProduct) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  }

  return (
    <StyledTableHeader className={collapse ? 'collapse' : ''}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            width={400}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              // onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell>
          {/*<SettingsLink onClick={handleSettingClick}>*/}
          {/*  <SettingsIcon />*/}
          {/*</SettingsLink>*/}
        </TableCell>
      </TableRow>
    </StyledTableHeader>
  )
}

export default TeamsTableHead;
