import { styled } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';

export const Container = styled(ToggleButtonGroup)`
  height: fit-content;
  background-color: ${({ theme }) => theme?.palette?.primary.contrastText};
  width: fit-content;
`;

export const Button = styled(ToggleButton)`
  && {
    width: fit-content;
    height: 40px;
    padding: 10px 20px;
    min-height: 32px;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.primary.contrastText};
  }
`;

export const WrapperButton = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Text = styled(Box)`
  margin-left: 6px;
`;
