import React from 'react';
// Icons
import { UserIcon } from 'assets/icons';
// Styles
import {
  Container,
  Wrapper,
  Title,
  Link,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const MOCK_LINKS_PROFILE = [
  {
    title: 'Персональна інформація',
    to: '/profile#info',
  },
  {
    title: 'Мова',
    to: '/profile#language',
    hash: 'language'
  },
  {
    title: 'Тарифні плани',
    to: '/profile#plans',
  },
  {
    title: 'Історія транзакцій',
    to: '/profile#history',
  },
  {
    title: 'Public Offer',
    to: '/public-offer',
  },
  {
    title: 'Privacy & Policy',
    to: '/privacy-policy',
  },
];

const UserTooltipBody: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <UserIcon color="#07B169" />

        <Title variant="h4">
          {t('Акаунт')}
        </Title>
      </Wrapper>

      {MOCK_LINKS_PROFILE.map(({ title, to, hash }) => (
        <Link to={to}>
          {t(`${title}`)}
        </Link>
      ))}
    </Container>
  )
};

export default UserTooltipBody;
