import { styled } from '@mui/material/styles';
import {Typography, Button, Tabs, Tab, Box} from '@mui/material';
import Input from "components/UI/Input";

export const Container = styled('div')`
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px;
  
  footer {
    position: fixed;
    width: 100%;
    max-width: 1122px;
    bottom: 0;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const H1  = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  display: flex;
  margin-right: 10px;
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 18px;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
`;

export const HeaderRightBlock = styled('div')`
  display: flex;
  align-items: center;

  .search-input {
    height: 48px;
  }

  .toolbar-btns {
    margin-right: 8px;
  }

  .filter-btn {
    min-width: 210px;
  }

  .create-btn {
    min-width: 117px;
    display: flex;
    align-items: center;
    .MuiButton-startIcon {
      height: 13px;
    }
  }

  & > * {
    margin-right: 30px;
  }
`;

export const SectionContainer = styled('div')`
  width: 100%;
  background-color: red;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 50px;
  height: fit-content;
  padding: 0 30px;
  max-width: 1122px;
  padding-bottom: 30px;
`;

export const TableContainer = styled(SectionContainer)`
  width: 100%;
  background-color: red;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 50px;
  height: fit-content;
  padding: 0 30px;
  max-width: 1122px;
  padding-bottom: 30px;
  .tabs-row {
    margin-top: 0;
  }
  .right-block {
    padding-top: 20px;
  }
`;

export const TabContainer = styled('div')`
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const SettingsLink = styled('a')`
  display: flex;
  align-self: center;
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    top: 0;
    background: ${({ theme }) => theme?.palette?.custom?.green.main};
  }

  .MuiTabs-flexContainer {
    height: 80px;
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme?.palette?.custom?.grey.lightIco};
  font-size: 16px;
  text-transform: inherit;
  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.custom?.grey.dark};
    font-weight: 400;
  }
`;

export const StyledInput = styled(Input)`
  max-width: 218px;
  margin-left: 12px;

  .MuiFormHelperText-root {
    display: none;
  }
`;

export const ChartWrapper = styled('div')`
  .custom-tooltip {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 150px;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.06);
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
`;
