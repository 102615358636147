import {css, styled} from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('div')`
  display: flex;
  margin-bottom: 6px;
  flex-direction: column;
  justify-content: space-between;
  
  .callapse {
    margin: 0 -40px;
    padding: 12px 20px;
    background: ${({ theme }) => theme.palette.custom.grey.darkLite};
  }
`;

export const SubcategoryFieldWrap = styled('div')`
  display: flex;
  align-items: center;
  max-height: 79px;
  justify-content: space-between;
  margin-bottom: 20px;
  
  .name-input {
    max-width: 340px;
  }
  
  & > .MuiFormControl-root {
    margin-right: 20px;
  }
`;

export const CollapseHeader = styled('a')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  margin: 0 -40px;
`;

export const DepartmentNameCollapse = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.green.main};
  svg {
    margin-right: 17px;
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.green.main};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};
  
`;


export const FieldWrap = styled('div')`
  color: ${({ theme }) => theme.palette.custom.black.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
  
  &:last-child {
    border-bottom: none;
  }
`;

export const DeleteButton = styled(Button)`
  transform: translateX(0);
  transition: all .3s ease-in-out;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  && {
    text-decoration: none;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.custom.red.main};
    &:hover {
      color: ${({ theme }) => theme.palette.custom.red.main};
    }
    svg {
      margin-right: 10px;
    }
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: 100%;
  margin: 20px 0;
`;


export const DeleteBtn = styled(Button)`
  background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  align-self: center;
  &:hover, &:focus {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  }
`;

export const LinkButton = styled(Button)`
  text-decoration: none;
  padding: 0;
  margin-right: 15px;
  &:hover {
    text-decoration: none;
  }
`;

export const FormFooter = styled('div')`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;



export const InlineFormWrap = styled('div')`
  width: 100%;
  display: flex;
  
  .inline-input {
    min-width: 198px;
  }
  
  .time-input {
    margin-right: 10px;
  }
  
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;