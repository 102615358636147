import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useFieldArray, FieldErrors, Control, useWatch,
} from 'react-hook-form';
import { Typography } from '@mui/material';
// Store
import { selectTutor } from 'store/tutors/tutorsSlice';
// Interface
import { IEmployeeProducts } from 'store/tutors/tutorsTypes';
// Components
import EmployeeProductFormRow from '../EmployeeProductFormRow';
// Styles
import { Container, LinkButton, FormFooter } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface EmployeeProductItemProps {
  errors: FieldErrors<{ employeeProduct: IEmployeeProducts[] }>
  control: Control<{ employeeProduct: IEmployeeProducts[] }>,
  indexProduct: number;
  itemProduct: IEmployeeProducts;
  addNewEmployee: () => void;
}

const EmployeeProductItem: FC<EmployeeProductItemProps> = ({
  indexProduct, itemProduct, control, errors, addNewEmployee,
}) => {
  const { t } = useTranslation();
  const { employeesSelect } = useSelector(selectTutor);
  const watched = useWatch({ control });
  const laborsCategory = watched.employeeProduct?.[indexProduct]?.labors;
  const [isDisabled, setIsDisabled] = useState(false);

  const filteredEmployees = () => {
    let notMyService = employeesSelect.filter(empl => laborsCategory?.every(item => item.department !== empl.label));

    return notMyService || [];
  }

  const formStateArray = useFieldArray({
    control,
    name: `employeeProduct.${indexProduct}.labors`,
  });

  const appendRow = () => {
    formStateArray.append({
      department: '',
      employees: [],
    });
  };

  const deleteRow = (index: number) => {
    formStateArray.remove(index);
  }

  useEffect(() => {
    setIsDisabled(!filteredEmployees()?.length)
  }, [filteredEmployees()?.length])

  return (
    <Container>
      {formStateArray.fields.map((labor, index) => (
        <EmployeeProductFormRow
          addNewEmployee={addNewEmployee}
          deleteRow={deleteRow}
          dataSelect={filteredEmployees()}
          key={labor.id}
          indexProduct={indexProduct}
          indexRow={index}
          control={control}
          errors={errors}
          labor={labor}
        />
      ))}

      <FormFooter>
        <LinkButton
          onClick={appendRow}
          variant="link"
          disabled={isDisabled}
        >
          {t('Додати ще один')}
        </LinkButton>
      </FormFooter>
    </Container>
  );
};

export default EmployeeProductItem;
