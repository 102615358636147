import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface ValueProps {
  color: string;
}

export const Container = styled(Box)`
  outline: none;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 167px;
  height: fit-content;
  padding: 10px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(Box)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`;

export const Value = styled(Box)<ValueProps>`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color};
`;
