import { styled } from '@mui/material/styles';
import {TableCell, TableRow} from '@mui/material';

export const Title = styled(TableCell)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  padding: 10px 10px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  &.first-th {
    padding-left: 30px;
  }
  &.bordered {
    border-top: 1px solid ${({ theme }) => theme?.palette?.primary.main};
    border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary.main};
    border-left: 1px solid ${({ theme }) => theme?.palette?.primary.main};
  }
`;

export const StyledTableRow = styled(TableRow)`
  th:nth-child(3) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid ${({ theme }) => theme?.palette?.primary.main};
  }
`

