import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import AuthLayout from 'layouts/AuthLayout';
import TutorLayout from 'layouts/TutorLayout';
// Pages
import ButtonsPage from 'pages/ButtonsPage';
import InputsPage from 'pages/InputsPage';
import Projects from 'pages/Projects';
import AddProject from 'pages/AddProject';
import EditPropject from 'pages/EditPropject';
import Pipelines from 'pages/Pipelines';
import Tutor from 'pages/Tutor';
import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import PasswordRecovery from 'pages/PasswordRecovery';
import Registration from 'pages/Registration';
import Teams from 'pages/Teams';
import Expenses from 'pages/Expenses';
import Profit from 'pages/Profit';
import AddService from 'pages/AddService';
import AddProductPage from 'pages/AddProduct';
import SuccessfullySent from 'pages/SuccessfullySent';
import UserProfile from 'pages/UserProfile';
import PlanDashboard from 'pages/PlanDashboard';
import PublicOffer from 'pages/PublicOffer';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ProductsAndServices from 'pages/ProductsAndServices';
import ComparePipeliners from "pages/ComparePipeliners";
import ActivationUser from 'pages/ActivationUser';
import VideoLesson from 'pages/VideoLesson';

// Route Path
import { authRoutePath, freelanceUserRoutesPath, tutorRoutesPath, userRoutesPath } from './routePath';
import ErrorPage from "../pages/ErrorPage";
import HelpPage from "../pages/HelpPage";
import FreelancerLayout from "layouts/FreelancerLayout";
import Invoices from "components/Freelancer/Invoices";
import FreelancerProjects from "components/Freelancer/Projects";
import FreelancerDashboard from "components/Freelancer/Dashboard";
import Services from "components/Freelancer/Services";
import Members from "components/Freelancer/Members";
import Settings from "components/Freelancer/Settings";
import FreelancerExpenses from "components/Freelancer/Expenses";
import FreelancerProfit from "components/Freelancer/Profit";

export const userRoutes: RouteObject = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: userRoutesPath.ERROR_PAGE,
      element: <ErrorPage />,
    },
    {
      path: '/',
      element: <Projects />,
    },
    {
      path: userRoutesPath.VIDEO_LESSONS,
      element: <VideoLesson />,
    },
    {
      path: userRoutesPath.PROJECTS_ADD,
      element: <AddProject />,
    },
    {
      path: userRoutesPath.PROJECTS_EDIT,
      element: <EditPropject />,
    },
    {
      path: userRoutesPath.PROJECTS_ADD_COPY,
      element: <AddProject />,
    },
    {
      path: '/buttons',
      element: <ButtonsPage />,
    },
    {
      path: '/inputs',
      element: <InputsPage />,
    },
    {
      path: userRoutesPath.PIPELINES,
      element: <Pipelines />,
    },
    // {
    //   path: '/products-n-services',
    //   element: <ProductsAndServices/>,
    // },
    {
      path: userRoutesPath.PRODUCT_ADD,
      element: <AddProductPage />
    },
    {
      path: userRoutesPath.SERVICE_ADD,
      element: <AddService />
    },
    {
      path: userRoutesPath.PRODUCT_EDIT,
      element: <AddProductPage />
    },
    {
      path: userRoutesPath.SERVICE_EDIT,
      element: <AddService />
    },
    {
      path: userRoutesPath.PROFILE,
      element: <UserProfile />,
    },
    {
      path: userRoutesPath.TUTOR_ID,
      element: <Tutor />,
    },
    {
      path: userRoutesPath.DASHBOARD,
      element: <PlanDashboard />,
    },
    {
      path: userRoutesPath.PRODUCTS_AND_SERVICES,
      element: <ProductsAndServices/>,
    },
    {
      path: userRoutesPath.TEAMS,
      element: <Teams />,
    },
    {
      path: userRoutesPath.EXPENSES,
      element: <Expenses />,
    },
    {
      path: userRoutesPath.PROFIT,
      element: <Profit />
    },
    {
      path: userRoutesPath.PUBLIC_OFFER,
      element: <PublicOffer />,
    },
    {
      path: userRoutesPath.PRIVACY_POLICY,
      element: <PrivacyPolicy />,
    },
    {
      path: userRoutesPath.HELP,
      element: <HelpPage />,
    },
    {
      path: userRoutesPath.COMPARE_PIPELINES,
      element: <ComparePipeliners />
    },
    // {
    //   path: '/dashboard-tables',
    //   element: <DashboardTable />,
    // },
    { path: '*', element: <Navigate to="/" /> },
  ],
};

export const freelanceUserRoutes: RouteObject = {
  path: freelanceUserRoutesPath.DASHBOARD,
  element: <FreelancerLayout />,
  children: [
    {
      path: userRoutesPath.ERROR_PAGE,
      element: <ErrorPage />,
    },
    {
      path: freelanceUserRoutesPath.DASHBOARD,
      element: <FreelancerDashboard />,
    },
    {
      path: freelanceUserRoutesPath.PROJECTS,
      element: <FreelancerProjects />,
    },
    {
      path: freelanceUserRoutesPath.INVOICES,
      element: <Invoices />,
    },
    {
      path: freelanceUserRoutesPath.SERVICES,
      element: <Services />,
    },
    {
      path: freelanceUserRoutesPath.PROFIT,
      element: <FreelancerProfit />,
    },
    {
      path: freelanceUserRoutesPath.EXPENSES,
      element: <FreelancerExpenses />,
    },
    {
      path: freelanceUserRoutesPath.MEMBERS,
      element: <Members />,
    },
    {
      path: freelanceUserRoutesPath.SETTINGS,
      element: <Settings />,
    },
    { path: '*', element: <Navigate to={freelanceUserRoutesPath.DASHBOARD} /> },
  ],
};

export const authRoutes: RouteObject = {
  element: <AuthLayout />,
  children: [
    {
      path: authRoutePath.LOGIN,
      element: <Login />,
    },
    {
      path: authRoutePath.COUNTRY_SENT,
      element: <SuccessfullySent />,
    },
    {
      path: authRoutePath.RECOVERY,
      element: <ForgotPassword />,
    },
    {
      path: authRoutePath.SET_NEW_PASSWORD,
      element: <PasswordRecovery />,
    },
    {
      path: authRoutePath.REGISTRATION,
      element: <Registration />,
    },
    {
      path: '/buttons',
      element: <ButtonsPage />,
    },
    {
      path: authRoutePath.LOGIN_VERIFY,
      element: <ActivationUser />,
    },

    { path: '*', element: <Navigate to="/" /> },
  ],
};

export const tutorRoutes: RouteObject = {
  path: tutorRoutesPath.TUTOR,
  element: <TutorLayout />,
  children: [
    {
      path: tutorRoutesPath.TUTOR_STEP,
      element: <Tutor />,
    },
    // {
    //   path: '/tutor/helper/:id',
    //   element: <Stepper />,
    // },
    // {
    //   path: '/tutor/steper/:id/:stepNumber',
    //   element: <TutorHelper />,
    // },
    // {
    //   path: '/tutor/steper/:id/:stepNumber/:edit',
    //   element: <TutorHelper />,
    // },
    // {
    //   path: '/tutor/:id',
    //   element: <Tutor />,
    // },
    { path: '*', element: <Navigate to="/" /> },
  ],
};

export const userProfileRoutes: RouteObject = {
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <UserProfile />,
    },
  ],
};
