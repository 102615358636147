import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';
// Interface
import { IProduct } from 'interfaces/product';
// Components
import CollapseTableRow from '../CollapseTableRow';
import TeamsTableHead from '../TeamsTableHead';
import ProductTableRow from '../ProductTableRow';

type Order = 'asc' | 'desc';

interface Data {
  product: string,
  sources: string,
  price: number,
}

interface ProductsTableBodyProps {
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: (name: string) => boolean;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IProduct,
  ) => void;
  order: Order;
  orderBy: any;
  groupName: string;
  products: IProduct[] | [];
  group: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: IProduct[], comparator: (a: IProduct, b: IProduct) => number) {
  const stabilizedThis = array.map((el: IProduct, index) => [el, index] as [IProduct, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a?.[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ProductsTableBody: React.FC<ProductsTableBodyProps> = ({

  groupName,
  products,
  order,
  orderBy,
  handleSelectAllClick,
  handleRequestSort,
  isSelected,
  group
}) => {
  const [open, setOpen] = useState(true);

  const openHandleTable = () => setOpen(prev => !prev);

  const filteredProduct = () => {
    const prod = products?.filter(item => item?.group === group);

    return prod || [];
  }

  return (
    <>
      <CollapseTableRow groupName={groupName} openHandleTable={openHandleTable} open={open} />

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TeamsTableHead
                numSelected={0}
                order={'asc'}
                orderBy={"group"}
                onSelectAllClick={() => {}}
                onRequestSort={() => {}}
                rowCount={6}
                handleSettingClick={() => {}}
                collapse={true}
              />
              <TableBody>
                {filteredProduct()?.map((product, index) => (
                  <ProductTableRow
                    key={product?.id}
                    product={product}
                    index={index}
                    isSelected={isSelected}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ProductsTableBody;
