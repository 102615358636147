import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  width: 48%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled('span')`
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 440px;
  position: relative;
`;

export const Image = styled('img')`
  width: 100%;
  max-width: 440px;
  border-radius: 8px;
  object-fit: cover;
`;

export const IconButton = styled(Button)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  position: absolute;
  top: 20px;
  right: 20px;
  background: #EBF7F2;
  border-radius: 8px;
  color: #07B169;
`;
