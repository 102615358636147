import { styled } from '@mui/material/styles';
import Input from 'components/UI/Input';

export const StyledInput = styled(Input)`
  width: 130px;

  .MuiInputBase-root {
    background: #FCFCFC;
  }

  .MuiFormHelperText-root {
    display: none;
  }
`;
