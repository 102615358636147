import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import IconButton from 'components/UI/Buttons/IconButton/IconButton';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  max-width: 450px;
  align-items: center;
`;

export const Button = styled(IconButton)`
  width: 34px;
  height: 34px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  margin-left: 10px;
  padding-top: 2px;
`;
