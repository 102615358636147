import React from 'react';
import { Button, Checkbox, Radio } from '@mui/material';
import { PlusIcon, DownloadIcon, CompareIcon } from 'assets/icons';
import IconButton from 'components/UI/Buttons/IconButton/IconButton';
import CheckboxLight from 'components/UI/CheckboxLight';
// Styles
import { Container } from './styles';

const ButtonsPage: React.FC = () => (
  <Container>
    <h1>ButtonsPage</h1>
    <Button variant="contained">test</Button>
    <Button variant="light">test</Button>
    <Button variant="textIcon" startIcon={<PlusIcon />}>test</Button>
    <IconButton icon={<PlusIcon />} />
    <IconButton icon={<DownloadIcon />} />
    <IconButton icon={<CompareIcon />} />
    <Checkbox />
    <CheckboxLight />
    <Radio
      value="a"
      name="radio-buttons"
      inputProps={{ 'aria-label': 'A' }}
    />
    <Radio
      checked={false}
      value="b"
      name="radio-buttons"
      inputProps={{ 'aria-label': 'B' }}
    />
  </Container>
);

export default ButtonsPage;
