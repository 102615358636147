import { styled } from '@mui/material/styles';
import { Button as StyledButton } from '@mui/material';

export const Button = styled(StyledButton)`
  height: 55px;
`;

export const GoogleButton = styled(StyledButton)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  border: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
  height: 55px;
  margin-top: 10px;

  &:hover {
    background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
    color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  }
`;
