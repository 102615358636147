import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const StyledIconBtn = styled(Button)`
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
  padding: 0;
  
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  
  &.transparent {
    background: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
