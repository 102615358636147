import { Theme, ComponentsOverrides } from '@mui/material';

interface muiTextFieldProps {
  styleOverrides: ComponentsOverrides['MuiTextField'];
}

export const muiTextField = (theme: Theme): muiTextFieldProps => ({
  styleOverrides: {
    root: {
      padding: 0,
      '& .MuiInput-underline:after': {
      },
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        background: `${theme.palette.primary?.contrastText}`,
        '& fieldset': {
          borderWidth: '1px',
        },
        '&:hover fieldset': {
          borderWidth: '1px',
        },
        '&.Mui-focused fieldset': {
          borderWidth: '1px',
        },
      },
      '& .MuiFormHelperText-root': {
        margin: '8px 0 0 0',
      },
      input: {
        color: theme.palette.primary.dark,
        padding: '13px 20px',
        '&::placeholder': {
          color: `${theme.palette.custom?.primary?.silver}`,
        },
      },
    },
  },
});
