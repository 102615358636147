import { useNavigate } from 'react-router';
import { authRoutePath } from 'routes/routePath';

interface HookReturnType {
  redirectToLogin: () => void;
  redirectToRecoveryPassword: () => void;
  redirectToCountrySent: () => void;
}

export const useAuthRoutes = (): HookReturnType => {
  const navigate = useNavigate();

  const redirectToLogin = () => navigate(authRoutePath.LOGIN, { replace: true });
  const redirectToRecoveryPassword = () => navigate(authRoutePath.RECOVERY);
  const redirectToCountrySent = () => navigate(authRoutePath.COUNTRY_SENT);

  return {
    redirectToLogin,
    redirectToCountrySent,
    redirectToRecoveryPassword,
  };
};
