import React from 'react';
// Components
import Pagination from './Pagination';
import RowHistory from './RowHistory';
// Styles
import { Container } from './styles';

const History: React.FC = () => (
  <Container>
    <Pagination />

    <RowHistory />
    <RowHistory />
    <RowHistory />
  </Container>
);

export default History;
