import { Box } from '@mui/material';
import DatePicker from 'react-datepicker';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled('span')`
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;

export const ContainerInput = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  width: 100%;
  height: 49px;
  border-radius: 8px;
  padding: 13px 20px;

  &:hover {
    border-color: ${({ theme }) => theme?.palette?.primary?.light};
  }

  .react-datepicker {
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
  }

  .react-datepicker__header {
    background: #FFFFFF;
    border-color: #EBEBEB;
  }

  .react-datepicker__month-container {
    padding: 0 20px;
  }

  .react-datepicker__header {
    font-family: 'Visuelt Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #292929;
  }

  .react-datepicker__month-text {
    color: #838383;
    font-family: 'Visuelt Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .react-datepicker__month-text--keyboard-selected {
    background: #EBF7F2;
    color: #07B169;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border: none;
    background-color: #ffffff;
  }

  .datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border: none;
    background-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border: none;
    background-color: #ffffff;
  }
`;

export const Input = styled(DatePicker)`
  border: none;
  outline: none;
  height: 45px;
  width: 100%;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #292929;

  &::placeholder {
    color: #cbcbcb;
  }

  input {
    width: 100%;
  }
`;
