import { styled } from '@mui/material/styles';
import {Typography, Button, Box} from '@mui/material';

export const Container = styled('div')`
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px;

  footer {
    position: fixed;
    width: 100%;
    max-width: 1122px;
    bottom: 0;
  }
`;

export const TableContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 50px;
  height: fit-content;
  padding: 0 30px;
  max-width: 1122px;
  padding-bottom: 30px;
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 18px;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  &.no-top-space {
    margin-top: 0;
  }
`;

export const HeaderRightBlock = styled('div')`
  display: flex;
  align-items: center;
  .toolbar-btns {
    margin-right: 8px;
  }
  .filter-btn {
    min-width: 210px;
  }
  & > * {
    margin-right: 30px;
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const SettingsLink = styled('a')`
  display: flex;
  align-self: center;
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 30px;
  }
`;

export const WrapperButton = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Text = styled(Box)`
  margin-left: 6px;
`;
