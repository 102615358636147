import React from 'react';
// Styles
import { Container, StyledLoading } from './styles';

const Loading: React.FC = () => (
  <Container>
    <StyledLoading size={80} />
  </Container>
);

export default Loading;
