import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #F8F9FD;
  border-radius: 8px;
  max-width: 520px;
`;
