import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 18px 18px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette?.primary?.main};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;

export const IconButton = styled(Button)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  background: ${({ theme }) => theme?.palette?.primary?.contrastText};
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
`;
