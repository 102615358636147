import React from 'react';

import { Typography } from '@mui/material';

import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
// Styles
import { Container, Box } from './styles';

const data = [
  {
    value: 'Test1', label: 'AAAA',
  },
  {
    value: 'Test2', label: 'BBBB',
  },
  {
    value: 'Test3', label: 'CCCC',
  },
];

const InputsPage: React.FC = () => (
  <Container>
    <h1>InputsPage</h1>

    <Box>
      <Input label="Input" placeholder="Stactic" />
    </Box>

    <Box>
      <Input label="Input" value="TextField" />
    </Box>

    <Box>
      <Input label="Input" error value="TextField" helperText="Error" />
    </Box>

    <Box>
      <Select label="Label" data={data} />
    </Box>

    <Box>
      <Select label="Label" data={data} defaultValue={data?.[0]?.value} />
    </Box>

    <Box>
      <Select label="Label" data={data} error errorMessage="Error" />
    </Box>

    <Typography variant="h1">
      variant - H1
    </Typography>

    <Typography variant="h2">
      variant - H2
    </Typography>

    <Typography variant="h3">
      variant - H3
    </Typography>

    <Typography variant="regularText">
      variant - regularText
    </Typography>

    <Typography variant="smallRegularText">
      variant - smallRegularText
    </Typography>
  </Container>
);

export default InputsPage;
