import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

interface LinkProps {
  isActive: boolean;
  isLock?: boolean;
}

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  width: 100%;
`;

export const Link = styled(Button)<LinkProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ isActive }) => (isActive ? '#292929' : '#838383')};
  // opacity: ${ ({isLock}) => isLock ? '0.6' : '1'};
  text-transform: inherit;
  padding: 0 13px 0 0;
  margin: 0;

  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }


  &:hover {
    opacity: 0.7;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0 15px;
  padding-right: 10px;
`;

export const ContainerLink = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.5s ease;
  height: 100%;

  &:hover {
    div {
      opacity: 1;
      background-color: #838383;
    }
  }
`;

export const BorderLink = styled(Box)<LinkProps>`
  transition: all 0.5s ease;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background: #07B169;
  position: absolute;
  width: calc(100% - 13px);
  height: 2px;
  top: -21px;
  left: 0;
`;
