import React, { FC } from 'react';
// Styles
import { Container, LinkButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface EmployeesAddButtonsProps {
  appendEmployee: (type: string) => void;
}

const buttonsType = [
  {
    type: 'administration',
    title: 'Додати адміністрацію',
  },
  {
    type: 'partner',
    title: 'Додати співробітника',
  },
  {
    type: 'contractor',
    title: 'Додати контранента',
  },
];

const EmployeesAddButtons: FC<EmployeesAddButtonsProps> = ({ appendEmployee }) => {
  const { t } = useTranslation();
  return (
    <Container>
      {buttonsType.map(({ type, title }) => (
        <LinkButton
          onClick={() => appendEmployee(type)}
          variant="link"
          key={type}
        >
          {t(`${title}`)}
        </LinkButton>
      ))}
    </Container>
  )
};

export default EmployeesAddButtons;
