import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

import IconButton from 'components/UI/Buttons/IconButton/IconButton';

interface LabelItemProps {
  isActive: boolean;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Button = styled(IconButton)`
  width: 34px;
  height: 34px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  margin-left: 10px;
`;

export const ContainerStep = styled(Box)`
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 134px;
`;

export const LabelItem = styled(Box)<LabelItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme, isActive }) => (isActive ? theme?.palette?.custom?.green?.main : theme?.palette?.primary?.main)};
`;

export const Link = styled(StyledLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  text-decoration: underline;
  margin-left: 5px;
`;

export const Item = styled(Box)<LabelItemProps>`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  margin-top: 2px;
  color: ${({ theme, isActive }) => (isActive ? theme?.palette?.primary?.contrastText : theme?.palette?.custom?.primary?.silver)};
`;

export const Image = styled('img')`
  width: 80px;
  height: 20px;
  margin-top: 5px;
`;
