import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
`;

export const Wrapper = styled('div')`
  height: 100%;
  height: calc(100% - 73px);
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
`;

export const ContainerCard = styled('div')`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
`;
