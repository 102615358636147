import { styled } from '@mui/material/styles';
import { Typography, Button, Box } from '@mui/material';

interface CompareButtonProprs {
  isSelected?: boolean;
}

export const Container = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding-top: 30px;
  background-color: rgba(255, 255, 255, 1);
  max-width: 546px;
  height: fit-content;
  max-height: 800px;
  width: 32%;
`;

export const CardList = styled('ul')`
  margin-top: 50px;
  margin-bottom: 30px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardListItem = styled('li')`
  width: 100%;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};

  &&.no-border {
    border-bottom: none;
  }

  &&.accentuated {
    background: ${({ theme }) => theme.palette.primary.main};
    margin: 0 -33px;
    width: auto;
    padding: 0 33px;
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};
`;

export const TextGreen = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.green.main};
  padding-right: 5px;
`;

export const ListSubheader = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.custom.black.main};
`;

export const IncomeSumBlock = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CompareButton = styled(Button)<CompareButtonProprs>`
  && {
    padding: 0;
    max-height: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;

    ${({ isSelected }) => isSelected ? 'background: #07B169; color: #ffffff;' : ''}

    svg {
      width: 17px;
      height: 15px;
      color: ${({ isSelected }) => isSelected ? '#ffffff' : '#07B169'};
    }

    &:hover {
      svg {
        color: #ffffff;
      }
    }
  }
`;

export const CreateButton = styled(Button)`
  width: 100%;
  max-width: 410px;
  margin-right: 16px;
`;

export const ButtonsRowStyled = styled('div')`
  display: flex;
  width: 100%;
`;

export const WrapperButton = styled(Box)`
  padding: 30px;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
`;
