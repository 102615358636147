import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";

import {useGetProjectByIdQuery, useGetSalesPlanProductsQuery} from "store/dashboard/dashboardServices";
import {selectDashboard} from "store/dashboard/dashboardSlice";

import SummarySection from "pages/PlanDashboard/SummarySection";
import SalesPlanSection from "pages/PlanDashboard/SalesPlanSection";
import AnalysisIncomeSection from "pages/PlanDashboard/AnalysisIncomeSection";
import StructureAnalysisSections from "pages/PlanDashboard/StructureAnalysisSections";
// import DashboardTables from "pages/PlanDashboard/DashboardTables";
import {ContainerPrint} from "pages/PlanDashboard/styles";
import ProfitAndLossTable from "pages/PlanDashboard/DashboardTables/ProfitAndLossTable";
import TableWrapper from "./TableWrapper";
import CashflowTable from "pages/PlanDashboard/DashboardTables/CashflowTable";
import SalesMonthsPrint from "pages/PlanDashboard/DashboardTables/SalesMonthsPrint";
import {DATE} from "utils/constants/date";

import {Title, TitleWrap} from "./styles";
import {IPipeline, IPlanSales} from "../../store/dashboard/dashboardTypes";
import {getUtcFormatDate} from "../../utils/helpers";

interface IDate {
  startDate: any;
  endDate: any;
}

const PrintDashboard = React.forwardRef((props, ref: any) => {
  const { project_id, pipeline_id } = useParams();
  const { t } = useTranslation();
  const { pipeline } = useSelector(selectDashboard);
  const [dateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data: salesPlanProductsData } = useGetSalesPlanProductsQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const { data: project } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  return (
    <ContainerPrint ref={ref}>
      <TitleWrap>
        <Title variant="h1">{"pipeline_name" in pipeline ? pipeline?.pipeline_name : ''}</Title>
        {new Date().toLocaleDateString()}
      </TitleWrap>

      <Typography variant={"h2"}>{t('Огляд показників')}</Typography>

      <SummarySection pipeline={pipeline as IPipeline}
                      refetchPipeline={()=> {}}
                      refetchSalesPlanProducts={()=>{}}
                      refetchSalesPlan={()=>{}}
      />

      <SalesPlanSection salesPlanProductsData={salesPlanProductsData as IPlanSales[]}  />

      <AnalysisIncomeSection currency={project?.currency as string} />

      {/*<StructureAnalysisSections currency={project?.currency as string} />*/}

      {/*<DashboardTables currency={project?.currency as string} />*/}
      <TableWrapper title={t('Профіт&Лосс')}>
        <ProfitAndLossTable currency={project?.currency as string} />
      </TableWrapper>

      <TableWrapper title={t('Кешфлоу')}>
        <CashflowTable currency={project?.currency as string} />
      </TableWrapper>

      <TableWrapper title={t('Продажі за місяцями')}>
        <SalesMonthsPrint />
      </TableWrapper>

    </ContainerPrint>
  )
});

export default PrintDashboard;
