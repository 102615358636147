import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
`;

export const Box = styled('div')`
  margin-top: 20px;
`;
