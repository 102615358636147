import React from 'react';
// Constants
import { paymentMethodData, USER_PROFILE_TOGGLE_BUTTONS } from 'utils/constants/user-profile';
// Components
import Select from 'components/UI/Select';
import ToggleButtons from '../ToggleButtons';
// Styles
import {
  Container,
  DisabledInput,
  Button,
  Wrapper,
} from './styles';

interface UserSubscriptioInputsProps {
  onOpnen: () => void;
}
const UserSubscriptioInputs: React.FC<UserSubscriptioInputsProps> = ({ onOpnen }) => (
  <Container>
    <DisabledInput
      name="plan"
      label="Діючий план"
      value="Безкоштовний"
      disabled
    />

    <Select
      data={paymentMethodData}
      defaultValue={paymentMethodData[0]?.value}
      name="pay"
      label="Метод оплати"
    />

    <Button
      onClick={onOpnen}
      variant="contained"
    >
      Додати картку
    </Button>

    <Wrapper>
      {USER_PROFILE_TOGGLE_BUTTONS.map((item) => (
        <ToggleButtons
          label={item.label}
          firstValue={item.firstValue}
          lastValue={item.lastValue}
        />
      ))}
    </Wrapper>
  </Container>
);

export default UserSubscriptioInputs;
