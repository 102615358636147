import {styled} from "@mui/material/styles";

export const AccordionContainer = styled('div') `
  position: relative;
  margin-bottom: 30px;
`;

export const AccordionHeader = styled('div')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  &.open {
    border-radius: 8px 8px 0px 0px;
  }
`;
