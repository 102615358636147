import { styled, css } from '@mui/material/styles';
import {Typography, Button, TableHead, TableContainer, TableRow, List, ListItem, ListItemButton} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const SourcesWrap = styled('div')`
  display: flex;
`;

export const SourcesContent = styled('div')`
  max-width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuList = styled(List)`
  margin: -8px -17px;
  padding: 0;
  width: 217px;
`;

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  height: 38px;
`;

export const MenuListItemButton = styled(ListItemButton)`
  //a {
  //  width: 100%;
  //}
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};

  &.status {
    margin-right: 5px;
  }

  &.red {
    color: ${({ theme }) => theme.palette.custom.red.main};
  }
`;

export const MenuButton = styled('span')`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 20px;
    min-width: 20px;
    padding: 0;
    width: 20px;
    background: transparent;
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};

    &:hover {
      border-radius: 4px;
      color: ${({ theme }) => theme.palette.custom.green.main};
      background: ${({ theme }) => theme.palette.custom.green.light};
    }
  }
`

export const TooltipLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  &:hover {
    color: ${({ theme }) => theme.palette.custom.green.main};
  }
`;
