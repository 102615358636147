import React, { FC } from 'react';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
// Store
import { ICreateEmployeeForm, IEmployeeProducts, ICreateAdditionalCosts } from 'store/tutors/tutorsTypes';
import { IProductCreate } from 'interfaces/product';
// Icons
import { ArrowLeftIcon } from 'assets/icons';
// Styles
import { Container, PaginationButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface TutorFooterProps {
  handleSubmit: UseFormHandleSubmit<
    { employees: ICreateEmployeeForm[]; } |
    { products: IProductCreate[] } |
    { materialProducts: IProductCreate[] } |
    { employeeProduct: IEmployeeProducts[] } |
    { additionalCosts: ICreateAdditionalCosts[] }>;
  onNextStep: SubmitHandler<any>;
  onPrevStep: () => void;
  activeStep: number;
  disabled: boolean;
  step: number;
}

const TutorFooter: FC<TutorFooterProps> = ({
  activeStep, disabled, step, onNextStep, handleSubmit, onPrevStep,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <PaginationButton
        variant="textIcon"
        className={activeStep === step ? 'hidden' : ''}
        onClick={onPrevStep}
      >
        <ArrowLeftIcon /> {t('До попереднього кроку')}
      </PaginationButton>

      <PaginationButton
        onClick={handleSubmit(onNextStep)}
        disabled={disabled}
        variant="contained"
      >
        {step === 7 ? t('А ну, подивимось') : t('Наступний крок')}
      </PaginationButton>
    </Container>
  )
};

export default TutorFooter;
