import React from 'react';
// Styles
import { Container, Title, SubTitle } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const BankCardBody: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title variant="h6">
        {t('components.BankCard.BankCardBody.Номер картки')}    </Title>

      <SubTitle variant="h1">
        XXXX-XXXX-XXXX-0758
      </SubTitle>
    </Container>
  )
};

export default BankCardBody;
