/* eslint-disable react/no-unstable-nested-components */
import React, {forwardRef, useMemo, useState} from 'react';
import {
  SelectProps,
  MenuItem,
} from '@mui/material';
// Icons
import { SelectArrowIcon } from 'assets/icons';

import {
  Label,
  FormHelperText,
  ContainerItem,
  LabelItem,
  Container,
  Image,
  CheckboxLabel,
  StyledRadio,
  MaterialUISelect,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import countryList from 'react-select-country-list';
export interface SelectCountriesProps {
  errorMessage?: string;
  isSpaceBottom?: number;
  isCheckbox?: boolean;
  country?: string;
}

export interface IconProps {
  className: string;
}

const SelectCountries = forwardRef(({
  error,
  label,
  errorMessage,
  isSpaceBottom,
  isCheckbox,
  country,
  defaultValue,
  ...props
}: SelectCountriesProps & SelectProps, ref) => {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), [])

  return (
    <Container
      ref={ref as React.RefObject<HTMLDivElement>}
      isSpaceBottom={isSpaceBottom}
    >
      {label && <Label>{label}</Label>}

      <MaterialUISelect
        defaultValue={defaultValue}
        IconComponent={(iconProps) => (
          <SelectArrowIcon {...iconProps} />
        )}
        variant="outlined"
        displayEmpty
        fullWidth
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        error={error}
        {...props}
      >
        <MenuItem disabled value="">
          <Label>
            {t('components.UI.SelectCountries.Оберіть країну')}
          </Label>
        </MenuItem>

        {options.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            <ContainerItem>
              {/*<Image src={item.icon} alt={item.label} />*/}
              <LabelItem variant="regularText">
                {t(`${item.label}`)}
              </LabelItem>
            </ContainerItem>
          </MenuItem>
        ))}


        {isCheckbox && (
          <MenuItem value="noСountry">
            <ContainerItem>
              <CheckboxLabel
                checked={country === 'noСountry'}
                label={t('components.UI.SelectCountries.Моєї країни немає в списку')}
                control={<StyledRadio />}
              />
            </ContainerItem>
          </MenuItem>
        )}
      </MaterialUISelect>

      <FormHelperText isError>
        {errorMessage}
      </FormHelperText>
    </Container>
  )
});

export default SelectCountries;
