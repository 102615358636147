import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// Interface
import { IProject } from 'interfaces/project';
// Store
import { useDeleteProjectMutation } from 'store/projects/projectsServices';
import { setCurrency } from 'store/tutors/tutorsSlice';
import { useGetPipelineByIdQuery } from 'store/tutors/tutorsServices';
import { setProject, setPipelineID, setPipelineCount } from 'store/projects/projectSlice';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Components
import ProjectsCardHeader from './ProjectsCardHeader';
import ProjectsCardBody from './ProjectsCardBody';
import ProjectsCardFooter from './ProjectsCardFooter';
import ProjectDeleteModal from './ProjectDeleteModal';
// Styles
import { Container, Wrapper } from './styles';

interface ProjectsCardProps {
    project: IProject;
}

const ProjectsCard: React.FC<ProjectsCardProps> = ({ project }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { redirectAddToPipeliner } = useProjectRoutes();
  const dispatch = useDispatch();
  const [deleteProject] = useDeleteProjectMutation();

  const { data } = useGetPipelineByIdQuery({ project_id: project.project_id as string, pipeline_id: project?.main_pipeline_id as string }, {
    skip: !project?.main_pipeline_id,
  });

  const handlerModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setOpenDeleteModal((prev) => !prev);
  };

  const onCloseModal = () => setOpenDeleteModal(false);

  const deleteProjectHandler = async () => {
    const res = await deleteProject({ id: project.project_id });

    if (res) {
      onCloseModal();
    }
  };

  const redirectToPipeline = () => {
    dispatch(setPipelineID(project?.main_pipeline_id as string))
    dispatch(setPipelineCount(project?.pipelines_count))

    redirectAddToPipeliner(project.project_id);
    dispatch(setCurrency(project?.currency as string));
  };

  const setProjectHandler = () => {
    dispatch(setPipelineCount(project?.pipelines_count))
    dispatch(setProject({
      project_name: project.project_name,
      tax_system_type: project.tax_system_type,
      tax_type: project.tax_type,
      tax_group: project.tax_group,
      currency: project.currency,
      monthly_hour_limit: project.monthly_hour_limit,
      residence: project.residence
    }));
  };

  return (
    <>
      <Container
        onClick={redirectToPipeline}
      >
        <Wrapper>
          <ProjectsCardHeader
            handlerModal={handlerModal}
            id={project.project_id}
            name={project.project_name || 'IM'}
            setProjectHandler={setProjectHandler}
          />

          <ProjectsCardBody
            name={project.project_name || 'IM'}
            countPipeline={project.pipelines_count}
            countProductPipeline={data?.products?.length || 0}
            countEmployees={data?.employees?.length || 0}
            pipelineName={data?.pipeline_name || 0}
          />
        </Wrapper>
        <ProjectsCardFooter id={project.project_id} />
      </Container>

      <ProjectDeleteModal
        deleteProjectHandler={deleteProjectHandler}
        onClose={onCloseModal}
        isOpen={openDeleteModal}
      />
    </>
  );
};

export default ProjectsCard;
