import React, { FC } from 'react';
import { Typography } from '@mui/material';
import {
  useFieldArray, Control, FieldErrors, useWatch,
} from 'react-hook-form';
// Store
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';
// Interface
import { IProductCreate, IMaterialCreate } from 'interfaces/product';
// Components
import MaterialsFormRow from '../MaterialsFormRow';
// Styles
import {
  Form, FormFooter, LinkButton, TotalCost,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface MaterialItemProps {
  updateItem: (productIndex: number, id?: string) => void;
  errors: FieldErrors<{ materialProducts: IProductCreate[] }>
  control: Control<{ materialProducts: IProductCreate[] }>,
  product: IProductCreate;
  indexProduct: number;
  currency: string;
}

const MaterialItem: FC<MaterialItemProps> = ({
  product, control, indexProduct, errors, updateItem, currency,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `materialProducts.${indexProduct}.expenses.Materials`,
  });

  const updateProductMaterial = (id?: string) => {
    updateItem(indexProduct, id)
  }

  const watch = useWatch({ control });

  const [deleteExpenses] = useDeleteExpensesMutation();

  const deleteItem = async (id: number, material_id?: string) => {
    if (material_id) {
      remove(id);
      await deleteExpenses(material_id).unwrap();

      return;
    }

    remove(id);
  };

  const addedMAterial = () => {
    append({
      amount: 0,
      cost: 0,
      name: '',
      value: '',
      product_id: '',
      material_id: '',
      group: {
        parent_id: null,
        name: 'Materials',
      },
      extras: {
        dimension: '',
      },
    });
  };

  const caclTotalValueMaterials = () => {
    const productsTotal = watch?.materialProducts?.[indexProduct]?.expenses?.Materials as IMaterialCreate[];
    const totalValue = productsTotal?.reduce((acc: number, item: IMaterialCreate) => {
      const cost = item?.value || '0';
      const per_product = item?.amount || '0';
      acc += (+cost * +per_product);

      return +acc;
    }, 0);

    return totalValue?.toFixed(2) || 0;
  };

  return (
    <Form>
      <Typography variant="h1">
        {product?.name}
      </Typography>

      {fields.map((material, index) => (
        <MaterialsFormRow
          updateItem={updateProductMaterial}
          deleteItem={deleteItem}
          errors={errors}
          key={material.id}
          indexMaterial={index}
          indexProduct={indexProduct}
          material={material}
          control={control}
        />
      ))}

      <FormFooter>
        <LinkButton
          onClick={addedMAterial}
          variant="link"
        >
          {t('Додати ще один')}
        </LinkButton>

        <TotalCost>
          <Typography variant="regularText">
            {t('Вартість всіх матеріалів')}:
          </Typography>

          <Typography className="total-cost-value" variant="h1">
            {caclTotalValueMaterials()} {currency}
          </Typography>
        </TotalCost>
      </FormFooter>
    </Form>
  );
};

export default MaterialItem;
