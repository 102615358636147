import { styled } from '@mui/material/styles';
import { Typography, Box, Button as StyledButton } from '@mui/material';
import Input from 'components/UI/Input';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 8px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.silver};
  width: 100%;
  margin-bottom: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette?.primary?.main};
  margin-bottom: 30px;
`;

export const Button = styled(StyledButton)`
  width: fit-content;
  height: 48px;
  margin-right: 16px;
`;

export const StyledInput = styled(Input)`
  .MuiFormHelperText-root {
    display: none;
  }
`;
