import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { IEmployee } from 'interfaces/employee';
// Utils
import { PRODUCT_DEFAULT_VALUES, EMPLOYEES_DEFAULT_VALUES, ADDITIONAL_VALUES } from 'utils/helpers/tutor';
import {
  updateEmployees, updateProducts, employeesSelect, updateCreateProducts, updateAdditionalLosses, updateCreateMaterialProducts,
} from 'utils/helpers/employyers';
import { tutorsServices } from './tutorsServices';
import { IRootStateTutor } from './tutorsTypes';

const initialState: IRootStateTutor = {
  currency: '$',
  project_id: '',
  pipeline_id: '',
  employees: EMPLOYEES_DEFAULT_VALUES,
  products: PRODUCT_DEFAULT_VALUES,
  materialProducts: PRODUCT_DEFAULT_VALUES,
  additionalValues: ADDITIONAL_VALUES,
  employeeProduct: [],
  employeesSelect: [],
  step1: {
    pipeline_name: '',
  },
};

export const tutorsSlice = createSlice({
  name: 'rootTutor',
  initialState,

  reducers: {
    setDefaultValueTutor: (state) => {
      state.employeeProduct = [];
      state.employeesSelect = [];
      state.additionalValues = ADDITIONAL_VALUES;
      state.products = PRODUCT_DEFAULT_VALUES;
      state.materialProducts = PRODUCT_DEFAULT_VALUES;
      state.employees = EMPLOYEES_DEFAULT_VALUES;
      state.step1.pipeline_name = '';
      state.pipeline_id = '';
    },
    setStepOne: (state, action: PayloadAction<string>) => {
      state.step1.pipeline_name = action.payload;
    },
    setDefaultValuePipeline: (state, action: PayloadAction<string>) => {
      state.project_id = action.payload;
      state.step1.pipeline_name = '';
      state.pipeline_id = '';
      state.employees = EMPLOYEES_DEFAULT_VALUES;
      state.additionalValues = ADDITIONAL_VALUES;
      state.products = PRODUCT_DEFAULT_VALUES;
      state.materialProducts = PRODUCT_DEFAULT_VALUES;
      state.employeeProduct = [];
      state.employeesSelect = [];
    },
    setPipelineId: (state, action: PayloadAction<{ project_id: string, pipeline_id?: string }>) => {
      state.project_id = action.payload.project_id;
      state.pipeline_id = action.payload?.pipeline_id;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      tutorsServices.endpoints.createTutor.matchFulfilled,
      (state, { payload }) => {
        state.pipeline_id = payload.pipeline_id;
        state.project_id = payload.project_id;
        state.step1.pipeline_name = payload.pipeline_name;
      },
    );
    builder.addMatcher(
      tutorsServices.endpoints.getPipelineById.matchFulfilled,
      (state, { payload }) => {
        state.step1.pipeline_name = payload.pipeline_name;

        if (payload?.employees?.length) {
          state.employees = updateEmployees(payload?.employees);
          state.employeesSelect = employeesSelect(payload?.employees);
        }

        if (payload?.products?.length) {
          state.employeeProduct = updateProducts(payload?.products);
          state.products = updateCreateProducts(payload?.products);
          state.materialProducts = updateCreateMaterialProducts(payload?.products);
        }

        if (payload?.additional_losses?.expenses?.length) {
          state.additionalValues = updateAdditionalLosses(payload?.additional_losses?.expenses);
        }
      },
    );
  },
});

export const {
  setStepOne, setPipelineId, setCurrency, setDefaultValuePipeline, setDefaultValueTutor,
} = tutorsSlice.actions;

export default tutorsSlice.reducer;

export const selectTutor = (state: RootState) => state.rootTutor;
