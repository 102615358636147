import createPalette from '@mui/material/styles/createPalette';

type Custom = {
  primary: {
    black: string;
    transparent: string;
    silver: string;
  },
  grey: {
    main: string;
    light: string;
    dark: string;
    background: string;
    lightIco: string;
    darkLite: string;
    backgroundInput: string;
    menuHover: string;
    blueGrey: string;
    toggleBackground: string;
  },
  green: {
    main: string;
    chlorine: string;
    dark: string;
    light: string;
    mainLight: string;
    checkboxButton: string;
    chartGreen: string;
    chartGreenText: string;
  },
  red: {
    main: string;
    light: string;
    dark: string;
    silver: string;
    menuHover: string;
  },
  blue: {
    main: string;
    azure: string;
  },
  yellow: {
    main: string;
  },
  black: {
    main: string;
    light: string;
    silver: string;
  }
  silver: {
    main: string;
  },
  dark: {
    main: string;
  }
};

declare module '@mui/material/styles' {
  interface Palette {
    custom: Custom;
  }
  interface PaletteOptions {
    custom: Custom;
  }
}

export const palette = createPalette({
  primary: {
    main: '#EBEBEB',
    dark: '#292929',
    light: '#DDDDDD',
    contrastText: '#ffffff',
  },
  // success: {
  //   main: "#47B347",
  //   dark: "#5DDE3C",
  //   light: "#E8F8E4",
  //   contrastText: "#ffffff",
  // },
  // warning: {
  //   main: "#FF2E00",
  //   light: "#FFF1EE",
  //   dark: "#FF9A03",
  //   contrastText: "#ffffff",
  // },
  // info: {
  //   main: "#5783F1",
  //   light: "#69DAF2",
  //   dark: "#29A3CC",
  //   contrastText: "#ffffff",
  // },
  // error: {
  //   main: "#FF6B4A",
  // },
  custom: {
    primary: {
      black: '#DDDDDD',
      transparent: 'transparent',
      silver: '#838383',
    },
    silver: {
      main: '#FAFAFA',
    },
    grey: {
      main: '#747474',
      light: '#D7D7D7',
      dark: '#292929',
      background: '#F6F6F6',
      lightIco: '#A4A4A4',
      darkLite: '#F8F9FD',
      backgroundInput: '#FCFCFC',
      menuHover: '#FAFAFA',
      blueGrey: '#E5E5E5',
      toggleBackground: '#F6F6F6',
    },
    red: {
      main: '#F15353',
      light: '#FE8C8C',
      dark: '#D52727',
      silver: '#F77171',
      menuHover: '#FFE5E5',
    },
    blue: {
      main: '#39C7F4',
      azure: '#845AF7',
    },
    yellow: {
      main: '#FFC06A',
    },
    black: {
      main: '#292929',
      light: '#1F1F1F',
      silver: '#838383',
    },
    green: {
      main: '#07B169',
      chlorine: '#1BC57D',
      dark: '#009D55',
      light: '#EBF7F2',
      mainLight: 'rgba(7, 177, 105, 0.06)',
      checkboxButton: '#009D55',
      chartGreen: '#28B05F',
      chartGreenText: '#2CB261',
    },
    dark: {
      main: '#333333',
    },
  },
});
