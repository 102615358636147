export const addEmployee = (type: string) => ({
  amount_type: 'fixed',
  type,
  department: '',
  name: '',
  wage: 0,
  id: '',
  employee_id: '',
});

export const EMPLOYEES_DEFAULT_VALUES = [
  {
    name: '', department: '', type: 'partner', wage: 0, amount_type: 'fixed', id: 'partner', employee_id: '',
  },
  {
    name: '', department: '', type: 'contractor', wage: 0, amount_type: 'fixed', id: 'contractor', employee_id: '',
  },
  {
    name: '', department: '', type: 'administration', wage: 0, amount_type: 'fixed', id: 'administration', employee_id: '',
  },
];

export const PRODUCT_DEFAULT_VALUES = [
  {
    name: '', group: 'product', amount: 0, price: 0, id: '1', pipeline_id: '', extras: { type: '' },
  },
];

export const addProduct = {
  name: '', group: 'product', amount: 0, price: 0, pipeline_id: '', extras: { type: '' },
};

export const ADDITIONAL_VALUES = [
  {
    name: '',
    value: '',
    amount: 1,
    category: '',
    additional_id: '',
  }
]
