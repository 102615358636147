import { ComponentsOverrides, Theme } from '@mui/material';

interface muiToggleButtonGroupProps {
  styleOverrides: ComponentsOverrides['MuiToggleButtonGroup'];
}

export const muiToggleButtonGroup = (theme: Theme): muiToggleButtonGroupProps => ({
  styleOverrides: {
    root: {
      height: '48px',
      padding: '4px',
      borderRadius: '8px',
      background: theme.palette.primary.contrastText,
    },
  },
});
