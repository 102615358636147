import React, { FC } from 'react';
import {
  Control, Controller, FieldErrors, UseFormTrigger, UseFieldArrayUpdate,
} from 'react-hook-form';
import { FormControl, ToggleButton } from '@mui/material';
// Store
import { ICreateEmployeeForm } from 'store/tutors/tutorsTypes';
// Components
import { CancelIcon } from 'assets/icons';
import CreatableSelect from 'components/UI/CreatableSelect';
import Input from 'components/UI/Input';
import { Label } from 'components/UI/Input/styles';
// Styles
import {
  Container, StyledToggleButtonGroup, DeleteBtn, WrapperDeleteBtn,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface EmployeeRowItemProps {
  updateItem: (id?: string) => void;
  index: number;
  type: string;
  label: string;
  deleteItem: (id: number, employee_id?: string) => void;
  employee: ICreateEmployeeForm;
  trigger: UseFormTrigger<{ employees: ICreateEmployeeForm[] }>
  update: UseFieldArrayUpdate<{ employees: ICreateEmployeeForm[] }>
  control: Control<{ employees: ICreateEmployeeForm[] }>,
  errors: FieldErrors<{ employees: ICreateEmployeeForm[] }>
}

const EmployeeRowItem: FC<EmployeeRowItemProps> = ({
  control, index, label, errors, deleteItem, update, trigger, employee, updateItem,
}) => {
  const deleteEmployee = () => {
    deleteItem(index, employee?.employee_id);
  };

  const updateEmployee = () => {
    updateItem(employee?.employee_id);
  };
  const { t } = useTranslation();
  return (
    <Container onBlur={updateEmployee}>
      <Controller
        render={({ field }) => (
          <Input
            label={label}
            {...field}
            placeholder={t('Введіть ПІБ')}
            className="name-input"
            error={!!errors?.employees?.[index]?.name?.message}
            helperText={errors?.employees?.[index]?.name?.message}
          />
        )}
        control={control}
        name={`employees.${index}.name`}
        rules={{ required: { value: true, message: t('Це поле є обовязковим') } }}
      />

      <FormControl className="select-form-control">
        <Label>
          {t('Підрозділ')}
        </Label>

        <CreatableSelect
          updateEmployee={updateEmployee}
          errors={errors}
          control={control}
          index={index}
          update={update}
          trigger={trigger}
        />
      </FormControl>

      <Controller
        render={({ field }) => (
          <Input
            label={t('Платня')}
            {...field}
            type="number"
            className="sum-input"
            placeholder="0"
            error={!!errors?.employees?.[index]?.wage?.message}
            helperText={errors?.employees?.[index]?.wage?.message}
          />
        )}
        control={control}
        name={`employees.${index}.wage`}
        rules={{
          min: { value: -1, message: t('Не менше -1') },
          required: { value: true, message: t('Це поле є обовязковим') },
        }}
      />

      <Controller
        render={({ field }) => (
          <StyledToggleButtonGroup
            {...field}
            disabled={!!employee?.employee_id}
            exclusive
          >
            <ToggleButton value="fixed">
              {t('Фіксована')}
            </ToggleButton>
            <ToggleButton value="hourly">
              {t('Погодинна')}
            </ToggleButton>
          </StyledToggleButtonGroup>
        )}
        control={control}
        name={`employees.${index}.amount_type`}
      />

      <WrapperDeleteBtn>
        <DeleteBtn
          variant="icon"
          onClick={deleteEmployee}
        >
          <CancelIcon />
        </DeleteBtn>
      </WrapperDeleteBtn>
    </Container>
  );
};

export default EmployeeRowItem;