import { styled } from '@mui/material/styles';
import { Box, TableCell, Table } from '@mui/material';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';

interface StyledInputProps {
  isBackground?: boolean;
}

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  border-radius: 8px;
  padding: 30px;
  height: fit-content;
  @media print {
    flex-grow: 1;
    padding: 15px;
  }
`;

export const ContainerInput = styled(Box)`
  display: flex;
  gap: 0 12px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
`;

export const StyledInput = styled(Input)<StyledInputProps>`
  max-width: 218px;

  .MuiOutlinedInput-root {
    background: ${({ isBackground }) => isBackground ? '#EBEBEB' : '#ffffff'} !important;
  }
`;

export const StyledSelect = styled(Select)<StyledInputProps>`
  max-width: 218px;

  .Mui-disabled {
    background: ${({ isBackground }) => isBackground ? '#EBEBEB' : '#ffffff'} !important;
  }
`;

export const Paper = styled(Box)`
  width: 100%;
`;

export const ItemRow = styled(TableCell)`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`;

export const StyledTable = styled(Table)`
  min-width: 678px;
  @media print {
    min-width: initial;
  }
`
