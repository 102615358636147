import React, { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
// Store
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
// Utils
import { calcSummary } from 'utils/calculates/summary-dashboard';
import { isMinusValue } from 'utils/helpers';
// Styles
import {
  Wrapper, Title, SubTitle, Label,
} from './styles';
// Translation
import { useTranslation } from 'react-i18next';

const SummaryList: FC = () => {
  const { pipeline, residence } = useSelector(selectDashboard);
  const { tax } = pipeline as IPipeline;
  const { t } = useTranslation();
  const summary = useMemo(() =>
    calcSummary(pipeline as IPipeline, tax, residence), [pipeline, tax, residence]);

  return (
    <>
      <Wrapper>
        <Title variant="h1">
          {t('Середні показники')}
        </Title>
      </Wrapper>

      {summary.map((item, index) => (
        <Wrapper
          padding={index + 1 !== summary?.length ? '15px 0' : '15px 0 0 0'}
          isBorder={index + 1 !== summary?.length}
          key={item?.kye + index}
        >
          <SubTitle variant="h6">
            {t(`${item?.name}`)}
          </SubTitle>

          <Label variant="h6" isMinusValue={isMinusValue(item?.money?.toString())} isRed={item.isRed}>
            {item?.money}
          </Label>
        </Wrapper>
      ))}
    </>
  );
};

export default SummaryList;
