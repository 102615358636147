import React from 'react';
// Icons
import { ArrowLeftIcon } from 'assets/icons';
// Styles
import {
  Container,
  Title,
  Wrapper,
  Label,
  Button,
} from './styles';

const Pagination: React.FC = () => (
  <Container>
    <Title variant="h2">
      Історія
    </Title>

    <Wrapper>
      <Label variant="h6">
        Сторінка  1 - 10
      </Label>

      <Button>
        <ArrowLeftIcon color="#07B169" />
      </Button>

      <Button>
        <ArrowLeftIcon color="#07B169" />
      </Button>
    </Wrapper>
  </Container>
);

export default Pagination;
