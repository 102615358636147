import { styled } from '@mui/material/styles';
import { Box, Button as StyledButton } from '@mui/material';

export const Container = styled(Box)`
  width: 48%;
`;

export const Button = styled(StyledButton)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;
