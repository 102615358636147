import { ISales } from 'store/pipeline/pipelineTypes';
import TeamTutorIcon from 'assets/icons/TeamTutorIcon.png';
import ProductTutorIcon from 'assets/icons/ProductTutorIcon.png';
import MaterialsTutorIcon from 'assets/icons/MaterialsTutorIcon.png';
import PackagingTutorIcon from 'assets/icons/PackagingTutorIcon.png';
import EmployeeTutorIcon from 'assets/icons/EmployeeTutorIcon.png';
import AdditionalTutorIcon from 'assets/icons/AdditionalTutorIcon.png'
import {userRoutesPath} from "../../routes/routePath";

export const stepsDescription = [
  {
    header: 'Почнемо з команди',
    description: `Для того, щоб в подальшому рахувати собівартість ваших продуктів / сервiсiв,
     буде добре знати, скільки людей з вами працюють і скільки грошец ви плануєте їм платити,
     якщо ви ще не думали про це, то саме час! І не хвилюйтеся, всі ці дані можна  буде змінити
     на будьякому етапі планування – зараз головне мати базовий варіант,
     який дозволить почати рахувати.`,
    warning: `Ми знаємо, що дуже часто важко на старті проекту без попереднього аналізу
     зрозуміти, яку платню вимагає той чи інший спеціаліст.
     Якщо у вас ще немає розуміння про обсяги платні, додайте одного працівника з базовим рейтом,
     за яким ми зможемо підрахувати базові цифри.`,
    icons: () => TeamTutorIcon,
    link: `${userRoutesPath.HELP}#team`
  },
  {
    header: 'Що ми робимо?',
    description: `Будь-який комерційний проєкт щось продає – продукт або сервіс, один або
      цілим списком. Щоб порахувати, чи добре реалізується проєкт, нам важливо знати які
      продукти або сервіси ви продаєте.`,
    warning: 'Вам потрібно ввести хоча б один продукт або сервіс.',
    icons: () => ProductTutorIcon,
    link: `${userRoutesPath.HELP}#product-service`,
  },
  {
    header: 'Витрати на виробництво',
    description: `Кожен продукт з чогось складається, і його складові, на жаль, все ще не роздають безплатно ;(
      Тому нам потрібна вартість і кількість прямих витрат на виробнитство – матеріалів, затрачених на кожен продукт.
      Не забувайте про дрібниці, або, принаймні, не забувайте про те, що до кожної таблиці можна повернутись потім,
      щоб щось додати або виправити.`,
    warning: `Для розрахунку собівартості вам потрібно додати хоча б один матеріал або іншу витрату, що йде на
      виробнитство одиниці товару або послуги.`,
      icons: () => MaterialsTutorIcon,
      link: `${userRoutesPath.HELP}#production-costs`,
  },

  {
    header: 'Витрати на упаковку і логистику одиницi',
    description: `А ще окрім матеріалів інколи вам знадобиться спакувати вироблені продукти та витратити певні гроші на доставку.`,
    warning: `Для розрахунку собівартості вам потрібно додати хоча б один матеріал або іншу витрату, що йде на
      виробнитство одиниці товару або послуги.`,
      icons: () => PackagingTutorIcon,
      link: `${userRoutesPath.HELP}#packaging-logistics-costs`,
  },

  {
    header: 'Людино-години',
    description: `Вкажіть тут, які саме співробітники витрачають свій робочий час на виготовлення продукту
      і скільки часу у них уходить на одну одиницю товару. Ми порахуємо вартість роботи згідно з
      таблицею заробітної плати`,
    warning: `Для розрахунку собівартості вам потрібно вказати скілки годин витрачає ви витрачаєте
      на виробнитство одного продукту або сервісу часу.`,
      icons: () => EmployeeTutorIcon,
      link: `${userRoutesPath.HELP}#man-hours`,
  },
  {
    header: 'Постійні витрати',
    description: `Окрім прямих витрат, що йдуть безпосередньо на виробництво у вас може працювати людина
     або підрозділ, що впряму не беруть участі у виготовленні продукту але забезпечують життєдіяльність
     компанії. Окрім того, кожного місяця вам очевидно доведеться сплачувати оренду, послуги та сервіси.
     Ну і насамкінець, можливо, вам потрібно докупити обладнання чи виробничі площі для запуску продукта.
     Все це варто записати тут.`,
    warning: `Окрім виготовлення продуктів та послуг, вам доведеться витратити час на виконання інших завдань.
     Для їх розрахунку вам потрібно вказати хоча б декільяка основних витрат.
     Наприклад оренда приміщення або послуги хостингу.`,
     icons: () => AdditionalTutorIcon,
     link: `${userRoutesPath.HELP}#fixed-expenses`,
  },
];

export const fieldsByStep = [
  {
    fields: ['employees'],
  },
  {
    fields: ['products'],
  },
  {
    fields: ['products'],
  },
];

export const EMPLOYEES_DEFAULT_VALUES = [
  {
    name: '', unit_id: '', type: 'partner', amount: '', amount_type: 'fixed',
  },
  {
    name: '', unit_id: '', type: 'contractor', amount: '', amount_type: 'fixed',
  },
  {
    name: '', unit_id: '', type: 'administration', amount: '', amount_type: 'fixed',
  },
];

export const packagingLogistics = [
  {
    name: '',
    price: 0,
  },
];

export const PRODUCT_DEFAULT_VALUES = [
  {
    name: '',
    type: 'product',
    materials: [
      {
        dimension: '',
        name: '',
        per_product: 0,
        price: 0,
      },
    ],
    packagingLogistics: [
      {
        name: '',
        price: 0,
      },
    ],
    manufacturingCostList: [
      {
        manufacturing_cost_id: '',
        name: '',
        dimension: '',
        product_id: '',
        per_product: '',
        price: 0,
      },
    ],
    EmployeeProductList: [
      {
        product_id: '',
        unit_id: '',
        employee: [],
      },
    ],
  },
];

export const manufacturingCostList = [
  {
    name: '',
    dimension: '',
    per_product: '',
    price: 0,
  },
];

export const addEmployeValue = (type: string) => ({
  unit_id: '',
  type,
  amount: 0,
  amount_type: 'fixed',
  name: '',
});

export const addProductValue = {
  type: 'product',
  name: '',
};

export const addedCostValue = {
  name: '',
  dimension: '',
  per_product: 0,
  price: 0,
};

export const addedAdditionalCostsValue = {
  name: '',
  category: '',
  frequency: '',
  amount: '',
};

export const SELECT_UNITS = [
  {
    value: 'Адміністрація',
    label: 'Адміністрація',
  },
  {
    value: 'Маркетинг',
    label: 'Маркетинг',
  },
  {
    value: 'Майстерня',
    label: 'Майстерня',
  },
  {
    value: 'Логістика',
    label: 'Логістика',
  },
  {
    value: 'R&D',
    label: 'R&D',
  },
  {
    value: 'Пiдрядники',
    label: 'Пiдрядники',
  },
];

export const APPEND_PRODUCT = {
  name: '',
  type: 'product',
};

export const calcPercent = (month_sales: number, percent: number) => {
  const sum = month_sales / 100 * percent;

  return Math.round(sum);
};

export const PLAN_MONTH = [
  {
    id: 'JAN',
    plan_sales: 100,
  },
  {
    id: 'FEB',
    plan_sales: 100,
  },
  {
    id: 'MAR',
    plan_sales: 100,
  },
  {
    id: 'APR',
    plan_sales: 100,
  },
  {
    id: 'MAY',
    plan_sales: 100,
  },
  {
    id: 'JUN',
    plan_sales: 100,
  },
  {
    id: 'JUL',
    plan_sales: 100,
  },
  {
    id: 'AUG',
    plan_sales: 100,
  },
  {
    id: 'SEP',
    plan_sales: 100,
  },
  {
    id: 'OCT',
    plan_sales: 100,
  },
  {
    id: 'NOV',
    plan_sales: 100,
  },
  {
    id: 'DEC',
    plan_sales: 100,
  },
];

export const salesPlanAllProducts = [
  {
    id: 'JAN',
    profit: 0,
  },
  {
    id: 'FEB',
    profit: 0,
  },
  {
    id: 'MAR',
    profit: 0,
  },
  {
    id: 'APR',
    profit: 0,
  },
  {
    id: 'MAY',
    profit: 0,
  },
  {
    id: 'JUN',
    profit: 0,
  },
  {
    id: 'JUL',
    profit: 0,
  },
  {
    id: 'AUG',
    profit: 0,
  },
  {
    id: 'SEP',
    profit: 0,
  },
  {
    id: 'OCT',
    profit: 0,
  },
  {
    id: 'NOV',
    profit: 0,
  },
  {
    id: 'DEC',
    profit: 0,
  },
];

export const calcPlanMonth = (month_sales: number, plans: ISales[]) => {
  const updatePlanMonth = PLAN_MONTH?.map((month, index) => ({
    ...month,
    sum_month_sales: calcPercent(month_sales, +plans?.[index]?.completion_percent),
    numberOfSales: month_sales,
    completion_percent: +plans?.[index]?.completion_percent,
    plan_sales: +plans?.[index]?.completion_percent,
  }));

  return updatePlanMonth;
};

export const AMOUNT_DATA_SELECT = [
  { value: 0, label: 'Oдноразово' },
  { value: 4, label: 'Раз на тиждень' },
  { value: 1, label: 'Раз на місяць' },
];

export const objMonts: any = {
  JAN: 0,
  FEB: 0,
  MAR: 0,
  APR: 0,
  MAY: 0,
  JUN: 0,
  JUL: 0,
  AUG: 0,
  SEP: 0,
  OCT: 0,
  NOV: 0,
  DEC: 0,
};
