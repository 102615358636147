/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { Box } from '@mui/material';
import Footer from 'components/Footer';
// Styles
import {
  Container, Wrapper, H1, Content, P, H2, SubTitle,
} from './styles';

const PublicOffer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <H1>{t('Публічна')} <Box> {t('Оферта')}</Box></H1>
        <SubTitle>
          {t('редакція від 01.09.2022')}
        </SubTitle>
        <Content>
          <P variant="h5">
            {t('Ця оферта є публічною пропозицією компанії PipelinerOnline, Inc. (далі – Виконавець) необмеженому колу осіб укласти договір про надання консультаційних та навчальних послуг «Фінансове моделювання» (далі – Договір) на таких умовах:')}
          </P>
          <H2 variant="h2">
            {t('Визначення термінів')}
          </H2>
          <P>
            {t('Клієнт – особа, яка шляхом акцепту оферти укладає Договір з Виконавцем і отримує послуги.')}
          </P>
          <P>
            {t("PipelinerOnline – сервіс який є програмним забезпеченням, результатом комп'ютерного програмування у вигляді онлайн-сервісу, який доступний у мережі Інтернет за посиланням https://app.pipeliner.online і використовується для моделювання фінансових моделей на руху грошових коштів суб'єктів малого та середнього підприємництва.")}
          </P>
          <P>
            {t('Послуги – консультаційні та навчальні послуги з організації і ведення фінансового обліку, що надаються Виконавцем за Договором.')}
          </P>
          <H2 variant="h2">
            {t("publicoffer.1")}
          </H2>
          <P>{t("publicoffer.1d1")}</P>
          <P>{t("publicoffer.1d2")}</P>
          <P>{t("publicoffer.1d3")}</P>
          <H2 variant="h2">{t("publicoffer.2")}</H2>
          <P>
            {t("publicoffer.2d1")}
          </P>
          <P>
            {t("publicoffer.2d2")}
          </P>
          <P>
            {t("publicoffer.2d3")}
          </P>
          <H2 variant="h2">
            {t("publicoffer.3")}
          </H2>
          <P>
            {t("publicoffer.3d1")}

          </P>
          <P>
            {t("publicoffer.3d2")}
          </P>
          <P>
            {t("publicoffer.3d3")}
          </P>
          <P>
            {t("publicoffer.3d4")}
          </P>
          <H2 variant="h2">{t("publicoffer.4")}</H2>
          <P>
            {t("publicoffer.4d1")}
          </P>
          <P>
            {t("publicoffer.4d2")}
          </P>
          <P>
            {t("publicoffer.4d3")}
          </P>
          <P>
            {t("publicoffer.4d4")}
          </P>
          <P>
            {t("publicoffer.4d5")}
          </P>
          <P>
            {t("publicoffer.4d6")}
          </P>
          <P>
            {t("publicoffer.4d7")}
          </P>
          <P>
            {t("publicoffer.4d8")}
          </P>
          <H2 variant="h2">{t("publicoffer.5")}</H2>
          <P>
            {t("publicoffer.5d1")}
          </P>
          <P>
            {t("publicoffer.5d2")}
          </P>
          <P>
            {t("publicoffer.5d3")}
          </P>
          <P>
            {t("publicoffer.5d4")}

          </P>
          <P>
            {t("publicoffer.5d5")}

          </P>
          <P>
            {t("publicoffer.5d6")}

          </P>
          <P>
            {t("publicoffer.5d7")}

          </P>
          <P>
            {t("publicoffer.5d8")}

          </P>
          <H2 variant="h2">
            {t("publicoffer.6")}

          </H2>
          <P>
            {t("publicoffer.6d1")}

          </P>
          <P>
            {t("publicoffer.6d2")}

          </P>
          <P>
            {t("publicoffer.6d3")}

          </P>
          <H2 variant="h2">{t("publicoffer.7")}</H2>
          <P>
            {t("publicoffer.7d1")}

          </P>
          <P>
            {t("publicoffer.7d2")}
          </P>
          <P>
            {t("publicoffer.7d3")}
          </P>
          <H2 variant="h2">{t("Реквізити виконавця")}</H2>
          <P>PipelinerOnline, Inc.</P>
          <P>EIN 61-2030800</P>
          <P>2093 PHILADELPHIA PIKE </P>
          <P>#9664</P>
          <P>CLAYMONT, DE 19703</P>
          <P>hello@pipeliner.online</P>
          <P>+13024809584</P>
        </Content>
      </Wrapper>
      <Footer isSpace />
    </Container>
  )
};

export default PublicOffer;
