import { Theme, ComponentsVariants } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    light: true;
    textIcon: true;
    icon: true;
    link: true;
  }
}

interface muiButtonProps {
  variants: ComponentsVariants['MuiButton'];
}

export const muiButton = (theme: Theme): muiButtonProps => ({
  variants: [
    {
      props: { variant: 'text' },
      style: {},
    },
    {
      props: { variant: 'contained' },
      style: {
        borderRadius: '8px',
        height: '60px',
        minWidth: '138px',
        padding: '18px 48px',
        fontWeight: 500,
        textTransform: 'none',
        lineHeight: '24px',
        background: theme.palette.custom.green.main,
        fontSize: '16px',
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',
        transition: '0.3s',
        '&:hover': {
          background: theme.palette.custom.green.chlorine,
          boxShadow: 'none',
        },
      },
    },
    {
      props: { variant: 'light' },
      style: {
        borderRadius: '8px',
        height: '60px',
        minWidth: '138px',
        padding: '18px 48px',
        fontWeight: 500,
        textTransform: 'none',
        lineHeight: '24px',
        background: theme.palette.custom.green.light,
        fontSize: '16px',
        color: theme.palette.custom.green.main,
        boxShadow: 'none',
        transition: '0.3s',
        '&:hover': {
          background: theme.palette.custom.green.main,
          color: theme.palette.primary.contrastText,
          boxShadow: 'none',
        },
      },
    },
    {
      props: { variant: 'textIcon' },
      style: {
        borderRadius: '8px',
        height: '48px',
        minWidth: '138px',
        padding: '13px 17px',
        fontWeight: 500,
        textTransform: 'none',
        lineHeight: '22px',
        background: theme.palette.custom.green.light,
        fontSize: '14px',
        color: theme.palette.custom.green.main,
        boxShadow: 'none',
        transition: '0.3s',
        '& .MuiButton-startIcon': {
          marginRight: '14px',
        },
        '&:hover': {
          background: theme.palette.custom.green.main,
          color: theme.palette.primary.contrastText,
          boxShadow: 'none',
        },
      },
    },
    {
      props: { variant: 'icon' },
      style: {
        borderRadius: '8px',
        height: '48px',
        minWidth: '48px',
        background: theme.palette.custom.green.light,
        color: theme.palette.custom.green.main,
        boxShadow: 'none',
        transition: '0.3s',
        '&:hover': {
          background: theme.palette.custom.green.main,
          boxShadow: 'none',
        },
        '&:hover svg path': {
          fill: theme.palette.primary.contrastText,
        },
        '&:focus': {
          background: theme.palette.custom.green.main,
          boxShadow: 'none',
        },
        '&:focus svg path': {
          fill: theme.palette.primary.contrastText,
        },
      },
    },
    {
      props: { variant: 'link' },
      style: {
        fontWeight: 500,
        textTransform: 'none',
        lineHeight: '24px',
        background: 'none',
        fontSize: '16px',
        textDecoration: 'underline',
        color: theme.palette.custom.green.main,
        boxShadow: 'none',
        transition: '0.3s',
        '&:hover': {
          background: 'none',
          color: theme.palette.custom.green.main,
          boxShadow: 'none',
          textDecoration: 'underline',
        },
      },
    },
  ],
});
