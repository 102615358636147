import React, {useEffect, useState} from 'react';
import { useForm, useWatch, Controller} from "react-hook-form";
import { useParams } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
// import DatePicker from "react-datepicker";
// Types
import { IAdditionalLosseResponse } from 'store/dashboard/dashboardTypes'
import {IPackaging, IMaterial, IProduct} from 'interfaces/product';
import { useCreateMaterialMutation, useUpdateAdditionalLossesMutation, useUpdateMaterialMutation } from 'store/tutors/tutorsServices';
import { useCreatePackagingMutation, useUpdatePackagingMutation } from 'store/tutors/tutorsServices';
import {
  IExpensesPackaging,
  ICreatePackagingRequest,
  IExpensesMaterial,
  ICreateMaterialRequest
} from 'store/tutors/tutorsTypes';
import { useCreateAdditionalLossesMutation } from 'store/tutors/tutorsServices';
// import { ICreateAdditionalCosts } from 'store/tutors/tutorsTypes';
import { getSelectFormat } from 'utils/helpers/getSelectFormat';
// Global components
import {
  CalendarIcon, CloseFactIcon,
} from 'assets/icons';
import Modal from 'components/UI/Modal';
// import {Container as InputContainer, Label} from 'components/UI/Input/styles';
import Select from 'components/UI/Select';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  Button,
  LinkButton,
  ContainerBody,
  DatePickerWrapper,
  IconWrap,
  StyledInput,
  InlineFormWrap
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import {AMOUNT_DATA_SELECT} from "utils/constants/tutor";
export interface ExpenseForm {
  name: string;
  type:  string;
  category?: string;
  value: number | string | null;
  amount?: number;
  dimension?: string;
  cost?: number | string | null;
  product?: number | string;
}

interface AddExpenseProps {
  onClose: () => void;
  expense?: IAdditionalLosseResponse | IPackaging | IMaterial | null;
  isOpen: boolean;
  products?: IProduct[],
}
const typeData = [
  {
    value: 'additionalLoss',
    label: 'Постійні витрати'
  },
  {
    value: 'material',
    label: 'Витрати на виробництво'
  },
  {
    value: 'package',
    label: 'Логістика та упаковка'
  }
]
const categoryData = [
  {
    value: 'Адміністративно-управліньскі',
    label: 'Адміністративно-управліньскі',
  },
  {
    value: 'Непрямі витрати на виробнитство',
    label: 'Непрямі витрати на виробнитство',
  },
  {
    value: 'Маркетинг та продаж',
    label: 'Маркетинг та продаж',
  },
  {
    value: 'Інвестиції',
    label: 'Інвестиції',
  },
];

const units = [
  { value: 'm', label: 'M' },
  { value: 'm2', label: 'M²' },
  { value: 'шт', label: 'ШТ' },
  { value: 'кг', label: 'КГ' },
];

const AddExpenseModal: React.FC<AddExpenseProps> = ({
  onClose,
  expense,
  isOpen,
  products = [],
}) => {
  const { t } = useTranslation();
  const { project_id, pipeline_id } = useParams();
  const [createAdditionalLosses] = useCreateAdditionalLossesMutation();
  const [create] = useCreateMaterialMutation();
  const [updateMaterial] = useUpdateMaterialMutation();
  const [createPackaging] = useCreatePackagingMutation();
  const [updatePackaging] = useUpdatePackagingMutation();
  const [updateAdditionalLosse] = useUpdateAdditionalLossesMutation();
  const {
    control,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm<ExpenseForm>({
    mode: "all",
    defaultValues: expense || {
      name: '',
      type: 'additionalLoss',
      category: 'Адміністративно-управліньскі',
      value: 0,
      amount: 1,
    }
  });

  const watched = useWatch({
    control,
  });
  const wCost = watched?.value || 0;
  const wAmount = watched?.amount || 0;

  const totalPrice = (+wCost * +wAmount)?.toFixed(2);

  //const [anableTargetField, setAnableTargetField] = useState(true);

  useEffect(() => {
    if(expense) {
      if (expense.extras && expense.extras.category) {
        // @ts-ignore
        setValue('type', 'additionalLoss');
        setValue('name', expense.name);
        setValue('category', expense.extras.category);
        setValue('value', expense.value);
        setValue('amount', 1);
      } else if (expense.extras && expense.extras.dimension) {
        // @ts-ignore
        setValue('type', 'material');
        setValue('name', expense.name);
        setValue('dimension', expense.extras.dimension);
        setValue('product', expense.product_id);
        setValue('value', expense.value);
        setValue('cost', expense.cost);
        setValue('amount', expense.amount);
      } else {
        // @ts-ignore
        setValue('type', 'package');
        setValue('name', expense.name);
        setValue('value', expense.value);
        setValue('product', expense.product_id);
      }
    }
  }, [expense]);

  const addAdditionalLosses = async () => {
    const {name, amount, value, category} = getValues();
    const creatableData = {
      name,
      value: value as string,
      amount: amount as number,
      extras: {
        category: category  as string,
        isDuration: amount === 0,
      },
      group: {
        parent_id: null,
        name: '',
      }
    };
    try {
      await createAdditionalLosses({
        pipeline_id: pipeline_id as string,
        project_id: project_id as string,
        body: [creatableData],
      }).unwrap();
    } catch(err) {
      console.log(err);
    }
  }

  const editAdditionalLosses = async () => {
    const {name, amount, value, category} = getValues();
    if (expense) {
      const editableData = {
        amount: amount as number || expense.amount as number,
        name: name || expense.name,
        value: value as string || expense.value as string,
        extras: {
          category: category as string || expense.extras.category,
        },
      };
      try {
        await updateAdditionalLosse({
          additional_loss_id: expense?.id as string,
          body: editableData,
        }).unwrap();
      } catch(err) {
        console.log(err);
      }
    }
  }

  const addMaterial = async () => {
    const {name, amount, value, dimension, product} = getValues();
    const creatableData = {
      name,
      value: value as string,
      amount: amount as number,
      extras: {
        dimension: dimension  as string,
      },
      group: {
        parent_id: null,
        name: 'Materials',
      }
    };
    try {
      const filteredProducts = products?.filter((item) => item?.id === product);

      if (filteredProducts?.length) {
        const mats = filteredProducts.map((item) => ({ product_id: item.id, expenses: [creatableData] }));
        const filteredMaterials = mats.filter((item) => item.expenses?.length);
        await create(filteredMaterials as ICreateMaterialRequest[]).unwrap();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const editMaterial = async () => {
    const {name, amount, value, dimension} = getValues();
    if (expense) {
      const editableData = {
        ...expense,
        amount: amount as number || expense.amount,
        name: name || expense.name,
        value: value || expense.value,
        extras: {
          dimension: dimension || expense.extras.dimension,
        },
      };
      try {
        await updateMaterial({
          material_id: expense?.id as string,
          body: editableData,
        }).unwrap();
      } catch(err) {
        console.log(err);
      }
    }
  }

  const addPackage = async () => {
    const {name, value, product} = getValues();
    const creatableData = {
      name,
      value: value as string,
      amount: 1,
      extras: {},
      group: {
        parent_id: null,
        name: 'Packaging',
      }
    };
    try {
      const filteredProducts = products?.filter((item) => item?.id === product);

      if (filteredProducts?.length) {
        const packs = filteredProducts.map((item) => ({ product_id: item.id, expenses: [creatableData] }));
        const filteredPackages = packs.filter((item) => item.expenses?.length);
        await createPackaging(filteredPackages as ICreatePackagingRequest[]).unwrap();
      }
    } catch (err) {
      console.error(err);
    }
  }
  const editPackage = async () => {
    const {name, value} = getValues();
    if (expense) {
      const editableData = {
        amount: 1,
        extras: {},
        name: name || expense.name,
        value: value as string || expense.value as string,
      };
      try {
        await updatePackaging({
          packaging_id: expense?.id,
          body: editableData,
        }).unwrap();
      } catch(err) {
        console.log(err);
      }
    }
  }
  const submit = async (id?: string) => {
    const { type } = getValues();
    if (type === 'additionalLoss') {
       if (!id) {
         await addAdditionalLosses();
       } else {
         await editAdditionalLosses();
       }
    }
    if (type === 'material') {
      if (!id) {
        await addMaterial();
      } else {
        await editMaterial();
      }
    }
    if (type === 'package') {
      if (!id) {
        await addPackage();
      } else {
        await editPackage()
      }
    }
    reset();
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {t('Додати витрату')}
        </Title>
        <ContainerBody>
          <Controller
            render={({ field }) => <StyledInput
              label={t("Назва")}
              {...field}
              className="inline-input time-input"
              fullWidth
            />}
            control={control}
            name="name"
            rules={{ required: t("Це поле є обовязковим") }}
          />
          <Controller
            render={({ field }) => <Select
              label={t("Тип витрати")}
              data={typeData}
              {...field}
              className="select"
              fullWidth
            />}
            control={control}
            name="type"
            rules={{ required: t("Це поле є обовязковим") }}
          />
          <InlineFormWrap>
            <Controller
              render={({ field }) => <Select
                label={t("Категорія")}
                {...field}
                data={categoryData}
                disabled={watched.type !== 'additionalLoss'}
                className="inline-input time-input select"
                fullWidth
              />}
              control={control}
              name="category"
              rules={{ required: t("Це поле є обовязковим") }}
            />
            <Select
              label={t("Підрозділ, чия витрата")}
              disabled
              data={[]}
              className="inline-input"
              fullWidth
            />
          </InlineFormWrap>
          {watched.type === 'package' || watched.type === 'material'
            ? (
                <Controller
                  render={({ field }) => <Select
                    {...field}
                    label={t("Ціль витрати, зв’язати з продуктом")}
                    data={getSelectFormat(products, 'id', 'name')}
                    disabled={Boolean(expense)}
                    className="select"
                    fullWidth
                  />}
                  control={control}
                  name="product"
                  rules={{ required: "Це поле є обовязковим" }}
                />
            )
            : ''}
          {/*<LinkButton variant="link" onClick={() => (setAnableTargetField(prev => !prev))}>*/}
          {/*  {anableTargetField ? t('Скасувати') : t('Додати ціль')}*/}
          {/*</LinkButton>*/}
          {
            watched.type === 'additionalLoss' && (
                <InlineFormWrap>
                  <Controller
                    render={({ field }) => <StyledInput
                      label={t("Сума")}
                      {...field}
                      className="inline-input time-input"
                      type="number"
                      fullWidth
                    />}
                    control={control}
                    name="value"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                  <Controller
                    render={({ field }) => <Select
                      label={t("Повторюваність")}
                      {...field}
                      disabled
                      data={AMOUNT_DATA_SELECT}
                      className="inline-input"
                      fullWidth
                    />}
                    control={control}
                    name="amount"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                </InlineFormWrap>
              )
          }
          {
            watched.type === 'material' && (
              <>
                <InlineFormWrap>
                  <Controller
                    render={({ field }) => <StyledInput
                      label={t("На 1 айтем")}
                      {...field}
                      className="inline-input time-input"
                      type="number"
                      fullWidth
                    />}
                    control={control}
                    name="amount"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                  <Controller
                    render={({ field }) => <Select
                      label={t("Одиниця виміру")}
                      {...field}
                      data={units}
                      className="inline-input"
                      fullWidth
                    />}
                    control={control}
                    name="dimension"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                </InlineFormWrap>
                <InlineFormWrap>
                  <Controller
                    render={({ field }) => <StyledInput
                      label={t("Ціна")}
                      {...field}
                      className="inline-input time-input"
                      type="number"
                      fullWidth
                    />}
                    control={control}
                    name="value"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                  <Controller
                    render={({ field }) => <StyledInput
                      isBackground
                      disabled
                      label={t("Загалом")}
                      {...field}
                      value={totalPrice}
                      className="inline-input"
                      fullWidth
                    />}
                    control={control}
                    name="cost"
                    rules={{ required: t("Це поле є обовязковим") }}
                  />
                </InlineFormWrap>
              </>
            )
          }
          {
            watched.type === 'package' && (
              <Controller
                render={({ field }) => <StyledInput
                  label={t("Ціна")}
                  {...field}
                  className="inline-input time-input"
                  fullWidth
                  type="number"
                />}
                control={control}
                name="value"
                rules={{ required: t("Це поле є обовязковим") }}
              />
            )
          }
          {
            /*
            <InlineFormWrap>
              <InputContainer className="inline-input">
                <Label>Дата початку</Label>
                <DatePickerWrapper>
                  <DatePicker
                    selected={startDate}
                    readOnly={true}
                    onChange={() => {}}></DatePicker>
                  <IconWrap>
                    <CalendarIcon />
                  </IconWrap>
                </DatePickerWrapper>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label={<Label>Не визначен</Label>} />
                </FormGroup>
              </InputContainer>
              <InputContainer className="inline-input">
                <Label>Кінцева дата</Label>
                <DatePickerWrapper>
                  <DatePicker
                    selected={endDate}
                    readOnly={true}
                    onChange={() => {}}></DatePicker>
                  <IconWrap>
                    <CalendarIcon />
                  </IconWrap>
                </DatePickerWrapper>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label={<Label>Не визначен</Label>} />
                </FormGroup>
              </InputContainer>
            </InlineFormWrap>
            */
          }
        </ContainerBody>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => submit(expense?.id)}
          >
            {expense ? t('Зберегти витрату') : t('Додати витрату')}
          </Button>

          <Button
            variant="light"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            {t('Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default AddExpenseModal;
