import { FC } from 'react';
import { TableRow, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { isMinusValue } from 'utils/helpers';
// Store
import { selectTutor } from 'store/tutors/tutorsSlice';
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import { IPipeline, IPlanSalesPipeline } from 'store/dashboard/dashboardTypes';
// Utils
import { calcProfitAndLoss } from 'utils/calculates/profit-and-loss-table';
import { month } from 'utils/constants/date';
// Components
import TransitionInput from 'components/UI/TransitionInput';
import { ItemRow } from './styles';

interface CompareTableRowProps {
  planSales: IPlanSalesPipeline[] | [];
  color: string;
  index: number;
  typeCompare: string;
  activeIndex: number,
}

const CompareTableRow: FC<CompareTableRowProps> = ({
  typeCompare,
  planSales,
  index,
  color,
  activeIndex,
}) => {
  const { comparePipelines, residence, pipeline } = useSelector(selectDashboard);
  const { tax } = pipeline as IPipeline;

  const profitData = calcProfitAndLoss(
    comparePipelines?.[activeIndex] as IPipeline,
    planSales as IPlanSalesPipeline[],
    tax,
    residence
  )?.[index]?.month;

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <ItemRow
        component="th"
        scope="row"
        className="first-td"
      >
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
          <Box sx={{ border: 'none', background: color || '#07B169', width: '4px', height: '68px', marginRight: '10px' }}></Box>

          {typeCompare}
        </Box>

      </ItemRow>

      {profitData?.map((item, index) => (
        <ItemRow key={`${item?.income} ${index}`} isMinusValue={isMinusValue(item?.income?.toString())}>
          {item?.income}
        </ItemRow>
      ))}
    </TableRow>
  )
}

export default CompareTableRow;
