import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

export const Label = styled('span')`
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;
