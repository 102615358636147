import React from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
// Global components
import Modal from 'components/UI/Modal';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  Button,
  SettingsList,
  SettingsListItem,
  ContainerHeader
} from './styles';

interface SettingsModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {t('Налаштувати таблицю')}
        </Title>
        <ContainerHeader>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label={t("Назва стовбця")} />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label={t("Сховати нульові сутності")} />
          </FormGroup>
        </ContainerHeader>
        <SettingsList>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Назва стовбця")} />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Сховати нульові сутності")} />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Назва стовбця")} />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Сховати нульові сутності")} />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Назва стовбця")} />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label={t("Сховати нульові сутності")} />
            </FormGroup>
          </SettingsListItem>
        </SettingsList>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => { }}
          >
            {t('Зберегти')}
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            {t('Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SettingsModal;
