import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const Container = styled(Box)`
  width: 100%;
  padding: 13px 20px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
`;

export const Link = styled(HashLink)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  &:hover {
    opacity: 0.5;
  }
`;
