import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { ProjectState, ICreateProject } from './projectsTypes';

const initialState: ProjectState = {
  project: null,
  main_pipeline_id: '',
  pipelines_count: 0,
};

export const projectSlice = createSlice({
  name: 'oneProject',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ICreateProject>) => {
      state.project = action.payload;
    },

    setPipelineID: (state, action: PayloadAction<string>) => {
      state.main_pipeline_id = action.payload;
    },

    setPipelineCount: (state, action: PayloadAction<number>) => {
      state.pipelines_count = action.payload;
    },

    deleteProject: (state, action: PayloadAction<null>) => {
      state.project = action.payload;
    },
  },
});

export const {
  setProject,
  deleteProject,
  setPipelineID,
  setPipelineCount,
} = projectSlice.actions;

export default projectSlice.reducer;

export const selectProject = (state: RootState) => state.oneProject;
