import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {IPackagingCreate, IProductCreate} from "interfaces/product";
import {
  useCreatePackagingMutation,useUpdatePackagingMutation
} from "store/tutors/tutorsServices";
import {ICreatePackagingRequest, IExpensesPackaging} from "store/tutors/tutorsTypes";
import {selectTutor} from "store/tutors/tutorsSlice";

import PackagingItem from "./PackagingItem"

import {
  FormContainer,
  FormContainerFooter,
  FormRow,
  FormWrap,
  LinkButton,
  LinkWrapper,
  HeaderSection
} from "../styles";

interface PackagingSectionProps {
}

const PackagingSection: React.FC<PackagingSectionProps> = ({}) => {
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [hideContent, setHideContent] = useState(false);
  const { t } = useTranslation();
  const { project_id, materialProducts, products } = useSelector(selectTutor);
  const [createPackaging] = useCreatePackagingMutation();
  const [updatePackaging] = useUpdatePackagingMutation();
  const {product_id} = useParams<string>();
  const {
    setValue,
    control,
    formState,
    reset,
    getValues,
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues: { materialProducts },
  });

  const { fields } = useFieldArray({
    control: control,
    name: 'materialProducts',
  });

  const submitNewItem = async (materialProducts: IProductCreate[]) => {
    try {
      const filteredProducts = materialProducts.filter((item) => item?.pipeline_id);

      if (filteredProducts?.length) {
        const mats = filteredProducts.map((item) => {
          const packaging: IExpensesPackaging[] = [];

          item.expenses?.Packaging?.forEach((expens: IPackagingCreate) => {
            if (!expens.packaging_id) {
              packaging.push({
                extras: {},
                name: expens.name,
                value: expens.value,
                group: expens.group,
                amount: 1,
              });
            }
          });

          return { product_id: item.pipeline_id, expenses: packaging };
        });
        const filteredMaterials = mats.filter((item) => item.expenses?.length);
        if (filteredMaterials.length) {
          await createPackaging(filteredMaterials as ICreatePackagingRequest[]).unwrap();
          setIsSubmitDisable(true)
          reset();
        } else {
          // redirectToTutorStep(project_id, 6);
        }

        setValue('materialProducts', materialProducts);
        // redirectToTutorStep(project_id, 6);

        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateItem = async (productIndex: number, id?: string) => {
    const { materialProducts } = getValues();

    if (formState.isValid) {
      submitNewItem(materialProducts);
    }

    if (!id) return;

    const packaging = materialProducts?.[productIndex]?.expenses?.Packaging?.find((item) => item?.packaging_id === id);

    if (packaging?.packaging_id) {
      const updateDataMaterial = {
        amount: 1,
        name: packaging.name,
        value: packaging.value,
        extras: {},
      };

      await updatePackaging({
        body: updateDataMaterial,
        packaging_id: id,
      }).unwrap();
    }
  };

  const triggerValidate = () => {
    trigger();
  }

  useEffect(() => {
    // @ts-ignore
    const findService: IProductCreate = products.find(product => product.id === product_id)
    if (findService?.group === 'service') {
      setHideContent(true)
      return;
    }
    // @ts-ignore
    const findMaterialProduct: IProductCreate = materialProducts.find(material => material.pipeline_id === product_id)
    setValue('materialProducts', [findMaterialProduct]);
  }, [materialProducts]);

  if (hideContent) {
    return null;
  }
  return (
    <>
      <HeaderSection>
        <Typography variant="h2">{t('pages.AddProduct.Витрати на упаковку і логистику одиницi')}</Typography>
      </HeaderSection>
      <FormWrap>
        <FormContainer>
          {fields.map((product, index) => (
            <PackagingItem
              triggerValidate={triggerValidate}
              currency={''}
              updateItem={updateItem}
              errors={formState.errors}
              control={control}
              key={product.id}
              product={product}
              indexProduct={index}
            />
          ))}
        </FormContainer>
      </FormWrap>
    </>
  )
};

export default PackagingSection;
