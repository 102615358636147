import React, { FC } from 'react';
// Styles
import {
  Container, Label, Wrapper, Value,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface ChartTooltipProps {
  active: any;
  payload: any;
  label: any;
  currency: string;
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  active, payload, label, currency,
}) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Container>
        <Wrapper>
          <Label>
            {t('Загальний дохiд')}
          </Label>

          <Value color="#2CB261">
            {currency}{payload?.[0]?.value}
          </Value>
        </Wrapper>

        <Wrapper>
          <Label>
            {t('Загальні витрати')}
          </Label>

          <Value color="#D52727">
            {currency}{payload?.[1]?.value}
          </Value>
        </Wrapper>
      </Container>
    );
  }

  return null;
};

export default ChartTooltip;
