import { styled } from '@mui/material/styles';
import { Typography, Box, Button as StyledButton } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  margin-bottom: 8px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.silver};
  width: 100%;
  max-width: 102px;
  margin-right: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: fit-content;

  button:nth-of-type(2) {
    margin-left: 10px;
    transform: rotate(180deg);
  }
`;

export const Button = styled(StyledButton)`
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  background-color: #EBF7F2;
  border-radius: 8px;
`;
