import { FC } from 'react';
import TableHead from '@mui/material/TableHead';
// Styles
import { Title, StyledTableRow } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
const monthMap = {
  '1': 'Січень',
  '2': 'Лютий',
  '3': 'Березень',
  '4': 'Квітень',
  '5': 'Травень',
  '6': 'Червень',
  '7': 'Липень',
  '8': 'Серпень',
  '9': 'Вересень',
  '10': 'Жовтень',
  '11': 'Листопад',
  '12': 'Грудень',
}

interface TableHeaderProps {
  monthes?: number[]
}
const TableHeader: FC<TableHeaderProps> = ({ monthes }) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <StyledTableRow>
        <Title className='first-th' align="left">
          {t('Назва')}
        </Title>
        <Title align="left">
          {t('Од. виміру')}
        </Title>
        {monthes?.map((month) => (
          // @ts-ignore
          <Title key={month} className='bordered' align="left">
            {
              // @ts-ignore
              t(monthMap[month]) || ''
            }
          </Title>
        ))}
      </StyledTableRow>
    </TableHead>
  )
};

export default TableHeader;

