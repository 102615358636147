import { FC, useState } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import {IPlanSales} from "store/dashboard/dashboardTypes";
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

// Styles
import { Title, StyledTableRow, TitleColapce } from './styles';
interface DataByMonthProps {
  name: number,
  profit: number
}
interface RowTableProps {
  productName: string,
  dataByMonth?: DataByMonthProps[] | undefined,
  currency?: string,
  isBold?: boolean
}

const RowTable: FC<RowTableProps> = ({
 productName,
 dataByMonth,
 currency,
 isBold
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <StyledTableRow
        onClick={() => setOpen(!open)}
        sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        <Title component="th" className="product-title" scope="row" isBold={isBold}>
          {productName}
        </Title>
        <Title component="th" align='left' className="product-title" scope="row" isBold={isBold}>
          {t('грн')+` (${currency})`}
        </Title>

        {dataByMonth?.map(({name, profit}) => (
          <Title key={name} align='left' isBold={isBold}>
            {profit}
          </Title>
        ))}
      </StyledTableRow>
      {
        /*
         <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={13}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ border: 'none', paddingTop: '15px', paddingLeft: '30px', background: '#FAFAFA' }}>
                <Box sx={{ borderLeft: '1px solid #07B169', paddingLeft: '20px'}}>
                  <TableRow sx={{ border: 'none' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Rozetka
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Prom.ua
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Google
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Yahoo
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Website
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Instagram
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Facebook
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: 'none', background: '#FAFAFA' }}>
                    <TableCell style={{ padding: 0 }} colSpan={14} sx={{ border: 'none' }}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <TitleColapce component="th" className="source-title" scope="row">
                          Etsy
                        </TitleColapce>

                        {titleRow.map((title) => (
                          <Title key={title} align='left'>
                            {title}
                          </Title>
                        ))}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        */
      }
    </>
  )
}

export default RowTable;
