import React from 'react';
import {useForm, useWatch, useFieldArray} from "react-hook-form";
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
// Global components
import Modal from 'components/UI/Modal';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import FormRow from './FormRow';
import {
  Container,
  Title,
  ContainerButton,
  Button,
  LinkButton,
  ContainerHeader,
  FormRowWrap,
  ContainerBody
} from './styles';

interface Source {
  expenseName: string;
  investmentSize: number;
  startDate: string;
  endDate: string;
}

export interface SourcesForm {
  sources: Source[]
}

interface PagesSettingProps {
  onClose: () => void;
  isOpen: boolean;
  id?: string;
}

const SourceModal: React.FC<PagesSettingProps> = ({
   onClose,
   isOpen,
   id
 }) => {
  const {
    control,
    formState: { errors }
  } = useForm<SourcesForm>({
    mode: "all",
    defaultValues: {
      sources: [
        {
          expenseName: '',
          investmentSize: 0,
          startDate: '',
          endDate: ''
        },
        {
          expenseName: '',
          investmentSize: 0,
          startDate: '',
          endDate: ''
        }
      ]
    }
  });

  const watched = useWatch({
    control,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "sources"
  });

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      max-width="500"
    >
      <Container>
        <Title variant="h1">
          Доданi джерела інвестицій
        </Title>
        <ContainerHeader>
          <LinkButton variant="link">
            Помiтити усi
          </LinkButton>
        </ContainerHeader>
        <ContainerBody>
          {
            // @ts-ignore
            watched.sources.map((item, index) => (
              <FormRowWrap>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Джерело фiнансування #1" />
                </FormGroup>

                <FormRow
                  errors={errors}
                  control={control}
                  index={index}
                  update={update}
                  remove={remove}
                  watched={watched}
                />
              </FormRowWrap>
            ))
          }
        </ContainerBody>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => {}}
          >
            Зберегти зміни
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SourceModal;
