import React from 'react';
// Hooks
import { useAuth } from 'hooks/useAuth';
// Components
import UserTooltipHeader from '../UserTooltipHeader';
import UserTooltipBody from '../UserTooltipBody';
import UserTooltipFooter from '../UserTooltipFooter';
// Styles
import { Container } from './styles';

const UserTooltipContent: React.FC = () => {
  const { user } = useAuth();
  const isMembership = user?.membership_status === 'free';

  return (
    <Container>
      <UserTooltipHeader
        firstname={user?.firstname as string}
        lastname={user?.lastname as string}
        isMembership={isMembership}
      />

      <UserTooltipBody />

      <UserTooltipFooter />
    </Container>
  );
};

export default UserTooltipContent;
