import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const Header = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  margin-bottom: 6px;
`;

export const Subtext = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom.primary?.silver};
`;
