import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface IBlockProps {
  block: boolean;
}

export const Container = styled('div')`
  padding: 30px;
  width: 32%;
  max-width: 546px;
  min-height: 545px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const Box = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.custom?.green?.mainLight};
  height: 100%;

  svg {
    width: 26px;
    height: 26px;
  }
`;

export const Title = styled(Typography)<IBlockProps>`
  color: ${({ block, theme }) => block ? theme?.palette?.custom?.grey?.lightIco : theme?.palette?.custom?.green?.main};
  margin-top: 27px;
`;

export const IconWrap = styled('span')`
  svg {
    display: flex;
    margin: auto;
  }
`