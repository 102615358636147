import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend,
} from 'recharts';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { updateDataDiagramProduct, updateExpensesDiagram } from 'utils/calculates/diagram-income-expenses';
import ChartTooltipIncome from './ChartTooltipIncome';
import ChartTooltip from './ChartTooltip';
// Styles
import { Wrapper, Container, Title } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface StructureAnalysisSectionsProps {
  currency: string;
}

const StructureAnalysisSections: FC<StructureAnalysisSectionsProps> = ({ currency }) => {
  const { planSales, salesPlanTable, pipeline, residence } = useSelector(selectDashboard);

  const evenOrOdd = (index: number, isGreen?: boolean) => {
    if (isGreen) {
      return index % 2 === 0 ? "#07B169" : "#58C784"
    }

    return index % 2 === 0 ? "#D52727" : "#F77171"
  }

  const dataExpense = updateExpensesDiagram(salesPlanTable, pipeline as IPipeline, residence);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Title variant="h1">
          {t('Аналіз структури доходів')}
        </Title>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={updateDataDiagramProduct(planSales)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip wrapperStyle={{ outline: 'none' }} content={((props: any) => <ChartTooltip {...props} currency={currency} />)} />
            <Legend />

            {updateDataDiagramProduct(planSales)?.map((_: any, index: number) => (
              <Bar dataKey={index} stackId="a" fill={evenOrOdd(index, true)} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Container>

      <Container>
        <Title variant="h1">
          {t('Аналіз структури витрат')}
        </Title>

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={dataExpense}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip wrapperStyle={{ outline: 'none' }} content={((props: any) => <ChartTooltipIncome currency={currency} {...props} />)} />
            <Legend />

            <Bar dataKey="loss" stackId="a" fill="#FE8C8C" />
            <Bar dataKey="fixedCost" stackId="a" fill="#F77171" />
            <Bar dataKey="taxes" stackId="a" fill="#D52727" />
          </BarChart>
        </ResponsiveContainer>
      </Container>
    </Wrapper>
  )
};

export default StructureAnalysisSections;
