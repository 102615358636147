import React, { useState } from 'react';
import { useGetTariffsQuery } from 'store/payments/paymentsServicers';
// Components
import CancelSubscribeModal from './CancelSubscribeModal';
import { ITariff } from 'store/payments/paymentsTypes';
import TariffPlanCard from 'components/TariffPlanCard';
import ToggleButtons from './ToggleButtons';
// Styles
import { Section } from '../styles';
import { Container, Title } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface UserTariffPlansProps {
  isUnSubscribe: boolean;
  membershipStatus: string;
}

const UserTariffPlans: React.FC<UserTariffPlansProps> = ({ membershipStatus, isUnSubscribe }) => {
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const { t } = useTranslation();
  const [value, setValue] = useState('month');

  const typeSubscribe = value === 'month' ? t('міс.') : 'p';

  const { data } = useGetTariffsQuery({});

  const compareAge = (a: ITariff, b: ITariff) => {
    return a.amountCents - b.amountCents;
  }

  const filteredTariffs = () => {
    const tariffs = data?.filter((item) => item?.recurrency === value && item.amountCents !== 1500);
    if (tariffs?.length) {
      return tariffs?.sort(compareAge)
    }
    return [];
  };

  const onClose = () => setOpenSubscribe(prev => !prev);
  return (
    <Section isSpaceBottom={30} id="plans">
      <Title variant="h2">
        {t('pages.UserProfile.UserTariffPlans.Тарифні плани')}
      </Title>

      <ToggleButtons
        membershipStatus={membershipStatus}
        value={value}
        setValue={setValue}
      />

      <Container>
        {filteredTariffs()?.map((tariff) => (
          <TariffPlanCard
            isUnSubscribe={isUnSubscribe}
            onClose={onClose}
            typeSubscribe={typeSubscribe}
            tariff={tariff}
            key={tariff?.price_id}
            membershipStatus={membershipStatus}
          />
        ))}
      </Container>

      <CancelSubscribeModal onClose={onClose} open={openSubscribe} />
    </Section>
  );
};

export default UserTariffPlans;
