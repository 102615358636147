import { useNavigate } from 'react-router';

interface HookReturnType {
  redirectToTutorCreate: (id: string, step: string | number) => void;
  redirectToTutorEdit: (id: string, step: string | number) => void;
  redirectToTutor: (id: string) => void;
  redirectToTutorStep: (id: string, step: number) => void;
  redirectToComparePipeline: (project_id: string) => void;
}

export const useTutorRoutes = (): HookReturnType => {
  const navigate = useNavigate();

  const redirectToTutor = (id: string) => navigate(`/tutor/${id}`);
  const redirectToTutorCreate = (id: string, step: string | number) => navigate(`/tutor/steper/${id}/${step}`);
  const redirectToTutorEdit = (id: string, step: string | number) => navigate(`/tutor/steper/${id}/${step}/edit`);
  const redirectToTutorStep = (id: string, step: number) => navigate(`/tutor/${id}/${step}`);
  const redirectToComparePipeline = (project_id: string) => navigate(`/compare-pipelines/${project_id}`);

  return {
    redirectToTutor,
    redirectToTutorCreate,
    redirectToTutorEdit,
    redirectToTutorStep,
    redirectToComparePipeline,
  };
};
