import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { AuthState, IRequestHistory } from './authTypes';
import { authService } from './authService';

const initialState: AuthState = {
  token: null,
  user: null,
  requestHistory: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    setUserLogout: (state) => {
      state.token = null;
      state.user = null;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      authService.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.access_token;
      },
    );

    builder.addMatcher(
      authService.endpoints.verifyUser.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.access_token;
      },
    );

    builder.addMatcher(
      authService.endpoints.loginGoogle.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.accessToken;
      },
    );

    builder.addMatcher(
      authService.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        if (!payload.user_id) {
          state.token = null;
          state.user = null;

          return;
        }

        state.user = payload;
      },
    );
  },
});

export const { setUserLogout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth;
