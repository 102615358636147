import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import Input from 'components/UI/Input';

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledInput = styled(Input)`
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
`;

export const ContainerInputs = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Wrapper = styled(Box)`
  width: 48%;
  max-width: 100%;
`;

export const WrapperInput = styled(Box)`
  width: 100%;
`;
