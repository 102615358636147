import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// Store
import { selectPipeline } from 'store/pipeline/pipelineSlice';
import { IPipeline, IPlanSalesPipeline } from 'store/dashboard/dashboardTypes';
// Utils
import { calcProfitAndLoss } from 'utils/calculates/profit-and-loss-table';
// Components
import TableHeader from '../TableHeader';
import RowTable from './RowTable';
// Styles
import { StyledPaper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface ProfitAndLossTableProps {
  currency: string;
}

const ProfitAndLossTable: FC<ProfitAndLossTableProps> = ({ currency }) => {
  const { t } = useTranslation();
  const { pipeline, salesPlanTable, residence } = useSelector(selectDashboard);
  const { tax } = pipeline as IPipeline;

  const profitData = calcProfitAndLoss(
    pipeline as IPipeline,
    salesPlanTable as IPlanSalesPipeline[],
    tax,
    residence
  )

  return (
    <StyledPaper>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHeader />

          <TableBody>
            {profitData?.map((item: any, index) => (
              <RowTable
                currency={currency}
                key={item?.key + index}
                item={
                  {
                    name: t(item.name),
                    key: item.key,
                    month: item.month,
                    color: item.color,
                  }
                } index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledPaper>
  );
};

export default ProfitAndLossTable;
