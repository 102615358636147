import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
`;

export const LinkButton = styled(Button)`
  text-decoration: none;
  padding: 0;
  margin-top: 30px;
  margin-right: 15px;

  &:hover {
    text-decoration: none;
  }
`;
