import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 32%;
  max-width: 546px;
  min-height: 545px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  border-radius: 8px;
`;

export const IconWrapper = styled('div')`
  margin: auto;
  width: 100px;
  height: 130px;
  transition: all 0.5s ease;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const TextWrapper = styled('div')`
  width: 100%;
  max-width: 320px;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`;

export const Link = styled('span')`
  text-decoration: underline;
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #07B169;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const BottomContainer = styled('span')`
  display: flex;
  align-items: center;
`

export const SmallTooltipText = styled('span')`
  display: flex;
  padding-top: 10px;
  font-size: 12px;
`

export const PresentSmallIconWrap = styled('span')`
  display: flex;
  margin-left: auto;
`

