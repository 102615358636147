export interface IRowItem {
  name: string;
  cost: number;
  percent: string | number;
  color: string;
  key: number;
}

const calcPercent = (totalValue: number, value: number) =>{
  if (totalValue && value) {
    const percent = Math.round(value) * 100 / Math.round(totalValue)

    return percent?.toFixed(2);
  }

  return 0;
}

export const expensesData = (payload?: any[]) => {
  if (payload?.length) {
    const loss = payload?.[0]?.payload?.loss || 0;
    const fixedCost = payload?.[0]?.payload?.fixedCost || 0;
    const taxes = payload?.[0]?.payload?.taxes || 0;

    const total = payload?.[0]?.payload?.tatal;

    const dataRow: IRowItem[] | [] = [
      {
        name: 'Собівартість реалізованої продукції',
        cost: Math.round(loss),
        percent: calcPercent(total, loss),
        color: '#FE8C8C',
        key: 1,
      },
      {
        name: 'Постійні витртати',
        cost: Math.round(fixedCost),
        percent: calcPercent(total, fixedCost),
        color: '#F77171',
        key: 2,
      },
      {
        name: 'Податки та збори',
        cost: Math.round(taxes),
        percent: calcPercent(total, taxes),
        color: '#D52727',
        key: 4,
      }
    ]

    return {
      dataRow: dataRow,
      totalValue: Math.round(total),
    }
  }

  return {
    totalValue: 0,
    dataRow: [],
  }
}
