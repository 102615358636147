import React, { FC, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
// Store
import { ICreateLaborEmployee } from 'store/tutors/tutorsTypes';
// Components
import EditEmployeeModal from '../../EditEmployeeModal';
import EmployeeEditTooltip from '../EmployeeEditTooltip';
// Styles
import { Container } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface EmployeeProductTableBodyProps {
  updateItem: (index: number, value: ICreateLaborEmployee) => void;
  departmen: string;
  deleteEmployee: (index: number, id?: string) => void;
  indexEmployee: number;
  employee: ICreateLaborEmployee;
  handleCheck: (employee: ICreateLaborEmployee, evt: React.ChangeEvent<HTMLInputElement>) => void;
  selected: ICreateLaborEmployee[] | [];
}

const EmployeeProductTableBody: FC<EmployeeProductTableBodyProps> = ({
  indexEmployee, employee, deleteEmployee, departmen, updateItem, handleCheck, selected,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(prev => !prev);

  const removeEmployee = () => {
    deleteEmployee(indexEmployee, employee?.labor_id);
  };

  const updateEmployeeAmount = (amount: string) => {
    updateItem(indexEmployee, { ...employee, amount })
  }

  return (
    <Container>
      <EditEmployeeModal
        isOpen={open}
        onClose={onClose}
        departmen={departmen}
        name={employee.name}
        amount={employee.amount}
        labor_id={employee?.labor_id}
        updateEmployeeAmount={updateEmployeeAmount}
      />

      <ListItemIcon>
        <FormGroup>
          <FormControlLabel
            label={employee.name}
            control={(
              <Checkbox
                checked={!!selected.find(({ employee_id }) => (employee_id === employee.employee_id))}
                onChange={(e) => handleCheck(employee, e)}
              />
            )}
          />
        </FormGroup>
      </ListItemIcon>

      <ListItemText>
        {employee.amount} {t('годин')}
      </ListItemText>

      <EmployeeEditTooltip removeEmployee={removeEmployee} onEdit={onClose} />
    </Container>
  );
};

export default EmployeeProductTableBody;
