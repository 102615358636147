import React from 'react';
import { TableContainer, Table } from '@mui/material';
// Components
import TransactionTableHeader from './TransactionTableHeader';
import TableFilter from './TableFilter';
import TableBody from './TableBody';
// Styles
import { Container, Paper } from './styles';

const TransactionTable: React.FC = () => (
  <Container>
    {/*<TransactionTableHeader />*/}

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableFilter />

        <TableBody />
      </Table>
    </TableContainer>
  </Container>
);

export default TransactionTable;
