import React from 'react';
// Store
import { useCreateSubscribeMutation } from 'store/payments/paymentsServicers';
import { useGetInvoicesQuery } from 'store/auth/authService';
// Interface
import { ITariff } from 'store/payments/paymentsTypes';
// Utils
import { subscribeStatus } from 'utils/helpers/index';
import PayWallIcon from 'assets/icons/PayWallIcon.svg';
// Components
import Loading from 'components/UI/Loading';
import TariffPlanCardHeader from './TariffPlanCardHeader';
import TariffPlanCardBody from './TariffPlanCardBody';
import TariffPlanCardFooter from './TariffPlanCardFooter';
import dayjs from 'dayjs';
// Styles
import { Container, Image } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface TariffPlanCardProps {
  isUnSubscribe: boolean;
  onClose: () => void;
  tariff: ITariff;
  typeSubscribe: string;
  membershipStatus: string;
}

const TariffPlanCard: React.FC<TariffPlanCardProps> = ({ tariff, typeSubscribe, membershipStatus, isUnSubscribe, onClose }) => {
  const { t } = useTranslation();
  const isActive = subscribeStatus(tariff?.name) === membershipStatus;
  const isDisabled = 'Діскавері' !== tariff?.name

  const [createSubscribe, { isLoading }] = useCreateSubscribeMutation();
  const { data } = useGetInvoicesQuery({});

  const endDate = dayjs.unix(data?.[0]?.description?.[0]?.period?.end as number).format('DD.MM.YYYY')

  const onSubmit = async () => {
    const res = await createSubscribe({ price_id: tariff?.endpoint?.payload?.price_id }).unwrap();

    if (res?.url) {
      window.location.href = res?.url;
    }
  };


  const transferСurrency = () => {
    const currency = tariff?.amountCents / 100;

    return currency || 0;
  };

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container isSubscribe={isActive} isDisabled={isDisabled}>
      <Image src={PayWallIcon} isActive={isActive} />

      <TariffPlanCardHeader
        title={t(tariff?.name as string)}
        subTitle={tariff.description}
      />

      <TariffPlanCardBody
        isDisabled={isDisabled}
        countPipelines={tariff?.description as string}
      />

      <TariffPlanCardFooter
        isUnSubscribe={isUnSubscribe}
        onClose={onClose}
        isDisabled={isDisabled}
        onSubmit={onSubmit}
        price={transferСurrency()}
        typeSubscribe={typeSubscribe}
        isActive={isActive}
        dateString={endDate}
        name={tariff.name}
      />
    </Container>
  );
};

export default TariffPlanCard;
