import { styled } from '@mui/material/styles';
import { Button as StyledButton, ToggleButton } from '@mui/material';

interface ButtonProps {
  isCompare?: boolean;
}

export const PipelinerCardContainer = styled('div')`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
`;

export const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  margin-right: 33px;
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 30px;
  }
`;

export const ContainerButtonCreate = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  max-width: 546px;
  width: 32%;
  height: 800px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const ButtonCreate = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background: rgba(7, 177, 105, 0.06);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.custom.green.main};

  svg {
    margin-bottom: 25px;
  }
`;

export const WrapperButton = styled('div')`
  display: flex;
  width: fit-content;
`;

export const Button = styled(StyledButton)<ButtonProps>`
  margin-left: 15px;
  text-transform: inherit;
  width: 200px;
  display: ${({ isCompare }) => (isCompare ? 'flex' : 'none')};
  background: rgba(164, 164, 164, 0.1);
  color: ${({ theme }) => theme.palette.custom.primary.silver};
  line-height: 22px;
  align-items: center;
  &:hover {
    background: rgba(164, 164, 164, 0.5);
    color: ${({ theme }) => theme.palette.custom.primary.silver};
  }
`;

export const ButtonStopCompare = styled(StyledButton)<ButtonProps>`
  text-transform: inherit;
  display: ${({ isCompare }) => (isCompare ? 'flex' : 'none')};
  background: rgba(164, 164, 164, 0.5);
  color: ${({ theme }) => theme.palette.custom.primary.silver};
  line-height: 22px;
  align-items: center;
  margin-left: 15px;
  width: 180px;
  && {
    &:hover {
      background: rgba(164, 164, 164, 0.1);
      color: ${({ theme }) => theme.palette.custom.primary.silver};
    }
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  position: relative;

  svg {
    position: absolute;
    top: 0px;
    right: -2px;
  }
`;

export const CompareButton = styled(Button)`
  && {
    max-height: 60px;
    max-width: 60px;
    height: 60px;
    width: 60px;
    flex-basis: 100%;
  }
`;
