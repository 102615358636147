import React from 'react';
// Icons
import { ExitIcon } from 'assets/icons';
// Styles
import { Container, Button } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface SideBarFooterProps {
  logoutHandler: () => void;
  isOpen: boolean;
}

const SideBarFooter: React.FC<SideBarFooterProps> = ({ logoutHandler, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Button
        onClick={logoutHandler}
        open={isOpen}
      >
        <ExitIcon />

        {isOpen && t('components.SideBar.SideBarFooter.Вийти з акаунту')}
      </Button>
    </Container>
  )
};

export default SideBarFooter;
