import { type FC, type ReactNode, useEffect } from 'react';

import { useAuth } from './hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { identify, loadSegmentAnalytics, segmentAnalytics, trackPage } from './store/analytics/analyticsService';

interface AnalyticsProviderProps {
  children: ReactNode;
}

const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const analytics = useSelector(segmentAnalytics);

  useEffect(() => {
    if (analytics) {
      dispatch(trackPage());
    }
  }, [analytics]);

  useEffect(() => {
    const segmentKey = process.env.REACT_APP_SEGMENT_KEY;
    if (segmentKey) {
      dispatch(loadSegmentAnalytics(segmentKey));
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    dispatch(identify(user.email));
  }, [user]);

  return (
    <>
      {children}
    </>
  );
};

export default AnalyticsProvider;
