import React, { FC } from 'react';
// Icons
import { DotsIcon } from 'assets/icons';
// Styles
import {
  Container, Wrapper, Title, SubTitle,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const TitleTable: FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Title variant="h1">
          {t('Продукти')}
        </Title>
      </Wrapper>
    </Container>
  )
};

export default TitleTable;
