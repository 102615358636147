import React, { FC } from 'react';
// Styles
import { Container, Link } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { useLogout } from '../../../../hooks/useLogout';

const UserTooltipFooter: FC = () => {
  const { logoutHandler } = useLogout();
  const { t } = useTranslation();
  return (
    <Container>
      <Link variant="h6" onClick={logoutHandler}>
        {t("components.HeaderUserMenu.UserTooltip.UserTooltipFooter.Вийти з акаунту")}
      </Link>
    </Container>
  );
};

export default UserTooltipFooter;
