import { styled, css } from '@mui/material/styles';
import {
  Typography,
} from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('a')`
  max-height: 38px;
  height: 100%;
  display: block;
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};

  &.status {
    margin-right: 5px;
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};

`;
