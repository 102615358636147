import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 25px 30px 20px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette?.primary?.main};
  justify-content: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
`;
