import React from 'react';
// Icons
import { ArrowDownIcon, DotsIcon } from 'assets/icons';
// Styles
import { Container, Title, IconButton } from './styles';

const ServiceCard: React.FC = () => (
  <Container>
    <Title>
      Bitrix
    </Title>

    <ArrowDownIcon color="#07B169" />

    <IconButton>
      <DotsIcon />
    </IconButton>
  </Container>
);

export default ServiceCard;
