import { styled } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  width: fit-content;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;
`;

export const Container = styled(ToggleButtonGroup)`
  height: fit-content;
  background-color: #F6F6F6;
  width: fit-content;
`;

export const Button = styled(ToggleButton)`
  && {
    width: fit-content;
    height: 40px;
    padding: 10px 20px;
    min-height: 32px;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.primary.contrastText};
  }
`;

export const Label = styled('span')`
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
  margin-bottom: 10px;
`;
