import {useEffect} from "react";
import {useNavigate} from "react-router";

export const useHandleRequestError = (isError: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate('/error-page')
      return;
    }
  }, [isError])
}
