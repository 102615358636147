import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

interface LinkProps {
  isDisabled: boolean;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 235px;
  overflow: hidden;
  padding: 14px 30px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  margin-bottom: 5px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  margin-bottom: 5px;
`;

export const Link = styled(StyledLink)<LinkProps>`
  color: ${({ theme, isDisabled }) => isDisabled ? '#838383' : theme?.palette?.custom?.green?.main};
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: fit-content;
`;
