/* eslint-disable max-len */
import React, {FC, useCallback} from 'react';
import Tooltip from '@mui/material/Tooltip';
import { HashLink } from 'react-router-hash-link';
// Icons
import PayWallIcon from 'assets/icons/PayWallIcon.svg';
import ErrorPayWallIcon from 'assets/icons/ErrorPayWallIcon.svg';
// Components
import TooltipText from './TooltipText';
// Styles
import {
  Container, ContainerIcon, DateText, Image, Button,
} from './styles';
// Translation
import {Trans, useTranslation,} from 'react-i18next';
import {
  BottomContainer,
  PresentSmallIconWrap,
  SmallTooltipText,
  TextWrapper,
} from "../../../pages/Projects/ProjectsCard/ProjectCardBlocked/styles";
import {PresentSmallIcon} from "../../../assets/icons";
import { useAuth } from '../../../hooks/useAuth';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { useDispatch } from 'react-redux';
import { addEvent } from '../../../store/analytics/analyticsService';

interface UserMembershipTooltipProps {
  differenceDate: string;
  isEndFreePeriod: boolean;
  startDate: number;
}

const UserMembershipTooltip: FC<UserMembershipTooltipProps> = ({ isEndFreePeriod, startDate }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const addAnalyticsEvent = useCallback(() => {
    const eventPayload: SegmentEventPayload = {
      eventName: SEGMENT_EVENT_NAME.GO_TO_CHECKOUT,
      payload: { email: user?.email },
    };
    dispatch(addEvent(eventPayload));
  }, [dispatch, user]);

  return (
    <Container>
      <Tooltip title={
        <TextWrapper>
          <Trans i18nKey={'tooltipSubText'}>
            Вам доступний промо-код зі знижкою 50%! Для його застосування, при офорленні підписки Вам потрібно ввести
            <strong>PIPE50U</strong>.
          </Trans>
          <BottomContainer>
            <SmallTooltipText>
              {t('Ця знижка буде дійсна впродовж 6 місяців.')}
            </SmallTooltipText>
            <PresentSmallIconWrap>
              <PresentSmallIcon/>
            </PresentSmallIconWrap>
          </BottomContainer>
        </TextWrapper>
      }
               placement='bottom'
      >

        <ContainerIcon>
          <PresentSmallIcon/>
        </ContainerIcon>
      </Tooltip>
      <Tooltip title={<TooltipText />} placement="bottom">
        <ContainerIcon>
          <DateText isEndFreePeriod={isEndFreePeriod}>
            {startDate}
          </DateText>

          {isEndFreePeriod ? (
            <Image src={ErrorPayWallIcon} />
          ) : (
            <Image src={PayWallIcon} />
          )}
        </ContainerIcon>
      </Tooltip>

      <HashLink to='/profile#plans'>
        <Button onClick={addAnalyticsEvent} variant="contained">
          {t('Підписатися')}
        </Button>
      </HashLink>

    </Container>
  )
};

export default UserMembershipTooltip;
