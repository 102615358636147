/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import { HorizontalMenuIcon, ErrorInfoIcon } from 'assets/icons';
import Tooltip from 'components/UI/Tooltip';
import InlineEditor from './InlineEditor';
// Styles
import {
  Container,
  HeaderRow,
  Text,
  TextGreen,
  SubheaderRow,
  StatusRow,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  ContainerIcon,
  ErrorContainer,
  ErrorText,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface PipelinerHeaderProp {
  updateProjectHandler: () => void;
  redirectToEditPipeline: () => void;
  deleteHandler: () => void;
  isMainPipeline: boolean;
  isEmptyProductValue: boolean;
  status: number;
  title: string,
  pipeline_id: string;
  project_id: string;
}

const PipelineHeader: React.FC<PipelinerHeaderProp> = ({
  deleteHandler,
  isMainPipeline,
  status,
  title,
  pipeline_id,
  project_id,
  updateProjectHandler,
  isEmptyProductValue,
  redirectToEditPipeline,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <HeaderRow>
        <InlineEditor
          title={title}
          pipeline_id={pipeline_id}
          project_id={project_id}
          status={status}
        />

        <Tooltip
          arrow
          placement="bottom"
          tooltipText=""
          tooltipContent={(
            <MenuList disablePadding>
              {!isMainPipeline && (
                <MenuListItem disablePadding>
                  <MenuListItemButton onClick={updateProjectHandler}>
                    <Text variant="regularText" isGreen>
                      {t('Зробити основним')}
                    </Text>
                  </MenuListItemButton>
                </MenuListItem>
              )}
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={redirectToEditPipeline}>
                  <Text variant="regularText">
                    {t('Редагувати')}
                  </Text>
                </MenuListItemButton>
              </MenuListItem>
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={deleteHandler}>
                  <Text variant="regularText" isRed>
                    {t('Видалити')}
                  </Text>
                </MenuListItemButton>
              </MenuListItem>
            </MenuList>
          )}
        >
          <ContainerIcon>
            <HorizontalMenuIcon />
          </ContainerIcon>
        </Tooltip>
      </HeaderRow>
      <SubheaderRow>
        <StatusRow>
          <Text className="status">
            {t('Статус')}:
          </Text>

          {isMainPipeline ? <TextGreen>{t('Основний')}</TextGreen> : status ? (
            <TextGreen>{t('Зробити основним')}</TextGreen>
          ) : (
            <Text>{t('Чорновик')}</Text>
          )}
        </StatusRow>

        {!isEmptyProductValue && (
          <ErrorContainer>
            <ErrorInfoIcon />

            <ErrorText>
              {t('Для відображення розрахунків потрібно ввести на дашборді кількість продажів та вартість продуктів')}
            </ErrorText>
          </ErrorContainer>
        )}
      </SubheaderRow>
    </Container>
  )
};

export default PipelineHeader;
