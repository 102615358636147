import React from 'react';
// Styles
import { Container, Button } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const BankCardActionButtons: React.FC = () => {

  const { t } = useTranslation();
  return (
    <Container>
      <Button variant="text">
        {t('components.BankCard.BankCardActionButtons.Змінити спосіб оплати')}
      </Button>

      <Button variant="text">
        {t('components.BankCard.BankCardActionButtons.Додати резервний спосіб оплати')}      </Button>
    </Container>
  )
};

export default BankCardActionButtons;
