/* eslint-disable max-len */
import React from 'react';
import {useTranslation} from "react-i18next";
// Global components
import Modal from 'components/UI/Modal';
// Styles
import {
  Container,
  Title,
  SubTitle,
  ContainerButton,
  Button,
  WrapperButton,
} from './styles';

interface ProjectDeleteModalProps {
  deleteProjectHandler: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const ProjectDeleteModal: React.FC<ProjectDeleteModalProps> = ({ deleteProjectHandler, onClose, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {t('Видалення проекту')}
        </Title>

        <SubTitle variant="regularText">
          {t('Ви дійсно хочете видалити проект?')}
        </SubTitle>

        <ContainerButton>
          <WrapperButton htmlFor="scale-input">
            <Button
              isDelete
              onClick={deleteProjectHandler}
              variant="contained"
              component="span"
            >
              {t('Видалити')}
            </Button>
          </WrapperButton>

          <Button
            isDelete={false}
            onClick={onClose}
            variant="light"
          >
            {t('Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
}

export default ProjectDeleteModal;
