/* eslint-disable no-nested-ternary */
import { styled } from '@mui/material/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';

interface TextProps {
  isRed?: boolean;
  isGreen?: boolean;
}

export const Container = styled('div')`
  margin: -30px;
  max-height: 125px;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;

  && > * {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const ContainerIcon = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    background: #EBF7F2;
  }
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
`;

export const Text = styled(Typography)<TextProps>`
  color: ${({ theme, isRed, isGreen }) => (isRed ? 'red' : isGreen ? '#07B169' : theme.palette.custom.primary.silver)};

  &.status {
    margin-right: 5px;
  }
  &.red {
    color: ${({ theme }) => theme.palette.custom.red.main};
  }
`;

export const TextGreen = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.green.main};
  padding-right: 5px;
`;

export const SubheaderRow = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const ErrorContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const ErrorText = styled(Typography)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #D52727;
  margin-left: 5px;
`;

export const StatusRow = styled('p')`
  display: flex;
`;

export const MenuList = styled(List)`
  margin: -8px -17px;
  padding: 0;
  width: 217px;
`;

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  height: 38px;
`;

export const MenuListItemButton = styled(ListItemButton)`
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
