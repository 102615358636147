import React, { useState } from 'react';
import {
  FormControl,
  Typography,
  Button,
  Box
} from '@mui/material';

import { FileIcon, UploadArrow, CancelIcon } from 'assets/icons';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';
import { Label } from 'components/UI/Input/styles';
import EmployeesEditableList from './EmployeesEditableList';

// Styles
import {
  H1,
  HeaderRow,
  FormContainer,
  Container,
  FormRow,
  StyledAutocomplete,
  FormContainerFooter,
  FormWrap,
  LinkButton,
  ContainerButton,
  WrapperButton,
  ScaleInput,
  UploadedFile,
  FileUploadContainer,
  UploadedFilesContainer,
  HistoryContainer,
  LinkWrapper
} from './styles';
import {useLocation} from "react-router-dom";

const data = [
  {
    value: 'Виробництво', label: 'Виробництво',
  },
  {
    value: 'Виробництво1', label: 'Виробництво1',
  },
  {
    value: 'Виробництво2', label: 'Виробництво2',
  },
];

const data1 = [
  {
    value: '10', label: '10',
  },
  {
    value: '50', label: '50',
  },
  {
    value: '100', label: '100',
  },
];

const AddService: React.FC = () => {
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { pathname } = useLocation();

   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // @ts-ignore
      setUploadedFiles([...uploadedFiles, e.target.files?.[0].name])
      e.target.value = '';
    }
  };

  return (
    <Container>
      <HeaderRow>
        <H1 variant="h1">
          {pathname === '/add-service' ? 'Додати сервіс' : 'Редагувати сервіс'}
        </H1>
      </HeaderRow>
      <FormWrap>
        <FormContainer>
          <Typography variant="h2">Продукт</Typography>
          <FormRow>
            <Input
              label="Назва продукта або сервісу"
              className="inline-input time-input"
              fullWidth
            />
            <Input
              label="Ціна продажу"
              className="inline-input time-input"
              fullWidth
            />
          </FormRow>
          <FormRow>
            <StyledAutocomplete
              multiple
              options={data}
              defaultValue={[data[1]]}
              filterSelectedOptions
              ChipProps={{deleteIcon: <CancelIcon />}}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="Канали продажів"
                  className="inline-input time-input"
                  fullWidth
                  placeholder="Створити новий канал"
                />
              )}
            />
            <StyledAutocomplete
              multiple
              options={data}
              defaultValue={[data[1]]}
              filterSelectedOptions
              ChipProps={{deleteIcon: <CancelIcon />}}
              renderInput={(params) => (
                <Input
                  {...params}
                  label="Додати в групу товарів"
                  className="inline-input time-input"
                  fullWidth
                  placeholder="Створити нову групу"
                />
              )}
            />
          </FormRow>
          <Input
            label="Найменування сервісу, послуги"
            className="inline-input time-input"
            multiline
            rows={4}
            fullWidth
          />
        </FormContainer>
        <FormContainerFooter>
          Маржа складає: <Typography variant="h1">155%</Typography>
        </FormContainerFooter>
      </FormWrap>

      <FormWrap>
        <FormContainer>
          <Typography variant="h2">Витрати робочого часу на одиницю</Typography>
          <Box className="bordered">
            <FormRow>
              <Select
                label="Залучені підрозділи"
                data={data}
                className="inline-input time-input"
              />
              <FormControl>
                <Label>Витрачений час</Label>
                <Typography variant="h1" className="green-label">18 годин</Typography>
              </FormControl>
            </FormRow>
            <EmployeesEditableList />
            <LinkWrapper>
              <LinkButton
                variant="link"
                onClick={() => {}}
              >
                Додати ще підрозділ
              </LinkButton>
              <LinkButton
                variant="link"
                onClick={() => {}}
              >
                Додати обладнання
              </LinkButton>
              <LinkButton
                variant="link"
                onClick={() => {}}
              >
                Додати робочi години підрозділу
              </LinkButton>
            </LinkWrapper>
          </Box>

          <FormRow>
            <Select
              label="Залучені контрагенти"
              data={data}
              className="inline-input time-input"
            />
            <FormControl>
              <Label>Витрачений час</Label>
              <Typography variant="h1" className="green-label">109 годин</Typography>
            </FormControl>
          </FormRow>
          <EmployeesEditableList />
          <LinkWrapper>
            <LinkButton
              variant="link"
              onClick={() => {}}
            >
              Додати ще контрагента
            </LinkButton>
            <LinkButton
              variant="link"
              onClick={() => {}}
            >
              Додати обладнання
            </LinkButton>
            <LinkButton
              variant="link"
              onClick={() => {}}
            >
              Додати робочi години контрагенту
            </LinkButton>
          </LinkWrapper>
        </FormContainer>
        <FormContainerFooter>
          Загальна кількість робочого часу: <Typography variant="h1">145 годин</Typography>
        </FormContainerFooter>
      </FormWrap>
      <FileUploadContainer>
        <UploadedFilesContainer>
          {
            uploadedFiles.map((name) => (
              <UploadedFile variant="textIcon" startIcon={<FileIcon />} endIcon={<CancelIcon />}>{name}</UploadedFile>
            ))
          }
        </UploadedFilesContainer>
        <ScaleInput
          onChange={handleFileChange}
          id="upload-input"
          accept="image/*"
          type="file"
        />
        <WrapperButton htmlFor="upload-input">
          <Button
            className="uploadBtn"
            variant="textIcon"
            component="span"
          >
            <UploadArrow />
            Завантажити файл
          </Button>
        </WrapperButton>
      </FileUploadContainer>
      <FormWrap>
        <HistoryContainer>
          <Typography variant="h2">Історія витрат</Typography>
          <FormRow className="bordered">
            <Typography variant="h3">21.02.2021 - підвищення ціни. 203 грн./шт →205 грн./шт</Typography>
          </FormRow>
          <Typography variant="h3">21.02.2021 - підвищення ціни. 203 грн./шт →205 грн./шт</Typography>
        </HistoryContainer>
      </FormWrap>
      <ContainerButton>
        <Button
          variant="contained"
        >
          {pathname === '/add-service' ? 'Створити сервіс' : 'Редагувати сервіс'}
        </Button>

        <Button
          variant="light"
        >
          Скасувати
        </Button>
      </ContainerButton>
    </Container>
  );
};

export default AddService;
