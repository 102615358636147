import React, { FC } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
// Store
import { useCopyProjectMutation } from "store/projects/projectsServices";
// Styles
import { Container, Button } from './styles';

interface ProjectsCardFooterProps {
  id: string;
}

const ProjectsCardFooter: FC<ProjectsCardFooterProps> = ({ id }) => {
  const [copyProject] = useCopyProjectMutation();
  const { t } = useTranslation();
  const copyProjectHandler = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    try {
      await copyProject({ project_id: id })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container>
      <Button
        fullWidth
        onClick={copyProjectHandler}
        variant="light"
      >
        {t('Створити копію')}
      </Button>

      {/* <IconButton icon={<CompareIcon />} /> */}
    </Container>
  );
};

export default ProjectsCardFooter;
