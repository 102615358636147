import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  Button as StyledButton,
} from '@mui/material';

interface WrapperProps {
  isSpace?: number;
}

export const Button = styled(StyledButton)`
  && {
    height: 60px;
  }
`;

export const ContainerButton = styled(Box)`
  display: flex;
  gap: 0 20px;
  margin-top: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #EBEBEB;
`;

export const Container = styled('div')`
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const Wrapper = styled(Box)<WrapperProps>`
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: ${({ isSpace }) => (isSpace ? `${isSpace}px` : 0)};
`;
