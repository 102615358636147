import React from 'react';
import { Menu } from '@mui/material';
// Components
import UserTooltipContent from './UserTooltipContent';

interface UserTooltipProps {
  handleCloseMenu: () => void;
  anchorEl: null | HTMLElement;
}

const UserTooltip: React.FC<UserTooltipProps> = ({ handleCloseMenu, anchorEl }) => (
  <Menu
    onClose={handleCloseMenu}
    onClick={handleCloseMenu}
    anchorEl={anchorEl}
    id="account-menu"
    open={!!anchorEl}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    PaperProps={{
      elevation: 0,
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 40,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    }}
  >
    <UserTooltipContent />
  </Menu>
);

export default UserTooltip;
