import { ISales, IPlanSales } from 'store/dashboard/dashboardTypes';

interface IPlanDataSales {
  sales: ISales | undefined;
  name: string;
  product_id: string;
  amount: number;
  average: number;
}

const calcTotalIncome = (sales: IPlanDataSales[]) => {
  if (sales?.length) {
    const totalValue = sales?.reduce((acc: number, item: IPlanDataSales) => {
      const sum = item?.sales?.result?.income ? item?.sales?.result?.income : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

const calcPercent = (totalValue: number, value: number) =>{
  if (totalValue && value) {
    const percent = Math.round(value) * 100 / Math.round(totalValue)

    return percent?.toFixed(2);
  }

  return 0;
}

export const incomData = (planSales: IPlanSales[], payload?: any[]) => {
  if (payload?.length) {
    const activeMonth = payload?.[0]?.payload?.data?.month;

    const updateSalesData = planSales?.map(item => ({
      ...item,
      sales: item?.sales?.find(m => m?.month === activeMonth)
    }))

    const income = calcTotalIncome(updateSalesData);

    const items = updateSalesData?.map((item: IPlanDataSales) => ({
      product_name: item?.name,
      income: Math.round(item?.sales?.result?.income || 0),
      id: item?.product_id,
      percent: calcPercent(income, item?.sales?.result?.income || 0),
      color: '#07B169',
    }))



    return {
      dataRow: items,
      totalValue: income ? Math.round(income): 0,
    }
  }

  return {
    totalValue: 0,
    dataRow: [],
  }
}