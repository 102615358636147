import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import {useTranslation} from "react-i18next";
// Store
import { useUpdateProjectMutation } from 'store/projects/projectsServices';
import { ICreateProject } from 'store/projects/projectsTypes';
import { selectProject, deleteProject } from 'store/projects/projectSlice';
import { useGetPipelineByIdQuery } from 'store/tutors/tutorsServices';
// Utils
import { defaultValues } from 'utils/constants/add-project';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Componetns
import UploadFile from 'components/Projects/UploadFile';
import GeneralInputs from 'components/Projects/GeneralInputs';
import TaxesInputs from 'components/Projects/TaxesInputs';
import Loading from 'components/UI/Loading';
import Footer from 'components/Footer';
// Styles
import {
  Container,
  Title,
  Form,
  Wrapper,
  ContainerButton,
  Button,
} from './styles';

const EditPropject: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { project, pipelines_count, main_pipeline_id } = useSelector(selectProject);
  const { id } = useParams<string>();

  const formState = useForm<ICreateProject>({ defaultValues: project || defaultValues, mode: 'all' });
  const { formState: {isValid}, watch } = formState;
  const watchCountry = watch('residence');
  const { data } = useGetPipelineByIdQuery({ pipeline_id: main_pipeline_id as string, project_id: id as string }, {
    skip: !main_pipeline_id
  })

  const { redirectToProjects } = useProjectRoutes();
  const [updateProject, { isLoading }] = useUpdateProjectMutation();

  const onSubmit: SubmitHandler<ICreateProject> = async (values) => {
    try {
      const {
        currency, project_name, tax_group, tax_system_type, tax_type, residence
      } = values;

      const responce = await updateProject({
        body: {
          currency,
          project_name,
          tax_group,
          tax_system_type,
          tax_type,
          monthly_hour_limit: 160,
          residence
        },
        id: id as string,
      }).unwrap();

      if (responce?.project_id) {
        dispatch(deleteProject(null));
        redirectToProjects();
      }
    } catch (err) {
      formState.setError('project_name', {
        type: 'custom',
        message: 'Помилка сервера',
      });
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Title variant="h1">
        {t("Налаштування проекту")}
      </Title>

      <Form>
        <Wrapper isSpace={30}>
          {/*<UploadFile />*/}

          <GeneralInputs
            formState={formState}
            pipelineName={data?.pipeline_name || t("Створіть перший пайплайн")}
            isPipeline={!!pipelines_count}
          />
        </Wrapper>

        {
          watchCountry === 'UA' ?
            <Wrapper isSpace={30}>
              <TaxesInputs formState={formState}/>
            </Wrapper>
            : ''
        }

        <ContainerButton>
          <Button
            onClick={formState.handleSubmit(onSubmit)}
            disabled={!isValid}
            variant="contained"
          >
            {t("Зберегти налаштування")}
          </Button>

          <Button
            onClick={redirectToProjects}
            variant="light"
          >
            {t("Скасувати")}
          </Button>
        </ContainerButton>
      </Form>

      <Footer isSpace />
    </Container>
  );
};

export default EditPropject;
