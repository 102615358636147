import { styled } from '@mui/material/styles';
import { TableHead } from '@mui/material';

export const StyledTableHeader = styled(TableHead)`
  &.collapse {
    visibility: collapse;
  }
`;

export const SettingsLink = styled('a')`
`;
