import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface LabelProps {
  isMembership: boolean;
}

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 20px 20px 20px;
  border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const WrapperInfo = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Image = styled('img')`
  width: 52px;
  height: 52px;
  margin-right: 16px;
  border-radius: 8px;
`;

export const LinkProfile = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 16px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;

export const Name = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;

export const Label = styled(Typography)<LabelProps>`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: 69px;
  border-radius: 4px;
  background-color:  ${({ theme, isMembership }) => isMembership ? 'rgba(255, 210, 51, 0.5)' : theme?.palette?.custom?.green?.light};
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  margin-top: 5px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
