import React from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
// Interfaces
import { ICreateProject } from 'store/projects/projectsTypes';
// Utils
import { TaxeInputs, TaxGroupInputs, TaxationSystemInputs } from 'utils/constants/add-project';
import { InputsRules } from 'utils/constants/auth';
// Components
import RadioGroup from './RadioGroup';
// Styles
import {
  Container,
  Wrapper,
  WrapperInput,
  Title,
  StyledInput,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface TaxesInputsProps {
  formState: UseFormReturn<ICreateProject>;
}
const TaxesInputs: React.FC<TaxesInputsProps> = ({ formState }) => {
  const tax_type = formState.watch('tax_type');
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <RadioGroup
          isSpaceBottom={tax_type === 'other' ? 0 : 30}
          formState={formState}
          inputs={TaxeInputs.map(function (item) {
            return {
              label: t(`utils.constants.add-project.${item.label}`),
              value: item.value,
            }
          })}
          title={t('components.Projects.TaxesInputs.Податки')}
          name="tax_type"
        />

        <RadioGroup
          isDisabled={tax_type === 'other'}
          inputs={TaxationSystemInputs.map(function (item) {
            return {
              label: t(`utils.constants.add-project.${item.label}`),
              value: item.value,
            }
          })}
          formState={formState}
          title={t('components.Projects.TaxesInputs.Система оподаткування')}
          name="tax_system_type"
        />
      </Wrapper>

      <Wrapper>
        <WrapperInput>
          <RadioGroup
            isDisabled={tax_type === 'other'}
            formState={formState}
            inputs={TaxGroupInputs.map(function (item) {
              return {
                label: t(`utils.constants.add-project.${item.label}`),
                value: item.value,
              }
            })}
            title={t('components.Projects.TaxesInputs.Група оподаткування')}
            name="tax_group"
          />
        </WrapperInput>
      </Wrapper>
    </Container>
  );
};

export default TaxesInputs;
