import React, { useState } from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip as TooltipRecharts, Legend,
} from 'recharts';
// Store
import { useGetPipelineByIdQuery, useGetProjectByIdQuery } from 'store/dashboard/dashboardServices';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { IPipeline } from 'store/dashboard/dashboardTypes';
import { updateDataDiagramProduct, updateExpensesDiagram } from 'utils/calculates/diagram-income-expenses';
import DatePicker from 'react-datepicker';
import {
  Button,
  Typography,
  InputAdornment
} from '@mui/material';
import ChartTooltipIncome from './ChartTooltipIncome'

import {
  CalendarIcon,
  DownloadIcon,
  UploadIcon,
  PlusIcon,
  SettingsIcon,
  SearchIcon,
  CloseFactIcon
} from 'assets/icons';
import Footer from 'components/Footer';
import PagesSetting from './PagesSetting';
import AddMaterialModal from './ExpensesTable/AddMaterialModal';
import AddExpenseModal from './ExpensesTable/AddExpenseModal';
import ExpensesTable from './ExpensesTable'
import ExpensesByType from './ExpensesByType';
import VariableExpensesTable from './VariableExpensesTable';
import {LockTooltipText} from "components/UI/Tooltip/LockTooltipText";
import Tooltip from "components/UI/Tooltip";

// Styles
import {
  H1,
  HeaderRow,
  HeaderRightBlock,
  TitleContainer,
  TableContainer,
  SectionContainer,
  Container,
  SettingsLink,
  StyledTabs,
  StyledTab,
  TabContainer,
  ChartWrapper,
} from './styles';
import { StyledInput } from "../PlanDashboard/SalesPlanSection/styles";
import {IProject} from "../../interfaces/project";

const data1 = [
  {
    value: '10', label: '10',
  },
  {
    value: '50', label: '50',
  },
  {
    value: '100', label: '100',
  },
];

const RangeDatePicker: React.FC = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        // @ts-ignore
        setDateRange(update);
      }}
      isClearable={true}
    />
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Expenses: React.FC = () => {
  const { t } = useTranslation();
  const [openAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  const { pipeline_id, project_id } = useParams();
  const [openAddMaterialModal, setOpenAddMaterialModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const { planSales, salesPlanTable, pipeline, residence } = useSelector(selectDashboard);
  const { data: project } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  const { isLoading, data, refetch } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dataExpense = updateExpensesDiagram(salesPlanTable, pipeline as IPipeline, residence);

  return (
    <Container>
      <TitleContainer>
        <H1 variant="h1">
          {t('Витрати')}
          {/* <SettingsLink onClick={() => {setOpenSettingsModal((prev) => !prev);}}>
            <SettingsIcon />
          </SettingsLink> */}
        </H1>
      </TitleContainer>
      <SectionContainer>
        <HeaderRow>
          <Typography variant="h2">{t('Витрати згідно типам')}</Typography>
        </HeaderRow>

        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            width={500}
            height={300}
            data={dataExpense}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <TooltipRecharts wrapperStyle={{ outline: 'none' }} content={((props: any) => <ChartTooltipIncome currency={project?.currency as string} {...props} />)} />
            {/* <Legend /> */}

            <Bar dataKey="loss" stackId="a" fill="#FE8C8C" />
            <Bar dataKey="fixedCost" stackId="a" fill="#F77171" />
            <Bar dataKey="taxes" stackId="a" fill="#D52727" />
          </BarChart>
        </ResponsiveContainer>
      </SectionContainer>
      {/* <SectionContainer>
        <HeaderRow>
          <Typography variant="h2">Щомісячні витрати</Typography>
        </HeaderRow>
        <ExpensesByType />
      </SectionContainer> */}
      <TableContainer className="tabs">
        <HeaderRow className="tabs-row">
          <StyledTabs
            value={value}
            onChange={handleChange}
          >
            <StyledTab label={t('Постійні витрати')} />
            <StyledTab label={t('Змінні витрати')} />
            <Tooltip
              arrow
              placement="bottom"
              tooltipText=""
              tooltipContent={<LockTooltipText/>}
              PopperProps={{
                modifiers: [{
                  name: "offset",
                  options: { offset: [0, -20] }
                }],
              }}
            >
              <span style={{display: 'flex'}}>
                  <StyledTab
                    label={t('Капітальні витрати')}
                    icon={<CloseFactIcon/>}
                    iconPosition="start"
                    disabled={true}
                  />
              </span>
            </Tooltip>
          </StyledTabs>
          <HeaderRightBlock className="right-block">
            <StyledInput
              placeholder={t("Пошуковий запит...")}
              className="toolbar-btns search-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/*
              <Button
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
              onClick={() => {setOpenAddMaterialModal((prev) => !prev);}}
            >
              Матеріал
            </Button>
            */}
            <Button
              variant="textIcon"
              className="toolbar-btns create-btn"
              startIcon={<PlusIcon />}
              onClick={() => { setOpenAddExpenseModal((prev) => !prev); }}
            >
              {t('Витрата')}
            </Button>
          </HeaderRightBlock>
        </HeaderRow>
        <TabContainer>
          <TabPanel value={value} index={0}>
            <ExpensesTable additionalLosse={data?.additional_losses?.expenses} refetch={refetch} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VariableExpensesTable products={data?.products} refetch={refetch} />
          </TabPanel>
          <TabPanel value={value} index={0}></TabPanel>
        </TabContainer>
      </TableContainer>
      {/*<PagesSetting*/}
      {/*  isOpen={openSettingsModal}*/}
      {/*  onClose={() => { setOpenSettingsModal((prev) => !prev); }}*/}
      {/*/>*/}
      <AddExpenseModal
        isOpen={openAddExpenseModal}
        products={data?.products}
        onClose={() => { setOpenAddExpenseModal((prev) => !prev); refetch() }}
      />
      {/*
      <AddMaterialModal
        isOpen={openAddMaterialModal}
        onClose={() => {setOpenAddMaterialModal((prev) => !prev);}}
      />
      */}
      <Footer isSpace isSticki/>
    </Container>
  );
};

export default Expenses;
