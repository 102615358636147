import {
  Box,
  Typography,
  Button as StyledButton,
  ToggleButtonGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonProps {
  component?: string | undefined;
}
export const ToggleFormWrap = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    width: fit-content;
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    height: 40px;
    .MuiToggleButton-root {
      width: 100%;
    }
    .Mui-selected {
      height: 32px;
    }
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: fit-content;
  margin-bottom: 10px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  max-width: 315px;
  margin-bottom: 10px;
`;

export const IconContainer = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerForm = styled(ContainerButton)`
  display: block;
`;

export const Button = styled(StyledButton)<ButtonProps>`
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  
  && {
    height: 60px;
  }
`;

export const WrapperButton = styled('label')`
  margin-bottom: 10px;
`;

export const InlineFormWrap = styled('div')`
  width: 100%;
  display: flex;
  
  .time-input {
    margin-right: 10px;
  }
  
  &.checkbox-container {
    justify-content: end;
    & > * {
        width: 50%;
    }
  }
  
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const FieldWrap = styled('div')`
  color: ${({ theme }) => theme.palette.custom.black.main};
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
  
  &.toggle-container {
    display: flex;
    & > .MuiFormControl-root {
     width: 49%;
     justify-content: center;
     margin-right: 15px;
    }
  }
  
  &:last-child {
    border-bottom: none;
  }
`;