/* eslint-disable max-len */
import React, { FC } from 'react';
import {
  ToggleButtonGroup,
} from '@mui/material';
// Icons
import { CancelIcon, InfoIconToglButton, RatingIcon } from 'assets/icons';
// Styles
import { Container, Wrapper, Button, StyledToggleButton, ButtonStopCompare, WrapperButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface PipelinesFilterSectionProps {
  handleChangeMode: (event: React.MouseEvent<HTMLElement>, newMode: number,) => void;
  redirectToComparePipeline: () => void;
  deleteCompare: () => void;
  isCompare: boolean;
  isDraft: boolean;
  mode: number;
}

const PipelinesFilterSection: FC<PipelinesFilterSectionProps> = ({
  redirectToComparePipeline,
  handleChangeMode,
  deleteCompare,
  isCompare,
  mode,
  isDraft
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <ToggleButtonGroup
          onChange={handleChangeMode}
          value={mode}
          exclusive
        >
          <StyledToggleButton value={1}>
            {t('Активні')}
          </StyledToggleButton>

          <StyledToggleButton value={0}>
            {isDraft && <InfoIconToglButton />}

            {t('Чернетки')}
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Wrapper>

      <WrapperButton>
        <Button
          onClick={deleteCompare}
          isCompare={isCompare}
          startIcon={<CancelIcon />}
          variant="textIcon"
        >
          {t('Припинити порівняння')}
        </Button>

        <Button
          onClick={redirectToComparePipeline}
          isCompare={isCompare}
          startIcon={<RatingIcon />}
        >
          {t('Порівняти сценарії')}
        </Button>
      </WrapperButton>
    </Container>
  )
};

export default PipelinesFilterSection;
