import { styled } from '@mui/material/styles';
import { Button, ToggleButtonGroup } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  margin-bottom: 30px;
  max-height: 79px;

  .name-input {
    max-width: 300px;
  }

  .select-form-control {
    max-width: 267px;
    width: 100%;
  }

  .sum-input {
    max-width: 320px;
  }
  .frequency-input {
    max-width: 130px;
  }

  .amount-input {
    width: 100%;
    max-width: 190px;
  }

  & > .MuiFormControl-root, .MuiToggleButtonGroup-root {
    margin-right: 20px;
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    height: 48px;
    align-self: end;
  }
`;

export const DeleteBtn = styled(Button)`
  background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  align-self: end;
  &:hover, &:focus {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  }
`;
