import React from 'react';
// Styles
import {
  Container,
  Button,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface ToggleButtonsProps {
  value: string;
  setValue: (value: string) => void;
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({ value, setValue }) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;

    setValue(nextView);
  };
  const { t } = useTranslation();
  return (
    <Container
      onChange={handleChange}
      orientation="horizontal"
      value={value}
      exclusive
    >
      <Button
        value="plan_sales"
        aria-label="list"
      >
        %
      </Button>

      <Button
        value="sum_month_sales"
        aria-label="module"
      >
        {t('Шт.')}
      </Button>
    </Container>
  );
};

export default ToggleButtons;
