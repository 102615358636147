import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
// Constants
import { EMPLOYEES_DEFAULT_VALUES, PRODUCT_DEFAULT_VALUES, packagingLogistics } from 'utils/constants/tutor';
import { TutorState, IEmployee, IProduct } from './tutorTypes';
import { tutorServices } from './tutorServices';

const initialState: TutorState = {
  employees: EMPLOYEES_DEFAULT_VALUES,
  products: PRODUCT_DEFAULT_VALUES,
};

export const tutorSlice = createSlice({
  name: 'tutors',
  initialState,

  reducers: {
    setEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      state.employees = action.payload;
    },

    setProducts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      tutorServices.endpoints.getPipelineById.matchFulfilled,
      (state, { payload }) => {
        if (payload.employees?.length) {
          state.employees = payload.employees;
        }

        if (payload?.products?.length) {
          const updateArray = payload?.products?.map((item) => ({
            ...item,
            EmployeeProductList: [{
              product_id: item?.product_id as string,
              unit_id: '',
              employee: item?.EmployeeProducts || [],
            }],
            materials: [
              {
                dimension: '',
                name: '',
                per_product: 0,
                price: 0,
              },
            ],
            packagingLogistics,
          }));

          state.products = updateArray;
        }
      },
    );
  },
});

export const { setEmployees, setProducts } = tutorSlice.actions;

export default tutorSlice.reducer;

export const selectTutor = (state: RootState) => state.tutors;
