import { styled } from '@mui/material/styles';
import {
 List, ListItem, ListItemButton, Typography,
} from '@mui/material';

interface TextProps {
  isRed?: boolean;
}

export const MenuList = styled(List)`
  margin: -8px -17px;
  padding: 0;
  width: 217px;
`;

export const Container = styled(List)`
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  height: 38px;
`;

export const MenuListItemButton = styled(ListItemButton)`
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const Text = styled(Typography)<TextProps>`
  color: ${({ theme, isRed }) => isRed ? 'red' : theme.palette.custom.primary.silver};

  &.status {
    margin-right: 5px;
  }
`;
