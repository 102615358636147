import React from 'react';
import {useTranslation} from "react-i18next";

import {Button} from "@mui/material";
import CatErrorPage from 'assets/images/cat-error-page.svg';
import {ArrowLeftGreenIcon} from "assets/icons";
import {PageWrapper, Content, ImgWrap, Image, ContentWrap} from "./styles";
import {useNavigate} from "react-router";

const ErrorPage: React.FC = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <ImgWrap>
        <Image src={CatErrorPage} />
      </ImgWrap>
      <ContentWrap>
        <Content variant={"h1"}>{t('Йой! Щось пішло не так.')}</Content>
        <Button
          onClick={() => navigate('/')}
          variant="textIcon"
          className="toolbar-btns create-btn"
          startIcon={<ArrowLeftGreenIcon />}
        >
          {t('Повернутись')}
        </Button>
      </ContentWrap>
    </PageWrapper>
  )
};

export default ErrorPage;
