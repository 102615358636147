import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import { PlusIcon, MinusIcon } from 'assets/icons';
import { Checked, Unchecked } from './styles';

interface CheckboxButtonProps {
  title: string
};

const CheckboxButton: React.FC<CheckboxButtonProps & CheckboxProps> = ({ title, ...props }) => (
  <Checkbox
    {...props}
    icon={<Checked variant="textIcon" endIcon={<PlusIcon />}>{title}</Checked>}
    checkedIcon={<Unchecked variant="textIcon" endIcon={<MinusIcon />}>{title}</Unchecked>}
  />
);

export default CheckboxButton;
