import React, { FC } from 'react';
// Icons
import { TrashIcon, CancelIcon } from 'assets/icons';
// Styles
import { Container, ContainerButtons, Button } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface ControlPanelProps {
  deleteAllEmployee: () => void;
  cancelСhanges: () => void;
  count: number;
}

const ControlPanel: FC<ControlPanelProps> = ({ count, cancelСhanges, deleteAllEmployee }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <ContainerButtons>
      { t('Вибрано елементи') }: {count}
      </ContainerButtons>

      <ContainerButtons>
        <Button
          onClick={deleteAllEmployee}
          disabled={!count}
          variant="link"
        >
          <TrashIcon />{t('Видалити')}
        </Button>

        <Button
          disabled={!count}
          onClick={cancelСhanges}
          variant="link"
        >
          <CancelIcon /> {t('Скасувати')}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default ControlPanel;
