/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import { useRoutes, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
// Components
import Loading from 'components/UI/Loading';
// Store
import { useGetUserQuery } from 'store/auth/authService';
import { errorReducer } from "store/error/errorService";
// Hooks
import { useAuth } from 'hooks/useAuth';

import {
  userRoutes,
  authRoutes,
  tutorRoutes,
  userProfileRoutes,
  freelanceUserRoutes,
} from './routes';
import { USER_TYPE } from "utils/constants/auth";

const Router: React.FC = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { token, user } = useAuth();
  const [searchParams] = useSearchParams();
  const recoveryToken = searchParams.get('token')!;
  const router = useRoutes([userRoutes, tutorRoutes]);
  const freelancerRouter = useRoutes([freelanceUserRoutes]);
  const authRouter = useRoutes([authRoutes]);
  const profileRouter = useRoutes([userProfileRoutes]);
  const {error} = useSelector(errorReducer);

  const { isLoading } = useGetUserQuery({}, { skip: !token });

  useEffect(() => {
    if (error) {
      navigate('/error-page')
      return;
    }
  }, [error])

  useEffect(() => {
    if (!user) { return }
    i18n.changeLanguage(user.settings.language);
  }, [user])

  if (isLoading) {
    return <Loading />;
  }

  if (recoveryToken) {
    return authRouter;
  }

  if (!token && !user) {
    return authRouter;
  }

  if (token && user && user?.firstname && user?.lastname) {
    let userType = ''; // TODO: update with the value from API
    switch (userType) {
      case USER_TYPE.FREELANCER:
        return freelancerRouter;
      default:
        return router;
    }
  }

  if (user && !user?.firstname || !user?.lastname) {
    return profileRouter;
  }

  return authRouter;
};

export default Router;
