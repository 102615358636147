import React from 'react';
import { authRoutePath } from 'routes/routePath';
// Icons
import { ArrowSteps } from 'assets/icons';
import MasterCardIcon from 'assets/icons/visa-master-card.png';
// Styles
import {
  Container,
  Label,
  ContainerStep,
  LabelItem,
  Wrapper,
  Link,
  Item,
  Image,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface RegistrationFormFooterProps {
  step: number;
}

const RegistrationFormFooter: React.FC<RegistrationFormFooterProps> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Label variant="regularText">
        {t('Крок')}
      </Label>

      <ContainerStep>
        <LabelItem isActive={step === 1}>
          <Item isActive={step === 1}>
            1
          </Item>
        </LabelItem>

        <ArrowSteps />

        <LabelItem isActive={step === 2}>
          <Item isActive={step === 2}>
            2
          </Item>
        </LabelItem>
      </ContainerStep>

      <Wrapper>
        <Label variant="regularText">
          {t('Вже зареєстровані')}?
        </Label>

        <Link to={authRoutePath.LOGIN}>
          {t('Увійти')}
        </Link>
      </Wrapper>

      <Image src={MasterCardIcon} alt="cardIcon" />
    </Container>
  )
};

export default RegistrationFormFooter;
