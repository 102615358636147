import {styled} from '@mui/material/styles';
import {Button} from "@mui/material";

interface ButtonProps {
  disabled: boolean
}

export const Wrapper = styled('div')`
  position: absolute;
  top: 0;
  left: -99999px;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
`

export const TooltipText = styled('p')`
  width: 100%;
  max-width: 250px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #838383;
`

export const IconButton = styled(Button)<ButtonProps>`
  background: ${({ theme }) => theme?.palette?.primary.contrastText};
  // background: ${({ disabled, theme }) => theme.palette.custom.green.main};
  border-radius: 6px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  padding: 0;
  opacity: ${({ disabled, theme }) => disabled ? '.7' : 1};;
  
  &:hover {
    background: ${({ theme }) => theme.palette.custom.green.main};
    
    svg path {
      fill: #ffffff;
    }
  }
`;
