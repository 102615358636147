import React from 'react';
// Global components
import Tooltip from 'components/UI/Tooltip';
// Icons
import { InfoIcon } from 'assets/icons';
// Styles
import { Container, H1 } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const ProjectsTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <H1 variant="h1">
        {t('pages.Projects.ProjectsTitle.Проекти')}
      </H1>

      <Tooltip tooltipText={t('pages.Projects.ProjectsTitle.Проекти')}>
        <InfoIcon />
      </Tooltip>
    </Container>
  )
};

export default ProjectsTitle;
