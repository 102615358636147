import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px 30px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;
