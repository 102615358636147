import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;
`;

export const IconButton = styled(Button)`
  background: ${({ theme }) => theme?.palette?.primary.contrastText};
  border-radius: 6px;
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  max-width: 40px;
  min-width: 40px;
  margin-right: 16px;
`;
