import React, { useState } from 'react';
// Styles
import {
  Container,
  Button,
  Wrapper,
  Label,
} from './styles';

interface ToggleButtonsProps {
  label: string;
  firstValue: string;
  lastValue: string;
}

const ToggleButtons: React.FC<ToggleButtonsProps> = ({ label, firstValue, lastValue }) => {
  const [view, setView] = useState(firstValue);

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;

    setView(nextView);
  };

  return (
    <Wrapper>
      <Label>
        {label}
      </Label>
      <Container
        onChange={handleChange}
        orientation="horizontal"
        value={view}
        exclusive
      >
        <Button
          value={firstValue}
          aria-label="list"
        >
          {firstValue}
        </Button>

        <Button
          value={lastValue}
          aria-label="module"
        >
          {lastValue}
        </Button>
      </Container>
    </Wrapper>
  );
};

export default ToggleButtons;
