import {createSlice, isRejected} from "@reduxjs/toolkit";
import type { RootState } from 'store';

interface ErrorInitialState {
  error: boolean;
  message: string;
  endpointName: string;
}

const initialState: ErrorInitialState = {
  error: false,
  message: '',
  endpointName: ''
}

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      // global error handle reducer
      // console.log(action);
      // state.error = true;
      state.message = 'some thunk rejected';
    });
  },
});

export default errorSlice.reducer;

export const errorReducer = (state: RootState) => state.error;
