import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Store
import { IProductCreate, IMaterialCreate } from 'interfaces/product';
import { useCreateMaterialMutation, useUpdateMaterialMutation } from 'store/tutors/tutorsServices';
import { ICreateMaterialRequest, IExpensesMaterial } from 'store/tutors/tutorsTypes';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import MaterialItem from './MaterialItem';
// Styles
import { Container } from './styles';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';
import { useAuth } from '../../../hooks/useAuth';

interface MaterialsProps {
  currency: string;
}

const Materials: React.FC<MaterialsProps> = ({ currency }) => {
  const { project_id, materialProducts } = useSelector(selectTutor);
  const { step } = useParams();
  const { redirectToTutorStep } = useTutorRoutes();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [create] = useCreateMaterialMutation();
  const [updateMaterial] = useUpdateMaterialMutation();

  const formState = useForm({
    mode: 'all',
    defaultValues: { materialProducts },
  });

  const { fields } = useFieldArray({
    control: formState.control,
    name: 'materialProducts',
  });

  const updateItem = async (productIndex: number, id?: string) => {
    if (!id) return;

    const productsArray = formState.watch('materialProducts');
    const material = productsArray?.[productIndex]?.expenses?.Materials?.find((item) => item?.material_id === id);

    if (material?.material_id) {
      const updateDataMaterial = {
        amount: +material.amount,
        cost: +material.cost,
        name: material.name,
        value: material.value,
        extras: {
          dimension: material.extras.dimension,
        },
      };

      await updateMaterial({
        body: updateDataMaterial,
        material_id: id,
      }).unwrap();
    }
  };

  useEffect(() => {
    formState.setValue('materialProducts', materialProducts);
  }, [materialProducts]);

  const onNextStep: SubmitHandler<{ materialProducts: IProductCreate[] }> = async (values) => {
    try {
      const filteredProducts = values.materialProducts.filter((item) => item?.pipeline_id);

      if (filteredProducts?.length) {
        const mats = filteredProducts.map((item) => {
          const materials: IExpensesMaterial[] = [];

          item.expenses?.Materials?.forEach((expens: IMaterialCreate) => {
            if (!expens.material_id) {
              materials.push({
                extras: { dimension: expens.extras.dimension },
                name: expens.name,
                cost: expens.cost,
                value: expens.value,
                group: expens.group,
                amount: expens.amount,
              });
            }
          });

          return { product_id: item.pipeline_id, expenses: materials };
        });
        const filteredMaterials = mats.filter((item) => item.expenses?.length);
        if (filteredMaterials.length) {
          await create(filteredMaterials as ICreateMaterialRequest[]).unwrap();

          formState.reset();
        } else {
          redirectToTutorStep(project_id, 5);
        }
        formState.setValue('materialProducts', materialProducts);
        redirectToTutorStep(project_id, 5);
        const eventPayload: SegmentEventPayload = {
          eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_4,
          payload: { email: user?.email },
        };
        dispatch(addEvent(eventPayload));
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onPrevStep = () => {
    redirectToTutorStep(project_id, 3);
  };

  useEffect(() => {
    if (step === '4') {
      // TODO hot fix - remove it in feature
      setTimeout(() => {
        formState.trigger()
      }, 500)
    }
  }, [step, materialProducts])
  return (
    <TabPanel index={4} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={3} />

        {fields.map((product, index) => (
          <MaterialItem
            updateItem={updateItem}
            errors={formState.formState.errors}
            control={formState.control}
            key={product.id}
            product={product}
            indexProduct={index}
            currency={currency}
          />
        ))}
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={3}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default Materials;
