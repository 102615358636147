import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
// Icons
import { IncreaseIcon, DecreaseIcon } from 'assets/icons';
// Styles
import {
  Container, Head, TitleTable, SortBtns, SortBtn,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const HEADER_NAME_COLUMS = [
  'Ім’я та прізвище',
  'Витрачено годин',
];

interface EmployeeProductTableHeaderProps {
  selected: boolean;
  handleCheckAll: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmployeeProductTableHeader: React.FC<EmployeeProductTableHeaderProps> = ({ selected, handleCheckAll }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Head>
        {HEADER_NAME_COLUMS.map((title, index) => (
          <TitleTable key={title}>
            {index === 0 ? (
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={selected}
                      onChange={handleCheckAll}
                    />
                  )}
                  label={t(title) || ''}
                />
              </FormGroup>
            ) : t(title)}

            <SortBtns>
              <SortBtn>
                <IncreaseIcon />
              </SortBtn>

              <SortBtn>
                <DecreaseIcon />
              </SortBtn>
            </SortBtns>
          </TitleTable>
        ))}
      </Head>
    </Container>
  )
};

export default EmployeeProductTableHeader;
