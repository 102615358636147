import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { Button } from '../../../components/HeaderUserMenu/UserMembershipTooltip/styles';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  width: 100%;
  margin-bottom: 20px;
`;

export const Container = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const PrimaryButtonStyled = styled(Button)({
  display: 'flex',
  width: 'auto',
  padding: '20px 25px',
  '& > a': {
    whiteSpace: 'nowrap'
  }
});
