import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

export const Button = styled(LoadingButton)`
  && {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme?.palette?.primary?.contrastText};
    background: ${({ theme }) => theme?.palette?.custom?.green?.main};
    text-transform: inherit;
    border: none;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.custom.green.chlorine},
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme?.palette?.custom?.green?.main};
    color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  }
`;
