import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled(Box)`
  display: flex;
  width: 100%;
  gap: 0 20px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
`;

export const Image = styled('img')`
  width: 29px;
`;

export const Button = styled(StyledButton)`
  width: 100%;
`;
