import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import DatePicker from "react-datepicker";
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import { ClockIcon, CalendarIcon } from 'assets/icons';
import { Label, Container as InputContainer } from 'components/UI/Input/styles';
// Styles
import {
  Container,
  Title,
  ContainerButton,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  IconWrap,
} from './styles';
;
// Translation
import { useTranslation, } from 'react-i18next';

interface Option {
  value: string;
  label: string;
}

interface AddEmployeesModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const AddEmployeesModal: React.FC<AddEmployeesModalProps> = ({
  onClose,
  isOpen,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');
  const handleChange = (evt: any) => {
    // @ts-ignore
    setSelectedEmployee(evt.target.value);
  }
  const [hours, setHours] = useState<number>(0);
  const [date, setDate] = useState<Date>(new Date());
  const handleAddEmployee = () => {
    onClose();
  }
  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {t('Додати співробітника')}
        </Title>
        <Input
          fullWidth
          disabled
          label={t('Підрозділ')}
          value="Майстерня"
          InputProps={{
            endAdornment: (<InputAdornment position="end">
              <ClockIcon />
            </InputAdornment>)
          }}
        />
        <Select
          label={t('Співробітник')}
          data={[]}
          onChange={handleChange}
          className="amount-input"
        />
        <InlineFormWrap>
          <Input
            label={t('Витрачено годин')}
            type="number"
            placeholder="0"
            className="inline-input time-input"
            defaultValue={hours}
            onChange={(event) => setHours(+event.target.value)}
            InputProps={{
              endAdornment: (<InputAdornment position="end">
                <ClockIcon />
              </InputAdornment>)
            }}
          />
          <InputContainer className="inline-input">
            <Label>{t('Дата')}</Label>
            <DatePickerWrapper>
              <DatePicker selected={date} onChange={(date: Date) => setDate(date)}></DatePicker>
              <IconWrap>
                <CalendarIcon />
              </IconWrap>
            </DatePickerWrapper>
          </InputContainer>
        </InlineFormWrap>

        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={handleAddEmployee}
          >
            {t('Додати')}
          </Button>
          <Button
            variant="light"
            onClick={onClose}
          >
            {t('Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
}
export default AddEmployeesModal;
