import React, { useState } from 'react';
// Store
import { useUpdatePipelineMutation } from 'store/pipeline/pipelineServices';
import {
  Typography,
} from '@mui/material';
import { CheckIcon, CancelIcon } from 'assets/icons';
import {
  EditorWrap,
  ControlButtons,
  PencilButton,
  SubmitButton,
  CancelButton,
  StyledInput,
  Wrapper,
} from './styles';

interface InlineEditorProp {
  pipeline_id: string;
  project_id: string;
  title: string;
  status: number;
}
const InlineEditor: React.FC<InlineEditorProp> = ({ title, pipeline_id, project_id, status }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const [update] = useUpdatePipelineMutation();

  const handleCancel = () => {
    setIsEditing(false);
    setNewTitle(title);
  };

  const onChangeName = async () => {
    await update({
      project_id,
      pipeline_id,
      body: { pipeline_name: newTitle, status, tax: 0 }
    });

    setIsEditing(false);
  };

  return (
    <EditorWrap>
      {
        !isEditing
          ? (
            <>
              <Typography variant="h2">
                {newTitle || title}
              </Typography>
              <ControlButtons>
                <PencilButton
                  onClick={() => setIsEditing(true)}
                />
              </ControlButtons>
            </>
          )
          : (
            <Wrapper>
              <StyledInput
                label=""
                defaultValue={newTitle}
                onChange={(evt) => setNewTitle(evt.target.value)}
              />
              <ControlButtons>
                <SubmitButton
                  variant="icon"
                  onClick={onChangeName}
                >
                  <CheckIcon />
                </SubmitButton>
                <CancelButton
                  variant="icon"
                  onClick={() => handleCancel()}
                >
                  <CancelIcon />
                </CancelButton>
              </ControlButtons>
            </Wrapper>
          )
      }
    </EditorWrap>
  );
};

export default InlineEditor;
