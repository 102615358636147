import React, { FC } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Components
import HeaderUserMenu from 'components/HeaderUserMenu';
import { LockTooltipText } from "../../UI/Tooltip/LockTooltipText";
// Constants
import { NAVBAR_LINK } from 'utils/constants/nav-bar';
// Icon
import Tooltip from "../../UI/Tooltip";
import { CloseFactIcon } from 'assets/icons';
// Styles
import {
  Container, Link, Wrapper, ContainerLink, BorderLink,
} from './styles';

const NavBarDashboard: FC = () => {
  const { pipeline_id, project_id } = useParams();
  const { redirectToDashboard } = useProjectRoutes();
  const { pathname } = useLocation();
  const type = pathname?.split('/')?.[3];

  const onNextTub = (path: string) => redirectToDashboard(project_id as string, path, pipeline_id as string);

  const activeIcon = (index: number) => {
    if (index === 5 || index === 6) {
      return true
    }

    return false;
  }
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        {NAVBAR_LINK.map(({ title, path }, index) => (
          <ContainerLink key={path}>
            <BorderLink isActive={type === path} />
            {activeIcon(index) ? (
              <Tooltip
                arrow
                placement="bottom"
                tooltipText=""
                tooltipContent={<LockTooltipText />}
              >
                <span>
                  <Link disabled={activeIcon(index)} isActive={type === path} variant="text">
                    {t(`${title}`)} <CloseFactIcon />
                  </Link>
                </span>
              </Tooltip>
            ) :
              <Link
                disabled={activeIcon(index)}
                onClick={() => onNextTub(path)}
                isActive={type === path}
                variant="text"
              >
                {t(`${title}`)} {activeIcon(index) && <CloseFactIcon/>}
              </Link>
            }
          </ContainerLink>
        ))}
      </Wrapper>

      <HeaderUserMenu />
    </Container>
  );
};

export default NavBarDashboard;
