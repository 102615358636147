import React from 'react';
import { Checkbox } from '@mui/material';
import { authRoutePath } from 'routes/routePath';
// Styles
import { Container, Label, Link } from './styles';
// Translation
import { useTranslation } from 'react-i18next';

const LoginSave: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label control={<Checkbox />} label={t('pages.Login.LoginSave.Запам’ятати мене')} />
      <Link to={authRoutePath.RECOVERY}>
        {t('pages.Login.LoginSave.Забули пароль?')}
      </Link>
    </Container>
  )
};

export default LoginSave;
