import React, { FC } from 'react';
// Mock Avatar
import UserProfileDefaultPhoto from 'assets/icons/UserProfileDefaultPhoto.svg';
// Styles
import {
  Container,
  Image,
  Wrapper,
  WrapperInfo,
  LinkProfile,
  Name,
  Label,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface UserTooltipHeaderProps {
  firstname: string;
  lastname: string;
  isMembership: boolean;
}

const UserTooltipHeader: FC<UserTooltipHeaderProps> = ({ isMembership, firstname, lastname }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Image src={UserProfileDefaultPhoto} />

        <WrapperInfo>
          <Name variant="boldRegularText">
            {firstname} {lastname}
          </Name>

          <Label isMembership={isMembership}>
            {isMembership ? t("components.HeaderUserMenu.UserTooltip.UserTooltipHeader.ПОЧАТКІВЕЦЬ") : t("components.HeaderUserMenu.UserTooltip.UserTooltipHeader.Діскавері")}
          </Label>
        </WrapperInfo>
      </Wrapper>

      <LinkProfile to="/profile">
        {t("components.HeaderUserMenu.UserTooltip.UserTooltipHeader.Переглянути профіль")}
      </LinkProfile>
    </Container>
  )
};

export default UserTooltipHeader;
