import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 32%;
  max-width: 546px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  cursor: pointer;
  height: fit-content;
  justify-content: space-between;
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
