import type { FC, MouseEvent } from 'react';
// Styles
import {
  Container,
  Button,
  Wrapper,
  Label,
  ButtonContainer,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { Link } from "@mui/material";
import { MEMBERSHIP_STATUS } from '../../../../utils/constants/user-profile';
import { PrimaryButtonStyled } from '../styles';

interface ToggleButtonsProps {
  value: string;
  setValue: (value: string) => void;
  membershipStatus?: string;
}

const ToggleButtons: FC<ToggleButtonsProps> = ({ value, setValue, membershipStatus }) => {
  const handleChange = (event: MouseEvent<HTMLElement>, nextView: string) => {
    if (!nextView) return;
    setValue(nextView);
  };
  const { FREE, TRIAL } = MEMBERSHIP_STATUS;
  const hasMembership = membershipStatus && membershipStatus !== FREE && membershipStatus !== TRIAL;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Label>
        {t('Тип підписки')}
      </Label>
      <ButtonContainer>
        <Container
          onChange={handleChange}
          orientation="horizontal"
          value={value}
          exclusive>
          <Button
            value="month"
            aria-label="list">
            {t('Місячна')}
          </Button>
          <Button
            value="year"
            aria-label="module">
            {t('Річна')}
          </Button>
        </Container>
        <PrimaryButtonStyled disabled={!hasMembership} variant="contained">
          <Link
            href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL ?? ''}
            color="inherit"
            target="_blank"
            underline="none">
            {t('Керувати підпискою')}
          </Link>
        </PrimaryButtonStyled>
      </ButtonContainer>
    </Wrapper>
  );
};

export default ToggleButtons;
