import {styled} from '@mui/material/styles';
import {Typography} from "@mui/material";


export const TitleWrap = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const DateWrap = styled(Typography)`
  
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: fit-content;
  margin-bottom: 10px;
`;
