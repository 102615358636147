import React, {FC, useState} from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  Collapse,
  Button,
  Typography
} from '@mui/material';

import {IPackaging, IProduct, IMaterial} from 'interfaces/product';
import {SettingsIcon, MoreSetingsIcon, ArrowRightIcon, ArrowLeftIcon} from 'assets/icons';
import Tooltip from 'components/UI/Tooltip';
import dayjs from 'dayjs'
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';
import AddExpenseModal from '../ExpensesTable/AddExpenseModal';
import ConfirmationModal from "components/ConfirmationModal";
import SettingsModal from './SettingsModal';
// Styles
import {
  CollapseTableRow,
  SettingsLink,
  StyledTableHeader,
  SubheaderTableCell,
  Arrow,
  StyledTableContainer,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
  PaginationWrap,
  PaginationRow
} from './styles';

interface Data {
  name: string | number,
  cost: string,
  amount: number,
  dimension: string,
  created_at: string,
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Витрати',
  },
  {
    id: 'cost',
    numeric: false,
    label: 'Бюджет',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Кількість',
  },
  {
    id: 'dimension',
    numeric: true,
    label: 'Од. вимірювання',
  },
  {
    id: 'created_at',
    numeric: false,
    label: 'Початок',
  },
];

interface ExpensesTableHeaderProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSettingClick: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  collapse?: boolean;
}

function ExpensesTableHead(props: ExpensesTableHeaderProps) {
  const { t } = useTranslation();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, collapse, handleSettingClick } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <StyledTableHeader className={collapse ? 'collapse' : ''}>
      <TableRow>
        <TableCell padding="checkbox" className="empty-checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            width={400}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        {/*<TableCell>*/}
        {/*  <SettingsLink onClick={handleSettingClick}>*/}
        {/*    <SettingsIcon />*/}
        {/*  </SettingsLink>*/}
        {/*</TableCell>*/}
      </TableRow>
    </StyledTableHeader>
  );
}

interface VariableExpensesTableProps {
  products?: IProduct[],
  refetch: () => void
}

const UNITS = [
  { value: 'm', label: 'M' },
  { value: 'm2', label: 'M²' },
  { value: 'шт', label: 'ШТ' },
  { value: 'кг', label: 'КГ' },
];

const VariableExpensesTable: FC<VariableExpensesTableProps> = ({products, refetch}) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteExpensesItem, setDeleteExpensesItem] = useState('');
  const [deleteExpense] = useDeleteExpensesMutation();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddExpenseModal, setOpenAddExpenseModal] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState<boolean | number>(0);
  const [openSubheader, setOpenSubheader] = React.useState<boolean | number[] | number>([0, 0]);

  const [editing, setEditing] = React.useState<IMaterial | IPackaging | null>(null);
  const toggleModal = () => setOpenDeleteModal((prev) => !prev);
  const tableData = [
    {
      group: 'Витрати на виробнитство',
      items: products?.map(({expenses, name}) => (
        {
          name,
          rows: expenses && expenses?.Materials?.map((material) => (material)) || []
        }
      ))
    },
    {
      group: 'Логістика та упаковка',
      items: products?.map(({expenses, name}) => (
        {
          name,
          rows: expenses && expenses?.Packaging?.map((pack) => (pack)) || []
        }
      ))
    }
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.reduce((acc, {group, items}) => {
        // @ts-ignore
        acc = [...acc, ...items.map(n => n.name)];
        return acc;
      }, [])
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    // @ts-ignore
    const selectedIndex = selected?.indexOf(name);
    // @ts-ignore
    let newSelected = [];

    if (selectedIndex === -1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      // @ts-ignore
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    // @ts-ignore
    setSelected(newSelected || []);
  };
  // @ts-ignore
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const onCloseSettingsModal = () => setOpenSettingsModal((prev) => !prev);
  const getDimension = (dimension: string | undefined) => {
    if (!dimension) {
      return '';
    }

    const found = UNITS.find(({value}) => (dimension === value));
    return found ? found.label : '';
  }

  const updateDate = (data: string) => {
    const dataCreate = dayjs(data as any).format('DD.MM.YYYY');

    return dataCreate
  }

  const deleteAdditionalLosses = async () => {
    await deleteExpense(deleteExpensesItem);
    setOpenDeleteModal(false);
    refetch();
  }

  return (
    <StyledTableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
      >
        <ExpensesTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={6}
          handleSettingClick={() => {setOpenSettingsModal((prev) => !prev);}}
        />
        <TableBody>
          {
            tableData?.map(({group, items}, groupIndex) => (
              <>
                <CollapseTableRow
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                  onClick={() => {
                    // @ts-ignore
                    if ((openSubheader !== false && openSubheader[0] === groupIndex) || openSubheader === groupIndex) {
                      setOpenSubheader([])
                    } else {
                      setOpenSubheader([groupIndex, 0]);
                    }
                  }}
                >
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {t(group)}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Arrow
                      // @ts-ignore
                      $isOpen={(openSubheader !== false && openSubheader[0] === groupIndex) || openSubheader === groupIndex}
                    />
                  </TableCell>
                </CollapseTableRow>
                {
                  items?.map(({name, rows}, index) => (
                    <>
                      <TableRow sx={{ '& > *': { border: 'unset' } }}>
                        <TableCell style={{ padding: 0 }} colSpan={12}>
                          <Collapse in={
                            // @ts-ignore
                            (openSubheader !== false && openSubheader[0] === groupIndex) || openSubheader === groupIndex
                          } timeout="auto" unmountOnExit>
                            <Table>
                              <ExpensesTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={6}
                                collapse
                                handleSettingClick={() => {}}
                              />
                              <TableBody>
                                <TableRow sx={{ '& > *': { border: 'unset' } }}>
                                  <TableCell></TableCell>
                                  <SubheaderTableCell onClick={() => {
                                    // @ts-ignore
                                    if (openSubheader && openSubheader[0] === groupIndex && openSubheader[1] === index) {
                                      setOpenSubheader(groupIndex)
                                    } else {
                                      setOpenSubheader([groupIndex, index]);
                                    }
                                  }
                                  }>
                                    <Typography variant="tableSubheaderText">
                                      {name}
                                    </Typography>
                                    <Arrow
                                      // @ts-ignore
                                      $isOpen={openSubheader && openSubheader[0] === groupIndex && openSubheader[1] === index}
                                    />
                                  </SubheaderTableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell style={{ padding: 0 }} colSpan={12}>

                          <Collapse in={
                            // @ts-ignore
                            openSubheader !== false && openSubheader[0] === groupIndex && openSubheader[1] === index
                          } timeout="auto" unmountOnExit>
                            <Table>
                              <ExpensesTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={6}
                                collapse
                                handleSettingClick={() => {}}
                              />
                              <TableBody>
                                {
                                  // @ts-ignore
                                  stableSort(rows, getComparator(order, orderBy))
                                  .map((row, index) => {
                                    // @ts-ignore
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                      <TableRow
                                        hover
                                        // @ts-ignore
                                        onClick={(event) => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                      >
                                        <TableCell padding="checkbox"  component="th" scope="row">
                                          <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                              'aria-labelledby': labelId,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          component="th" scope="row"
                                          id={labelId}
                                        >
                                          {row.name}
                                        </TableCell>
                                        <TableCell>{row.value}</TableCell>
                                        <TableCell>{row.amount}</TableCell>
                                        <TableCell>{row.extras?.dimension ?  getDimension(row.extras.dimension) : ''}</TableCell>
                                        <TableCell>
                                          {updateDate(row?.created_at as any)}
                                        </TableCell>
                                        <TableCell>
                                          <Tooltip
                                            arrow
                                            placement="bottom"
                                            tooltipText=""
                                            tooltipContent={(
                                              <MenuList disablePadding>
                                                <MenuListItem disablePadding>
                                                  <MenuListItemButton
                                                    onClick={() => {
                                                      setEditing(row);
                                                      setOpenAddExpenseModal((prev) => !prev);
                                                    }}
                                                  >
                                                    <Text variant="regularText">{t('Редагувати')}</Text>
                                                  </MenuListItemButton>
                                                </MenuListItem>
                                                <MenuListItem disablePadding>
                                                  <MenuListItemButton onClick={() => {
                                                    setDeleteExpensesItem(row?.id as string);
                                                    toggleModal();
                                                  }}>
                                                    <Text variant="regularText" color="red">{t('Видалити')}</Text>
                                                  </MenuListItemButton>
                                                </MenuListItem>
                                              </MenuList>
                                            )}
                                          >
                                            <MenuButton>
                                              <MoreSetingsIcon />
                                            </MenuButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                }
              </>
            ))
          }
        </TableBody>
      </Table>
      {/*<PaginationRow>*/}
      {/*  <PaginationWrap>*/}
      {/*    <Text variant="h6">{t('Сторінка')}  1 - 10</Text>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowLeftIcon />*/}
      {/*    </Button>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowRightIcon />*/}
      {/*    </Button>*/}
      {/*  </PaginationWrap>*/}
      {/*</PaginationRow>*/}
      {/*<SettingsModal isOpen={openSettingsModal} onClose={onCloseSettingsModal} />*/}
      <AddExpenseModal
        isOpen={openAddExpenseModal}
        expense={editing}
        products={products}
        onClose={() => {
          setEditing(null);
          setOpenAddExpenseModal((prev) => !prev);
          refetch();
        }}
      />
      <ConfirmationModal
        deleteHandler={deleteAdditionalLosses}
        onClose={toggleModal}
        isOpen={openDeleteModal}
        title={t('Видалити')+'?'}
      />
    </StyledTableContainer>
  );
}

export default VariableExpensesTable;
