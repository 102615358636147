import { ComponentsOverrides } from '@mui/material';
import { VisueltProFont } from 'styles/theme/typography';

interface muiCssBaselineProps {
  styleOverrides: ComponentsOverrides['MuiCssBaseline'];
}

export const muiCssBaseline = (): muiCssBaselineProps => ({
  styleOverrides: {
    ['input::-webkit-inner-spin-button']: {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '@font-face': [VisueltProFont],
    'body, html, #root': {
      height: '100%',
      overflowY: 'hidden',
      '@media print': {
        height: 'initial'
      },
      '.selected-dates': {
        display: 'none !important',
      },
      '.default-footer': {
        height: 'fit-content',
        '.hint': {
          display: 'none',
        },
      },
      '.grids': {
        padding: 0,
      },
    },
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    a: {
      textDecoration: 'none',
    },
  },
});
