import React from 'react';
// Icons
import { ArrowLeftIcon, CalendarIcon } from 'assets/icons';
// Styles
import { Container, Label, Wrapper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
const titleTableColums = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

interface SalesMonthFiltersProps {
  onPrevMonth: () => void;
  onNextMonth: () => void;
  activeIndex: number;
}

const SalesMonthFilters: React.FC<SalesMonthFiltersProps> = ({ onPrevMonth, onNextMonth, activeIndex }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper onClick={onPrevMonth}>
        <ArrowLeftIcon />

        <Label>
          {t(titleTableColums[activeIndex === 0 ? 11 : activeIndex - 1])} 2022
        </Label>
      </Wrapper>

      <Wrapper sx={{ width: 200 }}>
        <CalendarIcon color="#ffffff" />

        <Label>
          {t(titleTableColums[activeIndex])} 2022
        </Label>
      </Wrapper>

      <Wrapper onClick={onNextMonth}>
        <Label>
          {t(titleTableColums[activeIndex === 11 ? 0 : activeIndex + 1])} 2022
        </Label>

        <ArrowLeftIcon />
      </Wrapper>
    </Container>
  )
};

export default SalesMonthFilters;
