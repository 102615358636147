import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;

  @media (max-width: 640px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 586px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin: 10px 0;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    font-size: 10px;
    margin-bottom: 20px;
  }
`;
