import {
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: left;
  width: 100%;
  margin-bottom: 7px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
`;

export const IconContainer = styled(Box)`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  border-top: 1px solid #EBEBEB;
  margin-top: 15px;
`;

export const WrapperCard = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0 20px;
`;
