import React from 'react';
import { useSelector } from 'react-redux';
// Translation
import { useTranslation, } from 'react-i18next';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { IPlanSalesPipeline, IPipeline } from 'store/dashboard/dashboardTypes';
// Utils
import { isMinusValue } from 'utils/helpers';
import { calcByPlanSalesPipeline } from 'utils/calculates/sales-months'
import { StyledTitle, StyledTableRow } from './styles';

interface TableRowProps {
  plan: IPlanSalesPipeline[] | [];
  activeIndex: number;
}

const TotalTableRow: React.FC<TableRowProps> = ({ plan, activeIndex }) => {
  const { pipeline, residence } = useSelector(selectDashboard);
  const { t } = useTranslation();
  return (
    <StyledTableRow
      sx={{ '& > *': { borderBottom: 'unset' } }}
      isBold
    >
      <StyledTitle
        isBold
        component="th"
        scope="row"
      >
        {t('Загалом')}
      </StyledTitle>

      {calcByPlanSalesPipeline(plan?.[activeIndex], pipeline as IPipeline, 5, residence)?.map((month: any) => (
        <StyledTitle
          isMinus={isMinusValue(month?.money?.toString())}
          isBold
          key={month?.key}
          isRed={month?.isRed}
        >
          {month?.money}
        </StyledTitle>
      ))}
    </StyledTableRow>
  )
}

export default TotalTableRow;
