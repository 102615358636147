import React, {
  FC, useState, ChangeEvent, useEffect,
} from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { IconButton } from '@mui/material/';
// Icons
import { EditValueIcon, SaveValueIcon, DeleteValueIcon } from 'assets/icons';
// Styles
import {
  Container, Label, StyledInput, ContainerInput,
} from './styles';
import {selectDashboard} from "store/dashboard/dashboardSlice";

interface TransitionInputProps {
  defaultValue: number | string;
  priceIcon?: string;
  editPrice?: (product_id: string, price: number) => void;
  id?: string;
  productId: string;
  isPercentIcon?: string;
}

const TransitionInput: FC<TransitionInputProps> = ({
  defaultValue, priceIcon, isPercentIcon, editPrice, id,productId
}) => {
  const { t } = useTranslation();
  const { products } = useSelector(selectDashboard);
  const [value, setValue] = useState<number | string>('');
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    if (!value) {
      setChecked(false);
      return;
    }

    setChecked((prev) => !prev);

    if (editPrice && id) {
      editPrice(id, +value);
    }
  };
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(() => e.target.value);
  }

  const onSetDefaultValue = () => {
    setValue(defaultValue);
    setChecked(false);
  };

  const openEdit = () => setChecked((prev) => !prev);

  const valueTransform = (value: number | string) => {
    const product = products.find(product => product.id === productId);
    if (!product) return 0;
    const result = +value/100 * product?.amount;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Container isDisabled={checked}>
        <Label isDisabled={isPercentIcon !== 'plan_sales'}>
          {priceIcon}
          {isPercentIcon === 'plan_sales' ?
            `${value} % `
            :
            `${valueTransform(value)} ${t('шт')}`
          }
        </Label>

        {isPercentIcon === 'plan_sales' ?
          <IconButton onClick={openEdit}>
            <EditValueIcon/>
          </IconButton> : ''
        }
      </Container>

      <ContainerInput isDisabled={checked} onMouseLeave={handleChangeChecked}>
        <StyledInput error={!value} name="price" type="number" value={value} onChange={onChangeValue} />

        <IconButton onClick={handleChangeChecked}>
          <SaveValueIcon />
        </IconButton>

        <IconButton onClick={onSetDefaultValue}>
          <DeleteValueIcon />
        </IconButton>
      </ContainerInput>
    </>
  );
};

export default TransitionInput;
