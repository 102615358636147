import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { useGetSalesPlanQuery } from 'store/dashboard/dashboardServices';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { getUtcFormatDate } from 'utils/helpers/index';
import { DATE, month } from "utils/constants/date";
// Components
import TotalTableRow from './TotalTableRow';
import TableRow from './TableRow';
import SalesMonthFilters from './SalesMonthFilters';
import TableHeader from './TableHeader';
// Styles
import {Container, Label, TableStyled, TitleContainer} from './styles';
import {CalendarIcon} from "../../../../assets/icons";

interface IDate {
  startDate: any;
  endDate: any;
}

const SalesMonthsPrint: React.FC = () => {
  const { t } = useTranslation();
  const { pipeline_id } = useParams();
  const { planSales } = useSelector(selectDashboard);

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data } = useGetSalesPlanQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  return (
    <>
      {month.map((item, index) => (
        <Container>
          <TitleContainer>
            <CalendarIcon color="#ffffff" />
            <Label>{t(item)}</Label>
          </TitleContainer>

          <TableContainer>
            <TableStyled aria-label="collapsible table">
              <TableHeader />

              <TableBody>
                {planSales?.map(product => (
                  <TableRow product={product} activeIndex={index} />
                ))}

                <TotalTableRow plan={data || []} activeIndex={index} />
              </TableBody>

            </TableStyled>
          </TableContainer>
        </Container>

      ))}
    </>
  )
}

export default SalesMonthsPrint;
