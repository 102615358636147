import React, {FC, useState} from 'react';
import { useNavigate } from 'react-router'
import { CardMedia, Button } from '@mui/material';
// Styles
import { Container, Wrapper, WrapperButtons } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import {AccordionContainer, ContentContainer, ImgContainer} from "../Tutor/AddTutor/styles";
import CreatePipelineImg from "assets/images/create-pipeline-pic.png";
import Accordion from "../../components/UI/Accordion";
import HelpPageEn from "../HelpPage/HelpPage.en";
import HelpPageUk from "../HelpPage/HelpPage.uk";

const VideoLesson: FC = () => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const navigate = useNavigate();

  const redirectToProjects = () => {
    navigate('/');
  }

  const redirectToProfile = () => {
    navigate('/profile');
  }
  const { t, i18n } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <ImgContainer src={CreatePipelineImg}/>
      </Wrapper>

      <WrapperButtons>
        <Button
          onClick={redirectToProfile}
          sx={{ width: '300px' }}
          variant="light"
        >
          {t('До попереднього кроку')}
        </Button>

        <Button
          onClick={redirectToProjects}
          sx={{ width: '300px' }}
          variant="contained"
        >
          {t('Наступний крок')}
        </Button>
      </WrapperButtons>

      <AccordionContainer>
        <Accordion title={t('Ознайомитись з тим як це працює')}
                   handleClick={() => setOpenAccordion((prevState) => !prevState)}
                   open={openAccordion}
        >
          <ContentContainer>
            {i18n.language === 'en' ? <HelpPageEn/> : <HelpPageUk />}
            <HelpPageEn/>
          </ContentContainer>
        </Accordion>
      </AccordionContainer>
    </Container>
  )
}

export default VideoLesson;
