import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  margin-bottom: 30px;
  max-height: 79px;

  .name-input {
    max-width: 100%;
  }
  .amount-input {
    max-width: 120px;
  }

  & > .MuiFormControl-root, .MuiToggleButtonGroup-root {
    margin-right: 20px;
  }
`;

export const DeleteBtn = styled(Button)`
  background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  align-self: end;
  &:hover, &:focus {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  }
`;
