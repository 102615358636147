import React from 'react';
import {useTranslation, } from "react-i18next";
import {Container, Wrapper, H1, SubTitle} from "./styles";
import Footer from "components/Footer";
import HelpPageEn from "./HelpPage.en";
import HelpPageUk from "./HelpPage.uk";

const HelpPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const switchContent = () => {
    if (i18n.language === 'en') {
      return <HelpPageEn />
    }
    if (i18n.language === 'uk') {
      return <HelpPageUk />
    }
  }

  return (
    <Container>
      <Wrapper>
        <H1>{t('Домомога')}</H1>

        <SubTitle>
          {t('редакція від 01.09.2022')}
        </SubTitle>
        {switchContent()}
      </Wrapper>
      <Footer isSpace />
    </Container>
  )
};

export default HelpPage;
