import { IPipeline, IPipelineEmployee, IPlanSalesPipeline, IAdditionalLosseResponse, IIncome } from 'store/dashboard/dashboardTypes';
import {calcTotalAdminEmployees} from "./share-calculation";

const calcTotalAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};

const calcIncomMonth = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map(month => ({
    income: month.result.income
  }))

  return data;
}

const calcExpensesMonth = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map(month => {
    const materialCosts = month?.result?.expenseGroups?.Materials?.loss || 0;
    const packagingCosts = month?.result?.expenseGroups?.Packaging?.loss || 0;
    const labor = month?.result?.expenseGroups?.labor?.loss || 0;

    const sum = materialCosts + packagingCosts + labor;

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcGrossProfitMonth = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map((month, i) => {
    const income = month.result.income;

    const sum = income - calcExpensesMonth(plan)[i].income

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcFixedCostMonth = (plan: IPlanSalesPipeline[], costs: number) => {
  const data = plan?.map(month => {
    const additionalLosses = calcTotalAdditionalLosses(month?.result?.additional_losses?.expenses || []);

    const sum = additionalLosses + costs;

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcTaxesMonth = (plan: IPlanSalesPipeline[], tax: number) => {
  const data = plan?.map(month => {
    const income = month.result.income;

    const sum = income * ((tax / 100));

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcOperatingProfitMonth = (plan: IPlanSalesPipeline[], grossProfit: IIncome[], fixedCostMonths: IIncome[], taxesMonths: IIncome[]) => {
  const data = plan?.map((month, i) => {
    const total = grossProfit[i].income - fixedCostMonths[i].income - taxesMonths[i].income;

    return {
      income: Math.round(total),
    }
  })

  return data;
}

export const calcProfitAndLoss = (
  pipeline: IPipeline,
  plan: IPlanSalesPipeline[],
  tax: number,
  residence: string
) => {
  // Доходы
  const calcIncomMonths = calcIncomMonth(plan);

  // Расходы
  const calcExpensesMonths = calcExpensesMonth(plan)

  // Валовий прибуток
  const calcGrossProfit = calcGrossProfitMonth(plan)

  // Постійні витрати
  const calcFixedCostMonths = calcFixedCostMonth(plan, calcTotalAdminEmployees(pipeline?.employees, residence))

  // Податки на бізнес
  const calcTaxesMonths = calcTaxesMonth(plan, tax)

  // Чистий прибуток
  const calcNetProfit = calcOperatingProfitMonth(plan, calcGrossProfit, calcFixedCostMonths, calcTaxesMonths)

  return [
    {
      name: 'Дохід',
      key: 1,
      month: calcIncomMonths,
      color: '#07B169',
    },
    {
      name: 'Собівартість реалізованої продукції',
      key: 2,
      month: calcExpensesMonths,
      color: '#D52727',
    },
    {
      name: 'Валовий дохід від операцій',
      key: 5,
      month: calcGrossProfit,
      color: '#07B169',
    },
    {
      name: 'Постійні витрати',
      key: 4,
      month: calcFixedCostMonths,
      color: '#D52727',
    },
    {
      name: 'Податки на бізнес',
      key: 6,
      month: calcTaxesMonths,
      color: '#D52727',
    },
    {
      name: 'Чистий прибуток',
      key: 7,
      month: calcNetProfit,
      isBackground: true,
      color: '#07B169',
      isBold: true,
    },
  ];
};
