import {
  IProduct, IMaterial, IEmployeeProduct, IEmployee, IMonthlySalesPlan,
} from 'store/pipeline/pipelineTypes';
import { IAdditionalLosses } from 'store/tutor/tutorTypes';
import {
  IPipeline,
  IPipelineEmployee,
  IPlanSalesPipeline,
  IAdditionalLosseResponse,
  IIncome
} from 'store/dashboard/dashboardTypes';
import { calcTotalAdminEmployees } from "./share-calculation";

export interface ICashflowTableRows {
  name: string;
  money: number;
  key: number;
}

export interface ICashflowTable {
  name: string;
  key: number;
  isBackground?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
  color?: string;
  rows?: { month: { income: number }[], name: string; }[];
  month?: { income: number }[];
}

const calcTotalAdditionalLosses = (additianal: IAdditionalLosseResponse[]) => {
  if (additianal?.length) {
    const totalValue = additianal?.reduce((acc: number, item: IAdditionalLosseResponse) => {
      const value = item?.extras?.isDuration && item?.value ? +item?.value / 12 : item?.value;

      const sum = value ? +value : 0;

      acc += sum;

      return +acc;
    }, 0);

    return totalValue;
  }

  return 0;
};


// Сума всех материалов по одному продукту
export const calcSumOfAllMaterials = (materials: IMaterial[]) => {
  const totalValue = materials?.reduce((acc: number, item: IMaterial) => {
    acc += (+item?.per_product * +item?.price);

    return +acc;
  }, 0);

  return totalValue;
};

const calcTotalSalesProduct = (plans: IMonthlySalesPlan[]) => {
  const totalValue = plans?.reduce((acc: number, item: IMonthlySalesPlan) => {
    acc += item?.sum_month_sales;

    return +acc;
  }, 0);

  return totalValue;
};

const sumTotalSalesProducts = (products: IProduct[]) => {
  const totalValue = products?.reduce((acc: number, item: IProduct) => {
    acc += (item?.totalSalesByPlan ? item?.totalSalesByPlan : 0);

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт доходов
export const calcSumPductCosts = (products: IProduct[]) => {
  const totalSalaryByProduct = products?.map((product) => ({
    ...product,
    totalSalesByPlan: calcTotalSalesProduct(product?.monthlySalesPlan) * product?.price,
  }));

  const totalSalary = sumTotalSalesProducts(totalSalaryByProduct);

  // const totalValue = products?.reduce((acc: number, item: IProduct) => {
  //   acc = acc + (+item?.month_sales * +item?.price);

  //   return +acc;
  // }, 0);

  return totalSalary;
};

// Расчёт потраченого времени одного работника на создание одного продукта взависимости от количества продаж продукта
export const calcEmployeeHoursByProduct = (EmployeeProducts: IEmployeeProduct[], month_sales: number) => {
  const updateEmployeeProducts = EmployeeProducts?.map((item) => ({
    ...item,
    totalHours: +item?.hours * month_sales,
  }));

  return updateEmployeeProducts;
};

// Работники по всем продуктам
export const employeeAllProducts = (products: IProduct[]) => {
  const employees: IEmployeeProduct[] = [];

  products?.map((item) => {
    employees.push(...item?.EmployeeProducts);
  });

  return employees;
};

// Количество часов одного работника по всем продуктам
const hoursEmployeeProducts = (employees: IEmployeeProduct[], EmployeeProducts: IEmployeeProduct[]) => {
  let newEmployee: IEmployeeProduct[] = [];

  EmployeeProducts?.forEach((productEmployee) => {
    let hours = 0;

    employees?.forEach((allEmployees) => {
      if (productEmployee?.employee_id === allEmployees?.employee_id) {
        hours += +allEmployees?.totalHours;
      }
    });

    newEmployee = [...newEmployee, { ...productEmployee, totalHourPipeline: hours }];
  });

  return newEmployee;
};

// Расчёт ППФО налога
const calcPpfo = (salary: number) => {
  // Расчёт 18%
  const percentEighteen = (1 / 100) * 18;
  // Расчёт 1.5%
  const percentOneAndHalf = (1 / 100) * 1.5;

  const ppfo = salary / (1 - percentEighteen - percentOneAndHalf) * percentEighteen;

  return Math.round(ppfo);
};

// Расчёт налога Военный сбор
const calcmilitaryLevy = (salary: number) => {
  // Расчёт 1.5%
  const percentOneAndHalf = (1 / 100) * 1.5;

  const militaryLevy = (salary + calcPpfo(salary)) * percentOneAndHalf;

  return Math.round(militaryLevy);
};

// Расчёт налога Социальный взнос
const calcSocialContribution = (salary: number) => {
  const calcPercent = (1 / 100) * 22;
  const socialContribution = (salary + calcPpfo(salary) + calcmilitaryLevy(salary)) * calcPercent;

  return Math.round(socialContribution);
};

const salaryFixed = (employee: IEmployeeProduct) => {
  // Расчёт зарплаты с фиксированой ставкой

  // Ставка
  const employeeSalary = employee?.amount ? +employee?.amount : 1;
  // Количество часов отработаных емплоерсом по пайплайну
  const totalHourPipeline = employee?.totalHourPipeline ? +employee?.totalHourPipeline : 1;
  // Количество часов отработаных по продукту
  const workingHours = +employee?.totalHours;
  // Чистая зарплата емплоера по продукту
  const salaryByProduct = (employeeSalary / totalHourPipeline) * workingHours;

  // Зарплата с учётом налогов
  const salaryWithTaxes = salaryByProduct + calcPpfo(salaryByProduct) + calcmilitaryLevy(salaryByProduct) + calcSocialContribution(salaryByProduct);

  return {
    ...employee,
    salary: salaryByProduct,
    salaryWithTaxes,
  };
};

const salaryHourly = (employee: IEmployeeProduct) => {
  // Расчёт зарплаты с почасовой ставкой

  // Ставка
  const employeeSalary = employee?.amount ? +employee?.amount : 1;
  // Количество часов отработаных по продукту
  const workingHours = +employee?.totalHours;
  // Чистая зарплата умплоера по продукту
  const salaryByProduct = employeeSalary * workingHours;

  return {
    ...employee,
    salary: salaryByProduct,
  };
};

// расчёт зарплаты всех емплоерсов по продукту
const payroll = (employeesProduct: IEmployeeProduct[], employees: IEmployee[]) => {
  const updateEmployees = employeesProduct?.map((employee) => {
    const item: IEmployeeProduct | any = employees?.find(({ employee_id }) => employee?.employee_id === employee_id);

    if (item?.amount_type === 'fixed') {
      return salaryFixed({ ...item, totalHourPipeline: employee?.totalHourPipeline, totalHours: employee?.totalHours });
    }
    return salaryHourly({ ...item, totalHourPipeline: employee?.totalHourPipeline, totalHours: employee?.totalHours });
  });

  return updateEmployees;
};

// Сумма зарплаты по одному продукту
const calcSalaryProduct = (employees: IEmployeeProduct[]) => {
  const totalValue = employees?.reduce((acc: number, item: IEmployeeProduct) => {
    acc += (item?.salaryWithTaxes ? item?.salaryWithTaxes : 0);

    return +acc;
  }, 0);

  return totalValue;
};

// Расчёт всех материалов по всем продуктам
const caclAllMaterialProducts = (products: IProduct[]) => {
  const totalValue = products?.reduce((acc: number, item: IProduct) => {
    acc += (item?.priceAllMaterials ? item?.priceAllMaterials : 0);

    return +acc;
  }, 0);

  return totalValue;
};

// Pасчёт суммы всех материалов по одному продукту
const caclpriceAllMaterials = (products: IProduct[]) => {
  const array = products?.map((item) => ({
    ...item,
    priceAllMaterials: calcSumOfAllMaterials(item?.materials) * item?.month_sales,
  }));

  const sum = caclAllMaterialProducts(array);

  return sum;
};

// Расчёт всей зарплаты емплоерсов
const caclAllEmployeeProducts = (products: IProduct[]) => {
  const totalValue = products?.reduce((acc: number, item: IProduct) => {
    acc += (item?.salaryEmployees ? item?.salaryEmployees : 0);

    return +acc;
  }, 0);

  return totalValue;
};

// Добавление поля сума зарплаты по пролукту
const calcSalaryProducts = (products: IProduct[], employees: IEmployee[]) => {
  // все емплоерсы по пайплайну
  const allEmployees = employeeAllProducts(products);

  const productSalary = products?.map((item) => {
    const employeeProducts = hoursEmployeeProducts(allEmployees, item?.EmployeeProducts);
    const salaryEmployee = payroll(employeeProducts, employees);
    const calcSalary = calcSalaryProduct(salaryEmployee);

    return {
      ...item,
      salaryEmployees: Math.round(calcSalary),
    };
  });

  const sum = caclAllEmployeeProducts(productSalary);

  return sum;
};

// Расчёт Постоянных витрат Additional Losses 5 step
const calcAdditionalLosses = (additionalLosses?: IAdditionalLosses[]) => {
  if (additionalLosses?.length) {
    return 1;
  }

  return 0;
};

// Расчёт Податки на бізнес
const calcBusinessTaxes = (income: number) => {
  // taxRate
  const percent = income / 100 * 5;
  const sum = income + percent;

  return sum;
};

// Расчёт средней зарплаты по плану продаж
const calcSalaryByPlanSales = (salary: number, monthlySalesPlan: IMonthlySalesPlan[]) => {
  const calcPercent = (percent: number) => {
    const sum = salary / 100 * percent;

    return Math.round(sum);
  };

  const updateMonthlySalesPlan = monthlySalesPlan?.map((month) => ({
    ...month,
    salary: calcPercent(month?.plan_sales),
  }));

  // Сумма зарплаты по всех месяцах
  const calcTotalSalary = () => {
    const totalValue = updateMonthlySalesPlan?.reduce((acc: number, item) => {
      acc += item?.salary;

      return +acc;
    }, 0);

    return totalValue;
  };

  const sum = calcTotalSalary() / 12;

  return sum;
};

// Расчёт среднего колочество продаж по плану продаж
const calcmonthSalesByPlanSales = (monthlySalesPlan: IMonthlySalesPlan[]) => {
  const calcTotalSales = () => {
    const totalValue = monthlySalesPlan?.reduce((acc: number, item) => {
      acc += item?.sum_month_sales;

      return +acc;
    }, 0);

    return totalValue;
  };

  const sum = calcTotalSales() / 12;

  return sum;
};

export const calculate = (
  product: IProduct,
  products: IProduct[],
  employees: IEmployee[],
  additionalLosses?: IAdditionalLosses[],
) => {
  // cумма всех материалов
  const sumMaterials = calcSumOfAllMaterials(product?.materials);

  // Расчёт зарплаты
  // все емплоерсы по пайплайну
  const allEmployees = employeeAllProducts(products);
  // все емплоерсы с суммой часов по одному продукту
  const employeeProducts = hoursEmployeeProducts(allEmployees, product?.EmployeeProducts);
  // зарплата емплоерсов по продукту
  const salaryEmployee = payroll(employeeProducts, employees);
  // Сумма зарплаты по продукту
  const calcSalary = calcSalaryProduct(salaryEmployee);

  // Средняя зарплата по плану продаж
  const sumSalaryByPlanManth = calcSalaryByPlanSales(calcSalary, product?.monthlySalesPlan);

  // Расчёт среднего колочество продаж по плану продаж
  const sumMonthSalesByPlanSales = calcmonthSalesByPlanSales(product?.monthlySalesPlan);

  // Пряма собівартість -> cумма всех материалов -> средняя зарплата по плану продаж -> Среднее количество продаж по плану продаж
  const sum = (sumMaterials + 0) + (sumSalaryByPlanManth / sumMonthSalesByPlanSales);

  // Расчёт маржи
  const marg = (product?.price - sum) / (product?.price);

  // Расчёт таблицы Кешфлоу

  // Доходы //
  const caclProfit = product?.month_sales * product?.price;
  // Доходы //

  // Расчёт затрат материалов по всем продуктам
  const allMaterialPrice = caclpriceAllMaterials(products);

  // Расчёт зарплаты по всем продуктам
  const allEmployeesSalary = calcSalaryProducts(products, employees);

  // Расчёт Змінні витрати
  const variableCosts = allMaterialPrice + allEmployeesSalary;

  // Расчёт Постійні витрати
  // Затраты по 5 степу
  const calcAdditionalLossesCosts = calcAdditionalLosses(additionalLosses);

  // Затраты по команде администраторов
  const adminTeam = 0;

  const fixedCosts = calcAdditionalLossesCosts + adminTeam;

  // Податки на бізнес
  const businessTaxes = calcBusinessTaxes(caclProfit);

  const createdataTable = () => [
    {
      name: 'На рахунку на початок місяця',
      money: 0,
      key: 1,
    },
    {
      name: 'Операційний потік',
      money: caclProfit + variableCosts + fixedCosts + businessTaxes,
      key: 2,
      rows: [
        {
          name: 'Дохід',
          money: caclProfit,
        },
        {
          name: 'Змінні витрати',
          money: variableCosts,
        },
        {
          name: 'Постійні витрати',
          money: fixedCosts,
        },
        {
          name: 'Податки на бізнес',
          money: businessTaxes,
        },
      ],
    },
    {
      name: 'Інвестиційний потік',
      money: 0,
      key: 3,
      rows: [
        {
          name: 'Земля',
          money: 0,
        },
        {
          name: 'Нерухомість',
          money: 0,
        },
        {
          name: 'Обладнання',
          money: 0,
        },
        {
          name: 'R&D',
          money: 0,
        },
      ],
    },
    {
      name: 'Фінансовий потік',
      money: 226575,
      key: 4,
      rows: [
        {
          name: 'Початкові інвестиції',
          money: 226575,
        },
      ],
    },
    {
      name: 'Чистий грошовий потік',
      money: caclProfit + variableCosts + fixedCosts + businessTaxes + 0 + 226575,
      key: 5,
    },
    {
      name: 'На рахунку на кінець місяця',
      money: caclProfit + variableCosts + fixedCosts + businessTaxes + 0 + 226575,
      key: 6,
    },
  ];

  return {
    sum: isFinite(sum) ? sum : 0,
    marg: isFinite(marg) ? marg : 0,
    month: product?.plan_month,
    cashflowTable: createdataTable(),
  };
};

const calcIncomMonth = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map(month => ({
    income: month.result.income
  }))

  return data;
}

const calcExpensesMonth = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map(month => {
    const materialCosts = month?.result?.expenseGroups?.Materials?.loss || 0;
    const packagingCosts = month?.result?.expenseGroups?.Packaging?.loss || 0;
    const labor = month?.result?.expenseGroups?.labor?.loss || 0;

    const sum = materialCosts + packagingCosts + labor;

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcFixedCostMonth = (plan: IPlanSalesPipeline[], costs: number) => {
  const data = plan?.map(month => {
    const additionalLosses = calcTotalAdditionalLosses(month?.result?.additional_losses?.expenses || []);

    const sum = additionalLosses + costs;

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcTaxesMonth = (plan: IPlanSalesPipeline[], tax: number) => {
  const data = plan?.map(month => {
    const income = month.result.income;

    const sum = income * ((tax / 100));

    return {
      income: Math.round(sum),
    }
  })

  return data;
}

const calcStartCostMonth = (calcOperationalFlowMonth: IIncome[]) => {
  return calcOperationalFlowMonth?.reduce((sum: IIncome[], next: IIncome, i: number) => {

    sum.push({income: sum[i].income + next.income})

    return sum;
  }, [{ income: 0 }]).slice(0, -1)
}

const calcClose = (plan: IPlanSalesPipeline[]) => {
  const data = plan?.map((month, i) => {
    const income = month.result.income;
    return {
      income: 0,
    }
  })

  return data;
}

interface Income {
  income: number
}

const calcOperationalFlow = (
  plan: IPlanSalesPipeline[],
  calcIncomMonths: Income[],
  calcExpensesMonths: Income[],
  calcFixedCostMonths: Income[],
  calcTaxesMonths: Income[]
) => {
  return plan.map((item, index) => (
    {
      income: calcIncomMonths[index].income -
        calcExpensesMonths[index].income -
        calcFixedCostMonths[index].income -
        calcTaxesMonths[index].income
    }
  ))
}

const calcCostEndMonth = (calcStartCostMonths: IIncome[], calcOperationalFlowMonth: IIncome[]) => {
  return calcStartCostMonths?.map((month, i) =>{
    return {
      income: month.income + calcOperationalFlowMonth[i].income
    }
  })
}

export const calcCashflowTable = (
  pipeline: IPipeline,
  plan: IPlanSalesPipeline[],
  tax: number,
  residence: string
) => {
  // Доходы
  const calcIncomMonths = calcIncomMonth(plan);

  // Расходы
  const calcExpensesMonths = calcExpensesMonth(plan)

  // Постійні витрати
  const calcFixedCostMonths = calcFixedCostMonth(plan, calcTotalAdminEmployees(pipeline?.employees, residence))

  // Податки на бізнес
  const calcTaxesMonths = calcTaxesMonth(plan, tax)

  // Операційний потік, Чистий грошовий потік
  const calcOperationalFlowMonth = calcOperationalFlow(plan, calcIncomMonths, calcExpensesMonths, calcFixedCostMonths, calcTaxesMonths);

  // Деньги на начало месяца
  const calcStartCostMonths = calcStartCostMonth(calcOperationalFlowMonth)

  // Деньги на конец месяца
  const calcCostEndMonths = calcCostEndMonth(calcStartCostMonths, calcOperationalFlowMonth);

  const closeCalc = calcClose(plan)

  return [
    {
      name: 'На рахунку на початок місяця',
      key: 1,
      month: calcStartCostMonths,
      isBold: true,
    },
    {
      name: 'Операційний потік',
      month: calcOperationalFlowMonth,
      key: 2,
      isBold: false,
      rows: [
        {
          name: 'Дохід',
          month: calcIncomMonths
        },
        {
          name: 'Змінні витрати',
          month: calcExpensesMonths
        },
        {
          name: 'Постійні витрати',
          month: calcFixedCostMonths
        },
        {
          name: 'Податки на бізнес',
          month: calcTaxesMonths,
        },
      ],
    },
    {
      name: 'Інвестиційний потік',
      month: closeCalc,
      key: 3,
      isBold: false,
    },
    {
      name: 'Чистий грошовий потік',
      key: 5,
      month: calcOperationalFlowMonth,
      isBold: false,
    },
    {
      name: 'На рахунку на кінець місяця',
      key: 6,
      month: calcCostEndMonths,
      isBold: true,
    },
  ]
}
