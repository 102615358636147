import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Input from 'components/UI/Input';

export const FormRow = styled('div')`
display: flex;
align-items: flex-end;
margin-bottom: 20px;
`;

export const InputName = styled(Input)`
  && {
    padding-right: 20px;
    .MuiOutlinedInput-root {
        height: 55px;
    }

    .MuiFormHelperText-root {
      display: none;
    }
  }
`;

export const CreateButton = styled(Button)`
  && {
    height: 55px;
    min-width: 327px;
  }
`;
