import React from 'react';
import { UseFormReturn, Controller, SubmitHandler } from 'react-hook-form';
import { authRoutePath } from 'routes/routePath';
import { useTranslation } from "react-i18next";
// Icons
import { Logo } from 'assets/logos';
// Global Components
import Input from 'components/UI/Input';
// Interfaces
import { IPasswordRecovery } from 'interfaces/auth';
// Utils
import { isMatch } from 'utils/validations/password-recovery';
import { InputsRules } from 'utils/constants/auth';
// Styles
import {
  Container, Form, Title, SubTitle, Button, Link,
} from './styles';

interface PasswordRecoveryFormProps {
  onSubmit: SubmitHandler<IPasswordRecovery>;
  formState: UseFormReturn<IPasswordRecovery>;
}

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({ formState, onSubmit }) => {
  const { t } = useTranslation();
  const { isValid } = formState.formState;
  const values = formState.watch();
  const { password, confirmPassword } = formState.formState.errors;

  return (
    <Container>
      <Form>
        <Logo />

        <Title variant="h1">
          {t("Введіть новий пароль")}
        </Title>

        <SubTitle variant="h3">
          {t('Мінімум 10 символів. Пароль має містити має в собі заголовні, малі букви та цифри.')}
        </SubTitle>

        <Controller
          name="password"
          control={formState.control}
          render={({ field }) => (
            <Input
              isSpaceBottom={10}
              label="Пароль"
              type="password"
              error={!!password?.message}
              helperText={password?.message}
              {...field}
            />
          )}
          rules={{
            required: {
              value: InputsRules.required.value,
              message: t(InputsRules.required.message)
            },
            pattern: {
              value: InputsRules.patternPassword.value,
              message: t(InputsRules.patternPassword.message),
            },
          }}
        />

        <Controller
          name="confirmPassword"
          control={formState.control}
          render={({ field }) => (
            <Input
              label={t('Повторіть пароль')}
              type="password"
              error={!!confirmPassword?.message}
              helperText={confirmPassword?.message}
              {...field}
            />
          )}
          rules={{
            required: {
              value: InputsRules.required.value,
              message: t(InputsRules.required.message)
            },
            pattern: {
              value: InputsRules.patternPassword.value,
              message: t(InputsRules.patternPassword.message),
            },
            validate: (value) => {
              const result = isMatch(value, values.password);
              if (typeof (result) === "string") {
                return t(result);
              } else {
                return result
              }
            },
          }}
        />

        <Button
          onClick={formState.handleSubmit(onSubmit)}
          disabled={!isValid}
          variant="contained"
          fullWidth
        >
          {t('Підтвердити зміну паролю')}
        </Button>
      </Form>

      <Link to={authRoutePath.LOGIN}>
        {t('Повернутись')}
      </Link>
    </Container>
  );
};

export default PasswordRecoveryForm;
