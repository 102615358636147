import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  cursor: pointer;
`;

export const TutorContainer = styled('div')`
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 30px;
  overflow-y: auto;
  height: calc(100vh - 60px);
  .MuiCardMedia-root {
    border-radius: 10px;
    height: 50vh;
    margin-bottom: 30px;
  }
`;

export const AccordionContainer = styled('div')`
  padding-top: 30px;
`;

export const ContentContainer = styled('div')`
  background-color: ${({ theme }) => theme?.palette?.primary?.contrastText};
`;

export const ImgContainer = styled('img')`
  display: block;
  width: 100%;
  margin-bottom: 30px;
`


