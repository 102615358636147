import React, { useState, useCallback, useEffect } from 'react';
import {
  Controller, Control, FieldErrors, UseFormTrigger, useWatch, UseFieldArrayUpdate,
} from 'react-hook-form';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { StyledInput } from 'components/InlineEditor/styles';
import { Option as OptionType, Option } from 'types/select';
// Store
import { ICreateAdditionalCosts } from 'store/tutors/tutorsTypes';
import SelectHeader from './SelectHeader';
import SelectFooter from './SelectFooter';
import OptionsList from './OptionsList';
// Styles
import {
  Container,
  OptionsListWrap,
  FormHelperText,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const mockData = [
  {
    value: 'Адміністративно-управліньскі',
    label: 'Адміністративно-управліньскі',
  },
  {
    value: 'Непрямі витрати на виробництво',
    label: 'Непрямі витрати на виробництво',
  },
  {
    value: 'Маркетинг та продаж',
    label: 'Маркетинг та продаж',
  },
  {
    value: 'Інвестиції',
    label: 'Інвестиції',
  },
];

interface ICreatableSelect {
  control: Control<{ additionalCosts: ICreateAdditionalCosts[] }>,
  errors: FieldErrors<{ additionalCosts: ICreateAdditionalCosts[] }>
  index: number,
  trigger: UseFormTrigger<{ additionalCosts: ICreateAdditionalCosts[] }>
  update: UseFieldArrayUpdate<{ additionalCosts: ICreateAdditionalCosts[] }>
}

const CreatableSelect: React.FC<ICreatableSelect> = ({
  control,
  index,
  errors,
  trigger,
  update,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<Option | undefined>();
  const [selectedForDelete, setSelectedForDelete] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(mockData);
  const [filteredData, setFilteredData] = useState(data);

  const watched = useWatch({ control });

  useEffect(() => {
    if (watched?.additionalCosts && watched?.additionalCosts?.[index]) {
      setSelected(mockData.find(({ label }) => (label === watched?.additionalCosts?.[index].category)));
    }
  }, []);

  const openHandler = () => {
    if (isOpen) {
      closeHandler();
    } else {
      setIsOpen((prev) => !prev);
    }
  };

  const closeHandler = () => {
    setIsOpen(false);
    setSelectedForDelete(false);
  };

  const handleSelect = useCallback(async (value: OptionType) => {
    setSelected(value);

    await update(index, {
      ...watched?.additionalCosts?.[index] as any,
      category: value.value,
    });

    await trigger(`additionalCosts.${index}.category`);
  }, [trigger, watched]);

  const findOption = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(evt.currentTarget.value);
    const filtered = data.filter(({ label }) => (
      label.toLowerCase().includes(evt.currentTarget.value.toLowerCase())
    ));
    setFilteredData(filtered);
  }, [data, searchValue]);

  const removeHandler = () => {
    setData(data.filter(
      (item) => (selected && selected.value !== item.value),
    ));
    setFilteredData(filteredData.filter(({ value }) => (
      selected && selected.value !== value
    )));
    setSelectedForDelete(false);
    setSelected(undefined);
  };

  const addHandler = () => {
    data.push({
      value: searchValue,
      label: searchValue,
    });
    setFilteredData(data);
    setSearchValue('');
  };

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <Container $isOpen={isOpen} $hasError={!!errors?.additionalCosts?.[index]?.category?.message}>
        <Controller
          render={({ field }) => (
            <StyledInput
              error={!!errors?.additionalCosts?.[index]?.category?.message}
              helperText={errors?.additionalCosts?.[index]?.category?.message}
              label=""
              {...field}
              value={selected?.value}
              className="hidden-input"
            />
          )}
          control={control}
          name={`additionalCosts.${index}.category`}
          rules={{
            required: t('Ви не обрали жодного варіанту'),
          }}
        />
        <SelectHeader toggleDropdown={openHandler} isOpen={isOpen}>
          {(selected && t(selected.label)) || t('Додати підрозділ')}
        </SelectHeader>
        <OptionsListWrap $isOpen={isOpen}>
          <StyledInput
            label=""
            onChange={findOption}
            value={searchValue}
          />
          <OptionsList
            setSelected={handleSelect}
            selected={selected}
            selectedForDelete={selectedForDelete}
            data={filteredData}
          />
          <SelectFooter
            enableDelete={!!selected}
            enableAdd={!!searchValue && filteredData.length === 0}
            selectForDelete={() => setSelectedForDelete(!selectedForDelete)}
            selectedForDelete={selectedForDelete}
            removeElement={removeHandler}
            addElement={addHandler}
          />
        </OptionsListWrap>
        <FormHelperText
          $isOpen={isOpen}
          $hasError={!!errors?.additionalCosts?.[index]?.category?.message}
        >
          {errors?.additionalCosts?.[index]?.category?.message}
        </FormHelperText>
      </Container>
    </ClickAwayListener>
  );
};

export default CreatableSelect;
