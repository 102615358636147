import { css, styled } from '@mui/material/styles';
import { List, Button } from '@mui/material';
import { ArrowDownIcon } from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('div')`
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;

  .callapse {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-top: none;
    border-radius: 0 0 8px 8px;
  }
`;

export const CollapseHeader = styled('a')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px 8px 0px 0px;
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};

  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};
`;

export const ListStyled = styled(List)`
  margin: 30px;
  padding: 0;
`;

export const LinkButton = styled(Button)`
  margin-left: 30px;
  padding-left: 0;
  && {
    text-decoration: none;
  }
`;
