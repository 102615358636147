import React, {useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import { IProduct } from 'interfaces/product';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
// Store
import { useDeleteProductMutation } from 'store/dashboard/dashboardServices';
// Icons
import {
  MoreSetingsIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from 'assets/icons';
// Components
import Tooltip from 'components/UI/Tooltip';
// Styles
import {
  SourcesWrap,
  SourcesContent,
  MenuList,
  MenuListItem,
  Text,
  MenuListItemButton,
  MenuButton,
  TooltipLink,
} from './styles';
import ConfirmationModal from "components/ConfirmationModal";

interface ProductTableRowProps {
  isSelected: (name: string) => boolean;
  product: IProduct;
  index: number;
}


const ProductTableRow: React.FC<ProductTableRowProps> = ({ isSelected, product, index }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { project_id, pipeline_id, product_id } = useParams<string>();
  const isItemSelected = isSelected(product?.name);
  const labelId = `enhanced-table-checkbox-${index}`;

  const [deleteProduct] = useDeleteProductMutation();
  const toggleModal = () => setOpenDeleteModal((prev) => !prev);
  const deleteItem = async () => {
    await deleteProduct(product?.id as string)
  }

  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.product)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={product?.id}
      selected={isItemSelected}
    >
      <TableCell sx={{ maxWidth: 400 }} padding="checkbox"  component="th" scope="row">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>

      <TableCell
        component="th" scope="row"
        id={labelId}
      >
        {product?.name}
      </TableCell>

      <TableCell>
        {/*<SourcesWrap>*/}
        {/*  <SourcesContent>*/}
        {/*    25*/}
        {/*  </SourcesContent>*/}

          {/* <Tooltip
            arrow
            placement="bottom"
            tooltipText=""
            tooltipContent={(
              <div>25</div>
            )}
          >
            <TooltipLink>Усі</TooltipLink>
          </Tooltip> */}
        {/*</SourcesWrap>*/}
      </TableCell>

      <TableCell>
        {product?.price}
      </TableCell>

      <TableCell>
        <Tooltip
          arrow
          placement="bottom"
          tooltipText=""
          tooltipContent={(
            <MenuList disablePadding>
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={() => navigate(`/dashboard/${project_id}/edit-product/${pipeline_id}/${product?.id}`)}>
                  <Text variant="regularText">{t('Редагувати')}</Text>
                </MenuListItemButton>
              </MenuListItem>
              <MenuListItem disablePadding>
                <MenuListItemButton onClick={toggleModal}>
                  <Text
                    variant="regularText"
                    className="red">
                    {t('Видалити')}
                  </Text>
                </MenuListItemButton>
              </MenuListItem>
            </MenuList>
          )}
        >
          <MenuButton>
            <MoreSetingsIcon />
          </MenuButton>
        </Tooltip>
      </TableCell>
      <ConfirmationModal
        deleteHandler={deleteItem}
        onClose={toggleModal}
        isOpen={openDeleteModal}
        title={t('Видалити')+'?'}
      />
    </TableRow>
  )
}

export default ProductTableRow;
