import React, { useState, useCallback} from 'react';
import { useForm, useWatch } from "react-hook-form";
import {FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import DatePicker from "react-datepicker";
// Global components
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import CreatableSelect from 'components/UI/CreatableSelect';
import { Label, Container as InputContainer } from 'components/UI/Input/styles';
import { ClockIcon, CalendarIcon } from 'assets/icons';

// Styles
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Title,
  ContainerButton,
  Button,
  InlineFormWrap,
  DatePickerWrapper,
  SettingsList,
  SettingsListItem,
  StyledToggleButtonGroup, ContainerHeader
} from './styles';

interface SettingsModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
 onClose,
 isOpen,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          Налаштувати таблицю
        </Title>
        <ContainerHeader>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Назва стовбця" />
          </FormGroup>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Сховати нульові сутності" />
          </FormGroup>
        </ContainerHeader>
        <SettingsList>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Назва стовбця" />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Сховати нульові сутності" />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Назва стовбця" />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Сховати нульові сутності" />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Назва стовбця" />
            </FormGroup>
          </SettingsListItem>
          <SettingsListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Сховати нульові сутності" />
            </FormGroup>
          </SettingsListItem>
        </SettingsList>
        <ContainerButton>
          <Button
            variant="contained"
            component="span"
            onClick={() => {}}
          >
            Зберегти
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            Скасувати
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  );
};

export default SettingsModal;
