import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {Box} from "@mui/material";
import { selectDashboard, resetCompare } from 'store/dashboard/dashboardSlice'
import { useGetSalesPlanQuery, useGetProjectByIdQuery } from 'store/dashboard/dashboardServices';
import CheckboxButton from 'components/UI/CheckboxButton';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import { getUtcFormatDate } from 'utils/helpers/index';
// Store
// Hooks
// Components
import Loading from 'components/UI/Loading';
import { CancelIcon } from 'assets/icons';
import Footer from 'components/Footer';
import CompareTableRow from './TableRow';
import CompareChart from './CompareChart';
import AddFilterModal from './AddFilterModal';
// Styles
import {
  Container,
  HeaderRow,
  H1,
  TableContainer,
  ItemRow,
  Button,
} from './styles';
import { DATE, month } from 'utils/constants/date';
// Translation
import { useTranslation, } from 'react-i18next';

interface IDate {
  startDate: any;
  endDate: any;
}

const Pipelines: FC = () => {
  const { t } = useTranslation();
  const [typeCompare, setTypeCompare] = useState(0);
  const { comparePipelines } = useSelector(selectDashboard);
  const [openAddFilterModal, setOpenAddFilterModal] = useState(false);
  const dispatch = useDispatch();
  const { redirectAddToPipeliner } = useProjectRoutes();

  const { project_id } = useParams();

  const { data: project, refetch } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data: pipelineOne, refetch: refetchOne } = useGetSalesPlanQuery({
    pipeline_id: comparePipelines?.[0]?.pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const { data: pipelineTwo, refetch: refetchTwo } = useGetSalesPlanQuery({
    pipeline_id: comparePipelines?.[1]?.pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const onChangeVelue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeCompare(+event.target.value)
  }

  const typeCompareByIndex = () => {
    if (typeCompare === 0) {
      return 'Дохід'
    }

    if (typeCompare === 2) {
      return 'Валовий дохід'
    }

    if (typeCompare === 5) {
      return 'Прибуток'
    }

    return 'Прибуток';
  }

  const deleteCompare = () => {
    redirectAddToPipeliner(project_id as string);

    dispatch(resetCompare());
  }

  useEffect(() => {
    refetch();
    refetchOne();
    refetchTwo();
  }, [])

  return (
    <Container>
      <HeaderRow>
        <H1 variant="h1">
          {t('Порівняння пайплайнів')} {comparePipelines?.[0]?.pipeline_name} {t('та')} {comparePipelines?.[1]?.pipeline_name}
        </H1>
      </HeaderRow>

      <HeaderRow>
        <Box>
          <CheckboxButton onChange={onChangeVelue} value={0} checked={typeCompare === 0} title={t('Дохід')} />
          <CheckboxButton onChange={onChangeVelue} value={2} checked={typeCompare === 2} title={t('Валовий дохід')} />
          <CheckboxButton onChange={onChangeVelue} value={5} checked={typeCompare === 5} title={t('Прибуток')} />
        </Box>

        <Button
          onClick={deleteCompare}
          startIcon={<CancelIcon />}
          variant="textIcon"
        >
          {t('Припинити порівняння')}
        </Button>
      </HeaderRow>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <ItemRow className="first-td">
              </ItemRow>
              {month.map((item) => (
                <ItemRow key={item}>
                  {t(`${item}`)}
                </ItemRow>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <CompareTableRow
              typeCompare={t(`${typeCompareByIndex()}`)}
              color="#07B169"
              planSales={pipelineOne || []}
              index={typeCompare}
              activeIndex={0}
            />

            <CompareTableRow
              typeCompare={t(`${typeCompareByIndex()}`)}
              color="#FE8C8C"
              planSales={pipelineTwo || []}
              index={typeCompare}
              activeIndex={1}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <CompareChart
        currency={project?.currency as string}
        planSalesOne={pipelineOne || []}
        planSalesTwo={pipelineTwo || []}
        index={typeCompare}
        typeCompare={t(`${typeCompareByIndex()}`)}
      />

      <AddFilterModal
        isOpen={openAddFilterModal}
        onClose={() => { setOpenAddFilterModal((prev) => !prev); }}
      />

      <Footer isSpace isSticki />
    </Container>
  );
};

export default Pipelines;
