import { styled } from '@mui/material/styles';
import { Typography, TableCell } from '@mui/material';

interface ItemRowProps {
  isMinusValue?: boolean;
}

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: fit-content;
`;

export const ItemRow = styled(TableCell)<ItemRowProps>`
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ isMinusValue }) => isMinusValue ? '#D52727' : '#292929'};

  &.first-td {
    max-width: 100px;
    padding-right: 20px;
  }
`;