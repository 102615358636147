/* eslint-disable max-len */
import { BaseQueryParams } from 'store/baseQuery';
import { IPipeline } from 'interfaces/pipeline';
import {
  CreatePipelineRequest,
  IUpdatePipeline,
  PipelineResponse,
  ResponsePipeline,
  IUpdateProduct,
  IPlanSales,
  IUpdateSalesMonts,
  IGetPipelines,
  IUpdatePipelineRequest,
} from './pipelineTypes';

export const pipelineServices = BaseQueryParams('pipeline', ['PIPELINES', 'PIPELINE', 'SALES_PLAN']).injectEndpoints({
  endpoints: (builder) => ({
    getPipeline: builder.query<PipelineResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/projects/${id}/pipelines`,
        method: 'GET',
      }),
      providesTags: () => ['PIPELINES'],
    }),

    getPipelineById: builder.query<ResponsePipeline, { id: string }>({
      query: ({ id }) => ({
        url: `/pipelines/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['PIPELINE'],
    }),

    updateProduct: builder.mutation<unknown, { id: string, body: IUpdateProduct }>({
      query: ({ id, body }) => ({
        url: `/products/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['PIPELINE'],
    }),

    copyPipeline: builder.mutation<IPipeline, { project_id: string, pipeline_id: string }>({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}/copy`,
        method: 'POST',
      }),
      invalidatesTags: () => ['PIPELINES'],
    }),

    updatePipeline: builder.mutation<IPipeline, IUpdatePipelineRequest>({
      query: ({ project_id, pipeline_id, body }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['PIPELINES', 'PIPELINE'],
    }),

    deletePipeline: builder.mutation<IPipeline, { project_id: string, pipeline_id: string }>({
      query: ({ project_id, pipeline_id }) => ({
        url: `/projects/${project_id}/pipelines/${pipeline_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['PIPELINES'],
    }),

    createSalesPlan: builder.mutation<unknown, { pipeline_id: string, from: string, to: string, product_id: string }>({
      query: ({
        pipeline_id, from, to, product_id,
      }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan?limit=12&skip=0&from=${from}&to=${to}&product_id=${product_id}`,
        method: 'POST',
      }),
      invalidatesTags: () => ['SALES_PLAN'],
    }),

    getSalesPlan: builder.query<IPlanSales[], { pipeline_id: string, from: string, to: string }>({
      query: ({ pipeline_id, from, to }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan/all?limit=12&skip=0&from=${from}&to=${to}`,
        method: 'GET',
      }),
      providesTags: () => ['SALES_PLAN'],
    }),

    updateSalesMonth: builder.mutation<unknown, IUpdateSalesMonts>({
      query: ({ pipeline_id, sales_plan_id, completion_percent }) => ({
        url: `/pipelines/${pipeline_id}/sales-plan/${sales_plan_id}`,
        method: 'PATCH',
        body: { completion_percent },
      }),
      invalidatesTags: () => ['SALES_PLAN'],
    }),
    //
    getPipelineByProjectId: builder.query<IGetPipelines, string>({
      query: (project_id) => ({
        url: `/projects/${project_id}/pipelines`,
        method: 'GET',
      }),
      providesTags: () => ['PIPELINES'],
    }),
  }),
});

export const {
  // useCreatePipelineMutation,
  useCopyPipelineMutation,
  useUpdatePipelineMutation,
  useDeletePipelineMutation,
  useGetPipelineQuery,
  useGetPipelineByIdQuery,
  useUpdateProductMutation,
  useCreateSalesPlanMutation,
  useGetSalesPlanQuery,
  useUpdateSalesMonthMutation,
} = pipelineServices;
