import React, { FC } from 'react';
import { Typography } from '@mui/material';
// Constants
import { stepsDescription } from 'utils/constants/tutor';
// Icons
import { TutorInfoIcon } from 'assets/icons';
// Styles
import { Container, Wrapper, Image, WrapperTitle, Link } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface TutorSubHeaderProps {
  step: number;
}

const TutorSubHeader: FC<TutorSubHeaderProps> = ({ step }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <WrapperTitle>
          <Typography
            variant="h1"
          >
            {t(`${stepsDescription[step - 1].header}`)}
          </Typography>

          <Image src={stepsDescription[step - 1]?.icons()} />
        </WrapperTitle>

        <Link to={stepsDescription[step - 1].link}>
          <TutorInfoIcon />
        </Link>
      </Wrapper>

      <Typography
        variant="h3"
        className="description"
      >
        {t(`${stepsDescription[step - 1].description}`)}
      </Typography>
    </Container>
  )
};

export default TutorSubHeader;
