import { styled } from '@mui/material/styles';

interface IBlockProps {
  block: boolean;
}

export const Container = styled('div')`
  position: relative;
  height: calc(100vh - 60px);
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px 0 30px;
  overflow-y: auto;

   footer {
     background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
   }
`;

export const PipelinerCardContainer = styled('div')`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
`;

export const ContainerButtonCreate = styled('div')`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  max-width: 546px;
  width: 32%;
  height: 618px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

export const ButtonCreate = styled('div')<IBlockProps>`
  overflow: hidden;
  border-radius: 8px;
  padding: 30px;
  background: rgba(7, 177, 105, 0.06);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ block, theme }) => block ? theme?.palette?.custom?.grey?.lightIco : theme?.palette?.custom?.green?.main};

  svg {
    margin-bottom: 25px;
  }
`;
