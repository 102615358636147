import React from 'react';
// Styles
import { Title } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const UserTitle: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Title variant="h1">
      {t('pages.UserProfile.UserTitle.Налаштування профіля')}
    </Title>
  )
};

export default UserTitle;
