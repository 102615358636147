import React, { FC, useEffect } from 'react';
import { IProduct } from 'interfaces/product';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  TableBody as StyledTableBody,
  TableRow,
} from '@mui/material';
// Types
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { useGetPipelineByIdQuery, useUpdateProductMutation } from 'store/dashboard/dashboardServices';
// Components
import EditInput from './EditInput';
// Utils
import { caclcProduct, calcMargProduct } from 'utils/calculates/product-table';
import { isMinusValue } from 'utils/helpers';
// Styles
import { ItemRow } from './styles';

interface TableBodyProps {
  refetchPipelinePlanSale: () => void;
}

const TableBody: FC<TableBodyProps> = () => {
  const { products, planSales } = useSelector(selectDashboard);
  const [updateProduct] = useUpdateProductMutation();
  const { pipeline_id } = useParams();

  const editPrice = async (product_id: string, price: number) => {
    const product = products.find(item => item.id === product_id);

    if (product?.id) {
      await updateProduct({
        body: {
          pipeline_id: pipeline_id as string,
          name: product.name,
          group: product.group,
          amount: product.amount,
          price: +price,
          extras: {}
        },
        product_id: product_id,
      }).unwrap();
    }
  };

  const editSales = async (product_id: string, month_sales: number) => {
    const product = products.find(item => item.id === product_id);

    if (product?.id) {
      await updateProduct({
        body: {
          pipeline_id: pipeline_id as string,
            name: product.name,
            group: product.group,
            amount: +month_sales,
            price: product.price,
            extras: {}
        },
        product_id: product_id,
      }).unwrap();
    }
  };

  useEffect(() => {
    // refetch()
  }, [])

  return (
    <StyledTableBody>
      {products?.map((product) => (
        <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          key={product?.id}
        >
          <ItemRow>
            {product.name}
          </ItemRow>

          <ItemRow>
            <EditInput
              productId={product.id as string}
              defaultValue={product?.price || 0}
              onChangeValue={editPrice}
            />
          </ItemRow>

          <ItemRow>
            <EditInput
              productId={product.id as string}
              defaultValue={product?.amount || 0}
              onChangeValue={editSales}
            />
          </ItemRow>

          <ItemRow>
            {caclcProduct(product as IProduct, planSales)}
          </ItemRow>

          <ItemRow
            color={isMinusValue(calcMargProduct(product as IProduct, caclcProduct(product as IProduct, planSales))) ? '#D52727' : '#07B169'}
          >
            {calcMargProduct(product as IProduct, caclcProduct(product as IProduct, planSales))} %
          </ItemRow>
        </TableRow>
      ))}
    </StyledTableBody>
  );
};

export default TableBody;
