/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from "react-i18next";
// Hooks
import { useAuthRoutes } from 'hooks/userAuthRoutes';
// Store
import { useRegistrationMutation } from 'store/auth/authService';
import { errorByType } from 'utils/helpers/index';
// Interface
import { IRegistration } from 'interfaces/auth';
// Utils
import { defaulRegistrationValues } from 'utils/constants/auth';
// Global Components
import AuthBackground from 'components/AuthBackgroud';
// Images
import LoginImage from 'assets/images/login-image.svg';
import ForgotPasswordImage from 'assets/images/forgot-password-image.svg';
// Components
import RegistrationForm from './RegistrationForm';
// Styles
import { Container } from './styles';

const Registration: React.FC = () => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [step, setStep] = useState(1);
  const { redirectToLogin } = useAuthRoutes();

  const [registerAccount, { isLoading }] = useRegistrationMutation();
  const formState = useForm<IRegistration>({ defaultValues: defaulRegistrationValues, mode: 'all' });

  const email = formState.watch('email');

  const onStepChange = (value: number) => setStep(value);

  const onSuccessfullyChange = () => {
    setIsSuccess((prev) => !prev);
  };

  const onSubmit: SubmitHandler<IRegistration> = async (values) => {
    const { email, password, residence } = values;

    try {
      await registerAccount({
        email,
        residence,
        password,
        firstname: '',
        lastname: '',
        phone_number: '',
      }).unwrap();

      onSuccessfullyChange();
    } catch (error: any) {
      formState.setError('email', {
        type: 'custom',
        message: t(errorByType(error?.data?.message)),
      });
    }
  };

  return (
    <Container>
      <RegistrationForm
        isSuccess={isSuccess}
        onStepChange={onStepChange}
        onSubmit={onSubmit}
        formState={formState}
        isLoading={isLoading}
        step={step}
        email={email}
      />

      <AuthBackground image={isSuccess ? ForgotPasswordImage : LoginImage} />
    </Container>
  );
};

export default Registration;
