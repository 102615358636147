import {css, styled} from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

interface TitleProps {
  visible: boolean;
}
interface SideBarAccordionProps {
  active: number | boolean;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme?.palette?.custom?.black?.light};
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled(Typography)<TitleProps>`
  color: ${({ theme }) => theme?.palette?.primary.contrastText};
  padding-left: 17px;
  transition: all 0.5s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const Accordion = styled(MuiAccordion)`
  padding: 0;
  margin: 0;
  width: 100%;
  background: ${({ theme }) => theme?.palette?.custom?.black?.light};
`;

export const BlockTitle = styled(Typography)<SideBarAccordionProps>`
  margin-left: 12px;
  color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  transition: all 0.5s ease;
  display: ${({ active }) => (active ? 'block' : 'none')};
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export const AccordionHeader = styled(MuiAccordionSummary)<SideBarAccordionProps>`
  padding: 0 20px 0 20px;
  margin: 0;
  transition: opacity 0.3s;

  svg {
    color: ${({ active }) => (active ? 'rgba(255, 255, 255, 1)' : 'rgba(131, 131, 131, 1)')};
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }

  ${({ active }) => (active && css`
    &::before {
      position: absolute;
      width: 4px;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 20px;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 0px 2px 2px 0px;
    }
  `)};

  &:hover {
    opacity: 0.5;
  }
`;
