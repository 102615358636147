import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Label = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
  margin-right: 10px;
`;

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
