import React from 'react';
// Styles
import {
  Container,
  Title,
  RowItem,
  TextInfo,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface ProjectsCardBodyProps {
  name: string;
  countPipeline: number;
  countProductPipeline: number;
  countEmployees: number;
  pipelineName: string | number;
}

const ProjectsCardBody: React.FC<ProjectsCardBodyProps> = ({
  countEmployees,
  countProductPipeline,
  countPipeline,
  pipelineName,
  name,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title variant="h4">
        {name}
      </Title>

      <Container>
        <RowItem isBorder>
          <TextInfo variant="regularText">
            {t('Активний пайплайн')}:
          </TextInfo>

          <TextInfo variant="regularText">
            {pipelineName}
          </TextInfo>
        </RowItem>

        <RowItem isBorder>
          <TextInfo variant="regularText">
            {t('Співробітники')}:
          </TextInfo>

          <TextInfo variant="regularText">
            {countEmployees}
          </TextInfo>
        </RowItem>

        <RowItem isBorder>
          <TextInfo variant="regularText">
            {t('Продукти')}:
          </TextInfo>

          <TextInfo variant="regularText">
            {countProductPipeline}
          </TextInfo>
        </RowItem>

        {/* <RowItem isBorder>
        <TextInfo variant="regularText">
          Загальний бюджет:
        </TextInfo>

        <TextInfo variant="regularText">
          408000 грн
        </TextInfo>
      </RowItem> */}

        {/* <RowItem isBorder>
        <TextInfo variant="regularText">
          Прибуток:
        </TextInfo>

        <TextInfo variant="regularText">
          5500 USD
        </TextInfo>
      </RowItem> */}

        {/* <RowItem isBorder>
        <TextInfo variant="regularText">
          Цілі досягнуті на:
        </TextInfo>

        <TextInfo variant="regularText">
          15%
        </TextInfo>
      </RowItem> */}

        <RowItem>
          <TextInfo variant="regularText">
            {t('Пайплайни')}:
          </TextInfo>

          <TextInfo variant="regularText">
            {countPipeline}
          </TextInfo>
        </RowItem>
      </Container>
    </Container>
  )
};

export default ProjectsCardBody;
