import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Store
import { useGetSalesPlanQuery } from 'store/dashboard/dashboardServices';
import { selectTutor } from 'store/tutors/tutorsSlice';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend,
} from 'recharts';
import { getUtcFormatDate } from 'utils/helpers/index';
import { DATE } from "utils/constants/date";

// Components
import ChartTooltip from './ChartTooltip';
// Styles
import { Container, Title, Wrapper } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface IDate {
  startDate: any;
  endDate: any;
}

interface AnalysisIncomeSectionProps {
  currency: string;
}

const AnalysisIncomeSection: FC<AnalysisIncomeSectionProps> = ({ currency }) => {
  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });
  const { t } = useTranslation();
  const { pipeline_id } = useParams();

  const { salesPlanPipeline } = useSelector(selectDashboard);

  const { data } = useGetSalesPlanQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  return (
    <Container maxWidth={1122}>
      <Title variant="h1">
        {t('Анлаіз доходів та витрат')}
      </Title>

      <Wrapper>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={salesPlanPipeline || []}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" />
            <Tooltip wrapperStyle={{ outline: 'none' }} content={((props: any) => <ChartTooltip {...props} currency={currency} />)} offset={-100} />
            <Legend />
            <Bar dataKey="pv" fill="#28B05F" isAnimationActive={false}/>
            <Bar dataKey="uv" fill="#D52727" isAnimationActive={false}/>
          </BarChart>
        </ResponsiveContainer>
      </Wrapper>
    </Container>
  );
};

export default AnalysisIncomeSection;
