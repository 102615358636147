import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background: #EBF7F2;
  border-radius: 8px;
  position: relative;
`;

export const Title = styled(Box)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #07B169;
  margin-right: 5px;
`;

export const IconButton = styled(Button)`
  width: fit-content;
  height: fit-content;
  min-width: fit-content;
  position: absolute;
  top: 20px;
  right: 20px;
`;
