import {
  ENTERPRISE_MONTH_STATUS,
  DISCOVERY_MONTH_STATUS,
  SCHOLAR_MONTH_STATUS,
  FREE_STATUS,
} from 'utils/constants/membership-status';
import {
  EMAIL_OR_PASSWORD_INCORRECT_ERROR_TYPE,
  USER_ALREADY_EXISTS_TYPE,
  USER_NOT_VERIFIED_TYPE,
  NO_SUCH_USER_EXISTS_TYPE,
  EMAIL_OR_PASSWORD_INCORRECT_ERROR_MESSAGE,
  USER_ALREADY_EXISTS_MESSAGE,
  USER_NOT_VERIFIED_MESSAGE,
  NO_SUCH_USER_EXISTS_MESSAGE,
  BASE_ERROR,
} from 'utils/constants/auth-errors';
import { AMOUNT_DATA_SELECT } from 'utils/constants/tutor';

export const isMinusValue = (value: string) => value?.split('')?.[0] === '-';

export const subscribeStatus = (name: string) => {
  switch (name) {
    case 'Початківець':
      return SCHOLAR_MONTH_STATUS;
    case 'Діскавері':
      return DISCOVERY_MONTH_STATUS;
    case 'Ентерпрайз':
      return ENTERPRISE_MONTH_STATUS;
    default:
      return FREE_STATUS;
  }
};

export const currencyType = (currency: string) => {
  switch (currency) {
    case '₴':
      return 'Гривня (₴)';

    case '€':
      return 'Євро (€)';

    default:
      return 'Долар ($)';
  }
};

export const currencyTypeTutor = (currency: string) => {
  switch (currency) {
    case '₴':
      return 'грн';

    case '€':
      return 'євр';

    default:
      return 'дол';
  }
};

export const valueColor = (value: number) => {
  if (value > 30000) {
    return '#292929';
  } if (value < 20000) {
    return '#D52727';
  }
  return '#07B169';
};

export const errorByType = (type: string) => {
  switch (type) {
    case EMAIL_OR_PASSWORD_INCORRECT_ERROR_TYPE:
      return EMAIL_OR_PASSWORD_INCORRECT_ERROR_MESSAGE;
    case USER_ALREADY_EXISTS_TYPE:
      return USER_ALREADY_EXISTS_MESSAGE;
    case USER_NOT_VERIFIED_TYPE:
      return USER_NOT_VERIFIED_MESSAGE;
    case NO_SUCH_USER_EXISTS_TYPE:
      return NO_SUCH_USER_EXISTS_MESSAGE;

    default:
      return BASE_ERROR;
  }
};

export const repeatabilityFlow = (type: string) => {
  const item = AMOUNT_DATA_SELECT?.find((amount) => amount?.label === type);

  return item?.value || 1;
};

export const getUtcFormatDate = (date: string) => {
  const defaultDate = new Date(date || new Date());

  return defaultDate?.toISOString();
};
