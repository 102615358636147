import React, { useState } from 'react';
import MaskedInput from 'react-maskedinput';
import { registerLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
// Icons
import { CalendarIcon } from 'assets/icons';
// Styles
import {
  Container, Input, Label, ContainerInput,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
registerLocale('uk', uk);

const DatePicker: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>();
  const { t } = useTranslation();
  return (
    <Container>
      <Label>
        {t('components.UI.DatePicker.Діє до')}
      </Label>

      <ContainerInput>
        <Input
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          dateFormat="MM/yy"
          showMonthYearPicker
          showFullMonthYearPicker
          placeholderText={t('components.UI.DatePicker.MM/PP')}
          locale="uk"
          customInput={
            <MaskedInput mask="11/11" placeholder={t('components.UI.DatePicker.MM/PP')} />
          }
        />

        <CalendarIcon />
      </ContainerInput>
    </Container>
  );
};

export default DatePicker;
