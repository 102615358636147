import React from 'react';
// Styles
import {
  Container,
  Title,
  SubTitle,
  WrapperText,
  TextButton,
} from './styles';

const RowHistory: React.FC = () => (
  <Container>
    <WrapperText>
      <Title variant="h5">
        21.02.2021 -
      </Title>

      <SubTitle variant="h5">
        Рахунок додано
      </SubTitle>
    </WrapperText>

    <TextButton>
      Повернути
    </TextButton>
  </Container>
);

export default RowHistory;
