import { styled } from '@mui/material/styles';
import { Box, Typography, TextField } from '@mui/material';

interface ContainerProps {
  isDisabled: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  width: fit-content;
  max-width: 110px;
  overflow-x: hidden;
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'flex')};
  align-items: center;
  height: 36px;
  cursor: pointer;
  transition: all 0.5s ease;

  button {
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

export const Label = styled(Typography)<ContainerProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #292929;
  border-bottom: 0.5px solid ${({ isDisabled }) => (isDisabled ? 'transparent' : '#647ee7')};
`;

export const StyledInput = styled(TextField)`
  max-width: 100px;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &&.readonly {
    .MuiInputBase-root {
      background: ${({ theme }) => theme?.palette?.primary?.main};
    }

    .MuiInputBase-input {
      color: ${({ theme }) => theme?.palette.custom?.primary?.silver};;
    }
  }

  .MuiOutlinedInput-input {
    padding: 6px 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  &&:hover {
    fieldset {
      border-color: ${({ theme }) => theme?.palette?.primary?.light};
    }
  }

  .MuiFormHelperText-root {
    display: none;
  }
`;

export const ContainerInput = styled(Box)<ContainerProps>`
  width: 110px;
  display: ${({ isDisabled }) => (isDisabled ? 'flex' : 'none')};
  align-items: center;
  height: 36px;
  cursor: pointer;
  transition: all 0.5s ease;
`;
