import React, { FC } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Styles
import { Title } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const TableHeader: FC = () => {
  const { t } = useTranslation();
  const titleTableColums = ['Назва вкладу', 'Од. виміру', 'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];
  return (
    <TableHead>
      <TableRow>
        {titleTableColums.map((title) => (
          <Title key={title} align="left">
            {t(`${title}`)}
          </Title>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
