import { type FC } from "react";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { freelanceUserRoutesPath } from "routes/routePath";
import { Logo } from "assets/logos";
import {
  ContentContainerStyled,
  HeaderStyled,
  LinkStyled,
  MobileDrawerStyled,
  FooterStyled,
  ExitContainerStyled,
  ExitIconStyled,
} from "./styles";
import { CrossIcon } from "assets/icons";
import { useTranslation } from "react-i18next";
import { getSideBarElements } from "utils/freelancer/helpers/getSideBarElements";
import { useLogout } from "hooks/useLogout";
import { IconContainerStyled } from "../styles";

interface MobileMenuProps {
  handleDrawer: () => void;
  isOpen: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({ handleDrawer, isOpen }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const sideBarDashboardLinks = getSideBarElements(pathname, t);
  const { logoutHandler } = useLogout();

  return (
    <MobileDrawerStyled
        open={isOpen}
        onClose={handleDrawer}
        transitionDuration={500}
        anchor="top">
      <HeaderStyled>
        <Link to={freelanceUserRoutesPath.DASHBOARD}><Logo /></Link>
        <IconContainerStyled onClick={handleDrawer}>
          <CrossIcon />
        </IconContainerStyled>
      </HeaderStyled>

      <ContentContainerStyled>
        {sideBarDashboardLinks.map(({isActive, label, route, icon}) => (
          <LinkStyled
            onClick={handleDrawer}
            to={route}
            isActive={isActive}
            key={`${route}-${label}`}>
            {icon}
            {label}
          </LinkStyled>
        ))}
      </ContentContainerStyled>
      <FooterStyled>
        <ExitContainerStyled onClick={logoutHandler}>
          <ExitIconStyled />
          <Typography variant="h2">
            {t('components.SideBar.SideBarFooter.Вийти з акаунту')}
          </Typography>
        </ExitContainerStyled>
      </FooterStyled>
    </MobileDrawerStyled>
  )
};

export default MobileMenu;
