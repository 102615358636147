import { styled } from '@mui/material/styles';

interface SectionProps {
  isSpaceBottom?: number;
}

export const Container = styled('div')`
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 0 30px;
`;

export const ContainerPrint = styled('div')`
  //height: calc(100vh - 60px);
  overflow: scroll;
  height: 100%;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  padding: 15px 30px;
  
`;
