import { ComponentsOverrides, Theme } from '@mui/material';

interface muiCheckboxProps {
   styleOverrides: ComponentsOverrides['MuiCheckbox'];
}

export const muiCheckbox = (theme: Theme): muiCheckboxProps => ({
  styleOverrides: {
    root: {
      color: 'rgba(41, 41, 41, 0.07)',
      '&.Mui-checked': {
        color: theme.palette.custom.green.main,
      },
    },
  },
});
