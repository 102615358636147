import React, { FC, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  useFieldArray, useForm, SubmitHandler,
} from 'react-hook-form';
// Store
import { useCreateProductMutation, useDeleteEmployeeMutation, useUpdateProductMutation } from 'store/tutors/tutorsServices';
import { selectTutor } from 'store/tutors/tutorsSlice';
// Utils
import { addProduct } from 'utils/helpers/tutor';
// Interface
import { IProductCreate } from 'interfaces/product';
// Hooks
import { useTutorRoutes } from 'hooks/useTutorRoutes';
// Components
import TutorSubHeader from 'components/Tutor/TutorSubHeader';
import TutorFooter from 'components/Tutor/TutorFooter';
import TabPanel from '../TabPanel';
import ProductRowItem from './ProductRowItem';
// Styles
import { Container, LinkButton } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import type { SegmentEventPayload } from '../../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../../utils/constants/analytics';
import { addEvent } from '../../../store/analytics/analyticsService';
import { useAuth } from '../../../hooks/useAuth';

interface ProductsProps {
  defaultValues: IProductCreate[];
}

const Products: FC<ProductsProps> = () => {
  const { t } = useTranslation();
  const { step } = useParams();
  const { redirectToTutorStep } = useTutorRoutes();
  const { pipeline_id, project_id, products } = useSelector(selectTutor);
  const [create] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const formState = useForm<{ products: IProductCreate[] }>({
    defaultValues: { products },
    mode: 'all',
  });

  const formStateArray = useFieldArray({
    control: formState.control,
    name: 'products',
  });

  const appendItem = () => {
    formStateArray.append(addProduct);
  };

  const [deleteEmployee] = useDeleteEmployeeMutation();

  const deleteItem = async (id: number, employee_id?: string) => {
    if (employee_id) {
      await deleteEmployee(employee_id).unwrap();
    }

    formStateArray.remove(id);
  };

  const updateItem = async (id?: string) => {
    if (!id) return;

    const productsArray = formState.watch('products');
    const product = productsArray.find((item) => item?.pipeline_id === id);

    if (product) {
      const updateDataProduct = {
        name: product.name,
        amount: product.amount,
        price: product.price,
        group: product.group,
        extras: product.extras,
        pipeline_id: pipeline_id,
      };

      await updateProduct({
        body: updateDataProduct,
        product_id: id,
      }).unwrap();
    }
  };

  const onNextStep: SubmitHandler<{ products: IProductCreate[] }> = async (values) => {
    try {
      const filteredProducts = values.products.filter((item) => !item?.pipeline_id);
      const checkGroupProduct = values.products?.filter(item => item.group === 'product')

      if (!filteredProducts?.length) {
        if (checkGroupProduct?.length === 0) {
          redirectToTutorStep(project_id, 6);

          return;
        }
        redirectToTutorStep(project_id, 4);

        return;
      }

      if (filteredProducts?.length) {
        const newProducts = filteredProducts.map((item) => ({
          name: item.name,
          group: item.group,
          amount: item.amount,
          extras: item.extras,
          price: item.price,
        }));

        await create({
          project_id: project_id as string,
          body: [{ products: newProducts, pipeline_id: pipeline_id as string }],
        }).unwrap();

        if (checkGroupProduct?.length === 0) {
          redirectToTutorStep(project_id, 6);
        } else {
          redirectToTutorStep(project_id, 4);
        }
      }

      formState.reset();
      const eventPayload: SegmentEventPayload = {
        eventName: SEGMENT_EVENT_NAME.PIPELINE_STEP_3,
        payload: { email: user?.email },
      };
      dispatch(addEvent(eventPayload));
    } catch (err) {
      console.error(err);
    }
  };

  const onPrevStep = () => {
    redirectToTutorStep(project_id, 2);
  };

  useEffect(() => {
    if (step === '3') {
      formState.trigger();
    }
  }, [step])

  useEffect(() => {
    formState.setValue('products', products);
  }, [products]);

  return (
    <TabPanel index={3} value={step ? +step : 0}>
      <Container>
        <TutorSubHeader step={2} />

        <Typography variant="h1">
          {t('Продукт або Сервіс')}
        </Typography>

        {formStateArray.fields.map((product, index) => (
          <ProductRowItem
            updateItem={updateItem}
            errors={formState.formState.errors}
            control={formState.control}
            key={product.id}
            product={product}
            deleteItem={deleteItem}
            index={index}
          />
        ))}



        <LinkButton
          onClick={appendItem}
          variant="link"
        >
          {t('Додати продукт або сервiс')}

        </LinkButton>
      </Container>

      <TutorFooter
        step={step ? +step : 0}
        activeStep={2}
        handleSubmit={formState.handleSubmit}
        onNextStep={onNextStep}
        disabled={!formState.formState.isValid}
        onPrevStep={onPrevStep}
      />
    </TabPanel>
  );
};

export default Products;
