export const NAVBAR_LINK = [
  {
    title: 'Огляд показників',
    path: 'indicators',
  },
  {
    title: 'Команда',
    path: 'team',
  },
  {
    title: 'Продукти та сервіси',
    path: 'products',
  },
  {
    title: 'Дохід',
    path: 'income',
  },
  {
    title: 'Витрати',
    path: 'costs',
  },
  {
    title: 'Активи',
    path: 'assets',
  },
  {
    title: 'Фінансування',
    path: 'financing',
  },
];

export const NAVBAR_GOOGLE_LINK = 'https://docs.google.com/document/d/1QHY3t2A6PXF8y3Ulq64tmZcfzbx_NUk22HPbFAol8ck';
