import {styled} from '@mui/material/styles';
import {Typography} from "@mui/material";

export const TableWrap = styled('div')`
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
`;

export const TableTitle = styled(Typography)`
  display: block;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
`
