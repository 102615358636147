import React, { FC } from 'react';
import { Controller, UseFormReturn, SubmitHandler } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
// Utils
import { InputsRules } from 'utils/constants/auth';
// Styles
import { FormRow, InputName, CreateButton } from './styles';

interface AddTutorFormProps {
  checked: boolean;
  formState: UseFormReturn<{ pipeline_name: string }>;
  onSubmit: SubmitHandler<{ pipeline_name: string }>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
// Translation
import { useTranslation, } from 'react-i18next';

const AddTutorForm: FC<AddTutorFormProps> = ({
  checked, formState, onSubmit, handleChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FormRow>
        <Controller
          name="pipeline_name"
          control={formState.control}
          rules={{ required: InputsRules.required }}
          render={({ field }) => <InputName {...field} placeholder={t('Назвіть свій пайплайн')} label={t('Назва пайплайну')} />}
        />

        <CreateButton
          onClick={formState.handleSubmit(onSubmit)}
          disabled={!formState.formState.isValid}
          variant="contained"
        >
          {t('Створити свій перший проект')}
        </CreateButton>
      </FormRow>

      <FormGroup sx={{ width: 'fit-content' }}>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={checked} />}
          label={t('Я хочу використати Помічник')}
        />
      </FormGroup>
    </>
  )
};

export default AddTutorForm;
