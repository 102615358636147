import React from 'react';
// Image
import { EditIcon, UserProfileDefaultPhoto } from 'assets/icons';
import {
  Container,
  Label,
  Wrapper,
  Image,
  IconButton,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const UserInfoAvatar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Label>
        {t('pages.UserProfile.UserInfoAvatar.Фотокартка')}
      </Label>

      <Wrapper>
        {/*<IconButton>*/}
        {/*  <EditIcon color="#07B169" />*/}
        {/*</IconButton>*/}

        <UserProfileDefaultPhoto />
      </Wrapper>
    </Container>
  )
};

export default UserInfoAvatar;
