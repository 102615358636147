import { styled } from '@mui/material/styles';
import {Typography, Button, Autocomplete, Box} from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: #F8F9FD;
  
  footer {
    width: 100%;
    max-width: 1122px;
  }
`;

export const ContainerSection = styled('div')`
  max-width: 1112px;
`;

export const HeaderSection = styled('div')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px 8px 0 0;
  //cursor: pointer;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    width: 49%;
    .MuiChip-root {
      border: 1px solid #EBEBEB;
      border-radius: 4px;
      color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
    }
    
    .MuiChip-deleteIcon: {
      color: ${({ theme }) => theme?.palette?.custom?.grey?.lightIco};
    }
  }
`;
export const FormWrap = styled('form')`
  margin-bottom: 30px;
  border-radius: 8px;
`;

export const FormContainer = styled('div')`
  border-radius: 0 0 8px 8px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 1);
  .bordered {
    border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  .green-label {
    color: ${({ theme }) => theme?.palette?.custom?.green.main};
  }
`;

export const FormContainerFooter = styled('div')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 26px 20px;
  & > h1 {
    margin-left: 10px;
  }
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const FormRow = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  
  &.bordered {
    border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
  }
  
  & > .MuiAutocomplete-root, & > .MuiFormControl-root  {
    width: 49%;
    margin-right: 15px;
  }
`;

export const LinkWrapper = styled(Box)`
  width: 100%;
  display: flex;
`;

export const LinkButton = styled(Button)`
  margin-right: 30px;
  padding-left: 0;
  && {
    text-decoration: none;
  }
`;

export const WrapperButton = styled('label')`
  margin-bottom: 10px;
  display: block;
  .uploadBtn {
    font-size: 16px;
    svg {
      margin-right: 15px;
    }
  }
`;

export const ScaleInput = styled('input')`
  display: none;
`;

export const UploadButton = styled(Button)`
￼    margin-bottom: 20px;
￼    height: 60px;
￼    font-size: 16px;
￼    line-height: 24px;
     color: ${({ theme }) => theme?.palette?.custom?.green.main};
     background: ${({ theme }) => theme.palette.custom.green.light};
￼    svg {
￼      color: ${({ theme }) => theme?.palette?.custom?.green.main};
￼      margin-right: 15px;
￼    }
￼    &:hover {
￼      svg {
￼        color: ${({ theme }) => theme?.palette?.primary?.contrastText};
￼      }
￼    }
￼    
￼`;

export const UploadedFile = styled(Button)`
  background: rgba(164, 164, 164, 0.1);
  color: ${({ theme }) => theme.palette.custom.primary.silver};
  height: 60px;
  font-size: 16px;
  display: flex;
  align-items: inherit;
  && {
    &:hover {
      background: rgba(164, 164, 164, 0.1);
      color: ${({ theme }) => theme.palette.custom.primary.silver};
    }
  }
`;

export const FileUploadContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
`;

export const UploadedFilesContainer = styled('div')`
  display: flex;
  align-items: center;
  & > button {
    margin-right: 15px;
  }
`;

export const HistoryContainer = styled(FormContainer)`
  && {
    border-radius: 8px 8px 8px 8px;
    h3 {
      color: ${({ theme }) => theme.palette.custom.primary.silver};
    }
  }
`;

export const ContainerButton = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
  & > button {
    margin-right: 15px;
  }
  
`;
