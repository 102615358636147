/* eslint-disable max-len */
import {
  createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { API_URL } from 'utils/constants/urls';
import type { RootState } from 'store';

export const customBaseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/v1`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await customBaseQuery(args, api, extraOptions);

  return result;
};

export const BaseQueryParams = (reducerPath: string, tags?: string[]) => {
  const baseQuery = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: () => ({}),
    reducerPath,
    tagTypes: tags?.length ? tags : [],
  });

  return baseQuery;
};
