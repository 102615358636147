import {css, styled} from '@mui/material/styles';
import {Button, List, ListItem, ListItemButton, ListSubheader, Typography} from '@mui/material';
import {ArrowDownIcon} from 'assets/icons';

interface ArrowProps {
  $isOpen: boolean
}

export const Container = styled('div')`
  display: flex;
  margin: 15px 0;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.custom.green.light};
  border-radius: 8px;
  
  .callapse {
    border-top: none;
    border-radius: 0 0 8px 8px;
  }
`;


export const ControlPanel = styled('div')`
  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
`;

export const ControlButtons = styled('div')`

`;

export const ControlButton = styled(Button)`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  && {
    text-decoration: none;
    svg {
      margin-right: 12px;
    }
  }
`;

export const LinkButton = styled(Button)`
  margin-left: 30px;
  padding-left: 0;
  && {
    text-decoration: none;
  }
`;

export const CollapseHeader = styled('a')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px;
  &.open {
    border-radius: 8px 8px 0px 0px;
  }
`;

export const Arrow = styled(ArrowDownIcon)<ArrowProps>`
  transition: all .3s ease-in-out;
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  ${({ $isOpen }) => ($isOpen && css`
    transform: rotate(180deg);
  `)};
  
`;

export const ListHeaderWrap = styled(ListSubheader)`
  padding: 0;
`;

export const Head = styled('div')`
  display: grid;
  background: ${({ theme }) => theme.palette.custom.green.light};
  grid-template-columns: 2fr 1fr 1fr 1fr;
  color: ${({ theme }) => theme.palette.custom.black.silver};
  padding: 0;
`;

export const TitleTable = styled('div')`
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};;
  display: flex;
  align-items: center;

`;

export const SortBtns = styled('div')`
  margin-left: 5px;
`;

export const SortBtn = styled('a')`
  display: flex;
  margin: 3px 0;
`;

export const ListStyled = styled(List)`
  margin: 30px;
  padding: 0;
`;

export const TableRow = styled(ListItem)`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  color: ${({ theme }) => theme.palette.custom.black.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-bottom: 13px;
  padding-left: 0;
  padding-right: 0;
  
  &:last-child {
    border-bottom: none;
  }
`;

export const MenuList = styled(List)`
  margin: -8px -17px;
  padding: 0;
  width: 217px;
`;

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
  height: 38px;
`;

export const MenuListItemButton = styled(ListItemButton)`
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.primary.silver};

  &.status {
    margin-right: 5px;
  }
`;


