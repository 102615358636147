import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface WrapperProps {
  isBorder?: boolean;
  padding?: number | string;
}

interface LabelProps {
  isRed: boolean;
  isMinusValue?: boolean;
}

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette.primary.dark};
`;

export const SubTitle = styled(Typography)`
  color: #838383;
  max-width: 200px;
`;

export const Label = styled(Typography)<LabelProps>`
  color: ${({ isRed, isMinusValue }) => isMinusValue ? '#D52727' : isRed ? '#D52727' : '#07B169'};
`;

export const Wrapper = styled(Box)<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ isBorder }) => (isBorder ? '1px solid #EBEBEB;' : 'none')};
  padding: ${({ padding }) => padding};

  svg {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`;
