export const EMAIL_OR_PASSWORD_INCORRECT_ERROR_TYPE = 'The username or password is incorrect';
export const USER_ALREADY_EXISTS_TYPE = 'User already exists';
export const USER_NOT_VERIFIED_TYPE = 'User is not verified';
export const NO_SUCH_USER_EXISTS_TYPE = 'There is no such user with that email';

export const EMAIL_OR_PASSWORD_INCORRECT_ERROR_MESSAGE = 'Не вірний логін або пароль';
export const USER_ALREADY_EXISTS_MESSAGE = 'Такий користувач вже існує';
export const USER_NOT_VERIFIED_MESSAGE = 'Користувач не активований. Перейдіть на пошту щоб активувати користувача';
export const NO_SUCH_USER_EXISTS_MESSAGE = 'Такого корисувача не існує';
export const BASE_ERROR = 'Помилка сервера';
