import { BaseQueryParams } from 'store/baseQuery';
import { ITariff, ISubscriptions } from './paymentsTypes';

export const paymentsServicers = BaseQueryParams('tariffs', ['TARIFFS']).injectEndpoints({
  endpoints: (builder) => ({
    getTariffs: builder.query<ITariff[], unknown>({
      query: () => ({
        url: '/payments/stripe/prices',
        method: 'GET',
      }),

      providesTags: () => ['TARIFFS'],
    }),

    getSubscriptions: builder.query<ISubscriptions[], unknown>({
      query: () => ({
        url: '/payments/stripe/subscriptions',
        method: 'GET',
      }),
    }),

    createSubscribe: builder.mutation<{ url: string }, { price_id: string }>({
      query: (body) => ({
        url: '/payments/stripe/start',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetTariffsQuery,
  useCreateSubscribeMutation,
} = paymentsServicers;
