import { styled } from '@mui/material/styles';

export const Unchecked = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: rgba(39, 71, 101, 0.06);
  display: flex;
  align-items: center;
  justify-content: center
`;
