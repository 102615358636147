import { styled } from '@mui/material/styles';
import { ButtonBase, Box } from '@mui/material';

interface ButtonProps {
  open: boolean;
}

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  background-color: rgba(51, 51, 51, 1);
  height: 60px;
`;

export const Button = styled(ButtonBase)<ButtonProps>`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  transition: all 0.3s ease;
  width: 100%;

  svg {
    margin-right: ${({ open }) => (open ? '15px' : 0)};
  }

  &:hover {
    opacity: 0.5;
  }
`;
