import { Theme } from '@mui/material';

export const muiSelect = (theme: Theme) => ({
  styleOverrides: {
    root: {
      borderRadius: '8px',
      '& .MuiSelect-icon': {
        right: 20,
      },
    },
    select: {
      width: '100%',
      color: theme.palette.primary.dark,
      padding: '13px 20px',
    },
  },
});
