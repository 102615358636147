import React from 'react';
// Components
import ServiceCard from 'components/ServiceCard';
import Pagination from './Pagination';
// Styles
import { Section } from '../styles';
import {
  Title,
  SubTitle,
  Wrapper,
  Button,
  StyledInput,
  Container,
} from './styles';

// Эта секция пока скрыта

const UserGenerateApiKey: React.FC = () => (
  <Section sx={{ display: 'none' }}>
    <Title variant="h2">
      Згенерувати API ключ
    </Title>

    <SubTitle variant="h6">
      Цей код ви можете використати для інтеграції з будь-яким доступним сервісом.
    </SubTitle>

    <Wrapper>
      <Button variant="contained">
        Оновити
      </Button>

      <StyledInput name="apiKey" value="1d21a0c8-de4d-44b7-99bc-0e9f3553d861" />
    </Wrapper>

    <Pagination />

    <Container>
      <ServiceCard />

      <ServiceCard />

      <ServiceCard />

      <ServiceCard />
    </Container>
  </Section>
);

export default UserGenerateApiKey;
