import {FC, useEffect, useState} from 'react';
// Translation
import { useTranslation, } from 'react-i18next';
import { IProduct } from 'interfaces/product';
import {IPlanSales, IPlanSalesPipeline} from "store/dashboard/dashboardTypes";
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// Components
import TableHeader from '../TableHeader';
import RowTable from './RowTable';
// Styles
import { StyledPaper, StyledTable } from './styles';
interface ProfitTableProps {
  products?: IProduct[],
  plan?: IPlanSales[],
  planByMonthes?: IPlanSalesPipeline[],
  currency?: string,
}
const ProfitTableBody: FC<ProfitTableProps> = ({products, plan, planByMonthes, currency}) => {
  const { t } = useTranslation();
  const getPriceByProduct = (product_id: number) => {
    const foundProduct = products?.find(({id}) => (
      // @ts-ignore
      id === product_id
    ))
    if (foundProduct) {
      return foundProduct.price;
    }
    return 0;
  }
  // @ts-ignore
  const header = plan && plan[0].sales.map(({month}) => (month));

  // @ts-ignore
  const productsRows = plan && plan.map(({name, product_id, sales}) => {
    // @ts-ignore
    const priceByProduct = getPriceByProduct(product_id);
    // @ts-ignore
    const dataByMonth = sales.map(({product_id, month, amount}) => ({
      profit: priceByProduct * amount,
      name: month
    }))
    return {
      name,
      dataByMonth
    }
  })

  // @ts-ignore
  const totalRow = {
    name: 'Загальний прибуток',
    // @ts-ignore
    dataByMonth: planByMonthes && planByMonthes.map(({month, result}) => ({
      name: +month,
      profit: result.income
    }))
  }


  return (
    <StyledPaper>
      <TableContainer>
        <StyledTable aria-label="table">
          <TableHeader monthes={header}/>

          <TableBody>
            {productsRows && productsRows.map(({name, dataByMonth}) => (
              <RowTable currency={currency as string} key={name} productName={name} dataByMonth={dataByMonth} />
            ))}
            <RowTable
              currency={currency as string}
              productName={t(totalRow.name)}
              dataByMonth={totalRow.dataByMonth}
              isBold={true}
            />
          </TableBody>
        </StyledTable>
      </TableContainer>
    </StyledPaper>
  )
}

export default ProfitTableBody;
