/* eslint-disable max-len */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// Store
import { selectCurrentUser } from 'store/auth/authSlice';
// Interface
import { ITariff } from 'store/payments/paymentsTypes';
// Components
import Modal from 'components/UI/Modal';
import TarrifCard from './TarrifCard';
// Styles
import {
  Container,
  Title,
  SubTitle,
  Wrapper,
  WrapperCard,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface PaywallModalProps {
  typeSubscribe: string;
  tariffs: ITariff[];
  open: boolean;
  onClose: () => void;
}

const PaywallModal: FC<PaywallModalProps> = ({
  tariffs = [], open, onClose, typeSubscribe,
}) => {
  const { user } = useSelector(selectCurrentUser);

  if (user?.membership_status !== 'free') {
    return null;
  }
  const { t } = useTranslation();

  return (
    <Modal
      maxWidth={870}
      onClose={onClose}
      isOpen={open}
    >
      <Container>
        <Title variant="h2">
          {t('components.PaywallModal.Сплатити підписку та продовжити користування сервісом')}
        </Title>

        <SubTitle variant="regularText">
          {t('components.PaywallModal.Наразі сервіс активно розробляється. У поточному релізі сума підписки не впливає на досутпний функціонал, а лише демонструє Ваше бажання підтримати нас сьогодні.')}
        </SubTitle>

        <Wrapper>
          <Title variant="h2">
            {t('components.PaywallModal.Наявні типи підписок')}
          </Title>

          <WrapperCard>
            {tariffs.map((tariff) => (
              <TarrifCard
                typeSubscribe={typeSubscribe}
                key={tariff?.endpoint?.payload?.price_id}
                tariff={tariff}
              />
            ))}
          </WrapperCard>
        </Wrapper>
      </Container>
    </Modal>
  );
};

export default PaywallModal;
