/* eslint-disable no-redeclare */
import React from 'react';
// Styles
import {
  Container, Title, SubTitle, Link,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface TariffPlanCardBody {
  isDisabled: boolean;
  countPipelines: string;
}

const TariffPlanCardBody: React.FC<TariffPlanCardBody> = ({ countPipelines, isDisabled }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title variant="h6">
        {t('components.TariffPlanCard.TariffPlanCardBody.Умови')}
      </Title>

      <SubTitle variant="h6">
        {t(countPipelines)}
      </SubTitle>

      {!isDisabled && (
        <Link to="/public-offer" isDisabled={isDisabled}>
          {t('components.TariffPlanCard.TariffPlanCardBody.Деталi')}
        </Link>
      )}
    </Container>
  )
};

export default TariffPlanCardBody;
