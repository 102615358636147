import React from 'react';
import { useLocation } from 'react-router-dom';
// Icons
import { Logo, LogoText } from 'assets/logos';
// Components
import NavBarMode from './NavBarMode';
// Styles
import { AppBar, Container, IconButton } from './styles';

interface NavBarProps {
  handleDrawer?: () => void;
  isOpen: boolean;
  noSideBar?: boolean;
}
const NavBars: React.FC<NavBarProps> = ({ handleDrawer = () => {}, isOpen, noSideBar }) => {
  const { pathname } = useLocation();

  const navBartype = () => {
    const type = pathname?.split('/')?.[1];

    if (type === 'dashboard') {
      return 'dashboard';
    }
    return 'propject';
  };

  navBartype();

  return (
    <AppBar
      position="fixed"
      open={isOpen}
    >
      <Container>
        <IconButton
          onClick={handleDrawer}
          sx={{
            ...(isOpen && { display: 'none' }),
          }}
          $noSideBar={noSideBar}
        >
          <Logo />
          {noSideBar ? <LogoText /> : ''}
        </IconButton>

        <NavBarMode type={navBartype()} />
      </Container>
    </AppBar>
  );
};

export default NavBars;
