import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';
import Input from "../../components/UI/Input";

export const Container = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px;
  position: relative;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
  
  footer {
    position: fixed;
    width: 100%;
    max-width: 1122px;
    bottom: 0;
  }
`;

export const SectionContainer = styled('div')`
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
`;

export const TableContainer = styled(SectionContainer)`
  max-width: 1122px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  .tabs-row {
    margin-top: 0;
  }
`;

export const TabContainer = styled('div')`
`;

export const StyledInput = styled(Input)`
  max-width: 218px;
  margin-left: 12px;

  .MuiFormHelperText-root {
    display: none;
  }
`;
