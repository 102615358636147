import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface RowItemProps {
  isBorder?: boolean;
}

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  margin-bottom: 6px;
`;

export const RowItem = styled('div')<RowItemProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0;
  border-bottom: ${({ isBorder, theme }) => (isBorder ? `1px solid ${theme?.palette?.primary?.main}` : 'none')};

  span:nth-of-type(2) {
    color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  }
`;

export const TextInfo = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
`;
