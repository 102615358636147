import React from 'react';
import { UseFormReturn, Controller, SubmitHandler } from 'react-hook-form';
// Global Components
import Input from 'components/UI/Input';
// Interfaces
import { ILogin } from 'interfaces/auth';
// Utils
import { LoginInputs } from 'utils/constants/auth';
// Icons
import { Logo } from 'assets/logos';
// Components
import LoginSave from '../LoginSave';
import LoginButtons from '../LoginButtons';
import CreateAccountLink from '../CreateAccountLink';
// Styles
import {
  Container,
  Form,
  Title,
  SubTitle,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface LoginFormProps {
  formState: UseFormReturn<ILogin>;
  onSubmit: SubmitHandler<ILogin>;
  isLoading: boolean;
}


const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  formState,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Form>
        <Logo />
        <Title variant="h1">
          {t('Вітаємо')}
        </Title>

        <SubTitle variant="h3">
          {t('Найкращий інструмент для перевірки продуктових гіпотез')}
        </SubTitle>

          <Controller
            render={({ field }) => (
              <Input
                helperText={formState.formState?.errors?.email?.message}
                error={!!formState.formState?.errors?.email?.message}
                isSpaceBottom={5}
                label={t(`Пошта`)}
                type={'text'}
                {...field}
              />
            )}
            control={formState.control}
            name={'email'}
          />
          <Controller
            render={({ field }) => (
              <Input
                helperText={formState.formState?.errors?.password?.message}
                error={!!formState.formState?.errors?.password?.message}
                isSpaceBottom={5}
                label={t(`Пароль`)}
                type={'password'}
                {...field}
              />
            )}
            control={formState.control}
            name={'password'}
          />

        <LoginSave />

        <LoginButtons
          formState={formState}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      </Form>

      <CreateAccountLink />
    </Container>
  )
};
export default LoginForm;
