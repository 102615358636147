import {styled} from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  align-self: center;
  flex-direction: column;
  margin: auto;
  padding: 40px 25px;
  text-align: center;
`

export const RegularText = styled('p')`
  margin-top: 25px;
  color:  #838383;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 18px;
  border-bottom: 1px solid #EBEBEB;
`
export const ImgWrap = styled('div')`
  width: 100%;
`

export const Image = styled('img')`
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  object-fit: contain;
`;
