import { styled, css } from '@mui/material/styles';
import {Box, Typography} from '@mui/material';

interface CircleProps {
  color: string;
}

export const ChartWrapper = styled('div')`
  .custom-tooltip {
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    width: 170px;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.06);
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
`;

export const ContainerHeader = styled(Box)`
  width: 100%;
  max-width: 412px;
  padding-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const Header = styled(Typography)`
`;

export const Prise = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.red?.dark};
`;

export const Circle = styled('div')<CircleProps>`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  margin-right: 5px;
  ${({ color }) => (color && css`
    background: ${color};
  `)};
`;

export const TooltipRow = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TooltipTitle = styled('div')`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #07B169;
  margin-bottom: 5px;
`;

export const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface ParamValueProps {
  isMinusValue?: boolean;
}

export const ParamValue = styled('div')<ParamValueProps>`
  display: flex;
  color: ${({ isMinusValue }) =>  isMinusValue ? '#D52727' : '#07B169'};
`;

export const ParamTitle = styled('div')`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;