import React from 'react';

import {TableTitle, TableWrap} from './styles'

interface TableWrapperProps {
  title: string
}

const TableWrapper: React.FC<TableWrapperProps> = ({children, title}) => {

  return (
    <TableWrap>
      <TableTitle variant='h1'>{title}</TableTitle>
      {children}
    </TableWrap>
  )
};

export default TableWrapper;
