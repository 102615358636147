import React from 'react';
import { UseFormReturn, Controller, SubmitHandler } from 'react-hook-form';
// Global Components
import Input from 'components/UI/Input';
// Interface
import { IRegistration } from 'interfaces/auth';
// Utils
import { InputsRules, RegistrationInputs } from 'utils/constants/auth';
import { isMatch } from 'utils/validations/password-recovery';
// Components
import RegistrationEmailFooterButtons from './RegistrationEmailFooterButtons';
// Styles
import { SubTitle } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface RegistrationEmailStepProps {
  formState: UseFormReturn<IRegistration>;
  onSubmit: SubmitHandler<IRegistration>;
}

const RegistrationEmailStep: React.FC<RegistrationEmailStepProps> = ({ onSubmit, formState }) => {
  const { isValid } = formState.formState;
  const newPassword = formState.watch('password');
  const { t } = useTranslation();
  return (
    <>
      <SubTitle variant="h3">
        {t('Створіть аккаунт для перевірки продуктових гіпотез')}
      </SubTitle>

      {RegistrationInputs.map((input) => (
        <Controller
          name={input.name as keyof IRegistration}
          control={formState.control}
          rules={{
            required: {
              value: input.rules.required.value,
              message: t(input.rules.required.message)
            },
            pattern: {
              value: input.rules.pattern.value,
              message: t(input.rules.pattern.message),
            }
          }}
          key={input.name}
          render={({ field }) => (
            <Input
              error={!!formState.formState.errors?.[input.name as keyof IRegistration]?.message}
              helperText={formState.formState.errors?.[input.name as keyof IRegistration]?.message}
              label={t(`${input.label}`)}
              type={input.type}
              {...field}
            />
          )}
        />
      ))}

      <Controller
        render={({ field }) => (
          <Input
            error={!!formState.formState.errors?.confirmPassword?.message}
            helperText={formState.formState.errors?.confirmPassword?.message}
            isSpaceBottom={10}
            label={t('Повторіть пароль')}
            type="password"
            {...field}
          />
        )}
        control={formState.control}
        name="confirmPassword"
        rules={{
          required: {
            value: InputsRules.required.value,
            message: t(InputsRules.required.message)
          },
          pattern: {
            value: InputsRules.patternPassword.value,
            message: t(InputsRules.patternPassword.message),
          },
          validate: (value) => {
            const result = isMatch(value, newPassword);
            if (typeof (result) === "string") {
              return t(result);
            } else {
              return result
            }
          },
        }}
      />

      <RegistrationEmailFooterButtons
        isValid={isValid}
        formState={formState}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default RegistrationEmailStep;
