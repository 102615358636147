import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Store
import { useGetPipelineByIdQuery } from 'store/tutors/tutorsServices';
import { selectTutor } from 'store/tutors/tutorsSlice';
import { useGetProjectByIdQuery } from 'store/dashboard/dashboardServices';
import {useHandleRequestError} from "hooks/useHandleRequestError";
// Components
import Loading from 'components/UI/Loading';
import TutorHeader from 'components/Tutor/TutorHeader';
import AddTutor from './AddTutor';
import Employees from './Employees';
import Products from './Products';
import Materials from './Materials';
import PackagingLogisticsCosts from './PackagingLogisticsCosts';
import EmployeeProduct from './EmployeeProduct';
import AdditionalCosts from './AdditionalCosts';
// Styles
import { Container, Wrapper } from './styles';

const Tutor: FC = () => {
  const { step } = useParams();
  const {
    step1, pipeline_id, employees, products, project_id,
  } = useSelector(selectTutor);

  const { isLoading, data, refetch, isError } = useGetPipelineByIdQuery({
    pipeline_id: pipeline_id as string,
    project_id: project_id as string,
  }, {
    skip: !pipeline_id,
  });

  useHandleRequestError(isError);

  const { data: project, refetch: projectRefetch } = useGetProjectByIdQuery({
    project_id: project_id as string,
  });

  const refetchDatePipeline = () => {
    projectRefetch()
    refetch()
  }

  useEffect(() => {
    refetchDatePipeline();
  }, [pipeline_id])

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <>
      <AddTutor defaultValues={step1} />

      <Wrapper>
        {!!step && <TutorHeader step={+step - 1} />}

        <Employees defaultValues={employees} />

        <Products defaultValues={products} />

        <Materials currency={project?.currency as string} />

        <PackagingLogisticsCosts currency={project?.currency as string} />

        <EmployeeProduct />

        <AdditionalCosts />
      </Wrapper>
    </>
  );
};

export default Tutor;
