import React from 'react';
import { useSearchParams } from 'react-router-dom';
// Store
import { useVerifyUserQuery } from 'store/auth/authService';
// Styles
import { Container, H1, StyledLoading } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const ActivationUser = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token');

  const { isLoading } = useVerifyUserQuery(token as string, {
    skip: !token
  })
  const { t } = useTranslation();
  return (
    <Container>
      <H1 variant="h1">
        {t('Активація аккаунта')}
      </H1>

      <StyledLoading size={80} />
    </Container>
  )
}

export default ActivationUser;
