import React from 'react';
// Store
import { useGetInvoicesQuery } from 'store/auth/authService';
// Components
import TransactionTable from '../TransactionTable';
import FilterInputs from './FilterInputs';
// Styles
import { Section } from '../styles';
import {
  Title,
  Wrapper,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const TransactionHistory: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Section isSpaceBottom={30} id="history">
      <Wrapper>
        <Title variant="h2">
          {t('Історія транзакцій')}
        </Title>

        <FilterInputs />
      </Wrapper>

      <TransactionTable />
    </Section>
  )
};

export default TransactionHistory;
