/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import { IPipelineEmployee } from 'store/dashboard/dashboardTypes';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Checkbox,
  Collapse,
  Button,
  Typography,
} from '@mui/material';
import { useDeleteEmployeeMutation } from 'store/tutors/tutorsServices';
import {
  SettingsIcon, MoreSetingsIcon, ArrowRightIcon, ArrowLeftIcon,
} from 'assets/icons';
import Tooltip from 'components/UI/Tooltip';
import ConfirmationModal from "components/ConfirmationModal";
import SettingsModal from './SettingsModal';
import EditEmployeeModal from '../AddEmployeeModal';
// Styles
import {
  CollapseTableRow,
  SettingsLink,
  StyledTableHeader,
  Arrow,
  StyledTableContainer,
  MenuList,
  MenuListItem,
  MenuListItemButton,
  Text,
  MenuButton,
  PaginationWrap,
  PaginationRow,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface Data {
  name: string,
  position: string,
  unit: string,
  type_amount: string,
  amount: number
}

function createData(
  name: string,
  position: string,
  unit: string,
  type_amount: string,
  amount: number,
): Data {
  return {
    name,
    position,
    unit,
    type_amount,
    amount,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Ім’я та позиція',
  },
  {
    id: 'position',
    numeric: false,
    label: 'Посада',
  },
  {
    id: 'unit',
    numeric: false,
    label: 'Підрозділ',
  },
  {
    id: 'type_amount',
    numeric: false,
    label: 'Тип оплати',
  },
  {
    id: 'amount',
    numeric: true,
    label: 'Рейт / ставка',
  },
];

const amountTypeData = [
  {
    value: 'fixed',
    label: 'Фіксована',
  },
  {
    value: 'hourly',
    label: 'Погодинна',
  },
];

interface TeamTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSettingClick: (event: React.MouseEvent<unknown>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  collapse?: boolean;
}

function TeamsTableHead(props: TeamTableProps) {
  const {
    onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, collapse, handleSettingClick,
  } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();
  return (
    <StyledTableHeader className={collapse ? 'collapse' : ''}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            width={400}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          {/* <SettingsLink onClick={handleSettingClick}>
            <SettingsIcon />
          </SettingsLink> */}
        </TableCell>
      </TableRow>
    </StyledTableHeader>
  );
}

interface TeamTabelProps {
  employees?: IPipelineEmployee[],
  refetch: () => void,
}

const TeamTable: FC<TeamTabelProps> = ({ employees, refetch }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteEmployeeItem, setDeleteEmployeeItem] = useState('');
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState<boolean | number>(0);
  const [editing, setEditing] = React.useState<IPipelineEmployee | null>(null);

  const [deleteEmployee] = useDeleteEmployeeMutation();
  const toggleModal = () => setOpenDeleteModal((prev) => !prev);
  const deleteEmployeeHandle = async () => {
    await deleteEmployee(deleteEmployeeItem);
    setOpenDeleteModal(false);
    refetch();
  }

  const departments = employees?.reduce((acc, { department }) => {
    // @ts-ignore
    if (acc.includes(department)) {
      return acc;
    }
    // @ts-ignore
    acc.push(department);
    return acc;
  }, [])

  const tableData = departments?.map((dep) => ({
    name: dep,
    rows: employees?.filter(({ department }) => (dep === department))
  }));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = tableData?.reduce((acc, { name, rows }) => {
        // @ts-ignore
        acc = [...acc, ...rows.map((n) => n.name)];
        return acc;
      }, []);
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllDepartmentClick = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelecteds = tableData?.find((d) => (d.name === name))
        .rows.map((n) => (n.name));
      // @ts-ignore
      setSelected(newSelecteds || []);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    // @ts-ignore
    const selectedIndex = selected.indexOf(name);
    // @ts-ignore
    let newSelected = [];

    if (selectedIndex === -1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      // @ts-ignore
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    // @ts-ignore
    setSelected(newSelected || []);
  };

  const getAmountType = (amountType: string) => {
    if (!amountType) {
      return '';
    }
    const found = amountTypeData.find(({ value }) => (amountType === value));
    return found ? found.label : ''
  }

  // @ts-ignore
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const onCloseSettingsModal = () => setOpenSettingsModal((prev) => !prev);
  const { t } = useTranslation();
  return (
    <StyledTableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
      >
        <TeamsTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={6}
          handleSettingClick={() => { setOpenSettingsModal((prev) => !prev); }}
        />
        <TableBody>
          {
            tableData?.map(({ name, rows }, index) => (
              <>
                <CollapseTableRow
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                  onClick={() => {
                    // @ts-ignore
                    if (open !== false && open === index) {
                      setOpen(false)
                    } else {
                      setOpen(index);
                    }
                  }
                  }
                >
                  <TableCell padding="checkbox">
                    <Checkbox onChange={(event) => handleSelectAllDepartmentClick(event, name)} />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {t(name)}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <Arrow
                      // @ts-ignore
                      $isOpen={open !== false && open === index}
                    />
                  </TableCell>
                </CollapseTableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={12}>
                    <Collapse in={
                      // @ts-ignore
                      open !== false && open === index
                    } timeout="auto" unmountOnExit>
                      <Table>
                        <TeamsTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={6}
                          collapse
                          handleSettingClick={() => { }}
                        />
                        <TableBody>
                          { // @ts-ignore
                            stableSort(rows, getComparator(order, orderBy))
                              .map((row, index) => {
                                // @ts-ignore
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) => {
                                      // @ts-ignore
                                      handleClick(event, row.name)
                                    }}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.name}
                                    selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox" component="th" scope="row">
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      id={labelId}
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell>{t(row.type)}</TableCell>
                                    <TableCell>{t(row.department)}</TableCell>
                                    <TableCell>{t(getAmountType(row.amount_type))}</TableCell>
                                    <TableCell>{row.wage}</TableCell>
                                    <TableCell>
                                      <Tooltip
                                        arrow
                                        placement="bottom"
                                        tooltipText=""
                                        tooltipContent={(
                                          <MenuList disablePadding>
                                            <MenuListItem disablePadding>
                                              <MenuListItemButton
                                                onClick={() => {
                                                  setEditing(row);
                                                  setOpenEditModal((prev) => !prev);
                                                }}
                                              >
                                                <Text variant="regularText">{t('Редагувати')}</Text>
                                              </MenuListItemButton>
                                            </MenuListItem>
                                            <MenuListItem disablePadding>
                                              <MenuListItemButton onClick={() => {
                                                setDeleteEmployeeItem(row?.id as string);
                                                toggleModal();
                                              }}>
                                                <Text variant="regularText" className="red">{t('Видалити')}</Text>
                                              </MenuListItemButton>
                                            </MenuListItem>
                                          </MenuList>
                                        )}
                                      >
                                        <MenuButton>
                                          <MoreSetingsIcon />
                                        </MenuButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          }
        </TableBody>
      </Table>
      {/*<PaginationRow>*/}
      {/*  <PaginationWrap>*/}
      {/*    <Text variant="h6">{t('Сторінка')}  1 - 10</Text>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowLeftIcon />*/}
      {/*    </Button>*/}
      {/*    <Button variant="icon">*/}
      {/*      <ArrowRightIcon />*/}
      {/*    </Button>*/}
      {/*  </PaginationWrap>*/}
      {/*</PaginationRow>*/}
      <SettingsModal isOpen={openSettingsModal} onClose={onCloseSettingsModal} />
      <EditEmployeeModal
        isOpen={openEditModal}
        employee={editing}
        departments={departments}
        onClose={() => {
          setEditing(null);
          setOpenEditModal((prev) => !prev);
          refetch();
        }}
      />
      <ConfirmationModal
        deleteHandler={deleteEmployeeHandle}
        onClose={toggleModal}
        isOpen={openDeleteModal}
        title={t('Видалити')+'?'}
      />
    </StyledTableContainer>
  );
};

export default TeamTable;
