import React from 'react';
import { UseFormReturn, SubmitHandler } from 'react-hook-form';
// Interface
import { IRegistration } from 'interfaces/auth';
// Components
import RegistrationCountryStep from '../RegistrationCountryStep';
import RegistrationEmailStep from '../RegistrationEmailStep';

interface ActionsRegistrationStepsProps {
  formState: UseFormReturn<IRegistration>;
  onStepChange: (step: number) => void;
  onSubmit: SubmitHandler<IRegistration>;
  step: number;
}

const ActionsRegistrationSteps: React.FC<ActionsRegistrationStepsProps> = ({
  onStepChange,
  onSubmit,
  formState,
  step,
}) => {
  if (step === 2) {
    return (
      <RegistrationEmailStep
        formState={formState}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <RegistrationCountryStep
      onStepChange={onStepChange}
      formState={formState}
      onSubmit={onSubmit}
    />
  );
};

export default ActionsRegistrationSteps;
