import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Store
import { useGetPipelineByIdQuery } from 'store/dashboard/dashboardServices';
// Components
import Loading from 'components/UI/Loading';
import PagesSetting from '../Teams/PagesSetting';
import AddSources from './AddSources';
import TabPanel from 'components/UI/TabPanel';
import ProductsTable from './ProductsTable/ProductsTable';
import SourcesTable from './SourcesTable';
import TitleSection from './TitleSection';
import TableHeaderSection from './TableHeaderSection';
// Styles
import {
  TableContainer,
  Container,
  TabContainer
} from './styles';
import Footer from "../../components/Footer";

const ProductsAndServices: React.FC = () => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openAddSourcesModal, setOpenAddSourcesModal] = useState(false);
  const [tabMode, setTabMode] = useState(0);
  const { project_id, pipeline_id } = useParams();

  const { data, isLoading, refetch } = useGetPipelineByIdQuery({
    project_id: project_id as string,
    pipeline_id: pipeline_id as string,
  })

  const handleChangeTabMode = (event: React.SyntheticEvent, newValue: number) => {
    setTabMode(newValue);
  };

  const handleSettingModal = () => setOpenSettingsModal(prev => !prev);
  const handleAddSourcesModal = () => setOpenAddSourcesModal(prev => !prev);

  useEffect(() => {
    refetch();
  }, [])

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  return (
    <Container>
      <TitleSection handleSettingModal={handleSettingModal} />

      <TableContainer className="tabs">
        <TableHeaderSection
          handleChangeTabMode={handleChangeTabMode}
          handleAddSourcesModal={handleAddSourcesModal}
          tabMode={tabMode}
        />

        <TabContainer>
          <TabPanel value={tabMode} index={0}>
            <ProductsTable products={data?.products || []} />
          </TabPanel>

          <TabPanel value={tabMode} index={1}>
            <SourcesTable />
          </TabPanel>
        </TabContainer>
      </TableContainer>

      <PagesSetting
        isOpen={openSettingsModal}
        onClose={handleSettingModal}
      />

      <AddSources
        isOpen={openAddSourcesModal}
        onClose={handleAddSourcesModal}
      />
      <Footer isSpace isSticki />
    </Container>
  );
};

export default ProductsAndServices;
