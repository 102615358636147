import { ComponentsOverrides, Theme } from '@mui/material';

interface muiToggleButtonProps {
  styleOverrides: ComponentsOverrides['MuiToggleButton'];
}

export const muiToggleButton = (theme: Theme): muiToggleButtonProps => ({
  styleOverrides: {
    root: {
      border: 'none',
      color: theme.palette.custom.grey.lightIco,
      textTransform: 'none',
      '&.Mui-selected': {
        height: '40px',
        background: theme.palette.custom.green.main,
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',
        borderRadius: '8px',
      },
      '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },
    },
  },
});
