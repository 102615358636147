import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectDashboard } from 'store/dashboard/dashboardSlice';
import { useGetSalesPlanQuery } from 'store/dashboard/dashboardServices';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { getUtcFormatDate } from 'utils/helpers/index';
import { DATE } from "utils/constants/date";
// Components
import TotalTableRow from './TotalTableRow';
import TableRow from './TableRow';
import SalesMonthFilters from './SalesMonthFilters';
import TableHeader from './TableHeader';
// Styles
import { Container } from './styles';

interface IDate {
  startDate: any;
  endDate: any;
}

const SalesMonths: React.FC = () => {
  const [activeIndex, setactiveIndex] = useState(0)
  const { pipeline_id } = useParams();
  const { planSales } = useSelector(selectDashboard);

  const [dateValue, setDateValue] = useState<IDate>({
    startDate: DATE.startDate,
    endDate: DATE.endDate,
  });

  const { data } = useGetSalesPlanQuery({
    pipeline_id: pipeline_id as string,
    from: getUtcFormatDate(dateValue?.startDate),
    to: getUtcFormatDate(dateValue?.endDate),
  });

  const onNextMonth = () => {
    if (activeIndex === 11) {
      setactiveIndex(0)
    } else {
      setactiveIndex(activeIndex + 1)
    }
  }

  const onPrevMonth = () => {
    if (activeIndex === 0) {
      setactiveIndex(11)
    } else {
      setactiveIndex(activeIndex - 1)
    }
  }

  return (
    <Container>
      <SalesMonthFilters onNextMonth={onNextMonth} onPrevMonth={onPrevMonth} activeIndex={activeIndex} />

      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHeader />

          <TableBody>
            {planSales?.map(product => (
              <TableRow product={product} activeIndex={activeIndex} />
            ))}

            <TotalTableRow plan={data || []} activeIndex={activeIndex} />
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default SalesMonths;
