import { Theme } from '@mui/material';

type muiTooltipProps = {
  styleOverrides: {
    tooltip: {
      backgroundColor: string;
      padding: string;
      boxShadow: string;
    };
    arrow: {
      boxShadow: string;
      color: string;
    };
  };
};

export const muiTooltip = (theme: Theme): muiTooltipProps => ({
  styleOverrides: {
    tooltip: {
      backgroundColor: theme.palette?.primary?.contrastText,
      padding: '17px',
      boxShadow: '0px 5px 14px 4px rgba(0, 0, 0, 0.07)',
    },
    arrow: {
      boxShadow: '0px 10px 14px rgba(0, 0, 0, 0.07)',
      color: theme.palette?.primary?.contrastText,
    },
  },
});
