import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Container = styled(Box)`
`;

export const Form = styled(Box)`
  display: flex;
  margin-bottom: 30px;
  max-height: 79px;

  .subsection-input {
    max-width: 838px;
  }
  .amount-input {
    max-width: 120px;
  }

  & > .MuiFormControl-root, .MuiToggleButtonGroup-root {
    margin-right: 20px;
  }
`;

export const DeleteBtn = styled(Button)`
  background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
  color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  align-self: end;
  &:hover, &:focus {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    color: ${({ theme }) => theme.palette.custom.grey.lightIco};
  }
`;

export const FormFooter = styled('div')`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const LinkButton = styled(Button)`
  text-decoration: none;
  padding: 0;
  margin-bottom: 20px;
  margin-right: 15px;
  &:hover {
    text-decoration: none;
  }
`;
