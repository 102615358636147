import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 64px);
`;

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;
