import { styled } from '@mui/material/styles';
import { Box, Button as StyledButton } from '@mui/material';

export const Button = styled(StyledButton)`
  background-color: ${({ theme }) => theme?.palette?.custom?.green?.mainLight};
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '75%')};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '410px')};
  height: 48px;

  &:hover {
    color: ${({ theme }) => theme?.palette?.primary?.contrastText};
  }
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
`;
