import { styled } from '@mui/material/styles';
import { Button, LinearProgress, Box } from '@mui/material';

export const Header = styled('header')`
  position: sticky;
  top: 0;
  z-index: 10000;
  grid-area: header;
  width: 100%;
  padding: 20px 20px 20px 0px;
  height: 84px;
  background: ${({ theme }) => theme.palette.primary.contrastText};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Progress = styled(LinearProgress)`
  width: 100%;

  &.MuiLinearProgress-root {
    background: transparent;
    position: absolute;
    bottom: 0;
    height: 1px;
  }

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.custom.green.main};
    height: 1px;
  }
`;

export const LogoWrap = styled(Box)`
  cursor: pointer;
  transition: all 0.5s ease;
  .logo {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const Wrapper = styled(Box)`
  max-width: 1122px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CurrentStepIndicator = styled('div')`
  color: ${({ theme }) => theme.palette.custom.primary.silver};
`;

export const ControlButtons = styled(Box)`
`;

export const ControlButton = styled(Button)`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.custom.primary.silver};
  && {
    background: rgba(164, 164, 164, 0.1);
  }
  .cancel {
    margin-right: 12px;
  }
`;
