/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-self-compare */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
// Constants
import {
  EMPLOYEES_DEFAULT_VALUES, PRODUCT_DEFAULT_VALUES, PLAN_MONTH, calcPlanMonth,
} from 'utils/constants/tutor';
import { calcEmployeeHoursByProduct } from 'utils/calculates/summary-table';
import { pipelineServices } from './pipelineServices';
// import { TutorState, IEmployee, IProduct } from './tutorTypes';
import {
  PipelineState, ResponsePipeline, IProduct, IEditMonthlySalesPlan, ISales,
} from './pipelineTypes';

const initialState: PipelineState = {
  prodactsDataTable: [],
  employees: [],
  workingHours: 160,
  currency: '$',
  generalPipelineId: '',
  planSales: [],
};

export const pipelineSlice = createSlice({
  name: 'dashboard',
  initialState,

  reducers: {
    setProdactsDataTable: (state, action: PayloadAction<any>) => {
      state.prodactsDataTable = action.payload;
    },

    setWorkingHours: (state, action: PayloadAction<string>) => {
      state.workingHours = +action.payload;
    },

    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },

    setGeneralPipelineId: (state, action: PayloadAction<string>) => {
      state.generalPipelineId = action.payload;
    },

    setMonthlySalesPlan: (state, action: PayloadAction<IEditMonthlySalesPlan>) => {
      const updateMonthSalesProduct = state?.prodactsDataTable?.map((item) => {
        if (item.product_id === action?.payload?.product_id) {
          return { ...item, monthlySalesPlan: action.payload.monthlySalesPlan };
        }
        return item;
      });

      state.prodactsDataTable = updateMonthSalesProduct;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      pipelineServices.endpoints.getPipelineById.matchFulfilled,
      (state, { payload }) => {
        if (payload?.products?.length) {
          const updateProducts = payload?.products?.map((item) => {
            const employeeProducts = calcEmployeeHoursByProduct(item?.EmployeeProducts, item?.month_sales || 1);
            const plan = state.planSales.find((item) => item?.product_id === item?.product_id);

            return {
              ...item,
              monthlySalesPlan: calcPlanMonth(item?.month_sales, plan?.sales as ISales[]),
              EmployeeProducts: employeeProducts,
            };
          });

          state.prodactsDataTable = updateProducts;
        }

        if (payload?.employees?.length) {
          state.employees = payload?.employees;
        }
      },
    );
    builder.addMatcher(
      pipelineServices.endpoints.getSalesPlan.matchFulfilled,
      (state, { payload }) => {
        state.planSales = payload;

        if (state?.prodactsDataTable) {
          const updateProducts = state?.prodactsDataTable?.map((item) => {
            const plan = payload.find((item) => item?.product_id === item?.product_id);

            return {
              ...item,
              monthlySalesPlan: calcPlanMonth(item?.month_sales, plan?.sales as ISales[]),
            };
          });

          state.prodactsDataTable = updateProducts;
        }
      },
    );
  },
});

export const {
  setProdactsDataTable,
  setWorkingHours,
  setMonthlySalesPlan,
  setCurrency,
  setGeneralPipelineId,
} = pipelineSlice.actions;

export default pipelineSlice.reducer;

export const selectPipeline = (state: RootState) => state.dashboard;
