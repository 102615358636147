import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button as StyledButton,
} from '@mui/material';
import { Link as StyledLink } from 'react-router-dom';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  position: relative;
  padding: 20px;

  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: fit-content;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  text-align: center;
  width: 100%;
  margin: 10px 0;

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const Button = styled(StyledButton)`
  height: 55px;
  margin-top: 30px;

  @media (max-width: 640px) {
    font-size: 14px;
    padding: 18px 10px;
  }
`;

export const Link = styled(StyledLink)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
  text-decoration: underline;
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
`;

export const Image = styled('img')`
  width: 80px;
  height: 20px;
  margin-top: 5px;
`;
