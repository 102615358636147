import React from 'react';
// Icons
import { UserIcon, ArrowDownIcon } from 'assets/icons';
// Styles
import { Container, IconButton } from './styles';

interface UserMenuProps {
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}
const UserMenu: React.FC<UserMenuProps> = ({ handleOpenMenu, open }) => (
  <Container onClick={handleOpenMenu}>
    <IconButton
      aria-controls={open ? 'account-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
    >
      <UserIcon color="#A4A4A4" />
    </IconButton>

    <ArrowDownIcon color="#07B169" />
  </Container>
);

export default UserMenu;
