import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

interface LabelEditProps {
  isDisabled?: boolean;
  isRed?: boolean;
}

export const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme?.palette?.custom?.green?.mainLight};
  height: 216px;
  margin-bottom: 30px;
  position: relative;
`;

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.green?.main};
`;

export const ContainerIcon = styled('div')`
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 20px;
  transition: all 0.5s ease;
  border-radius: 4px;

  &:hover {
    background: #07B169;
    opacity: 0.5;
  }
`;

export const ContainerMenu = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 -17px;
`;

export const Label = styled('div')<LabelEditProps>`
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ isRed }) => (isRed ? 'red' : '#838383')};
  padding: 10px 0;
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background: #FAFAFA;
  }
`;

export const LabelEdit = styled('span')<LabelEditProps>`
  display: ${({ isDisabled }) => (isDisabled ? 'flex' : 'none')};
  background: #845AF7;
  border-radius: 8px;
  width: 132px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  position: absolute;
  top: 18px;
  left: 18px;
`;
