import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Collapse,
  Button,
} from '@mui/material';
// Styles
import { Container, Arrow } from './styles';

interface CollapseTableRowProps {
  groupName: string;
  openHandleTable: () => void;
  open: boolean;
}

const CollapseTableRow: React.FC<CollapseTableRowProps> = ({ groupName, openHandleTable, open }) => (
  <Container sx={{ '& > *': { borderBottom: 'unset' } }}>
    <TableCell padding="checkbox">
      <Checkbox
        // onChange={(event) => handleSelectAllDepartmentClick(event, name)}
      />
    </TableCell>

    <TableCell
      component="th"
      scope="row"
    >
      {groupName}
    </TableCell>

    <TableCell></TableCell>
    <TableCell></TableCell>

    <TableCell onClick={openHandleTable}>
      <Arrow
        $isOpen={open}
      />
    </TableCell>
  </Container>
)

export default CollapseTableRow;
