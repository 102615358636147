import {
  Box,
  Typography,
  Button as StyledButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonProps {
  component?: string | undefined;
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  && {
    background: ${({ theme }) => theme.palette.custom.grey.toggleBackground};
    height: 48px;
    align-self: end;
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.primary?.dark};
  width: 100%;
  margin-bottom: 10px;
  
  &.second-head {
    margin-top: 20px;
  }
  
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  max-width: 315px;
  margin-bottom: 10px;
`;
export const ContainerButton = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerHeader = styled(Box)`
  width: 100%;
  max-width: 412px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(StyledButton)<ButtonProps>`
  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  
  && {
    height: 60px;
  }
`;

export const WrapperButton = styled('label')`
  margin-bottom: 10px;
`;

export const InlineFormWrap = styled('div')`
  width: 100%;
  display: flex;
  
  .time-input {
    margin-right: 10px;
  }
  
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const LinkButton = styled(StyledButton)`
    text-decoration: none;
    align-items: flex-start;
    width: fit-content;
    padding-left: 0;
    svg {
      margin-right: 8px;
    }
    &:hover {
      text-decoration: none;
    }
`;

export const FormContainerFooter = styled('div')`
  background: ${({ theme }) => theme.palette.custom.green.light};
  color: ${({ theme }) => theme.palette.custom.green.main};
  margin: 0 -40px;
  display: flex;
  align-items: center;
  padding: 26px 40px;
`;
