import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { defaultTheme } from './theme/theme';

const Theme: React.FC = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />

    {children}
  </ThemeProvider>
);

export default Theme;
