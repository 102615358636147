import React from 'react';
import {useTranslation} from "react-i18next";
import Modal from "components/UI/Modal";
import {Button, Container, ContainerButton, Title} from "./styles";


interface ConfirmationModalProps {
  deleteHandler: () => void;
  onClose: () => void;
  isOpen: boolean;
  title: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({deleteHandler, onClose, isOpen, title}) => {

  const { t } = useTranslation();
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
    >
      <Container>
        <Title variant="h1">
          {title}
        </Title>

        <ContainerButton>
          <Button
            isDelete
            onClick={deleteHandler}
            variant="contained"
            component="span"
          >
            {t('components.Tutor.DeletePipelineModal.Видалити')}
          </Button>

          <Button
            variant="light"
            onClick={onClose}
          >
            {t('components.Tutor.DeletePipelineModal.Скасувати')}
          </Button>
        </ContainerButton>
      </Container>
    </Modal>
  )
};

export default ConfirmationModal;
