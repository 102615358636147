import React, { useState } from 'react';
// Components
import UserSubscriptioInputs from './UserSubscriptioInputs';
import UserBankCards from './UserBankCards';
import AddBankCardModal from './AddBankCardModal';
// Styles
import { Section } from '../styles';
import { Title, Container } from './styles';

// Пока эта секция скрыта

const UserSubscriptionOptions: React.FC = () => {
  const [openAddCardModal, setOpenAddCardModal] = useState(false);
  const modalHandler = () => setOpenAddCardModal((prev) => !prev);

  return (
    <Section isSpaceBottom={30} sx={{ display: 'none' }}>
      <Title variant="h2">
        Опції підписки
      </Title>

      <Container>
        <UserSubscriptioInputs onOpnen={modalHandler} />

        <UserBankCards />
      </Container>

      <AddBankCardModal
        open={openAddCardModal}
        onClose={modalHandler}
      />
    </Section>
  );
};

export default UserSubscriptionOptions;
