import { InputsRules } from 'utils/constants/auth';

export const TRIAL_PERIOD = 14

export const INFO_INPUTS_VALUES = {
  email: '',
  residence: '',
  phone_number: '',
  firstname: '',
  lastname: '',
};

export const USER_PROFILE_INPUTS = [
  {
    label: 'Ім’я',
    name: 'firstname',
    placeholder: 'Ім’я',
    type: 'text',
    rules: {
      required: InputsRules.required,
    },
  },
  {
    label: 'Фамілія',
    placeholder: 'Фамілія',
    name: 'lastname',
    type: 'text',
    rules: {
      required: InputsRules.required,
    },
  },
  // {
  //   label: 'Телефон',
  //   name: 'phone_number',
  //   placeholder: 'Номер телефону',
  //   type: 'number',
  //   rules: {
  //     required: InputsRules.required,
  //   },
  // },
  {
    label: 'E-mail',
    name: 'email',
    type: 'text',
    placeholder: 'E-mail',
    rules: {
      required: InputsRules.required,
      pattern: InputsRules.patternEmail,
    },
  },
];

export const languagesData = [
  {
    value: 'en', label: 'Англійська',
  },
  {
    value: 'uk', label: 'Українська',
  },
];

export const paymentMethodData = [
  {
    value: 'liqPay', label: 'LiqPay',
  },
];

export const USER_PROFILE_TOGGLE_BUTTONS = [
  {
    label: 'Автопоновлення',
    firstValue: 'Так',
    lastValue: 'Ні',
  },
  {
    label: 'Тип підписки',
    firstValue: 'Місячна',
    lastValue: 'Річна',
  },
];

export const itemsFilteredData = [
  {
    value: '100', label: '100',
  },
  {
    value: '50', label: '50',
  },
  {
    value: '10', label: '10',
  },
];

export enum MEMBERSHIP_STATUS
{
  FREE = 'free',
  TRIAL = 'trial',
  PREMIUM1 = 'premium1',
  PREMIUM2 = 'premium2',
  PREMIUM3 = 'premium3',
}
