import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  margin-right: 10px;
  margin-bottom: 12px;
  & > * {
    margin-left: 10px;
  }
`;
