import React, { FC } from 'react';
import { Typography } from '@mui/material';
import {
  useFieldArray, Control, FieldErrors, useWatch,
} from 'react-hook-form';
// Store
import { useDeleteExpensesMutation } from 'store/tutors/tutorsServices';
// Interface
import { IProductCreate, IPackagingCreate } from 'interfaces/product';
// Components
import PackagingFormRow from '../PackagingFormRow';
// Styles
import {
  Form, FormFooter, LinkButton, TotalCost,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

interface PackagingItemProps {
  updateItem: (productIndex: number, id?: string) => void;
  errors: FieldErrors<{ materialProducts: IProductCreate[] }>
  control: Control<{ materialProducts: IProductCreate[] }>,
  product: IProductCreate;
  indexProduct: number;
  currency: string;
}

const PackagingItem: FC<PackagingItemProps> = ({
  errors, control, product, indexProduct, updateItem, currency,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `materialProducts.${indexProduct}.expenses.Packaging`,
  });
  const { t } = useTranslation();
  const watch = useWatch({ control });

  const [deleteExpenses] = useDeleteExpensesMutation();

  const updateProductPackaging = (id?: string) => {
    updateItem(indexProduct, id)
  }

  const deleteItem = async (id: number, material_id?: string) => {
    if (material_id) {
      remove(id);
      await deleteExpenses(material_id).unwrap();

      return;
    }

    remove(id);
  };

  const caclTotalValue = () => {
    const productsTotal = watch?.materialProducts?.[indexProduct]?.expenses?.Packaging as IPackagingCreate[];
    const totalValue = productsTotal?.reduce((acc: number, item: IPackagingCreate) => {
      const cost = item?.value || '0';
      acc += +cost;

      return +acc;
    }, 0);

    return totalValue?.toFixed(2) || 0;
  };

  const addedPackejing = () => {
    append({
      name: '',
      value: '0',
      amount: '',
      packaging_id: '',
      group: {
        parent_id: null,
        name: 'Packaging',
      },
    });
  };

  return (
    <Form>
      <Typography variant="h1">
        {product?.name}
      </Typography>

      {fields.map((packaging, index) => (
        <PackagingFormRow
          updateItem={updateProductPackaging}
          deleteItem={deleteItem}
          errors={errors}
          key={packaging.id}
          indexMaterial={index}
          indexProduct={indexProduct}
          packaging={packaging}
          control={control}
        />
      ))}

      <FormFooter>
        <LinkButton
          onClick={addedPackejing}
          variant="link"
        >
          {t('Додати витрату')}
        </LinkButton>

        <TotalCost>
          <Typography variant="regularText">
            {t('Вартість всіх витрат')}:
          </Typography>

          <Typography className="total-cost-value" variant="h1">
            {caclTotalValue()} {currency}
          </Typography>
        </TotalCost>
      </FormFooter>
    </Form>
  );
};

export default PackagingItem;
