import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
// Store
import { useGetProjectsQuery } from 'store/projects/projectsServices';
// Hooks
import { useTrialUser } from 'hooks/useTrialUser';
// Components
import Loading from 'components/UI/Loading';
import SideBarAccordion from '../SideBarAccordion';
//Icons
import { CloseFactIcon } from 'assets/icons';
// Styles
import { Container, Title, AccordionHeader, Accordion, BlockTitle } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
interface SideBarBodyProps {
  isOpen: boolean;
}

const SideBarBody: React.FC<SideBarBodyProps> = ({ isOpen }) => {
  const { project_id } = useParams<string>();
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const { isLoading, data } = useGetProjectsQuery({});
  const { lockNotFreeFunctionality } = useTrialUser();
  const { t } = useTranslation();

  const handleChangeAccordion = (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
  // console.log(project_id, pipeline_id);
    data?.data?.map((project, index) => {
      if (project?.project_id === project_id) {
        setExpanded(`panel${index + 1}`)
      }
    })
  }, [data])

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (!data?.data?.length) {
    return (
      <Container>
        <Title visible={isOpen} variant="h6">
          {t('components.SideBar.SideBarBody.Проекти відсутні')}
        </Title>
      </Container>
    );
  }

  return (
    <Container>
      {data?.data?.map((project, index) => (
        lockNotFreeFunctionality ?
          <Accordion disableGutters elevation={0} square>
            <AccordionHeader aria-controls={`panel${index + 1}d-content`} active={false}>
              <CloseFactIcon />
              <BlockTitle active={true} variant="regularText">
                {project.project_name}
              </BlockTitle>
            </AccordionHeader>
          </Accordion>
          :
          <SideBarAccordion
            key={project.project_id}
            lockNotFreeFunctionality={lockNotFreeFunctionality}
            handleChangeAccordion={handleChangeAccordion}
            expanded={expanded}
            isOpen={isOpen}
            panel={index + 1}
            title={project.project_name || 'Без заголовку'}
            isDisabledButton={true}
            pipelines={project.__pipelines__  as any}
            projectId={project.project_id}
          />
      ))}
    </Container>
  );
};

export default SideBarBody;
