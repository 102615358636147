import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface WrapperProps {
  maxWidth?: string | number;
  width?: string | number;
}

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}%`};
  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  border-radius: 8px;
  padding: 30px;
  height: fit-content;
  margin-top: 30px;

  margin-bottom: 50px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: fit-content;
  margin-bottom: 30px;
`;

export const Wrapper = styled(Box)<WrapperProps>`
  height: 377px;

  .recharts-legend-wrapper {
    display: none;
  }
`;
