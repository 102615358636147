import React, {useEffect} from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
// Interfaces
import { ICreateProject } from 'store/projects/projectsTypes';
// Icons
import { InfoNameIcon } from 'assets/icons'
// Utils
import { currency } from 'utils/constants/currency';
import { InputsRules } from 'utils/constants/auth';
// Global Components
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import SelectCountries from 'components/UI/SelectCountries';
// Styles
import {
  DisabledInput,
  Container,
  Wrapper,
  Title,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import GooglePlacesAutocomplete from "components/UI/GooglePlacesAutocomplete";

interface GeneralInputsProps {
  formState: UseFormReturn<ICreateProject>;
  pipelineName: string;
  isPipeline?: boolean;
}

const GeneralInputs: React.FC<GeneralInputsProps> = ({ formState, pipelineName, isPipeline }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Title variant="h4">
          {t('components.Projects.GeneralInputs.Назва проєкту та тип пайплайну')}
        </Title>

        <Controller
          name="project_name"
          control={formState.control}
          rules={{
            required: {
              value: InputsRules.required.value,
              message: t(InputsRules.required.message)
            }
          }}
          render={({ field }) => (
            <Input
              {...field}
              isSpaceBottom={20}
              label={t('components.Projects.GeneralInputs.Назва')}
              placeholder={t('components.Projects.GeneralInputs.Назва проєкту')}
              helperText={formState.formState.errors?.project_name?.message}
              error={!!formState.formState.errors?.project_name?.message}
            />
          )}
        />

        <DisabledInput
          name="activePipeline"
          label={t('components.Projects.GeneralInputs.Активний пайплайн')}
          value={pipelineName}
          disabled
          InputProps={{
            endAdornment: !isPipeline && (
              <InputAdornment position="end">
                <InfoNameIcon />
              </InputAdornment>
            ),
          }}
        />
      </Wrapper>

      <Wrapper>
        <Title variant="h4">
          {t('components.Projects.GeneralInputs.Країна та валюта')}
        </Title>

        <Controller
          control={formState.control}
          name="residence"
          rules={{ required: InputsRules.required }}
          render={({ field ,}) => (
            <SelectCountries
              {...field}
              isSpaceBottom={20}
              label={t('components.Projects.GeneralInputs.Країна реєстрації бізнесу')} />
          )}
        />

        <Controller
          name="currency"
          control={formState.control}
          render={({ field }) => <Select {...field}
            label={t('components.Projects.GeneralInputs.Валюта проекту')}
            data={currency.map(function (item) {
              return {
                label: t(`utils.constants.currency.${item.label}`),
                value: item.value,
              }
            })}
            defaultValue={currency?.[2]?.value} />}
        />
      </Wrapper>
    </Container>
  )
};

export default GeneralInputs;
