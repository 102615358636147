import React from 'react';
import {Content, H2, P, Img, StyledLI, StyledUL} from "./styles";

import {
  Help1,
  Help2,
  Help3,
  Help4,
  Help5,
  Help6,
  Help7,
  Help8,
  Help9,
  Help10,
  Help11,
  Help12,
  Help13,
  Help14,
  Help15,
  Help16,
  Help17,
  Help18,
  Help19,
  Help20,
  Help21,
} from 'assets/images/help/uk';

const HelpPageUk = () => {
  return (
      <Content>
        <P>Дякуємо за те, що ви з нами! Проєкт Pipeliner наразі знаходиться на етапі тестування базового функціоналу. Тож
          ви маєте можливість першими долучитися до цього процесу. Ми будемо вдячні за зворотній зв’язок та ідеї щодо
          оптимізації наступних релізів.</P>
        <P>Наша поштова скринька завжди для цього відкрита <strong>hello@pipeliner.online</strong></P>
        <P>Також ви маєте можливість долучитися до нашого ком’юніті на фб, щоб першими дізнаватися про апдейти сервісу або
          приймати участь у дискусіях.</P>
        <P>Для нас це дуже важливо! <a target="_blank" href="https://www.facebook.com/pipeliner.online">Перейти до Pipeliner
          Community.</a></P>
        <H2 variant="h2">Користуючись сервісом, Ви зрозумієте:</H2>
        <P>- скільки грошей потрібно для початку бізнесу,</P>
        <P>- скільки часу знадобиться для повернення початкових вкладень,</P>
        <P>- які ціни на ваші товари чи послуги покриватимуть виробничі витрати та багато іншого.</P>
        <H2 variant="h2">Як користуватися сервісом.</H2>
        <H2 variant="h3">Створити Пайплайн</H2>
        <P>Після логіну ви попадаєте на сторінку вашого Профілю, де необхідно заповнити основу інформацію.</P>
        <Img src={Help1} />
        <P><strong>Сервіс має безкоштовний період 14 днів.</strong> Далі система запропонує вам перейти на платний тариф. Наразі
          доступний тільки базовий вартістю $7 на місяць. Сьогодні кожна оформлена підписка це велика підтримка, яка дозволяє
          нам рухатися далі!</P>
        <P>Після заповнення Профілю, Ви потрапите на головну сторінку Проєктів.</P>
        <Img src={Help2} />
        <P>Проєкти це фактично ваші бізнеси. Наприклад, у вас є пекарня. Це ваш Проєкт. Щоб його створити, потрібно натиснути
          “Створити новий проєкт”.</P>
        <Img src={Help3} />
        <P>Тут ви можете ввести назву проєкту (у нашому прикладі “Пекарня”), вказати країну походження, валюту та податкову
          інформацію, яка далі допоможе нам у розрахунках. Якщо ви ще не реєстрували бізнес, достатньо обрати “Ще не
          реєстрував”. Та натиснути “Створити проєкт”,</P>
        <P><strong>Для того щоб створити Пайплайн, Вам потрібно на сторінці Проєкту натиснути на “Створити новий
          пайплайн”.</strong></P>
        <Img src={Help4} />
        <P>Пайплайн у нашому контексті це один з ймовірних сценаріїв розвитку вашого бізнесу.</P>
        <P>Далі вам потрібно ввести назву вашого Пайплайну.</P>
        <Img src={Help5} />
        <P>У наступних кроках наш тьютор допоможе вам описати ваш бізнес.</P>
        <P>Поки що створити новий пайплайн можна лише за допомогою тьютора, тож залиште галочку.</P>
        <H2 variant="h3" id="team">Команда</H2>
        <P>На першому екрані тьютору потрібно описати вашу команду.</P>
        <Img src={Help6} />
        <P>Усі співробітники мають кілька загальних параметрів:</P>
        <StyledUL>
          <StyledLI>Ім’я</StyledLI>
          <StyledLI>Підрозділ до якого він належить у компанії</StyledLI>
          <StyledLI>Сума заробітної платні (або місячна, або погодинна).</StyledLI>
        </StyledUL>
        <P><strong>Адміністрація</strong> це тип ваших співробітників, яким Ви виплачуєте фіксовану зарплату на місяць та які не
          задіяні безпосередньо у виробництві вашої продукції (наприклад, бухгалтер, менеджер з постачання тощо.)</P>
        <P><strong>Команда</strong> це тип ваших співробітників, які безпосередньо задіяні у виробництві вашої продукції або
          сервісів. В них може бути або погодинна або фіксована заробітна платня.</P>
        <P><strong>Контрагенти</strong> це зовнішні партнери, які допомагають у створенні або реалізації продукції або сервісів.
          В них також може бути погодинна або фіксована заробітна платня. </P>
        <P>Важливо, що погодинна рахується як сума помножена на відпрацьовані години. А фіксована враховує ще податки за схемою
          сума + ппфо + військовий збір + соціальний внесок.</P>
        <P>Щоб додати співробітника, потрібно обрати “Додати.. ”, ввести ім’я, обрати з шаблонів або створити свій департамент,
          вести суму та обрати тип платні. Щоб перейти далі, потрібно натиснути “Наступний крок”. </P>
        <P>Якщо кнопка неактивна, це значить що є не заповнені дані.Зайві типи співробітників ми можете видалити.</P>
        <P><strong>Пам’ятайте!</strong> Ви у будь який час можете зберегти та залишити тьютор (іконка з дискеткою), у цьому
          випадку Ваш пайплайн потрапить у Чернетки, де щоб продовжити його заповнення потрібно буде навести на три крапки у
          правому верхньому куті картки пайплайну та натиснути “Редагувати”.</P>
        <P>Або ви можете обрати “Залишити тьютор”, тоді <strong>пайплайн буде видалено</strong>.</P>

        <H2 variant="h3" id="product-service">Продукт або сервiс</H2>
        <P>Вам потрібно ввести назви ваших продуктів або сервісів.</P>
        <Img src={Help7} />
        <P>Важливо що тут Ви можете обрати два типи – це Продукт або Сервіс. У першому випадку мається на увазі фізичний
          продукт, який складається з якихось складових, матеріалів тощо. У другому випадку Сервіс передбачає лише розрахунок
          людино-годин.</P>
        <H2 variant="h3" id="production-costs">Витрати на виробнитство</H2>
        <P>Для розрахунку собівартості Вам потрібно пройти два кроки. На першому – описати витрати на матеріали.</P>
        <Img src={Help8} />
        <P>Цей підрозділ складається з,</P>
        <StyledUL>
          <StyledLI>назва матеріалу,</StyledLI>
          <StyledLI>одиниця його виміру,</StyledLI>
          <StyledLI>скільки цього матеріалу йде на 1 продукт,</StyledLI>
          <StyledLI>скільки коштує одиниця цього матеріалу (наприклад якщо ви купуєте метрами або кілограмами).</StyledLI>
        </StyledUL>
        <H2 variant="h3" id="packaging-logistics-costs">Витрати на упаковку і логістику</H2>
        <P>На цьому кроку вам потрібно ввести додаткові витрати на логістику або упаковку вашого товару. Якщо їх немає, ці
          поля можна видалити, натиснувши хрестик.</P>
        <Img src={Help9} />
        <h3 id="man-hours">Людино-години</h3>
        <P>Для повного розрахунку виробничої собівартості вам потрібно вказати скільки і хто з вашої компанії витрачає часу на
          виробництво кожної одиниці продукції.</P>
        <Img src={Help10} />
        <P>Наприклад, щоб зробити один стілець майстру потрібно витратити 3 години у майстерні. Щоб додати години вам потрібно
          обрати департамент, далі розгорнути вкладинку та натиснути “Додати співробітника”. </P>
        <Img src={Help11} />
        <P>У меню обрати співробітника та вказати кількість робочого часу, яку він витрачає на виробництво однієї одиниці
          продукції.</P>
        <H2 variant="h3" id="fixed-expenses">Постійні витрати</H2>
        <P>Окрім витрат на виробництво, Ви також маєте непрямі витрати, тож у цьому розділі потрібно їх описати – на що ваша
          команда витрачає час ще для підтримки комерційної діяльності компанії.</P>
        <P><strong>Не враховуйте час безпосереднього виробництва продукції чи послуг! Це може бути оренда майстерні, інтернет,
          підписки тощо.</strong></P>
        <Img src={Help12} />
        <P>Щоб завершити та перейти до Дашборду, потрібно натиснути “А ну, подивимось”.</P>
        <H2 variant="h2">Дашборд або інформаційна панель.</H2>
        <H2 variant="h3">Огляд показників</H2>
        <P>Огляд показників дозволяє аналізувати кожний продукт в контексті загальної операційної діяльності. Для того щоб
          побачити розрахунки та почати працювати з даними, які були введені у тьюторі, потрібно вказати:</P>
        <StyledUL>
          <StyledLI>ціну продажу,</StyledLI>
          <StyledLI>заплановану кількість продажів на місяць.</StyledLI>
        </StyledUL>
        <Img src={Help13} />
        <P>Складові частини <strong>Огляду показників</strong>:</P>
        <StyledUL>
          <StyledLI>Назва товару або сервісу,</StyledLI>
          <StyledLI>Ціна продажу,</StyledLI>
          <StyledLI>Обсяг продажів на міс.</StyledLI>
          <StyledLI>Собівартість (розраховується автоматично).</StyledLI>
        </StyledUL>
        <P>Блок <strong>Коротко про головне</strong> розраховується автоматично та складається з:</P>
        <StyledUL>
          <StyledLI><strong>Дохід за місяць</strong> – це загальна сума доходу, яку, за оцінками, ви отримуєте за місяць на основі
            ціни продажу та обсягу, який ви ввели.
          </StyledLI>
          <StyledLI><strong>Накладні витрати на місяць</strong> – це витрати, які не змінюються залежно від обсягу продукції та
            послуг, які ви виробляєте, але ви повинні платити регулярно.
          </StyledLI>
          <StyledLI><strong>Матеріальні витрати на місяць</strong> – це сума матеріальних витрат, які ви можете заплатити, виходячи
            з витрат на виробнитство та очікуваних продажів, які ви ввели для кожного товару.
          </StyledLI>
          <StyledLI><strong>Загальна заробітна плата, що виплачується на місяць</strong> – це загальна сума грошей, яка буде
            виплачуватися заробітною платою за місяць.
          </StyledLI>
          <StyledLI><strong>Податки та збори</strong> – розраховуються залежно від суми введеної у “Податкова ставка”</StyledLI>
          <StyledLI><strong>Загальний прибуток, зароблений на місяць</strong> – це сума грошей, яку ви заробляєте після сплати всіх
            ваших щомісячних витрат, матеріальних витрат та заробітної плати.
          </StyledLI>
        </StyledUL>
        <H2 variant="h2">План продажів</H2>
        <P>Дозволяє вам прогнозувати відхилення від плану, що Ви вказали у Огляді показників як планована Кількість
          продажів на місяць. План дозволяє у абсолютних або відносних одиницях моделювати різні сценарії.</P>
        <Img src={Help14} />
        <H2 variant="h2">Блок аналітики</H2>
        <H2 variant="h3">Графік Дохід за продуктом vs витрати за продуктом</H2>
        <P>Показує співвідношення структури витрат до доходу з кожного продукту. Дозволяє оптимізувати витрати.</P>
        <Img src={Help15} />
        <H2 variant="h3">Аналіз структури доходів</H2>
        <P>Показує скільки займає кожний продукт в загальному об’єму прибутку.</P>
        <Img src={Help16} />
        <H2 variant="h3">Аналіз структури витрат</H2>
        <P>Показує структуру витрат по підрозділах. </P>
        <H2 variant="h3">Прибуток та збитки</H2>
        <P>Ця таблиця показує, скільки грошей компанія планує заробляти щороку. Передбачається, що Ви заплатили собі за
          відпрацьовані години, тому &quot;Чистий дохід&quot; внизу – це решта прибутку, отриманого вашою компанією. Ця
          таблиця також корисна для показу вашому банку або включення до заявок на гранти.</P>
        <P><strong>Розраховується автоматично.</strong></P>
        <Img src={Help17}/>
        <H2 variant="h3"><strong>Грошовий потік або Кешфлоу</strong></H2>
        <P>Аналіз грошових потоків показує, чи ви маєте протягом першого року достатньо грошей, щоб придбати матеріали,
          заплатити своїм працівникам або зробити інвестицію. </P>
        <P><strong>Розраховується автоматично.</strong></P>
        <Img src={Help18} />
        <H2 variant="h3"><strong>Продажі за місяцями</strong></H2>
        <P>Структура витрат та прибутків у контексті продажів. Дає можливість подивитись що саме дозволяє Вам заробляти або
          втрачати у контексті продажів.</P>
        <Img src={Help19} />
        <H2 variant="h2"><strong>Порівняння Пайплайнів</strong></H2>
        <P>Щоб поівняти Пайплайни, потрібно натиснути кнопку порівняня, яка заходиться у правому нижньому куті кожної картки
          Пайпалйна. </P>
        <P><strong>Важливо!</strong> Порівняти можна тільки два Пайплайни.</P>
        <Img src={Help20} />
        <P>Щоб відмінити порівняння, треба натиснути на кнопку “Припинити порівняння”. Щоб перейти до порівняння, потрібно
          натиснути на “Порівняти сценарії”.</P>
        <Img src={Help21} />
        <P>Для порівняння наразі доступні три параметри: прибуток, валовий дохід та дохід.</P>
        <P>Щоб повернутися до екрану з Пайплайнами, потрібно натиснути “Припинити порівняння”. </P>
      </Content>
  )
}

export default HelpPageUk;
