import { styled } from '@mui/material/styles';

export const Container = styled('div')`
  overflow-y: auto;
  height: calc(100vh - 60px);
  padding: 0 30px;
  background-color: ${({ theme }) => theme?.palette?.custom?.grey?.darkLite};
`;

export const Wrapper = styled('div')`
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
`;

export const WrapperButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
