import { styled } from '@mui/material/styles';
import { ToggleButtonGroup, ToggleButton, Box } from '@mui/material';

export const Container = styled(ToggleButtonGroup)`
  height: fit-content;
  background-color: ${({ theme }) => theme?.palette?.primary.contrastText};
  width: fit-content;
  border: 1px solid #EBEBEB;
  border-color: ${({ theme }) => theme?.palette?.primary?.light};
`;

export const Button = styled(ToggleButton)`
  && {
    width: fit-content;
    height: 40px;
    padding: 10px 20px;
    min-height: 32px;
  }

  &.Mui-selected {
    color: ${({ theme }) => theme?.palette?.primary.contrastText};
  }
`;
