import { styled, css } from '@mui/material/styles';
import {
  ListItem,
  ListItemButton,
} from '@mui/material';

interface ButtonProps {
  $forDelete: boolean
}

export const MenuListItem = styled(ListItem)`
  margin: 0;
  padding: 0;
`;

export const MenuListItemButton = styled(ListItemButton)<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  padding-left: 13px;
  padding-right: 13px;
  &:hover {
    background: ${({ theme }) => theme.palette.custom.grey.menuHover};
  }
  &:focus, &.selected {
    border: 1px solid ${({ theme }) => theme.palette.custom.green.main};
  }
  &:hover .MuiTypography-root {
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  ${({ $forDelete, theme }) => ($forDelete && css`
    background: ${theme.palette.custom.red.menuHover};
  `)};
`;
