import { FC } from 'react';
// Icons
import { DownloadIcon } from 'assets/icons';
// Components
import IconButton from 'components/UI/Buttons/IconButton/IconButton';
import {IPlanSales, IPlanSalesPipeline} from "store/dashboard/dashboardTypes";
import { IProduct } from 'interfaces/product';
import ProfitTableBody from './ProfitTableBody';
// Styles
import { Container, Wrapper } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ProfitByMonthProps {
  products?: IProduct[],
  plan?: IPlanSales[],
  planByMonthes?: IPlanSalesPipeline[],
  currency?: string,
}
const ProfitTable: FC<ProfitByMonthProps> = ({products, plan, planByMonthes, currency}) => {
  return (
    <Container>
      <Wrapper >
        <ProfitTableBody currency={currency as string} products={products} plan={plan} planByMonthes={planByMonthes} />
      </Wrapper>
    </Container>
  )
}

export default ProfitTable;
