import React, { FC } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
// Store
import { deleteProject } from 'store/projects/projectSlice';
import { useCreateProjectMutation } from 'store/projects/projectsServices';
import { ICreateProject } from 'store/projects/projectsTypes';
// Utils
import { defaultValues } from 'utils/constants/add-project';
// Hooks
import { useProjectRoutes } from 'hooks/useProjectRoutes';
// Componetns
import Loading from 'components/UI/Loading';
import Footer from 'components/Footer';
import GeneralInputs from 'components/Projects/GeneralInputs';
import TaxesInputs from 'components/Projects/TaxesInputs';
import History from './History';
// Styles
import {
  Container,
  Title,
  Form,
  Wrapper,
  ContainerButton,
  Button,
} from './styles';
// Translation
import { useTranslation, } from 'react-i18next';
import { addEvent } from '../../store/analytics/analyticsService';
import type { SegmentEventPayload } from '../../store/analytics/analyticsTypes';
import { SEGMENT_EVENT_NAME } from '../../utils/constants/analytics';
import { useAuth } from '../../hooks/useAuth';

const AddProject: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();

  const formState = useForm<ICreateProject>({ defaultValues: defaultValues, mode: 'all' });
  const { formState: { isValid }, watch } = formState;
  const watchCountry = watch('residence');
  console.log(watchCountry);
  const { redirectToProjects } = useProjectRoutes();
  const [createProject, { isLoading }] = useCreateProjectMutation();

  const onSubmit: SubmitHandler<ICreateProject> = async (values) => {
    try {
      const {
        currency, project_name, tax_group, tax_system_type, tax_type, residence
      } = values;

      const response = await createProject({
        currency,
        project_name,
        tax_group,
        tax_system_type,
        tax_type,
        monthly_hour_limit: 160,
        residence,
      }).unwrap();

      if (response?.project_id) {
        const eventPayload: SegmentEventPayload = {
          eventName: SEGMENT_EVENT_NAME.PROJECT_CREATED,
          payload: { email: user?.email },
        };
        dispatch(deleteProject(null));
        dispatch(addEvent(eventPayload));
        redirectToProjects();
      }
    } catch (err) {
      formState.setError('project_name', {
        type: 'custom',
        message: 'Помилка сервера',
      });
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Title variant="h1">
        {t('pages.AddProject.Створення проекту')}
      </Title>

      <Form>
        <Wrapper isSpace={30}>
          {/*<UploadFile />*/}

          <GeneralInputs
            formState={formState}
            pipelineName= {t('Потрібно створити перший пайплайн')}
          />
        </Wrapper>

        {
          watchCountry === 'UA' ?
            <Wrapper isSpace={30}>
              <TaxesInputs formState={formState} />
            </Wrapper>
            : ''
        }

        <Wrapper sx={{ display: 'none' }}>
          <History />
        </Wrapper>

        <ContainerButton>
          <Button
            onClick={formState.handleSubmit(onSubmit)}
            disabled={!isValid}
            variant="contained"
          >
            { t('pages.AddProject.Створити проект') }
          </Button>

          <Button
            onClick={redirectToProjects}
            variant="light"
          >
            { t('pages.AddProject.Скасувати') }
          </Button>
        </ContainerButton>
      </Form>

      <Footer isSpace />
    </Container>
  );
};

export default AddProject;
