import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.black?.main};
`;

export const Wrapper = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
`;
