import {styled} from '@mui/material/styles';
import {Button, Typography} from "@mui/material";

export const PageWrapper = styled('div')`
  display: flex;  
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ImgWrap = styled('div')`
  max-width: 700px;
`;

export const ContentWrap = styled('div')`
  
`;

export const Content = styled(Typography)`
  margin-bottom: 30px;
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  font-size: 30px;
`;

export const Image = styled('img')`
  width: 100%;
  max-width: fit-content;
  height: fit-content;
  object-fit: contain;
`;
