import { styled, css } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface CircleProps {
  color?: string;
  isMargin?: number;
}

export const Container = styled('div')`
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  width: 355px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.06);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
`;

export const ContainerHeader = styled(Box)`
  width: 100%;
  max-width: 412px;
  border-bottom: 1px solid ${({ theme }) => theme?.palette?.primary?.main};
`;

export const Header = styled(Typography)`
`;

export const Prise = styled(Typography)`
  color: #D52727;
`;

export const TooltipRow = styled('div')`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const Circle = styled('div')<CircleProps>`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  margin-right: 5px;
  ${({ color }) => (color && css`
    background: ${color};
  `)};
`;

export const Title = styled(Typography)`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #292929;
  margin-left: 10px;
`;

export const Label = styled(Typography)<CircleProps>`
  font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ color, }) => color || '#D52727'};
  margin-left: ${({ isMargin, }) => `${isMargin}px` || 0};
`;
