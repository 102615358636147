import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

export const Container = styled('div')`
  width: 100%;
  background-color: red;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 50px;
  height: fit-content;
  padding: 0 30px;
  max-width: 1122px;
  padding-bottom: 30px;

  .MuiTabs-indicator {
    background-color: #07B169;
    top: 0;
  }
`;

export const TitleTab = styled(Tab)`
 font-family: 'Visuelt Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A4A4A4;
  text-transform: inherit;

  &.Mui-selected {
    font-family: 'Visuelt Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
  }

  &.Mui-focusVisible {
    background-color: #07B169;
  }
`;

export const Wrapper = styled('div')`
  width: 100%;
`;
