import { styled } from '@mui/material/styles';
import {Paper, Table} from '@mui/material';

export const StyledPaper = styled(Paper)`
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
`;

export const StyledTable = styled(Table)`
  border-collapse: revert;
  padding-top: 20px;
`;
