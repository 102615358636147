import React, { FC } from 'react';
// Components
import Tooltip from 'components/UI/Tooltip';
import { InfoIcon } from 'assets/icons';
// Styles
import { H1 } from './styles';
// Translation
import { useTranslation, } from 'react-i18next';

const PipelinesTitleSection: FC = () => {
  const { t } = useTranslation();
  return (
    <H1 variant="h1">
      {t('Ваші пайплайни')}
      <Tooltip tooltipText={t('Ваші пайплайни')}>
        <InfoIcon />
      </Tooltip>
    </H1>
  )
};

export default PipelinesTitleSection;
