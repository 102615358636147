import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const Container = styled('div')`
  margin: 0 auto 30px;
  max-width: 1122px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PaginationButton = styled(Button)`
  &.hidden {
    visibility: hidden;
  }
  && {
    height: 48px;
    svg {
      margin-right: 17px;
    }
  }
`;
