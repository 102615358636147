import { IProduct } from 'interfaces/product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
// Constants
import {
  EMPLOYEES_DEFAULT_VALUES, PRODUCT_DEFAULT_VALUES, PLAN_MONTH, calcPlanMonth,
} from 'utils/constants/tutor';
import { calcEmployeeHoursByProduct } from 'utils/calculates/summary-table';
import { dashboardServices } from './dashboardServices';
import { updateDataDiagram } from 'utils/calculates/diagram-income-expenses';
// import { TutorState, IEmployee, IProduct } from './tutorTypes';
import { DashboardState, IPipeline } from './dashboardTypes';

const initialState: DashboardState = {
  pipeline: [],
  products: [],
  planSales: [],
  employees: [],
  productsExpenses: [],
  salesPlanPipeline: [],
  salesPlanTable: [],
  comparePipelines: [],
  residence: '',
};

export const dashboardSlice = createSlice({
  name: 'dashboardCalculate',
  initialState,

  reducers: {
    setProdacts: (state, action: PayloadAction<IProduct[]>) => {
      state.products = action.payload;
    },

    setPipelineCompare: (state, action: PayloadAction<IPipeline | string>) => {
      if (typeof action.payload === 'string') {
        const filteredPipeline = state.comparePipelines?.filter(item => item.pipeline_id !== action.payload)

        state.comparePipelines = filteredPipeline;

        return
      }

      state.comparePipelines = [...state.comparePipelines, action.payload];
    },

    resetCompare: (state) => {
      state.comparePipelines = [];
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardServices.endpoints.getPipelineById.matchFulfilled,
      (state, { payload }) => {
        state.pipeline = payload;

        if (payload.products?.length) {
          state.products = payload.products;
        }

        if (payload.employees?.length) {
          state.employees = payload.employees;
        }
      },
    );

    builder.addMatcher(
      dashboardServices.endpoints.getSalesPlanProducts.matchFulfilled,
      (state, { payload }) => {
        if (payload?.length) {
          state.planSales = payload;
        }
      },
    );

    builder.addMatcher(
      dashboardServices.endpoints.getSalesPlan.matchFulfilled,
      (state, { payload }) => {
        if (payload?.length) {
          state.salesPlanPipeline = updateDataDiagram(payload, state.residence);
          state.salesPlanTable = payload;
        }
      },
    );

    builder.addMatcher(
      dashboardServices.endpoints.getProjectById.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          state.residence = payload.residence
        }
      },
    );
  },
});

export const {
  setProdacts,
  setPipelineCompare,
  resetCompare,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectDashboard = (state: RootState) => state.dashboardCalculate;
