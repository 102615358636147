import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Input from "components/UI/Input";

export const H1 = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.grey?.dark};
  display: flex;
  align-items: center;
  & > * {
    margin-left: 10px;
  }
`;

export const HeaderRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const HeaderRightBlock = styled('div')`
  display: flex;
  align-items: center;

  .search-input {
    height: 48px;
  }

  .toolbar-btns {
    margin-right: 8px;
  }

  .filter-btn {
    min-width: 210px;
  }

  .create-btn {
    min-width: 117px;
    display: flex;
    align-items: center;
    .MuiButton-startIcon {
      height: 13px;
    }
  }

  & > * {
    margin-right: 30px;
  }
`;

export const DatePickerWrapper = styled('div')`
  position: relative;
  width: fit-content;
  .react-datepicker__input-container input {
    height: 49px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 8px;
    padding-left: 20px;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
`;

export const IconWrap = styled('a')`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 14px;
  height: 49px;
  display: flex;
  align-items: center;
`;

export const SettingsLink = styled('a')`
  display: flex;
  align-self: center;
`;
