import { createTheme } from '@mui/material/styles';

import { muiCssBaseline } from './components/muiCssBaseline';
import { palette } from './palette';
import { typography } from './typography';

import {
  muiButton,
  muiInput,
  muiTextField,
  muiSelect,
  muiCheckbox,
  muiRadioButton,
  muiToggleButton,
  muiToggleButtonGroup,
  muiTooltip,
} from './components';

export const theme = createTheme({
  palette,
  typography,
});

export const defaultTheme = createTheme({
  ...theme,
  components: {
    MuiCssBaseline: muiCssBaseline(),
    MuiButton: muiButton(theme),
    MuiCheckbox: muiCheckbox(theme),
    MuiRadio: muiRadioButton(theme),
    MuiInput: muiInput(theme),
    MuiTextField: muiTextField(theme),
    MuiSelect: muiSelect(theme),
    MuiToggleButton: muiToggleButton(theme),
    MuiToggleButtonGroup: muiToggleButtonGroup(theme),
    MuiTooltip: muiTooltip(theme),
  },
});
