import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme?.palette?.custom?.primary?.silver};
  text-align: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
